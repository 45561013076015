import { Input, notification } from "antd";
import CopyIcon from "assets/icons/copy.svg?react";
import { ConfirmationModal } from "components/common/ConfirmationModal";
import { useCallback, useState } from "react";
import { ModalScopesEnum, ModalTypesEnum } from "reducers/modalsReducer";
import { useDuplicateBlueprint } from "selectors/useBlueprints";
import { useModal } from "selectors/useModals";

export const DuplicateBlueprintModal: React.FC = () => {
    const { data: blueprint, hide } = useModal<IceBreaker>(ModalScopesEnum.BLUEPRINTS, ModalTypesEnum.DUPLICATE);
    const [originalName] = useState(blueprint.name);
    const [name, setName] = useState(blueprint.name);
    const [processing, setProcessing] = useState(false);
    const duplicate = useDuplicateBlueprint(blueprint.id);

    const onConfirm = useCallback(async () => {
        setProcessing(true);
        await duplicate(name);

        notification.open({
            icon: <CopyIcon />,
            message: <strong>Blueprint duplicated</strong>,
            description: <>Copy of <strong>{originalName}</strong> was created.</>
        });

        hide();
        setProcessing(false);
    }, [duplicate, hide, name, originalName]);

    return <ConfirmationModal
        key={"blueprint::duplicate"}
        confirmationButton={processing ? "Duplicating" : "Duplicate"}

        isOpen
        title={"Duplicate blueprint"}
        description={<>
            Blueprint name
            <Input defaultValue={originalName} onChange={e => setName(e.target.value)} />
        </>}
        onCancel={hide}
        onConfirm={onConfirm}
        processing={processing}
    />
}