import { Layout, message } from 'antd';
import { AgentFormData, AgentView } from 'components/agent-view/AgentView';
import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveAgent, useCreateAgent } from 'selectors/useAgents';
import styles from './Layout.module.scss';

export const NewAgentPage: React.FC = memo(() => {
  const createAgent = useCreateAgent();
  const navigate = useNavigate();
  const agent = useActiveAgent();

  const initialValues: Partial<AgentFormData> = {
    name: '',
    agent: '',
    description: '',
    agentParams: {
      modelName: '',
      instructions: '',
      memoriesPath: '',
      modelProvider: '',
      specializations: [],
    },
  };

  const onSubmit = useCallback(
    async (values: AgentFormData) => {
      const newAgent = await createAgent({
        ...values,
        agent: values.agent || 'prism-1',
      });
      navigate(`/agents/${newAgent.id}/edit`);

      message.success('Agent created successfully');
    },
    [createAgent, navigate],
  );

  return (
    <Layout className={styles.blueprintPage}>
      <AgentView agent={agent} edit onSubmit={onSubmit} initialValues={initialValues} isNew={true} />
    </Layout>
  );
});
