import { Flex, Popover, Typography } from 'antd';
import React, { FC } from 'react';
import styles from './MultipleElements.module.scss';

type MultipleLabelsProps = {
  children: React.ReactNode[];
  expanded?: number;
};
export const MultipleElements: FC<MultipleLabelsProps> = ({ children, expanded }) => {
  const showedLabels = expanded ? children.slice(0, expanded) : children.slice(0, 1);
  const rest = children.length - showedLabels.length;
  const restLabels = rest > 0 ? children.slice(showedLabels.length) : [];

  return (
    <Flex gap={12} align="center">
      {showedLabels}
      {rest > 0 && (
        <Popover
          color="var(--nri-color-white)"
          overlayClassName={styles.popover}
          content={
            <Flex wrap="wrap" gap="8px 12px" align="start" className={styles.elementsContainer}>
              {restLabels}
            </Flex>
          }
        >
          <Typography.Text className={styles.tooltipText}>+{rest}</Typography.Text>
        </Popover>
      )}
    </Flex>
  );
};
