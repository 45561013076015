import { App as AntdApp, ConfigProvider } from 'antd';
import React, { createContext, memo, PropsWithChildren, useContext, useEffect } from 'react';
import { useStreamListener } from 'selectors/useAuthSelector';
import { Actions, AppActionTypes } from 'types/actionTypes';
import { nriTheme } from '../nriTheme';
import { AppState, initialAppState, useCombinedReducer } from '../reducers/rootReducer';
import { AuthProvider, useAuth } from './AuthContext';
import { useActivityMonitor } from 'hooks/useActivityMonitor';
import { UIProvider } from './UIContext';
import { Loader } from 'components/loader/Loader';

type AppContextProps = {
  state: AppState;
  dispatch: React.Dispatch<Actions>;
};

const AppContext = createContext<AppContextProps | undefined>(undefined);

const AppProvider: React.FC<PropsWithChildren> = memo(({ children }) => {
  const authState = useAuth();
  const [state, dispatch] = useCombinedReducer(initialAppState, authState.state);
  const { startListener, isConnected } = useStreamListener(dispatch);

  useActivityMonitor({
    onInactivityTimeout: () => {
      window.location.href = '/login';
    },
  });

  useEffect(() => {
    dispatch({ type: AppActionTypes.READY });
  }, []);

  useEffect(() => {
    startListener();
  }, [startListener]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <>
        {children}
        <Loader fullscreen spinning={!isConnected} />
      </>
    </AppContext.Provider>
  );
});

const AppProviders: React.FC<PropsWithChildren> = memo(({ children }) => {
  return (
    <ConfigProvider theme={nriTheme}>
      <AntdApp component="div">
        <AuthProvider>
          <AppProvider>
            <UIProvider>{children}</UIProvider>
          </AppProvider>
        </AuthProvider>
      </AntdApp>
    </ConfigProvider>
  );
});

export default AppProviders;

// eslint-disable-next-line react-refresh/only-export-components
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return context;
};
