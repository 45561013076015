import { ConfigProvider, Menu } from 'antd';

import AccountIcon from '../../assets/iconsNewVersion/account.svg?react';
import LogoutIcon from '../../assets/iconsNewVersion/log_out.svg?react';
import styles from './MainMenu.module.scss';
import { FC } from 'react';
import { cx } from 'utils/cx';
import { useNavigate } from 'react-router-dom';

type SecondaryMenuProps = {
  collapsed: boolean;
  logout: () => void;
  selectedPathname: string;
  user: UserEntity | undefined;
};
type SecondaryMenuItems = {
  id: string;
  icon: JSX.Element;
  path: string;
  title: string;
  action: () => void;
  disabled?: boolean;
};
export const SecondaryMenu: FC<SecondaryMenuProps> = ({ collapsed, logout, selectedPathname, user }) => {
  const navigate = useNavigate();
  const secondaryMenuItems: SecondaryMenuItems[] = [
    {
      id: 'account',
      disabled: !user?.isSuperuser,
      icon: <AccountIcon />,
      path: '/account',
      title: 'Account',
      action: () => navigate('/account'),
    },
    {
      id: 'logout',
      disabled: false,
      icon: <LogoutIcon />,
      path: '/logout',
      title: 'Log out',
      action: () => logout(),
    },
  ];
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            collapsedIconSize: 24,
            itemActiveBg: 'var(--nri-color-disabled-bg)',
            itemColor: 'var(--nri-color-purple-grey)',
            itemHeight: 48,
            itemMarginBlock: 0,
            itemMarginInline: 0,
            itemPaddingInline: 12,
          },
        },
      }}
    >
      <Menu
        className={cx(styles.secondaryMenu, {
          [styles.secondaryMenuBg]: !collapsed,
        })}
        mode="inline"
        selectedKeys={[selectedPathname]}
        items={secondaryMenuItems.map(({ id, disabled, icon, action, title, path }) => ({
          className: cx(styles.item, {
            [styles.active]: path === selectedPathname,
          }),
          disabled,
          icon,
          key: id,
          onClick: () => action(),
          label: title,
          title: disabled ? `${title} coming soon` : title,
        }))}
      />
    </ConfigProvider>
  );
};
