import { Flex, Typography } from 'antd';
import { FC } from 'react';
import styles from './EntityList.module.scss';
import PlusIcon from 'assets/iconsNewVersion/plus.svg?react';
type NewEntityButtonProps = {
  entityName: string;
  onClick: () => void;
};

export const NewEntityButton: FC<NewEntityButtonProps> = ({ entityName, onClick }) => {
  return (
    <Flex className={styles.newEntityButton} gap="8px" justify="center" align="center" onClick={onClick}>
      <PlusIcon /> <Typography.Text className={styles.newEntityButtonText}>Add {entityName}</Typography.Text>
    </Flex>
  );
};
