import { Tag, Typography } from 'antd';
import CreditsIcon from 'assets/iconsNewVersion/diamond.svg?react';
import styles from './UsageTag.module.scss';
import { FC } from 'react';

type UsageTagProps = {
  credits: number;
  description?: boolean;
  subscription?: number;
};

export const UsageTag: FC<UsageTagProps> = ({ credits, description, subscription }) => {
  return (
    <Tag className={styles.tag} icon={<CreditsIcon className={styles.creditsIcon} />}>
      <Typography.Text className={styles.credits}>{credits}</Typography.Text>
      {description && <Typography.Text className={styles.description}>Credits used</Typography.Text>}
      {subscription && (
        <>
          <Typography.Text className={styles.subscription}>
            / ${subscription}{' '}
            <Typography.Text className={styles.subscriptionInfo}>Monthly subscription</Typography.Text>
          </Typography.Text>
        </>
      )}
    </Tag>
  );
};
