import { FC } from 'react';
import MoreIcon from 'assets/iconsNewVersion/more.svg?react';
import EditIcon from 'assets/iconsNewVersion/edit.svg?react';
import DesactivateIcon from 'assets/iconsNewVersion/desactivate.svg?react';
import TrashIcon from 'assets/iconsNewVersion/trash.svg?react';
import CheckIcon from 'assets/iconsNewVersion/check.svg?react';
import { ActionsMenu } from 'ui/ActionsMenu';
import { useToggleMemoryStatus } from 'selectors/userMemories';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';

type MemoriesDropdownProps = {
  memory: Memory;
};

export const MemoriesDropdown: FC<MemoriesDropdownProps> = ({ memory }) => {
  const toggleMemoryStatus = useToggleMemoryStatus();
  const { show: showEditMemoryModal } = useModal<{
    memory: Memory;
  }>(ModalScopesEnum.MEMORIES, ModalTypesEnum.EDIT);
  const { show: showDeleteModal } = useModal<{
    memory: Memory;
  }>(ModalScopesEnum.MEMORIES, ModalTypesEnum.DELETE);

  const items = [
    {
      key: 'edit',
      label: 'Edit',
      icon: EditIcon,
      color: 'var(--nri-color-primary)',
      iconColor: 'var(--nri-color-purple-grey)',
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: TrashIcon,
      color: 'var(--nri-color-primary)',
      iconColor: 'var(--nri-color-purple-grey)',
    },
    {
      key: 'activate',
      label: 'Activate',
      icon: CheckIcon,
      color: 'var(--nri-color-primary)',
      iconColor: 'var(--nri-color-purple-grey)',
    },
    {
      key: 'deactivate',
      label: 'Deactivate',
      icon: DesactivateIcon,
      color: 'var(--nri-color-primary)',
      iconColor: 'var(--nri-color-purple-grey)',
    },
  ].filter(item => (memory.isEnabled ? item?.key !== 'activate' : item?.key !== 'deactivate'));

  const handleMemoryAction = (event: string, memory: Memory) => {
    if (event === 'delete') {
      showDeleteModal({ memory });
    }
    if (event === 'edit') showEditMemoryModal({ memory });
    if (event === 'activate') {
      toggleMemoryStatus(memory.id as Memory['id'], memory.isEnabled!);
    }
    if (event === 'deactivate') {
      toggleMemoryStatus(memory.id as Memory['id'], memory.isEnabled!);
    }
  };
  return (
    <ActionsMenu
      items={items}
      onSelect={({ key }) => {
        handleMemoryAction(key, memory);
      }}
    >
      <MoreIcon style={{ width: '16px', height: '16px' }} />
    </ActionsMenu>
  );
};
