import { Flex, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
// import { AgentActionsMenu } from 'components/agent-actions-menu/AgentActionsMenu';
import { EntityTableProps } from 'components/entity-list/EntityList';
import { EntityTable } from 'components/entity-list/EntityTable';
import { FavoriteToggle } from 'components/favorite-toggle/FavoriteToggle';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type AgentTableProps = EntityTableProps<Analyst>;

export const AgentTable = ({ entities: agents, search }: AgentTableProps) => {
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (agent: Analyst) => {
      navigate(`/agents/${agent.id}`);
    },
    [navigate],
  );

  const columns: ColumnsType<Analyst> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      width: 15,
      render: (value, agent) => (
        <Flex align="center">
          {agent.isFavorite && <FavoriteToggle isFavorite={agent.isFavorite!} />}
          <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ margin: 0, color: 'var(--nri-color-primary)' }}>
            {value}
          </Typography.Paragraph>
        </Flex>
      ),
    },
    { key: 'description', dataIndex: 'description', title: 'Description', width: 35, ellipsis: true },
    {
      key: 'model',
      dataIndex: 'model',
      title: 'Model',
      width: 15,
    },
    { key: 'actions', title: '', width: 5 },
  ];

  return (
    <EntityTable<Analyst>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionMenu={_agent => <></>}
      columns={columns}
      entities={agents}
      search={search}
      onRowClick={handleRowClick}
    />
  );
};
