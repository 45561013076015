import { Flex, Tag } from 'antd';
import { useCallback } from 'react';
import styles from './DatasourceTags.module.scss';

type DatasourceTagsProps = {
  onUpdate?(datasources: DataSource[]): void;
  selected: DataSource[];
  locked?: DataSource[];
};

export const DatasourceTags = ({ locked, selected, onUpdate = () => {} }: DatasourceTagsProps) => {
  const remove = useCallback(
    (entity: DataSource, e: React.MouseEvent<HTMLElement>) => {
      // Prevent the default hiding behavior
      e.preventDefault();
      e.stopPropagation();

      const result = selected.filter(ds => ds.id !== entity.id);

      onUpdate(result);
    },
    [onUpdate, selected],
  );

  return (
    <Flex align="center">
      {locked?.map(ds => (
        <Tag key={ds.id} className={styles.tag} color="yellow">
          {ds.name}
        </Tag>
      ))}

      {selected?.map(ds => (
        <Tag key={ds.id} className={styles.tag} closeIcon color="purple" onClose={e => remove(ds, e)}>
          {ds.name}
        </Tag>
      ))}
    </Flex>
  );
};
