import { Grid, Col, Empty, Flex, Row, Space } from 'antd';
import styles from 'components/entity-list/EntityList.module.scss';
import { NewEntityCard } from 'components/entity-list/NewEntityCard';
import { Loader } from 'components/loader/Loader';
import { ProjectSelect } from 'components/project-select/ProjectSelect';
import { useCallback, useMemo, useState } from 'react';
import { RetrieveTypesEnum, ViewOptionsEnum } from 'types/enum';
import { InputSearch, InputSearchOptionType } from 'ui/InputSearch';
import { Segmented } from 'ui/Segmented';
import { formatDate } from 'utils/formatDate';
import { EntityViewOptions } from './EntityViewOptions';
import { NewEntityButton } from './NewEntityButton';
import { InputSearchOption } from 'ui/InputSearchOption';
const { useBreakpoint } = Grid;

/**
 * Standard list of props to be used for an entity card (Blueprint or Datasource)
 */
export type BaseEntityCardProps<Entity extends EntityBase> = {
  /** The entity to display in the card */
  entity: Entity;
  /** Whether the card is disabled */
  disabled?: boolean;
  /** Whether the card is selectable */
  isSelectable?: boolean;
  /** Whether the card is selected */
  isSelected?: boolean;
  /** The function to call when the card is clicked */
  onClick?(e: Entity): void;
  /** The search term to highlight in the card */
  search?: string;
};

export type EntityTableProps<Entity extends EntityBase> = {
  entities: Entity[];
  search?: string;
};

export type EntityListProps<T extends EntityBase> = {
  // FIXME, make this generic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardProps?: any;
  /** The number of columns to display in the card view */
  columns?: number;
  /** The components to use for the entity list */
  components: {
    Card: React.ComponentType<BaseEntityCardProps<T>>;
    Table: React.ComponentType<EntityTableProps<T>>;
  };
  /** The entities to display in the list */
  entities?: T[];
  /** The name of the entity */
  entityName: string;
  /** The options to filter the entities by */
  filterOptions?: { label: string; value: string }[];
  /** Whether the list is loading */
  loading?: boolean;
  /** The entities that are locked */
  locked?: T[];
  /** The action to display in the new entity card */
  newEntityAction?: string;
  /** The details to display for the new entity card */
  newEntityDetails?: React.ReactNode;
  /** A function to format the name of the new entity */
  newEntityNameFormat?(name: string): string;
  /** The callback to call when a card is clicked */
  onCardClick?(e: T): void;
  /** The callback to call when the filter changes */
  onFilterChange(val: string): void;
  /** The callback to call when a new entity is created */
  onNewEntity?(): void;
  /** Whether to show the project filter */
  projectFilter?: boolean;
  /** Whether the card should display a category */
  showCategoryOnCard?: boolean;
  /** Whether the card should display a favorite */
  showFavoriteOnCard?: boolean;
  /** Whether to show the empty placeholder */
  showEmptyPlaceholder?: boolean;
  /** The entities that are selected */
  selected?: T[];
  /** Whether to show the segments */
  showSegments?: boolean;
  /** Whether to show the view options */
  viewOptions?: boolean;
};

/**
 * Generic component for filterable entity list. Displays filters and can switch between card/table view
 */
export const EntityList = <T extends EntityBase>({
  cardProps = {},
  columns,
  components: { Card, Table },
  entities,
  entityName,
  filterOptions = [
    { label: 'All', value: RetrieveTypesEnum.ALL },
    { label: 'Favorite', value: RetrieveTypesEnum.FAVORITE },
    { label: 'Organization', value: RetrieveTypesEnum.ORGANIZATION },
    { label: `My ${entityName}s`, value: RetrieveTypesEnum.OWNED },
    { label: 'Shared with me', value: RetrieveTypesEnum.SHARED },
    { label: 'Recent', value: RetrieveTypesEnum.RECENT },
  ],
  loading,
  locked,
  newEntityAction,
  newEntityDetails,
  newEntityNameFormat,
  onCardClick,
  onFilterChange,
  onNewEntity,
  projectFilter = true,
  selected,
  showEmptyPlaceholder = true,
  showSegments = true,
  viewOptions = true,
}: EntityListProps<T>) => {
  const [search, setSearch] = useState<string>('');
  const [selectedProjectLabel, setSelectedProjectLabel] = useState<ProjectLabel | null>(null);
  const [typeAheadTerm, setTypeAheadTerm] = useState<string>('');
  const [view, setView] = useState<ViewOptionsEnum>(ViewOptionsEnum.CARD);

  const onSearch = useCallback((term: string) => setSearch(term), [setSearch]);
  const columnSpanCalc = useBreakpoint().xxl ? 6 : 8;
  const columnSpan = columns ? 24 / columns : columnSpanCalc;

  const filteredEntities: InputSearchOptionType<T>[] = useMemo(() => {
    if (!entities) return [];

    const list =
      selectedProjectLabel || search
        ? entities?.filter(entity => {
            const entityInProject = selectedProjectLabel
              ? entity.projectLabels.map(pl => pl.id).includes(selectedProjectLabel.id)
              : true;
            const entityInSearch = search
              ? entity.name.toLowerCase().includes(search.toLowerCase()) ||
                entity.description.toLowerCase().includes(search.toLowerCase())
              : true;

            return entityInProject && entityInSearch;
          })
        : entities;

    return list.map(ent => ({
      id: `entity-${ent.id}`,
      label: (
        <InputSearchOption
          title={ent.name}
          searchTerm={typeAheadTerm}
          subText={formatDate(ent.updatedAt, 'MM/dd/yy')}
        />
      ),
      meta: ent,
      value: ent.name,
    }));
  }, [entities, search, selectedProjectLabel, typeAheadTerm]);

  return (
    <Flex vertical gap={'16px'} style={{ width: '100%' }}>
      {showSegments && (
        <Flex className={styles.filterContainer} justify="space-between">
          <div style={{ flex: 0 }}>
            <Segmented
              defaultValue={RetrieveTypesEnum.ALL}
              // @ts-expect-error FIXME
              onChange={onFilterChange}
              options={filterOptions}
            />
          </div>
          <div>
            {search ? <span style={{ marginRight: '10px' }}>{filteredEntities?.length} Found</span> : null}
            <Space>
              {projectFilter && <ProjectSelect onClick={setSelectedProjectLabel} />}
              <InputSearch
                options={
                  entities
                    ? entities.map(entity => ({
                        id: `entity-${entity.id}`,
                        label: (
                          <InputSearchOption
                            title={entity.name}
                            searchTerm={typeAheadTerm}
                            subText={formatDate(entity.updatedAt, 'MM/dd/yy')}
                          />
                        ),
                        value: entity.name,
                      }))
                    : []
                }
                onFilter={setTypeAheadTerm}
                onOptionSelect={onSearch}
                // FIXME: Make responsive
                style={{ width: 240 }}
              />
              {viewOptions && <EntityViewOptions view={view} setView={setView} />}
            </Space>
          </div>
        </Flex>
      )}
      <>
        {loading === true || loading === null ? (
          <Loader />
        ) : (
          <div className={styles.entityListContainer}>
            <Row gutter={[20, 20]}>
              {showEmptyPlaceholder && filteredEntities?.length == 0 ? (
                <Flex align="center" justify="center" style={{ minHeight: '300px', width: '100%' }}>
                  <Empty
                    description={`No ${entityName}s`}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ margin: 0, padding: 0 }}
                  />
                </Flex>
              ) : view === ViewOptionsEnum.CARD ? (
                <>
                  {onNewEntity ? (
                    <Col span={columnSpan}>
                      <NewEntityCard
                        action={newEntityAction}
                        entityName={entityName}
                        formatName={newEntityNameFormat}
                        subText={newEntityDetails}
                        onClick={onNewEntity}
                      />
                    </Col>
                  ) : null}
                  {filteredEntities?.map(({ meta: entity }) =>
                    entity ? (
                      <Col key={entity.id} span={columnSpan}>
                        <Card
                          entity={entity}
                          disabled={!!locked?.find(i => i.id === entity.id)}
                          isSelectable={onCardClick !== undefined}
                          isSelected={!!selected?.find(i => i.id === entity.id)}
                          onClick={onCardClick}
                          search={search}
                          {...cardProps}
                        />
                      </Col>
                    ) : null,
                  )}
                </>
              ) : (
                <>
                  {onNewEntity && <NewEntityButton entityName={entityName} onClick={onNewEntity} />}
                  <Table
                    search={search}
                    entities={filteredEntities.map(({ meta: entity }) => entity).filter(Boolean) as T[]}
                  />
                </>
              )}
            </Row>
          </div>
        )}
      </>
    </Flex>
  );
};
