import { Actions } from 'types/actionTypes';
import { CommonStatusEnum, ConversationFeedbacksViewEnum } from 'types/enum';

export type ConversationFeedbacksContainer = {
  conversation: Conversation;
  status: CommonStatusEnum;
};
type ConversationFeedbacksPage = {
  activeTab: ConversationFeedbacksViewEnum;
};

export enum FeedbackActionTypes {
  CLEAN_ALL_FEEDBACKS = 'CLEAN_ALL_FEEDBACKS',
  FETCHING_ALL_FEEDBACKS = 'FETCHING_ALL_FEEDBACKS',
  FETCH_ALL_FEEDBACKS = 'FETCH_ALL_FEEDBACKS',
  FETCHING_CONVERSATION_FEEDBACKS = 'FETCHING_CONVERSATION',
  FETCH_CONVERSATION_FEEDBACKS = 'FETCH_CONVERSATION_FEEDBACKS',
  FETCH_FEEDBACK_SENTIMENTS = 'FETCH_FEEDBACK_SENTIMENTS',
  UPDATE_CONVERSATION_FEEDBACKS_MESSAGE = 'UPDATE_CONVERSATION_FEEDBACKS_MESSAGE',
  SET_PAGE_ACTIVE_TAB = 'SET_PAGE_ACTIVE_TAB',
}
export interface FeedbacksState {
  feedbacks: {
    pagination: PaginationType;
    results: FeedbackItemData[];
    status: CommonStatusEnum;
  };
  page: ConversationFeedbacksPage;

  conversationFeedbacks: {
    data: {
      [conversationId: string]: {
        conversation: Conversation;
      };
    };
    status: CommonStatusEnum;
  };
  sentiments: Sentiment[];
}

export const initialFeedbacksState: FeedbacksState = {
  feedbacks: {
    pagination: {
      next: null,
      previous: null,
      count: 0,
    },
    results: [],
    status: CommonStatusEnum.INITIAL,
  },
  page: {
    activeTab: ConversationFeedbacksViewEnum.ALL,
  },
  conversationFeedbacks: {
    data: {},
    status: CommonStatusEnum.INITIAL,
  },
  sentiments: [],
};

export type FeedbackActions =
  | { type: FeedbackActionTypes.FETCHING_ALL_FEEDBACKS }
  | {
      type: FeedbackActionTypes.FETCH_ALL_FEEDBACKS;
      payload: { results: FeedbackItemData[]; pagination: PaginationType };
    }
  | { type: FeedbackActionTypes.CLEAN_ALL_FEEDBACKS }
  | { type: FeedbackActionTypes.FETCHING_CONVERSATION_FEEDBACKS; payload: Conversation['id'] }
  | {
      type: FeedbackActionTypes.FETCH_CONVERSATION_FEEDBACKS;
      payload: { conversationId: ConversationId; conversation: Conversation };
    }
  | { type: FeedbackActionTypes.FETCH_FEEDBACK_SENTIMENTS; payload: Sentiment[] }
  | {
      type: FeedbackActionTypes.UPDATE_CONVERSATION_FEEDBACKS_MESSAGE;
      payload: { message: Message };
    }
  | { type: FeedbackActionTypes.SET_PAGE_ACTIVE_TAB; payload: ConversationFeedbacksViewEnum };

export const feedbacksReducer = (state: FeedbacksState, action: Actions): FeedbacksState => {
  switch (action.type) {
    case FeedbackActionTypes.CLEAN_ALL_FEEDBACKS:
      return {
        ...state,
        feedbacks: {
          pagination: {
            next: null,
            previous: null,
            count: 0,
          },
          results: [],
          status: CommonStatusEnum.INITIAL,
        },
      };
    case FeedbackActionTypes.FETCHING_ALL_FEEDBACKS:
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          status: CommonStatusEnum.FETCHING,
        },
      };
    case FeedbackActionTypes.FETCH_ALL_FEEDBACKS:
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          pagination: {
            ...state.feedbacks.pagination,
            ...action.payload.pagination,
          },
          results: action.payload.results,
          status: CommonStatusEnum.FETCHED,
        },
      };
    case FeedbackActionTypes.FETCHING_CONVERSATION_FEEDBACKS: {
      return {
        ...state,
        conversationFeedbacks: {
          ...state.conversationFeedbacks,
          status: CommonStatusEnum.FETCHING,
        },
      };
    }
    case FeedbackActionTypes.FETCH_CONVERSATION_FEEDBACKS:
      return {
        ...state,
        conversationFeedbacks: {
          data: {
            [action.payload.conversationId]: {
              conversation: action.payload.conversation,
            },
          },
          status: CommonStatusEnum.FETCHED,
        },
      };
    case FeedbackActionTypes.FETCH_FEEDBACK_SENTIMENTS:
      return {
        ...state,
        sentiments: action.payload,
      };
    case FeedbackActionTypes.UPDATE_CONVERSATION_FEEDBACKS_MESSAGE: {
      const { message } = action.payload;
      const conversationId = message.conversationId;

      return {
        ...state,
        conversationFeedbacks: {
          ...state.conversationFeedbacks,
          data: {
            ...state.conversationFeedbacks.data,
            [conversationId]: {
              conversation: {
                ...state.conversationFeedbacks.data[conversationId].conversation,
                messageSet: [
                  ...state.conversationFeedbacks.data[conversationId].conversation.messageSet.filter(
                    msg => msg.id !== message.id,
                  ),
                  message,
                ],
              },
            },
          },
        },
      };
    }
    case FeedbackActionTypes.SET_PAGE_ACTIVE_TAB: {
      return {
        ...state,
        page: {
          activeTab: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
