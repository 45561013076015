import { Layout, message } from 'antd';
import styles from './Layout.module.scss';
import { memo, useCallback } from 'react';
import { BlueprintFormData, BlueprintView } from 'components/blueprint-view/BlueprintView';
import { useActiveBlueprint, useUpdateBlueprint } from 'selectors/useBlueprints';
import { Loader } from 'components/loader/Loader';

export const BlueprintPage = memo(() => {
  const updateBlueprint = useUpdateBlueprint();
  const blueprint = useActiveBlueprint();

  const onSubmit = useCallback(
    async (values: BlueprintFormData) => {
      const removedSteps = blueprint?.steps
        .filter(step => !values.steps.find(s => s.id === step.id))
        .map(step => step.id);

      await updateBlueprint(
        {
          data_sources: values.dataSources,
          description: values.description,
          id: blueprint?.id,
          input_parameters: values.inputParameters,
          initial_message: values.initialMessage,
          // memories: values.memories.filter(Boolean),
          name: values.name,
          owner: values.owner,
          project_labels: values.projectLabels,
          share_with_organization: values.shareWithOrganization,
          steps: values.steps,
        },
        removedSteps ?? [],
      );

      message.success('Blueprint updated successfully');
    },
    [blueprint, updateBlueprint],
  );

  return (
    <Layout className={styles.blueprintPage}>
      {blueprint ? (
        <BlueprintView blueprint={blueprint} edit onSubmit={onSubmit} isNew={false} />
      ) : (
        <Loader fullscreen />
      )}
    </Layout>
  );
});
