import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useArchiveDataSource } from 'selectors/useDataSourcesSelector';
import { useCallback } from 'react';

export const ArchiveDataSourceModal: React.FC = () => {
  const { data: dataSource, hide } = useModal<DataSource>(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.ARCHIVE);
  const archive = useArchiveDataSource(dataSource.id);
  const onConfirm = useCallback(async () => {
    await archive();
    hide();
  }, [archive, hide]);

  return (
    <ConfirmationModal
      key={'datasource::archive'}
      isOpen={true}
      title={'Archive connector'}
      description={
        <>
          If you are the owner, this will archive the connector for everyone it is shared with. Are you sure you want to
          archive this connector <strong>{dataSource.name}</strong>?
        </>
      }
      onCancel={() => {
        hide();
      }}
      onConfirm={onConfirm}
      cancelButton={'Cancel'}
      confirmationButton={'Yes, archive'}
    />
  );
};
