import { Flex, Layout } from 'antd';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { EntityActionsEnum } from 'types/enum';
import { Button } from 'ui/Button';
import { IconButton } from 'ui/IconButton';
import styles from './EntityHeader.module.scss';
import { EntityHeaderTitle } from './EntityHeaderTitle';

export type EntityHeaderAction =
  | {
      key: EntityActionsEnum;
      component: React.ReactNode;
      onClick?: () => void;
      icon?: never;
      disabled?: never;
    }
  | {
      key: EntityActionsEnum;
      component?: never;
      onClick: () => void;
      icon: React.ReactNode;
      disabled?: boolean;
    };

export type EntityHeaderProps<T extends boolean> = {
  isEditable: T;
} & (T extends true ? { onEdit: (editedName: string) => void } : { onEdit?: (editedName: string) => void }) & {
    actions?: EntityHeaderAction[];
    canGoBack?: boolean;
    rightSideButtonText?: ReactNode;
    rightSideButtonOnClick?: () => void;
    rightSideButtonLoading?: boolean;
    title: string;
  };

export const EntityHeader = <T extends boolean>({
  actions,
  canGoBack = false,
  isEditable,
  onEdit = () => {},
  rightSideButtonText,
  rightSideButtonOnClick,
  rightSideButtonLoading,
  title,
}: EntityHeaderProps<T>) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <Layout.Header className={styles.header}>
      <Flex align="center" gap={8} justify="space-between" className={styles.headerContent}>
        <EntityHeaderTitle
          isEditable={isEditable}
          title={title}
          onEdit={onEdit}
          canGoBack={canGoBack}
          onGoBack={handleGoBack}
        />
        <Flex align="center" flex="0 0 auto" gap={8}>
          {actions?.map(action =>
            action.component ? (
              <div key={action.key} onClick={action.onClick}>
                {action.component}
              </div>
            ) : (
              <IconButton
                key={action.key}
                icon={action.icon}
                disabled={action.disabled ?? false}
                onClick={action.onClick}
              />
            ),
          )}
          {rightSideButtonText && (
            <Button type="primary" loading={rightSideButtonLoading} onClick={rightSideButtonOnClick}>
              {rightSideButtonText}
            </Button>
          )}
        </Flex>
      </Flex>
    </Layout.Header>
  );
};
