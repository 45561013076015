// eslint-disable-file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Form, Input, message } from 'antd';
import Typography from 'antd/es/typography/Typography';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { Modal } from 'ui/Modal';
import { useModal } from 'selectors/useModals';
import { useMemories, useUpdateMemory } from 'selectors/userMemories';
import { useCallback, useState } from 'react';
import styles from './MemoryModal.module.scss';
import { Select } from 'ui/Select';

export const EditMemoryModal: React.FC = () => {
  const {
    data: { memory },
    hide,
  } = useModal<{
    memory: Memory;
    onConfirm(memory: Memory): void;
  }>(ModalScopesEnum.MEMORIES, ModalTypesEnum.EDIT);
  const { sources, types } = useMemories();
  const updateMemory = useUpdateMemory();

  const [selectedSource] = useState<number | undefined>(sources.filter(source => source.name === 'manual')[0]?.id);
  const [selectedType, setSelectedType] = useState<number | undefined>(memory?.type as unknown as number);
  const [question, setQuestion] = useState<string>(memory?.index);
  const [response, setResponse] = useState<string>(memory?.value);
  const [name, setName] = useState<string>(memory?.name);

  const handleSave = useCallback(async () => {
    const memoryData = {
      index: question,
      name: name.substring(0, 30),
      source: selectedSource,
      type: selectedType,
      value: response,
    };

    if (!memoryData.index || !memoryData.name || !memoryData.source || !memoryData.type || !memoryData.value) {
      message.error('All fields are required');
      return;
    }

    updateMemory({
      ...memoryData,
      id: memory?.id,
      owner: memory?.owner,
    } as Memory);

    hide();
  }, [question, name, selectedSource, selectedType, response, hide]);
  return (
    <Modal okText="Save" onCancel={hide} onOk={handleSave} open={true} title="Edit Memory" width={720}>
      <Typography style={{ color: '#6A666E', fontSize: '16px', marginBottom: '30px' }}>
        Create a question-response example for use with this workflow template
      </Typography>
      <div className={styles.formLayout}>
        <Form layout="vertical" className={styles.form}>
          <Form.Item label="Name" valuePropName="checked" className={styles.header}>
            <Input
              value={name}
              placeholder="Name"
              onChange={e => {
                setName(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
        <Form layout="vertical" className={styles.form}>
          <Form.Item label="Type" valuePropName="checked" className={styles.header}>
            <Select
              onSelect={async type => {
                setSelectedType(type);
              }}
              options={types.map(type => ({
                label: type.name,
                value: type.id,
              }))}
              placeholder="Select Type"
              showSearch={false}
              value={selectedType}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Form>
      </div>

      <Form layout="vertical">
        <Form.Item label="Question">
          <Input.TextArea
            value={question}
            placeholder="Input text"
            onChange={e => {
              setQuestion(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Response">
          <Input.TextArea
            value={response}
            placeholder="Input text"
            autoSize={{ minRows: 6, maxRows: 6 }}
            onChange={e => {
              setResponse(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
