import { Badge, Button, ConfigProvider, Flex } from 'antd';
import styles from './UIKit.module.scss';
import classNames from 'classnames';
import CloseIcon from '../assets/iconsNewVersion/close.svg?react';

type ProjectTagProps<T extends boolean> = {
  filled?: T;
} & (T extends true ? { onRemove: () => void } : { onRemove?: () => void }) & {
    text: string;
    color?: string;
    textColor?: string;
    readOnly?: boolean;
  };

export const ProjectLabel = <T extends boolean>({
  color,
  filled,
  onRemove,
  readOnly,
  text,
  textColor,
}: ProjectTagProps<T>) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          // BUG: If I include the font size as string the dot from the badge disappears
          fontSize: 12,
          colorText: textColor ?? 'var(--nri-color-purple-grey)',
          lineHeight: 18,
        },
      }}
    >
      <Flex align="center" gap={10} className={classNames({ [styles.badgeContainerFilled]: filled })}>
        <Badge className={styles.badgeContainer} text={text} color={color ?? 'var(--nri-color-purple-grey)'} />
        {filled && !readOnly && (
          <Button
            className={styles.badgeCloseButton}
            type="link"
            icon={<CloseIcon onClick={onRemove} className={styles.badgeCloseIcon} />}
          />
        )}
      </Flex>
    </ConfigProvider>
  );
};
