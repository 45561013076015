import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import { Card, Flex, Input, message, Typography } from 'antd';
import QuestionIcon from 'assets/icons/question.svg?react';
import styles from './Message.module.scss';

import SendIcon from 'assets/iconsNewVersion/send.svg?react';
import { useList } from 'hooks/useList';
import { FC, useCallback, useEffect, useState } from 'react';
import { useCreateFeedback, useFeedbackSentiments } from 'selectors/useFeedbacksSelector';
import { FeedbackTypeEnum } from 'types/enum';
import { cx } from 'utils/cx';
import { NewtonApi } from 'utils/newtonApi';
import { validateText } from 'utils/validateText';
import { Button } from 'ui/Button';
import { useLocation } from 'react-router-dom';

type FeedbackCardProps = {
  showFeedbackCard: boolean;
  setShowFeedbackCard: (showFeedbackCard: boolean) => void;
  feedbackType: FeedbackTypeEnum | undefined;
  messageId: MessageId;
  feedbackId: FeedbackId;
  previousLabels: number[];
  user: FeedbackUser;
  currentSentiment: Sentiment | undefined;
};

const feedbackTitle = (fbType: FeedbackTypeEnum | undefined) => {
  switch (fbType) {
    case 'Positive':
      return (
        <>
          <LikeOutlined className={styles.cardHeaderIcon} /> Mark as positive
        </>
      );
    case 'Negative':
      return (
        <>
          <DislikeOutlined className={styles.cardHeaderIcon} /> Mark as negative
        </>
      );
    case 'Question':
      return (
        <>
          <QuestionIcon className={styles.cardHeaderIcon} /> Ask a question
        </>
      );
    default:
      return;
  }
};

const LabelButton: FC<{
  label: SentimentLabelSet;
  isClicked: boolean | SentimentLabelSet;
  toggleSelectedLabel: (sentiment: SentimentLabelSet) => void;
  disabled?: boolean;
}> = ({ label, isClicked, toggleSelectedLabel, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={cx({
        [styles.cardButtonActive]: isClicked,
        [styles.cardButton]: !isClicked,
      })}
      onClick={() => {
        toggleSelectedLabel(label);
      }}
    >
      {label.name}
    </button>
  );
};

export const FeedbackCard: FC<FeedbackCardProps> = ({
  feedbackType,
  messageId,
  setShowFeedbackCard,
  showFeedbackCard,
  feedbackId,
  currentSentiment,
}) => {
  const location = useLocation();
  const sentiments = useFeedbackSentiments();
  const createFeedback = useCreateFeedback();

  const [comment, setComment] = useState('');
  const [focus, setFocus] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [feedbackData, setFeedbackData] = useState<FeedbackData>();

  const [selectedLabels, { toggle, inList, add, clear }] = useList<SentimentLabelSet>({
    singleSelect: false,
    initialSelection: [],
  });

  useEffect(() => {
    setComment('');
  }, [feedbackType, showFeedbackCard]);

  useEffect(() => {
    if (feedbackId && feedbackType)
      NewtonApi.fetchFeedback(feedbackId).then(res => {
        setFeedbackData(res);
        if (res.sentiment[0]?.name === feedbackType)
          res?.sentiment[0]?.labelSet?.forEach((label: SentimentLabelSet) => {
            add(label);
          });
      });
    return () => {
      if (feedbackId && feedbackType) {
        clear();
      }
    };
  }, [feedbackId, feedbackType]);

  const handleSummitFeedback = useCallback(
    async (feedbackType: FeedbackTypeEnum | undefined, comment: string) => {
      if (feedbackType !== FeedbackTypeEnum.QUESTION && selectedLabels?.length === 0 && !comment) {
        message.error('Please select a option or leave a comment');
        return;
      }

      setIsSending(true);

      const commentData = {
        text: comment,
      };

      const feedback = {
        label: selectedLabels?.map(label => label.id) ?? [],
        comment: commentData.text ? [commentData] : [],
      };

      try {
        await createFeedback(feedback, messageId, location.pathname);
        setIsSending(false);
        setShowFeedbackCard(false);
        message.success('Feedback sent');
      } catch (error) {
        setIsSending(false);
      }
    },
    [createFeedback, messageId, setIsSending, setShowFeedbackCard, selectedLabels, location.pathname],
  );

  return (
    <>
      <Card
        className={styles.feedbackCard}
        styles={{
          header: {
            borderBottom: 'none',
            padding: 0,
            minHeight: 'auto',
            marginBottom: '20px',
          },
          body: {
            padding: 0,
          },
        }}
        title={<span className={styles.cardHeaderTitle}>{feedbackTitle(feedbackType)}</span>}
      >
        {feedbackType !== 'Question' && (
          <div className={styles.cardButtonContainer}>
            {sentiments
              ?.find((sentiment: Sentiment) => sentiment.name === feedbackType)
              ?.labelSet.map((label: SentimentLabelSet) => {
                const disabled = !!currentSentiment;
                const selected = inList(label);

                return (
                  <LabelButton
                    key={label.id}
                    disabled={disabled}
                    label={label}
                    isClicked={selected}
                    toggleSelectedLabel={toggle}
                  />
                );
              })}
          </div>
        )}
        <Flex vertical gap={1} className={styles.cardCommentContainer}>
          {feedbackData?.commentSet?.map((comment: CommentData) => {
            return (
              <div key={comment.id} className={styles.cardComment}>
                <Typography.Text className={styles.commentUser}>@ {comment.user?.fullName}</Typography.Text>
                <Typography.Text className={styles.commentText}>{comment.text}</Typography.Text>
              </div>
            );
          })}
        </Flex>
        <Flex
          className={cx(styles.cardInputContainer, {
            [styles.focus]: focus,
          })}
        >
          <Input.TextArea
            variant="borderless"
            className={styles.cardInput}
            autoSize={{
              minRows: 1,
              maxRows: feedbackType === 'Question' ? 3 : 1,
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            maxLength={300}
            value={comment}
            onChange={e => setComment(validateText(e.target.value))}
            placeholder={'Leave a comment'}
          />
          <Button
            type="text"
            loading={isSending}
            disabled={currentSentiment !== undefined}
            className={styles.cardInputButton}
            onClick={() => {
              handleSummitFeedback(feedbackType, comment);
            }}
            icon={<SendIcon className={styles.sendIcon} />}
          />
        </Flex>
      </Card>
    </>
  );
};
