import { PageSection } from 'components/page-section/PageSection';
import styles from './AccountView.module.scss';
import { UsageTag } from 'components/usage-tag/UsageTag';
import { Tabs } from 'antd';
import { Segmented } from 'ui/Segmented';
import { SegmentedItemLabel } from '../../newVersionComponents/common/SegmentedItemLabel';
import { useState } from 'react';
import { Usage } from './Usage';
import { useCreditsInfo } from 'selectors/useStatisticsSelector';

export const AccountView = () => {
  const [activeTab, setActiveTab] = useState('usage');
  const { totalTokens } = useCreditsInfo();

  const accountItems: {
    hide: boolean | undefined;
    label: string;
    key: string;
    children: JSX.Element;
  }[] = [
    {
      hide: false,
      label: 'Usage',
      key: 'usage',
      children: <Usage />,
    },
    {
      hide: true,
      label: 'Preferences',
      key: 'preferences',
      children: <div>Soon</div>,
    },
  ].filter(({ hide }) => !hide);

  return (
    <PageSection heading={'Account'} button={<UsageTag credits={totalTokens || 0} description />}>
      <div className={styles.account}>
        <Tabs
          items={accountItems}
          activeKey={activeTab}
          renderTabBar={({ activeKey }) => {
            return (
              <div>
                <Segmented
                  className={styles.accountSegmented}
                  options={accountItems.map(({ label, key }) => ({
                    label: (
                      <SegmentedItemLabel
                        label={label}
                        active={activeKey === key}
                        handleClick={() => {
                          setActiveTab(key);
                        }}
                      />
                    ),
                    value: key,
                  }))}
                />
              </div>
            );
          }}
        />
      </div>
    </PageSection>
  );
};
