import { Button } from 'ui/Button';
import { Typography } from 'antd';
import React, { FC } from 'react';
import { Modal } from 'ui/Modal';

type ConfirmationModalProps = {
  isOpen: boolean;
  cancelButton?: string;
  confirmationButton?: string;
  title: string;
  description: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  processing?: boolean;
  confirmColor?: string;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  confirmationButton,
  confirmColor,
  cancelButton,
  isOpen,
  onConfirm,
  onCancel,
  processing = undefined,
  title,
  description,
}) => {
  return (
    <Modal
      divider
      footer={[
        <Button key="cancel" type="secondary" onClick={onCancel}>
          {cancelButton || 'No, cancel'}
        </Button>,
        <Button
          autoFocus
          tabIndex={0}
          style={{ backgroundColor: confirmColor }}
          type="primary"
          loading={processing}
          onClick={onConfirm}
        >
          {confirmationButton || 'Confirm'}
        </Button>,
      ]}
      onOk={onConfirm}
      onCancel={onCancel}
      open={isOpen}
      title={title}
    >
      <Typography.Text>{description}</Typography.Text>
    </Modal>
  );
};
