import { useAuth0 } from "@auth0/auth0-react";
import { Layout } from "antd";
import { Loader } from "components/loader/Loader";
import { Navigate, useLocation } from "react-router-dom";

const Authorizing = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  if (isAuthenticated) {
    // Return to the original requested URL if available, otherwise go to conversations
    return <Navigate to={location.state?.returnTo || "/conversations"} replace />;
  }

  if (!isLoading) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Layout
      style={{ background: "white", height: "100vh", justifyContent: "center" }}
    >
      <Loader spinning={true} />
    </Layout>
  );
};

export default Authorizing;
