import ArchiveIcon from 'assets/iconsNewVersion/archive.svg?react';
import CopyIcon from 'assets/iconsNewVersion/copy.svg?react';
import TrashIcon from 'assets/iconsNewVersion/trash.svg?react';
import { GeneralStep } from 'components/agent-form/GeneralStep';
import { ConversationContent } from 'components/conversation-view/ConversationContent';
import { EntityAccess } from 'components/entity-access/EntityAccess';
import { EntityFormActions } from 'components/entity-actions/EntityFormActions';
import { EntityDetails } from 'components/entity-details/EntityDetails';
import { EntityHeader, EntityHeaderProps } from 'components/entity-header/EntityHeader';
import { FavoriteToggle } from 'components/favorite-toggle/FavoriteToggle';
import { EntityProvider, Step } from 'components/layouts/context/EntityProvider';
import { urlParameters } from 'hooks/useURLParams';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'selectors/useAuthSelector';
import { EntityActionsEnum } from 'types/enum';
import styles from './AgentView.module.scss';
import { Flex, Typography } from 'antd';

export type AgentFormData = {
  name: string;
  description: string;
  agent: string;
  agentParams: {
    modelName: string;
    instructions: string;
    memoriesPath: string;
    modelProvider: string;
    specializations: string[];
  };
  coder: number;
  hippocampus: number;
  isDefault: boolean;
  isEnabled: boolean;
  owner: UserEntity['id'];
};

type AgentViewProps = {
  agent?: Agent;
  edit: boolean;
  isNew: boolean;
  initialValues?: Partial<AgentFormData>;
  onSubmit: (values: AgentFormData) => Promise<void>;
};

export const AgentView: React.FC<AgentViewProps> = memo(
  ({ edit, isNew = false, onSubmit, agent, initialValues = {} }) => {
    const { me } = useAuth();
    const navigate = useNavigate();
    const urlParams = urlParameters({
      agent: agent?.id || 0,
    });

    const toggleAgentFavorite = () => {};
    const showArchiveModal = () => {};
    const showDeleteModal = () => {};
    const showDuplicateModal = () => {};

    const steps: Step[] = [
      {
        formTitle: '',
        title: '',
        key: 'general',
        description: '',
        content: <GeneralStep />,
      },
    ];

    const headerProps: Partial<EntityHeaderProps<boolean>> = {};

    if (agent?.id) {
      headerProps.actions = [
        {
          key: EntityActionsEnum.MANAGE_ACCESS,
          component: <EntityAccess owner={me as UserEntity} sharedUsers={[]} onAdd={() => {}} />,
        },
        {
          key: EntityActionsEnum.DELETE,
          disabled: agent?.owner !== me?.id,
          icon: <TrashIcon color={agent?.owner !== me?.id ? 'var(--nri-color-grey-stroke)' : 'var(--nri-color-red)'} />,
          onClick: () => {
            showDeleteModal();
          },
        },
        {
          key: EntityActionsEnum.FAVORITE,
          icon: <FavoriteToggle isFavorite={agent.isFavorite!} />,
          onClick: () => {
            toggleAgentFavorite();
          },
        },
        {
          key: EntityActionsEnum.ARCHIVE,
          icon: <ArchiveIcon />,
          onClick: () => {
            showArchiveModal();
          },
        },
        {
          key: EntityActionsEnum.COPY,
          icon: <CopyIcon />,
          onClick: () => {
            showDuplicateModal();
          },
        },
      ];
      headerProps.rightSideButtonText = 'Start new conversation';
      headerProps.rightSideButtonOnClick = () => {
        navigate(`/conversations/new?${urlParams}`, { replace: true });
      };
    }

    return (
      <EntityProvider<AgentFormData>
        steps={steps}
        initialFormValue={{
          ...initialValues,
          name: agent?.name ?? '',
          description: agent?.description ?? '',
          agent: agent?.agent ?? '',
          agentParams: {
            modelName: agent?.agentParams?.modelName ?? '',
            instructions: agent?.agentParams?.instructions ?? '',
            memoriesPath: agent?.agentParams?.memoriesPath ?? '',
            modelProvider: agent?.agentParams?.modelProvider ?? '',
            specializations: agent?.agentParams?.specializations ?? [],
          },
          coder: agent?.coder ?? 0,
          hippocampus: agent?.hippocampus ?? 0,
          isDefault: agent?.isDefault ?? false,
          isEnabled: agent?.isEnabled ?? true,
          owner: me!.id,
        }}
        isNew={isNew}
        readOnly={!edit}
        onSubmit={onSubmit}
      >
        <EntityHeader isEditable={false} title={agent?.name ?? ''} canGoBack {...headerProps} />
        <Flex>
          <EntityDetails<AgentFormData>
            className={styles.agentDetails}
            isEditable={false}
            fullHeight
            entityName="agent"
            showSegmented={false}
          >
            <EntityFormActions entityName="agent" />
          </EntityDetails>

          {!isNew && (
            <div className={styles.conversation}>
              <Typography.Title level={2}>Test Conversation</Typography.Title>
              <ConversationContent
                blankSlate={
                  <Flex align="center" justify="center" vertical style={{ height: '100%', paddingLeft: '20px' }}>
                    <Typography.Title level={1} style={{ lineHeight: '30px' }}>
                      You can start a conversation to test this agent
                    </Typography.Title>
                    <Typography.Paragraph
                      style={{ color: 'var(--nri-color-purple-grey)', lineHeight: '30px', marginTop: '16px' }}
                    >
                      Just start chat to view how agent works
                    </Typography.Paragraph>
                  </Flex>
                }
                messagesClassName={styles.messages}
                showAnalystSelect={false}
              />
            </div>
          )}
        </Flex>
      </EntityProvider>
    );
  },
);
