import { Actions } from 'types/actionTypes';
import { CommonStatusEnum } from 'types/enum';

export interface ProjectLabelsState {
  projectLabels: Map<ProjectLabel['id'], ProjectLabel>;
  pagination: PaginationType;
  status: CommonStatusEnum;
}

export const initialProjectLabelsState: ProjectLabelsState = {
  projectLabels: new Map(),
  pagination: {
    count: 0,
    next: null,
    previous: null,
  },
  status: CommonStatusEnum.INITIAL,
};

export enum ProjectLabelsActionTypes {
  ADD_PROJECT_LABEL = 'ADD_PROJECT_LABEL',
  FETCHING_PROJECT_LABELS = 'FETCHING_PROJECT_LABELS',
  FETCH_PROJECT_LABELS = 'FETCH_PROJECT_LABELS',
  UPDATE_PROJECT_LABEL = 'UPDATE_PROJECT_LABEL',
  DELETE_PROJECT_LABEL = 'DELETE_PROJECT_LABEL',
}

export type ProjectLabelsAction =
  | { type: ProjectLabelsActionTypes.ADD_PROJECT_LABEL; payload: ProjectLabel }
  | { type: ProjectLabelsActionTypes.FETCHING_PROJECT_LABELS }
  | {
      type: ProjectLabelsActionTypes.FETCH_PROJECT_LABELS;
      payload: { projectLabels: ProjectLabel[]; pagination: PaginationType };
    }
  | { type: ProjectLabelsActionTypes.UPDATE_PROJECT_LABEL; payload: ProjectLabel }
  | { type: ProjectLabelsActionTypes.DELETE_PROJECT_LABEL; payload: ProjectLabelId };

export const projectLabelsReducer = (state: ProjectLabelsState, action: Actions): ProjectLabelsState => {
  switch (action.type) {
    case ProjectLabelsActionTypes.ADD_PROJECT_LABEL: {
      const projectLabel = action.payload;
      return {
        ...state,
        projectLabels: new Map([...state.projectLabels, [projectLabel.id, projectLabel]]),
        status: CommonStatusEnum.FETCHED,
      };
    }
    case ProjectLabelsActionTypes.FETCHING_PROJECT_LABELS:
      return { ...state, status: CommonStatusEnum.FETCHING };
    case ProjectLabelsActionTypes.FETCH_PROJECT_LABELS: {
      const { projectLabels, pagination } = action.payload;
      const projectLabelsMap = new Map(projectLabels.map(analyst => [analyst.id, analyst]));
      return {
        ...state,
        projectLabels: new Map([...state.projectLabels, ...projectLabelsMap]),
        pagination,
        status: CommonStatusEnum.FETCHED,
      };
    }
    case ProjectLabelsActionTypes.UPDATE_PROJECT_LABEL: {
      const projectLabel = action.payload;
      return {
        ...state,
        projectLabels: new Map([...state.projectLabels, [projectLabel.id, projectLabel]]),
        status: CommonStatusEnum.FETCHED,
      };
    }
    case ProjectLabelsActionTypes.DELETE_PROJECT_LABEL: {
      const projectLabelId = action.payload;
      state.projectLabels.delete(projectLabelId);
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
