import { Form, Input } from 'antd';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useUpdateConversation } from 'selectors/useConversationSelectors';
import { useModal } from 'selectors/useModals';

export const RenameConversationModal: React.FC = () => {
  const { data: conversation, hide } = useModal<Conversation>(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.RENAME);
  const [processing, setProcessing] = useState(false);
  const [form] = Form.useForm();
  const updateConversation = useUpdateConversation();

  const onConfirm = useCallback(async () => {
    setProcessing(true);
    try {
      const values = await form.validateFields();
      await updateConversation({
        id: conversation.id,
        name: values.name,
      });
      hide();
    } finally {
      setProcessing(false);
    }
  }, [conversation.id, form, hide, updateConversation]);

  return (
    <ConfirmationModal
      key={'conversation::rename'}
      confirmationButton={processing ? 'Saving' : 'Save'}
      isOpen
      title={'Rename conversation'}
      description={
        <Form form={form} layout="vertical" initialValues={{ name: conversation.name }}>
          <Form.Item label="Conversation name" name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
            <Input autoFocus maxLength={100} />
          </Form.Item>
        </Form>
      }
      onCancel={hide}
      onConfirm={onConfirm}
      processing={processing}
    />
  );
};
