import { ModalScopesEnum, ModalTypesEnum } from "reducers/modalsReducer";
import { useModal } from "selectors/useModals";
import { ConfirmationModal } from "components/common/ConfirmationModal";
import { useCallback, useState } from "react";
import { useDeleteConversation } from "selectors/useConversationSelectors";

export const DeleteConversationModal: React.FC = () => {
    const { data: conversation, hide } = useModal<Conversation>(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.DELETE);
    const [processing, setProcessing] = useState(false);
    const remove = useDeleteConversation(conversation.id);
    const onConfirm = useCallback(async () => {
        setProcessing(true);
        await remove();
        hide();
        setProcessing(false);
    }, [remove, hide]);

    return <ConfirmationModal
        key={"conversation::delete"}
        isOpen
        title={"Delete conversation"}
        confirmationButton={processing ? "Deleting" : "Delete"}
        description={<>
            Are you sure you want to delete this conversation?
        </>}
        onCancel={hide}
        onConfirm={onConfirm}
        confirmColor={"#E23C26"}
        processing={processing}
    />
}