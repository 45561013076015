import { message } from 'antd';
import { useAppContext } from 'contexts/AppProviders';
import { useCallback, useEffect, useMemo } from 'react';
import { ProjectLabelsActionTypes } from 'reducers/projectsReducer';
import { CommonStatusEnum } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';

const useProjectLabelsState = () => {
  const {
    state: { projectLabelsState },
    dispatch,
  } = useAppContext();
  const { status } = projectLabelsState;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: ProjectLabelsActionTypes.FETCHING_PROJECT_LABELS,
        });
        const [{ results: projectLabels, ...pagination }] = await Promise.all([NewtonApi.fetchProjectLabels()]);

        dispatch({
          type: ProjectLabelsActionTypes.FETCH_PROJECT_LABELS,
          payload: { projectLabels, pagination },
        });
      })();
    }
  }, [dispatch, status]);

  return { state: projectLabelsState };
};

export const useProjectLabels = (ids?: ProjectLabel['id'][]) => {
  const {
    state: { projectLabels, status },
  } = useProjectLabelsState();

  const list = useMemo(() => Array.from(projectLabels.values()), [projectLabels]);

  if (ids === undefined) {
    return {
      projectLabels: list,
      loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
      status,
    };
  } else {
    return {
      projectLabels: list.filter(projectLabel => ids.includes(projectLabel.id)),
      loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
      status,
    };
  }
};

export const useCreateProjectLabel = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (projectData: ProjectLabel) => {
      const payload = new FormData();
      payload.append('name', projectData.name);
      payload.append('description', projectData.description as string);
      payload.append('color', projectData.color);

      try {
        const projectLabel = await NewtonApi.uploadProjectLabel(payload, true);
        dispatch({
          type: ProjectLabelsActionTypes.ADD_PROJECT_LABEL,
          payload: projectLabel,
        });
      } catch (error) {
        message.error('Failed to create project');
        return;
      }
      message.success('Project created successfully');
    },
    [dispatch],
  );
};

export const useUpdateProjectLabel = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (projectData: ProjectLabel) => {
      const projectLabel = await NewtonApi.updateProjectLabel(projectData);
      dispatch({
        type: ProjectLabelsActionTypes.UPDATE_PROJECT_LABEL,
        payload: projectLabel,
      });
    },
    [dispatch],
  );
};

export const useDeleteProjectLabel = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (id: ProjectLabel['id']) => {
      try {
        await NewtonApi.deleteProjectLabel(id, true);
      } catch (error) {
        message.error('Failed to delete project');
        return;
      }
      dispatch({
        type: ProjectLabelsActionTypes.DELETE_PROJECT_LABEL,
        payload: id,
      });
      message.success('Project deleted successfully');
    },
    [dispatch],
  );
};
