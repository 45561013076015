import { Button, Flex, Input, Layout } from 'antd';
import SendIcon from 'assets/icons/plane.svg?react';
import StopIcon from 'assets/icons/stop.svg?react';
import classnames from 'classnames';
import styles from 'components/conversation-view/ConversationView.module.scss';
import { SelectAnalystDropdown } from 'components/conversations-view/SelectAnalystDropdown';
import { DatasourceTags } from 'components/datasource-tags/DatasourceTags';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlocker, useParams } from 'react-router-dom';
import {
  useActiveConversation,
  useConversationMessages,
  useCreateConversation,
} from 'selectors/useConversationSelectors';
import { cx } from 'utils/cx';

type Props = {
  showAnalystSelect?: boolean;
};

export const Prompt: React.FC<Props> = ({ showAnalystSelect = true }) => {
  const { conversation, loading } = useActiveConversation();
  const createConversation = useCreateConversation();
  const { saveTempData, sendMessage, streaming, stopStreaming, temporary } = useConversationMessages(conversation?.id);

  const [activePrompt, setActivePrompt] = useState<Partial<Message> | undefined>(temporary?.prompt);
  const selectedDataSources = useMemo(() => temporary?.dataSources || [], [temporary?.dataSources]);
  const selectedAnalyst = useMemo(() => temporary?.analyst, [temporary?.analyst]);

  const [focus, setFocus] = useState(false);
  const [isStopping, setStopping] = useState(false);
  const conversationId = Number(useParams().conversationId) as Conversation['id'];

  const onDataSourcesUpdate = useCallback(
    (dataSources: DataSource[]) => {
      saveTempData({
        dataSources,
      });
    },
    [saveTempData],
  );

  // When conversation ID changes reset the prompt
  useEffect(() => {
    setActivePrompt(temporary?.prompt);
  }, [conversationId, temporary?.prompt]);

  useEffect(() => {
    if (!streaming) setStopping(false);
  }, [streaming]);

  // Prior to navigation, save the prompt
  const blockNavigation = useCallback(() => {
    if (activePrompt?.text !== temporary?.prompt?.text) {
      saveTempData({
        dataSources: selectedDataSources,
        prompt: activePrompt,
      });
    }

    return false;
  }, [activePrompt, temporary?.prompt?.text, saveTempData, selectedDataSources]);
  useBlocker(blockNavigation);

  const handleSubmit = useCallback(async () => {
    let convoId = conversationId;
    if (!conversationId) {
      const newConvo = await createConversation({});
      convoId = newConvo.id;
    }

    if (streaming) {
      setStopping(true);
      await stopStreaming(convoId!);
    } else if (activePrompt && !streaming) {
      if (activePrompt?.text) {
        setActivePrompt(undefined);
        saveTempData(undefined);

        await sendMessage({
          ...activePrompt,
          dataSources: selectedDataSources,
          conversationId: convoId,
          analystId: selectedAnalyst?.id,
        } as NewMessage);
        if (selectedAnalyst) {
          saveTempData({
            analyst: selectedAnalyst,
          });
        }
      }
    }
  }, [
    conversationId,
    streaming,
    activePrompt,
    createConversation,
    stopStreaming,
    saveTempData,
    sendMessage,
    selectedDataSources,
    selectedAnalyst,
  ]);

  const Icon = streaming ? StopIcon : SendIcon;

  return (
    <Layout.Footer
      className={classnames(styles.footer, {
        [styles.active]: activePrompt?.text,
        [styles.noDropdown]: !showAnalystSelect,
      })}
    >
      <Flex>
        {showAnalystSelect && <SelectAnalystDropdown />}
        <DatasourceTags locked={[]} selected={selectedDataSources} onUpdate={onDataSourcesUpdate} />
      </Flex>
      <Flex align="end" className={cx(styles.prompt, { [styles.focus]: focus })} flex={1}>
        <Input.TextArea
          autoSize={{ minRows: 1 }}
          disabled={loading || streaming || isStopping}
          onBlur={() => setFocus(false)}
          onChange={e =>
            setActivePrompt({
              text: e.target.value,
            })
          }
          onFocus={() => setFocus(true)}
          onKeyDown={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSubmit();
            }
          }}
          placeholder="Type a message..."
          value={activePrompt?.text}
          variant="borderless"
        />
        <Button
          className={styles.button}
          disabled={loading || isStopping}
          icon={<Icon height={20} />}
          onClick={handleSubmit}
          shape="circle"
          type="primary"
        />
      </Flex>
    </Layout.Footer>
  );
};
