import { MenuItemType } from 'antd/lib/menu/interface';
import { memo, useMemo, useState } from 'react';
import { useProjectLabels } from 'selectors/useProjectLabels';
import { Dropdown } from 'ui/Dropdown';

type Props = {
  onClick: (value: ProjectLabel | null) => void;
};

export const ProjectSelect: React.FC<Props> = memo(({ onClick }) => {
  const { projectLabels } = useProjectLabels();
  const [selectedProjectLabel, setSelectedProjectLabel] = useState<MenuItemType>({
    label: 'All Projects',
    key: 'all',
  });

  const projectItems = useMemo(() => {
    return [
      {
        label: 'All Projects',
        key: 'all',
      },
      ...projectLabels.map(label => ({
        label: label.name,
        key: `${label.id}`,
      })),
    ];
  }, [projectLabels]);

  return (
    <Dropdown
      menu={{
        items: projectItems,
        onClick: ({ key }) => {
          const label = projectItems.find(i => i.key === key)!;
          const projectLabel = projectLabels.find(l => l.id === Number(key))!;
          setSelectedProjectLabel(label);
          onClick(projectLabel);
        },
      }}
    >
      {selectedProjectLabel.label}
    </Dropdown>
  );
});
