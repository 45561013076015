import { AgentCard } from 'components/agent-card/AgentCard';
import { EntityList } from 'components/entity-list/EntityList';
import { message } from 'antd';
import { AgentTable } from 'components/agent-table/AgentTable';
import { FC } from 'react';
import { useFilteredAgents } from 'selectors/useAgents';
import { RetrieveTypesEnum } from 'types/enum';
import { useNavigate } from 'react-router-dom';

export type AgentListProps = {
  entities?: Analyst[];
  filterOptions?: { label: string; value: string }[];
  hideNewEntityCard?: boolean;
  showFavorite?: boolean;
  showOwner?: boolean;
  onFilter?: (key: string) => void;
};

export const AgentList: FC<AgentListProps> = ({
  entities = undefined,
  filterOptions = undefined,
  showFavorite = false,
  showOwner = true,
  onFilter = undefined,
}) => {
  const navigate = useNavigate();
  const { filtered, filter, loading } = useFilteredAgents();
  const onFilterChange = (key: RetrieveTypesEnum) => {
    if (onFilter) {
      onFilter(key);
    } else {
      if (Object.values(RetrieveTypesEnum).includes(key)) {
        filter(key);
      } else {
        message.error(`Operation "${key}" currently not supported `);
      }
    }
  };

  return (
    <>
      <EntityList
        cardProps={{ showFavorite, showOwner }}
        components={{ Card: AgentCard, Table: AgentTable }}
        entities={entities ?? filtered}
        entityName="agent"
        filterOptions={filterOptions}
        loading={loading}
        newEntityAction="Create new"
        onNewEntity={() => navigate('/agents/new')}
        onFilterChange={onFilterChange}
        projectFilter={false}
      />
    </>
  );
};
