import { AgentList } from 'components/agent-list/AgentList';
import { PageSection } from 'components/page-section/PageSection';

export const AgentsPage = () => {
  return (
    <PageSection heading="Agents">
      <AgentList showFavorite />
    </PageSection>
  );
};
