import { Actions } from 'types/actionTypes';
import { CommonStatusEnum, RetrieveTypesEnum } from 'types/enum';

type BlueprintScope = {
  blueprints: Map<Blueprint['id'], Blueprint>;
  pagination: PaginationType;
  status: CommonStatusEnum;
};

export interface BlueprintState {
  categories: BlueprintCategory[];
  scope: {
    LIBRARY: BlueprintScope;
    [RetrieveTypesEnum.ALL]: BlueprintScope;
    [RetrieveTypesEnum.FAVORITE]: BlueprintScope;
    [RetrieveTypesEnum.RECENT]: BlueprintScope;
  };
  status: CommonStatusEnum;
}

export const inititalBlueprintState: BlueprintState = {
  categories: [],
  scope: {
    LIBRARY: {
      blueprints: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [RetrieveTypesEnum.ALL]: {
      blueprints: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [RetrieveTypesEnum.FAVORITE]: {
      blueprints: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [RetrieveTypesEnum.RECENT]: {
      blueprints: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
  },
  status: CommonStatusEnum.INITIAL,
};

export enum BlueprintActionTypes {
  ARCHIVE_BLUEPRINT = 'ARCHIVE_BLUEPRINT',
  DELETE_BLUEPRINT = 'DELETE_BLUEPRINT',
  FETCHING_BLUEPRINTS = 'FETCHING_BLUEPRINTS',
  FETCH_BLUEPRINTS = 'FETCH_BLUEPRINTS',
  FETCH_BLUEPRINT = 'FETCH_BLUEPRINT',
  FETCH_BLUEPRINT_CATEGORIES = 'FETCH_BLUEPRINT_CATEGORIES',
}

export type BlueprintActions =
  | { type: BlueprintActionTypes.ARCHIVE_BLUEPRINT; payload: Blueprint['id'] }
  | { type: BlueprintActionTypes.DELETE_BLUEPRINT; payload: Blueprint['id'] }
  | { type: BlueprintActionTypes.FETCHING_BLUEPRINTS }
  | {
      type: BlueprintActionTypes.FETCH_BLUEPRINTS;
      payload: {
        blueprints: Blueprint[];
        pagination: PaginationType;
        scope: RetrieveTypesEnum | 'LIBRARY';
      };
    }
  | { type: BlueprintActionTypes.FETCH_BLUEPRINT; payload: Blueprint }
  | { type: BlueprintActionTypes.FETCH_BLUEPRINT_CATEGORIES; payload: BlueprintCategory[] };

export const blueprintsReducer = (state: BlueprintState, action: Actions): BlueprintState => {
  switch (action.type) {
    case BlueprintActionTypes.ARCHIVE_BLUEPRINT:
    case BlueprintActionTypes.DELETE_BLUEPRINT: {
      const BlueprintId = action.payload;
      const allBlueprints = new Map(state.scope[RetrieveTypesEnum.ALL].blueprints);
      const favoriteBlueprints = new Map(state.scope[RetrieveTypesEnum.FAVORITE].blueprints);

      allBlueprints.delete(BlueprintId);
      favoriteBlueprints.delete(BlueprintId);

      return {
        ...state,
        scope: {
          ...state.scope,
          [RetrieveTypesEnum.ALL]: {
            ...state.scope[RetrieveTypesEnum.ALL],
            blueprints: allBlueprints,
          },
          [RetrieveTypesEnum.FAVORITE]: {
            ...state.scope[RetrieveTypesEnum.FAVORITE],
            blueprints: favoriteBlueprints,
          },
        },
      };
    }
    case BlueprintActionTypes.FETCHING_BLUEPRINTS:
      return { ...state, status: CommonStatusEnum.FETCHING };

    case BlueprintActionTypes.FETCH_BLUEPRINTS: {
      const { blueprints, pagination, scope } = action.payload;

      return {
        ...state,
        scope: {
          ...state.scope,
          [scope]: {
            blueprints: new Map(blueprints.map(blueprint => [blueprint.id, blueprint])),
            pagination,
            status: CommonStatusEnum.FETCHED,
          },
        },
        status: CommonStatusEnum.FETCHED,
      };
    }

    case BlueprintActionTypes.FETCH_BLUEPRINT: {
      const blueprint = action.payload;
      const favoriteBlueprints = new Map(state.scope[RetrieveTypesEnum.FAVORITE].blueprints);

      if (blueprint.isFavorite) {
        favoriteBlueprints.set(blueprint.id, blueprint);
      } else {
        favoriteBlueprints.delete(blueprint.id);
      }

      return {
        ...state,
        scope: {
          ...state.scope,
          [RetrieveTypesEnum.ALL]: {
            ...state.scope[RetrieveTypesEnum.ALL],
            blueprints: new Map([...state.scope[RetrieveTypesEnum.ALL].blueprints, [blueprint.id, blueprint]]),
          },
          [RetrieveTypesEnum.FAVORITE]: {
            ...state.scope[RetrieveTypesEnum.FAVORITE],
            blueprints: favoriteBlueprints,
          },
        },
        status: CommonStatusEnum.FETCHED,
      };
    }

    case BlueprintActionTypes.FETCH_BLUEPRINT_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      };
    }

    default:
      return state;
  }
};
