/**
 * This card is styled different than any other cards.
 */

import { Card, ConfigProvider, Flex, Tag, Typography } from 'antd';
import React from 'react';
import styles from './Home.module.scss';
import classNames from 'classnames';
import { ArrowDownIcon } from '@heroicons/react/24/outline';

type Props = {
  collapsed?: boolean;
  disabled?: boolean;
  onClick?(): void;
  selected: boolean;
  tag: string;
  title: string;
  subtitle: string;
};

export const HomeCard: React.FC<Props> = ({ collapsed, disabled, onClick, selected, tag, title, subtitle }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            ...(selected
              ? {
                  colorBgContainer: 'var(--nri-color-primary)',
                }
              : { colorBgContainer: 'var(--nri-color-light-grey)' }),
            ...(collapsed ? { paddingLG: 16 } : {}),
          },
          Typography: selected
            ? {
                colorText: 'var(--nri-color-white)',
                colorTextHeading: 'var(--nri-color-white)',
              }
            : {},
        },
        token: {
          colorText: 'var(--nri-color-purple-grey)',
        },
      }}
    >
      <Card
        bordered={false}
        className={classNames(styles.card, {
          [styles.collapsed]: collapsed,
          [styles.disabled]: disabled,
          [styles.selected]: selected,
        })}
        onClick={onClick}
      >
        <Flex gap="8px" vertical>
          {!collapsed && (
            <div>
              <Tag className={styles.tag}>{tag}</Tag>
            </div>
          )}
          <Typography.Title>{title}</Typography.Title>
          {!collapsed && <Typography.Text>{subtitle}</Typography.Text>}
        </Flex>
        {!collapsed && selected && (
          <div className={styles.downArrow}>
            <ArrowDownIcon />
          </div>
        )}
      </Card>
    </ConfigProvider>
  );
};
