import BookOpenIcon from 'assets/icons/bookOpen.svg?react';
import { BlueprintList } from 'components/blueprint-list/BlueprintList';
import { PageSection } from 'components/page-section/PageSection';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui/Button';

export const BlueprintsPage = () => {
  const navigate = useNavigate();
  return (
    <PageSection
      button={
        <Button bordered onClick={() => navigate('/blueprint/library')} icon={<BookOpenIcon height={20} width={20} />}>
          Library Blueprints
        </Button>
      }
      heading="Blueprints"
    >
      <BlueprintList showFavorite />
    </PageSection>
  );
};
