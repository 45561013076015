import { FC, useCallback } from 'react';
import MoreIcon from 'assets/iconsNewVersion/more.svg?react';
import { ActionsMenu } from 'ui/ActionsMenu';
import NotesIcon from 'assets/iconsNewVersion/note.svg?react';
import TrashIcon from 'assets/iconsNewVersion/trash.svg?react';
import ArrowUp from 'assets/iconsNewVersion/arrow_up_right.svg?react';
import CheckIcon from 'assets/iconsNewVersion/check.svg?react';
import { useConversationFeedbacks } from 'selectors/useFeedbacksSelector';

type ConversationFeedbacksDropdownProps = {
  feedback?: FeedbackData;
};

const items = [
  {
    key: 'open',
    label: 'Open',
    icon: ArrowUp,
    color: 'var(--nri-color-primary)',
    iconColor: 'var(--nri-color-purple-grey)',
  },
  {
    disabled: true,
    key: 'memory',
    label: 'Create memory',
    icon: NotesIcon,
    color: 'var(--nri-color-primary)',
    iconColor: 'var(--nri-color-purple-grey)',
  },
  {
    disabled: true,
    key: 'revised',
    label: 'Check as revised',
    icon: CheckIcon,
    color: 'var(--nri-color-primary)',
    iconColor: 'var(--nri-color-purple-grey)',
  },
  {
    disabled: true,
    key: 'delete',
    label: 'Delete',
    icon: TrashIcon,
    color: 'var(--nri-color-red)',
    iconColor: 'var(--nri-color-red)',
  },
];

export const ConversationFeedbacksDropdown: FC<ConversationFeedbacksDropdownProps> = ({ feedback }) => {
  const selectConversationFeedbacks = useConversationFeedbacks();

  const handleAction = useCallback(
    (event: string) => {
      if (event === 'open') {
        selectConversationFeedbacks(
          Number(feedback?.conversation?.id) as Conversation['id'],
          { skipNavigate: false },
          feedback?.message as MessageId,
        );
      }
    },
    [useConversationFeedbacks],
  );

  return (
    <ActionsMenu
      items={items}
      onSelect={({ key }) => {
        handleAction(key);
      }}
    >
      <MoreIcon style={{ width: '16px', height: '16px' }} />
    </ActionsMenu>
  );
};
