import { PoweroffOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useToggleAgentEnabled } from 'selectors/useAgents';
import { useModal } from 'selectors/useModals';

export const ToggleAgentModal: React.FC = () => {
  const { data: agent, hide } = useModal<Agent>(ModalScopesEnum.AGENTS, ModalTypesEnum.TOGGLE);
  const { name } = agent ?? {};
  const [processing, setProcessing] = useState(false);
  const toggle = useToggleAgentEnabled();
  const action = agent.isEnabled ? 'disable' : 'enable';

  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await toggle(agent);

    const newState = !agent.isEnabled; // FIXME: Should toggle return the new state?
    notification.open({
      icon: <PoweroffOutlined />,
      message: <strong>Agent {newState ? 'enabled' : 'disabled'}</strong>,
      description: (
        <>
          <strong>{name}</strong> was {newState ? 'enabled' : 'disabled'}.
        </>
      ),
    });

    hide();
    setProcessing(false);
  }, [agent, hide, name, toggle]);

  return (
    <ConfirmationModal
      key={'agent::toggle'}
      confirmationButton={processing ? (agent.isEnabled ? 'Disabling' : 'Enabling') : `Yes, ${action}`}
      isOpen
      title={`${action.charAt(0).toUpperCase() + action.slice(1)} agent`}
      description={
        <>
          Are you sure you want to {action} this agent <strong>{agent.name}</strong>?
        </>
      }
      onCancel={hide}
      onConfirm={onConfirm}
      processing={processing}
    />
  );
};
