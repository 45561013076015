import { Actions } from 'types/actionTypes';
import { CommonStatusEnum } from 'types/enum';

export interface StatisticsState {
  dailyCredits: { status: CommonStatusEnum; data: Map<string, { date: string; credits: number }> };
  creditsInfo: { status: CommonStatusEnum; data: { [key: string]: number } };
}

export const initialStatisticsState: StatisticsState = {
  dailyCredits: {
    status: CommonStatusEnum.INITIAL,
    data: new Map(),
  },
  creditsInfo: {
    status: CommonStatusEnum.INITIAL,
    data: {},
  },
};

export enum StatisticsActionTypes {
  FETCHING_DAILY_CREDITS = 'FETCHING_DAILY_CREDITS',
  FETCH_DAILY_CREDITS = 'FETCH_DAILY_CREDITS',
  FETCHING_CREDITS_INFO = 'FETCHING_CREDITS_INFO',
  FETCH_CREDITS_INFO = 'FETCH_CREDITS_INFO',
  CLEAN_DAILY_CREDITS = 'CLEAN_DAILY_CREDITS',
}

export type StatisticsActions =
  | { type: StatisticsActionTypes.FETCHING_DAILY_CREDITS }
  | { type: StatisticsActionTypes.FETCH_DAILY_CREDITS; payload: Array<{ date: string; credits: number }> }
  | { type: StatisticsActionTypes.CLEAN_DAILY_CREDITS }
  | { type: StatisticsActionTypes.FETCHING_CREDITS_INFO }
  | { type: StatisticsActionTypes.FETCH_CREDITS_INFO; payload: { [key: string]: number } };

export const statisticsReducer = (state: StatisticsState, action: Actions): StatisticsState => {
  switch (action.type) {
    case StatisticsActionTypes.FETCHING_DAILY_CREDITS:
      return { ...state, dailyCredits: { status: CommonStatusEnum.FETCHING, data: new Map() } };
    case StatisticsActionTypes.FETCH_DAILY_CREDITS:
      return {
        ...state,
        dailyCredits: {
          status: CommonStatusEnum.FETCHED,
          data: new Map(action.payload.map(day => [day.date, { date: day.date, credits: day.credits }])),
        },
      };
    case StatisticsActionTypes.CLEAN_DAILY_CREDITS:
      return { ...state, dailyCredits: { status: CommonStatusEnum.INITIAL, data: new Map() } };
    case StatisticsActionTypes.FETCHING_CREDITS_INFO:
      return { ...state, creditsInfo: { status: CommonStatusEnum.FETCHING, data: {} } };
    case StatisticsActionTypes.FETCH_CREDITS_INFO:
      return { ...state, creditsInfo: { status: CommonStatusEnum.FETCHED, data: action.payload } };
    default:
      return state;
  }
};
