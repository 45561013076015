import { AuthActionTypes } from "types/actionTypes";

export interface AuthState {
    authenticated: boolean;
    loading: boolean;
    user?: UserEntity;
}

export type AuthAction =
    | { type: AuthActionTypes.SET_AUTHENTICATED; payload: boolean }
    | { type: AuthActionTypes.SET_USER; payload: UserEntity }
    | { type: AuthActionTypes.SET_LOADING; payload: boolean };

export const authReducer = (
    state: AuthState,
    action: AuthAction
): AuthState => {
    switch (action.type) {
        case AuthActionTypes.SET_AUTHENTICATED:
            return { ...state, authenticated: action.payload };
        case AuthActionTypes.SET_USER:
            return { ...state, user: action.payload };
        case AuthActionTypes.SET_LOADING:
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};