import { BlueprintCard } from 'components/blueprint-card/BlueprintCard';
import { EntityList } from 'components/entity-list/EntityList';
import { message } from 'antd';
import { BlueprintTable } from 'components/blueprint-table/BlueprintTable';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFilteredBlueprints } from 'selectors/useBlueprints';
import { RetrieveTypesEnum } from 'types/enum';

export type BlueprintListProps = {
  entities?: Blueprint[];
  filterOptions?: { label: string; value: string }[];
  hideNewEntityCard?: boolean;
  onFilter?: (key: string) => void;
  showCategory?: boolean;
  showFavorite?: boolean;
  showOwner?: boolean;
};

export const BlueprintList: FC<BlueprintListProps> = ({
  entities = undefined,
  filterOptions = undefined,
  hideNewEntityCard = false,
  onFilter = undefined,
  showCategory = false,
  showFavorite = false,
  showOwner = true,
}) => {
  const navigate = useNavigate();
  const { filtered, filter, loading } = useFilteredBlueprints();
  const onFilterChange = (key: RetrieveTypesEnum) => {
    if (onFilter) {
      onFilter(key);
    } else {
      if (Object.values(RetrieveTypesEnum).includes(key)) {
        filter(key);
      } else {
        message.error(`Operation "${key}" currently not supported `);
      }
    }
  };

  return (
    <>
      <EntityList
        cardProps={{ showCategory, showFavorite, showOwner }}
        components={{ Card: BlueprintCard, Table: BlueprintTable }}
        entities={entities ?? filtered}
        entityName="blueprint"
        filterOptions={filterOptions}
        loading={loading}
        onFilterChange={onFilterChange}
        onNewEntity={hideNewEntityCard ? undefined : () => navigate('/blueprints/new')}
        projectFilter={!hideNewEntityCard}
        showEmptyPlaceholder={false}
      />
    </>
  );
};
