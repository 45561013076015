import { Checkbox, Space } from 'antd';
import Avatar from 'antd/es/avatar/avatar';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { useSharedEntities } from 'selectors/useSharedEntitiesSelector';
import styles from './ManageConversationAccessModal.module.scss';
import { SelectedUser } from './SelectedUser';
import { Select } from 'ui/Select';
import { useConversationContainer, useShareConversation } from 'selectors/useConversationSelectors';

type ShareType = 'users' | 'organization';

const SHARE_OPTIONS = [
  { label: 'Specify users', value: 'users' },
  { label: 'Share with organization', value: 'organization', disabled: true },
];

export const ManageConversationAccess = () => {
  const { data: conversation, hide } = useModal<Conversation>(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.MANAGE);
  const getConversationContainer = useConversationContainer();
  const currentShareEntities = getConversationContainer(conversation.id)?.permissions;

  const { me, sharedEntities } = useSharedEntities();
  const [shareType, setShareType] = useState<ShareType>('users');
  const [selectedUsers, setSelectedUsers] = useState<ShareableEntity[]>(currentShareEntities || []);
  const [filteredEntities, setFilteredEntities] = useState<ShareableEntity[]>(sharedEntities);
  const [processing, setProcessing] = useState(false);
  const shareConversation = useShareConversation();

  const handleSearch = useCallback(
    (value: string) => {
      const filtered = sharedEntities?.filter(entity => entity.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredEntities(filtered);
    },
    [sharedEntities],
  );

  const handleUserChange = useCallback((user: ShareableEntity, action: 'add' | 'remove') => {
    setSelectedUsers(prev => {
      const exists = prev.some(u => u.id === user.id && u.type === user.type);

      if (action === 'add' && !exists) {
        return [...prev, user];
      }
      if (action === 'remove' && exists) {
        return prev.filter(u => u.id !== user.id || u.type !== user.type);
      }
      return prev;
    });
  }, []);

  const handleSave = useCallback(async () => {
    setProcessing(true);
    try {
      // Add your save logic here
      await shareConversation(conversation.id, selectedUsers);
      hide();
    } finally {
      setProcessing(false);
    }
  }, [conversation.id, hide, selectedUsers, shareConversation]);

  const renderUserItem = useCallback(
    ({ id, name, picture, type, ...rest }: ShareableEntityProps) => (
      <div className={styles.userItem} key={`${type}-${id}`}>
        <Checkbox
          checked={selectedUsers.some(u => u.id === id && u.type === type)}
          onChange={() => handleUserChange({ id, name, picture, type, ...rest }, 'add')}
        />
        {picture ? (
          <Avatar size={24} src={picture} />
        ) : (
          <Avatar className={styles.emptyAvatar} size={24}>
            {name.charAt(0).toUpperCase()}
          </Avatar>
        )}
        {name}
      </div>
    ),
    [handleUserChange, selectedUsers],
  );

  return (
    <ConfirmationModal
      isOpen
      title="Manage user access"
      confirmationButton={processing ? 'Saving' : 'Save'}
      description={
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          Here you can manage access to this conversation
          <Space direction="vertical" style={{ width: '100%' }}>
            <div>Share with</div>
            <Select value={shareType} onChange={setShareType} options={SHARE_OPTIONS} style={{ width: '100%' }} />
          </Space>
          {shareType === 'users' && (
            <div>
              <div>Invite users to view conversation</div>
              <Select
                onSearch={handleSearch}
                dropdownRender={() => (
                  <div className={styles.userList}>
                    {filteredEntities.length > 0 ? (
                      filteredEntities.map(renderUserItem)
                    ) : (
                      <div className={styles.userItem}>No user found</div>
                    )}
                  </div>
                )}
                mode="multiple"
                placeholder="Select user"
                className={styles.select}
              />
              <div className={styles.selectedUserContainer}>
                <SelectedUser key={`${me.type}-${me.id}`} user={me!} />
                {selectedUsers.map(user => (
                  <SelectedUser
                    key={`${user.type}-${user.id}`}
                    onDelete={user => handleUserChange(user, 'remove')}
                    user={user}
                  />
                ))}
              </div>
            </div>
          )}
        </Space>
      }
      onCancel={hide}
      onConfirm={handleSave}
      processing={processing}
    />
  );
};
