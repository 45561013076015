import { Badge, Card, ConfigProvider, Flex, Typography } from 'antd';
import classNames from 'classnames';
import styles from './ProjectsView.module.scss';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type ProjectCardProps = {
  project: ProjectLabel;
};

export const ProjectCard: FC<ProjectCardProps> = ({ project }) => {
  const navigate = useNavigate();

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            colorBorderSecondary: 'var(--nri-color-grey-stroke)',
          },
          Tooltip: {},
        },
        token: {
          colorText: 'var(--nri-color-primary)',
          fontSize: 18,
        },
      }}
    >
      <Card className={classNames(styles.projectCard)} onClick={() => navigate(`/projects/${project.id}`)}>
        <Flex gap={12} style={{ height: '100%' }} vertical>
          <Badge
            size="small"
            className={styles.projectCardTitle}
            text={project.name}
            color={project.color ?? 'var(--nri-color-purple-grey)'}
          />
          <Typography.Paragraph
            ellipsis={{
              rows: 2,
              suffix: '',
              tooltip: {
                color: 'var(--nri-color-primary)',
                overlayInnerStyle: {
                  fontSize: 'var(--nri-font-size-sm)',
                  color: 'var(--nri-color-white)',
                },
              },
            }}
            className={styles.projectCardDescription}
          >
            {project.description}
          </Typography.Paragraph>
        </Flex>
      </Card>
    </ConfigProvider>
  );
};
