export const highlightText = (text: string, highlight: string) => {
  if (!highlight || !text) return text;
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);
  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <span key={index} style={{ backgroundColor: 'var(--nri-color-selection)' }}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
};
