import { theme, ThemeConfig } from 'antd';

export const nriTheme: ThemeConfig = {
  algorithm: theme.defaultAlgorithm,
  cssVar: true,
  token: {
    colorBgContainer: 'transparent',
    colorPrimary: 'var(--nri-color-primary)',
    colorPrimaryBg: 'var(--nri-color-primary)',
    colorPrimaryHover: 'var(--nri-color-primary-light)',
    colorSplit: 'var(--nri-color-grey-stroke)',
    colorTextHeading: 'var(--nri-color-primary)',
    controlHeight: 44,
    fontFamily: 'var(--nri-font-family)',
    // @ts-expect-error type string is not assignable to type number, still works
    fontSizeHeading1: 'var(--nri-text-heading-1-font-size)',
    fontSize: 14,
    fontSizeHeading2: 18,
    fontWeightStrong: 500,
    // @ts-expect-error type string is not assignable to type number, still works
    lineHeight: '24px',
  },

  components: {
    Layout: {
      bodyBg: 'var(--nri-color-white)',
      paddingContentHorizontal: 24,
      headerHeight: 'var(--nri-conversation-header-height)',
      headerBg: 'var(--nri-color-white)',
      siderBg: 'var(--nri-color-light-grey)',
      triggerBg: 'var(--nri-color-light-grey)',
      triggerColor: 'var(--nri-color-primary)',
      triggerHeight: 64,
    },
    Alert: {
      colorWarning: '#EAC84F',
      colorWarningBg: '#EAC84F1F',
      colorWarningBorder: '#EAC84F',
      // @ts-expect-error type string is not assignable to type number, still works
      lineHeight: '24px',
    },
    Avatar: {
      groupBorderColor: 'white',
      groupOverlapping: -14,
    },
    Collapse: {
      borderRadiusLG: 12,
      controlItemBgActive: 'var(--nri-color-light-grey)',
      fontSize: 16,
      fontWeightStrong: 600,
      headerPadding: '6px 16px',
    },
    Input: {
      activeBorderColor: 'var(--nri-color-primary-light)',
      hoverBorderColor: 'var(--nri-color-purple-grey)',
      // @ts-expect-error type string is not assignable to type number, still works
      paddingBlock: '8px',
      // @ts-expect-error type string is not assignable to type number, still works
      paddingInline: '12px',
      // @ts-expect-error type string is not assignable to type number, still works
      inputFontSize: '14px',
      colorFillTertiary: 'var(--nri-color-light-grey)',
      colorPrimary: 'var(--nri-color-primary)',
      colorText: 'var(--nri-color-primary)',
      colorTextPlaceholder: 'var(--nri-color-purple-grey)',
      fontFamily: 'var(--nri-font-family)',
    },
    Button: {
      borderColorDisabled: 'var(--nri-color-grey)',
      colorBgContainerDisabled: 'var(--nri-color-grey)',
      colorTextDisabled: 'var(--nri-color-disabled-text)',
      // FIXME: For some reason, this can not inherit from the global colorPrimary token
      colorPrimary: 'var(--nri-color-primary)',
      // @ts-expect-error type string is not assignable to type number, still works
      contentFontSize: 'var(--nri-text-buttons-font-size)',
      // @ts-expect-error type string is not assignable to type number, still works
      contentLineHeight: '42px', // 44px button height
      colorText: 'var(--nri-color-primary)',
      // This is the "Secondary" theme in Figma
      defaultBorderColor: 'var(--nri-color-primary)',
      defaultActiveBg: 'var(--nri-color-purple-grey)',
      defaultActiveBorderColor: 'var(--nri-color-purple-grey)',
      defaultActiveColor: 'var(--nri-color-grey)',
      defaultHoverBg: 'var(--nri-color-white)',
      defaultHoverColor: 'var(--nri-color-primary-light)',
      fontWeight: 'var(--nri-text-buttons-font-weight)',
      paddingBlock: 0,
      paddingInline: 'var(--nri-button-padding-x)',
      primaryColor: 'var(--nri-color-white)',
      textHoverBg: 'transparent',
      // TODO: Figure out how to set text color on hover
      textTextColor: 'var(--nri-color-primary)',
    },
    Card: {
      actionsBg: 'var(--nri-card-footer-background)',
      colorBgContainer: 'var(--nri-card-background)',
      headerFontSize: 'var(--nri-card-header-font-size)',
    },
    // @ts-expect-error type string is not assignable to type number, still works
    Divider: { marginLG: '10px' },
    // TODO: open styles
    Dropdown: {
      // @ts-expect-error type string is not assignable to type number, still works
      borderRadiusSM: '10px',
      boxShadow: 'none',
      // @ts-expect-error type string is not assignable to type number, still works
      lineHeight: '34px',
      controlItemBgHover: 'var(--nri-color-light-grey)',
      // @ts-expect-error type string is not assignable to type number, still works
      controlHeight: '100px',
      // @ts-expect-error type string is not assignable to type number, still works
      controlPaddingHorizontal: '10px',
      paddingBlock: '4px',
    },
    Form: { verticalLabelPadding: '0 0 4px' },
    Modal: {
      colorBgMask: '#918CA652',
      contentPadding: '20px',
      // @ts-expect-error type string is not assignable to type number, still works
      titleFontSize: 'var(--nri-text-heading-1-font-size)',
    },
    Radio: {
      buttonBg: 'var(--nri-color-light-grey)',
      buttonCheckedBg: 'var(--nri-color-light-grey)',
      colorBorder: 'var(--nri-color-light-grey)',
      colorPrimary: 'var(--nri-color-light-grey)',
      colorPrimaryHover: 'var(--nri-color-light-grey)',
      // @ts-expect-error type string is not assignable to type number, still works
      buttonPaddingInline: '12px',
    },
    Segmented: {
      boxShadowTertiary: 'none',
      itemActiveBg: 'unset',
      itemColor: 'var(--nri-color-purple-grey)',
      itemHoverBg: 'unset',
      itemSelectedColor: 'var(--nri-color-primary)',
      trackBg: 'var(--nri-color-light-grey)',
    },
    Select: {
      optionPadding: '0 12px',
    },
    Switch: {
      handleBg: 'var(--nri-color-purple-grey)',
      handleShadow: 'none',
      colorPrimaryHover: 'var(--nri-color-primary)',
      colorTextTertiary: 'white',
      colorTextQuaternary: 'white',
      handleSize: 16,
      trackHeight: 20,
      trackMinWidth: 32,
    },

    Table: { rowHoverBg: 'var(--nri-color-light-grey)' },
    Tabs: { titleFontSize: 14 },
    Tag: {
      defaultBg: 'var(--nri-color-grey)',
      defaultColor: 'var(--nri-color-purple-grey)',
      colorBorder: 'var(--nri-color-purple-grey)',
      // @ts-expect-error type string is not assignable to type number, still works
      lineHeightSM: 'var(--nri-text-tags-line-height)',
    },
    Tooltip: {
      controlHeight: 24,
    },
    Typography: {
      titleMarginBottom: 0,
    },
  },
};
