import { Card, Flex, Space, Tag, Typography } from 'antd';
import classNames from 'classnames';
import styles from 'components/entity-list/EntityList.module.scss';

export type EntityCardTag = {
  icon?: React.ReactNode;
  label: string;
};

type EntityCardProps = {
  actionsMenu?: React.ReactNode;
  avatar?: React.ReactNode;
  customStyles?: Record<string, string>;
  description: React.ReactNode;
  /** The number of lines to show in the description */
  descriptionLines?: number;
  footer?: React.ReactNode;
  height?: number;
  icon?: React.ReactNode;
  tags?: EntityCardTag[];
  selected?: boolean;
  string?: string;
  showTags?: boolean;
  title?: React.ReactNode;
  titleLines?: number;
  onClick?: () => void;
};

export const EntityCard: React.FC<EntityCardProps> = ({
  actionsMenu,
  avatar,
  description,
  descriptionLines = 4,
  footer,
  height,
  icon,
  tags = [],
  selected,
  title: titleProp,
  titleLines = 1,
  onClick,
}) => {
  const title = (
    <Flex align="center" gap="4px" style={actionsMenu ? { width: 'calc(100% - 40px)' } : {}}>
      {icon ? <div className={styles.cardIcon}>{icon}</div> : null}
      <Typography.Title aria-hidden className={styles.cardTitle} ellipsis={{ rows: titleLines }} level={2}>
        {titleProp}
      </Typography.Title>
    </Flex>
  );

  return (
    <Card
      className={classNames(styles.card, {
        [styles.clickable]: !!onClick,
        [styles.selected]: selected,
        [styles.tags]: tags.length > 0,
      })}
      onClick={onClick}
      style={{ height: height ? `${height}px` : undefined }}
    >
      <div className={styles.cardContent}>
        <div
          className={classNames(styles.cardBody, {
            [styles.tags]: tags.length > 0,
            [styles.short]: !!avatar,
          })}
        >
          <Flex justify="space-between" className={styles.cardTitleContainer}>
            {tags.length > 0 ? (
              <Space direction="vertical">
                <Space>
                  {tags.map(tag => (
                    <Tag bordered={false} className={styles.tag} icon={tag.icon}>
                      &nbsp;{tag.label}
                    </Tag>
                  ))}
                </Space>
                {title}
              </Space>
            ) : (
              title
            )}
            {actionsMenu ? actionsMenu : null}
          </Flex>
          {description && (
            <Typography.Paragraph className={styles.cardDescription} ellipsis={{ rows: descriptionLines }}>
              {description}
            </Typography.Paragraph>
          )}
        </div>
        <div>
          <div className={styles.cardAvatar}>{avatar}</div>
          <Flex className={styles.cardFooter} justify="space-between" align="center">
            {footer}
          </Flex>
        </div>
      </div>
    </Card>
  );
};
