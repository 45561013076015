import { message } from 'antd';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { NewtonApi } from 'utils/newtonApi';

// Extend cache to store both data and blob URLs
interface CacheEntry {
  data: unknown;
  blobUrl?: string;
}

// Update cache type
const displayObjectCache = new Map<number, CacheEntry>();

export function useDisplayObject(displayObject?: DisplayObject | DisplayObject[]): unknown {
  const displayObjects = useMemo<DisplayObject[]>(
    () => (Array.isArray(displayObject) ? displayObject : [displayObject!]).filter(x => x?.id),
    [displayObject],
  );

  const initialData = useMemo(() => {
    const cachedResults = displayObjects.map(obj =>
      displayObjectCache.has(obj.id) ? displayObjectCache.get(obj.id)?.data : null,
    );

    const needsFetch = cachedResults.some(result => result === null);
    
    return {
      data: needsFetch ? null : (displayObjects.length === 1 ? cachedResults[0] : cachedResults),
      isFetching: needsFetch
    };
  }, [displayObjects]);

  const [state, setState] = useState(initialData);

  // Cleanup blob URLs when component unmounts
  useEffect(() => {
    return () => {
      displayObjects.forEach(obj => {
        const cached = displayObjectCache.get(obj.id);
        if (cached?.blobUrl) {
          URL.revokeObjectURL(cached.blobUrl);
        }
      });
    };
  }, [displayObjects]);

  const fetch = useCallback(async () => {
    if (!displayObjects.length || !state.isFetching) return;

    try {
      const response = await Promise.all(
        displayObjects.map(async obj => {
          if (displayObjectCache.has(obj.id)) {
            return displayObjectCache.get(obj.id)?.data;
          } else {
            const result = await NewtonApi.fetchDisplayObject(obj);
            
            // For images, the API returns a presigned URL directly
            if (obj.type === 'png' || obj.type === 'jpg' || obj.type === 'jpeg' || obj.type === 'gif') {
              displayObjectCache.set(obj.id, { data: result });
              return result;
            } else {
              // For other types (python, csv, etc), we get the text content
              displayObjectCache.set(obj.id, { data: result });
              return result;
            }
          }
        }),
      );
      setState({
        data: displayObjects.length === 1 ? response[0] : response,
        isFetching: false
      });
    } catch (e) {
      setState({ data: false, isFetching: false });
      console.error('Error fetching display objects', e);
    }
  }, [displayObjects, state.isFetching]);

  useEffect(() => {
    if (state.isFetching) {
      fetch();
    }
  }, [fetch, state.isFetching]);

  if (!displayObjects.length) return false;

  return { data: state.data, displayObjects, fetch };
}

export const useDownloadDisplayObject = () => {
  return useCallback(async (displayObject: DisplayObject) => {
    await NewtonApi.downloadDisplayObject(displayObject);
    message.config({
      top: 600,
      duration: 4,
    });
    message.success('File downloaded');
  }, []);
};
