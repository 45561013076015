import { DatabaseOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { DatasourceCard } from 'components/datasource-card/DatasourceCard';
import { EntityList, EntityListProps } from 'components/entity-list/EntityList';
import { useCallback, useMemo, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useFilterDataSources } from 'selectors/useDataSourcesSelector';
import { useModal } from 'selectors/useModals';
import { RetrieveTypesEnum } from '../../types/enum';
import { DataSourceTable } from 'components/datasource-table/DatasourceTable';

const enhanceDataSource = (
  source: DataSource,
): DataSource & {
  color: string;
  icon: React.ComponentType;
} => ({
  ...source,
  icon: DatabaseOutlined,
  color: ['var(--nri-color-primary-vibrant)', 'var(--nri-color-primary)', 'var(--nri-color-secondary)'][source.id! % 3],
});

type DataSourcesListProps = Omit<EntityListProps<DataSource>, 'components' | 'entityName' | 'onFilterChange'> & {
  entries?: DataSource[] | null;
  lockedSelections?: DataSource[];
  onNewEntityConfirm?(dataSource: DataSource): void;
  onSelect?: (dataSources: DataSource[]) => void;
};

export const DataSourceList = ({
  entries = null,
  selected = [],
  lockedSelections = [],
  newEntityDetails = (
    <div>
      Supported formats: CSV or TXT
      <br />
      Maximum file size: 50MB
    </div>
  ),
  onNewEntityConfirm = () => {},
  onSelect,
  projectFilter = true,
  viewOptions = true,
  ...props
}: DataSourcesListProps) => {
  const { show: showAddDataSourcesModal } = useModal(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.ADD);
  const { filteredDataSources, filterDataSources, loading } = useFilterDataSources();
  const [selections, setSelections] = useState<DataSource[]>(selected);

  const enhancedSources = useMemo(() => {
    const list = entries !== null ? entries : filteredDataSources;
    return list.map(enhanceDataSource) || [];
  }, [entries, filteredDataSources]);

  const onCardSelect = useCallback(
    (entity: DataSource) => {
      const isSelected = !!selections.find(ds => ds.id === entity.id);
      const selected = isSelected ? selections.filter(item => item.id !== entity.id) : [...selections, entity];

      setSelections(selected);
      onSelect?.(selected);
    },
    [onSelect, selections],
  );

  const onFilterChange = (key: RetrieveTypesEnum) => {
    if (Object.values(RetrieveTypesEnum).includes(key)) {
      filterDataSources(key);
    } else {
      message.error(`Operation "${key}" currently not supported `);
    }
  };

  return (
    <EntityList
      components={{ Card: DatasourceCard, Table: DataSourceTable }}
      entities={enhancedSources}
      entityName="connector"
      loading={loading}
      locked={lockedSelections}
      newEntityDetails={newEntityDetails}
      onCardClick={onSelect ? onCardSelect : undefined}
      onFilterChange={onFilterChange}
      onNewEntity={() =>
        showAddDataSourcesModal({
          onConfirm: onNewEntityConfirm,
        })
      }
      projectFilter={projectFilter}
      selected={selections}
      showEmptyPlaceholder={false}
      viewOptions={viewOptions}
      {...props}
    />
  );
};
