import { Typography } from 'antd';
import { InputProps } from 'antd/lib/input';
import React, { useState } from 'react';
import EditIcon from '../assets/iconsNewVersion/edit.svg?react';
import { InputSimple } from './Input';

interface InputInlineEditProps extends InputProps {
  // Any additional props can be added here
}

export const InputInlineEdit: React.FC<InputInlineEditProps> = ({ onChange, value, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div
      style={{
        borderBottom: isEditing ? '1px dashed var(--nri-color-grey-stroke)' : 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      {isEditing ? (
        <InputSimple
          autoFocus
          {...props}
          value={value}
          onChange={onChange}
          onBlur={() => setIsEditing(false)}
          onPressEnter={() => setIsEditing(false)}
          style={{ paddingLeft: 0, width: '100%' }}
        />
      ) : (
        <Typography.Text
          style={{ 
            fontWeight: 'bold', 
            cursor: 'pointer',
            maxWidth: 'calc(100% - 24px)', // 24px = icon width (16px) + margin (8px)
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          onClick={e => {
            e.stopPropagation();
            setIsEditing(true);
          }}
        >
          {`${value}`}
        </Typography.Text>
      )}
      {!isEditing && (
        <EditIcon
          tabIndex={0}
          onKeyDown={() => setIsEditing(true)}
          onClick={e => {
            e.stopPropagation();
            setIsEditing(true);
          }}
          style={{
            cursor: 'pointer',
            flexShrink: 0,
            width: '16px',
            height: '16px',
          }}
          aria-label="Edit"
        />
      )}
    </div>
  );
};

export default InputInlineEdit;
