import { ConfigProvider, Flex, Table, Typography } from 'antd';
import styles from './Memories.module.scss';
import { useFilteredMemories } from 'selectors/userMemories';
import { TableProps } from 'antd/lib';
import { MemoriesDropdown } from './MemoriesDropdown';
import { useState } from 'react';
import { Button } from 'ui/Button';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';

export const Memories = () => {
  const [selectedRow, setSelectedRow] = useState<undefined | number>();
  const [page, setPage] = useState(1);
  const { results, pagination, loading } = useFilteredMemories(page);
  const { show: ShowAddMemoryModal } = useModal(ModalScopesEnum.MEMORIES, ModalTypesEnum.ADD);

  const handleShowMenu = (id: number, action: string) => {
    if (!action) return;

    if (action === 'open') {
      setSelectedRow(id);
    } else {
      setSelectedRow(undefined);
    }
  };

  const columns: TableProps['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 80,
      render: value => (
        <Typography.Paragraph
          ellipsis={{ rows: 1 }}
          style={{
            fontSize: '12px',
            margin: 0,
            fontWeight: 'var(--nri-weight-medium)',
            color: 'var(--nri-color-primary)',
          }}
        >
          {value}{' '}
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'Question',
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Text + code',
      dataIndex: 'textCode',
      key: 'textCode',
      width: 120,
      ellipsis: true,
    },

    {
      title: '',
      dataIndex: 'x',
      key: 'actions',
      width: 15,
      render: data => {
        return (
          <div
            style={{
              width: '25px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {selectedRow === data.id && <MemoriesDropdown memory={data} />}
          </div>
        );
      },
    },
  ];
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <Flex vertical className={styles.memories}>
      <Button className={styles.createButton} type="primary" onClick={ShowAddMemoryModal}>
        Create new memory
      </Button>
      <div>
        <ConfigProvider
          theme={{
            token: { colorText: 'var(--nri-color-purple-grey)' },
            components: {
              Table: {
                headerColor: 'var(--nri-color-purple-grey)',
                headerBg: 'var(--nri-color-light-grey)',
                headerSplitColor: 'transparent',
                cellPaddingBlock: 16,
                cellPaddingInline: 16,
                cellFontSize: 12,
              },
            },
          }}
        >
          <Table
            onRow={row => {
              return {
                onMouseEnter: () => handleShowMenu(row.id, 'open'),
                onMouseLeave: () => handleShowMenu(row.id, 'close'),
              };
            }}
            tableLayout="fixed"
            loading={loading}
            className={styles.memoriesTable}
            columns={columns}
            dataSource={results?.map((memory: Memory) => {
              return {
                id: memory.id,
                name: memory.name,
                question: memory.index,
                textCode: memory.value,
                x: memory,
              };
            })}
            scroll={{ x: 1100 }}
            pagination={{
              total: pagination?.count,
              pageSize: 10,
              current: page,
              onChange: handlePageChange,
            }}
          />
        </ConfigProvider>
      </div>
    </Flex>
  );
};
