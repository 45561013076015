import { notification } from 'antd';
import CopyIcon from 'assets/icons/copy.svg?react';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useDuplicateConversation } from 'selectors/useConversationSelectors';
import { useModal } from 'selectors/useModals';

export const DuplicateConversationModal: React.FC = () => {
  const { data: conversation, hide } = useModal<Conversation>(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.DUPLICATE);
  const [processing, setProcessing] = useState(false);
  const duplicate = useDuplicateConversation(conversation.id);

  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await duplicate();

    notification.open({
      icon: <CopyIcon />,
      message: <strong>Conversation duplicated</strong>,
      description: (
        <>
          Copy of <strong>{conversation.name}</strong> was created.
        </>
      ),
    });

    hide();
    setProcessing(false);
  }, [conversation.name, duplicate, hide]);

  return (
    <ConfirmationModal
      key={'conversation::duplicate'}
      confirmationButton={processing ? 'Duplicating' : 'Duplicate'}
      isOpen
      title={'Duplicate Conversation'}
      description={
        <>
          Do you want to duplicate <strong>{conversation.name}</strong> ?
        </>
      }
      onCancel={hide}
      onConfirm={onConfirm}
      processing={processing}
    />
  );
};
