import React, { useMemo, useState } from 'react';
import { Flex, Form, Input, Typography } from 'antd';
import { useProjectLabels } from 'selectors/useProjectLabels';
import { Select } from 'ui/Select';
import styles from './Steps.module.scss';
import { useEntityContext } from 'components/layouts/context/EntityProvider';
import { BlueprintFormData } from 'components/blueprint-view/BlueprintView';
import { DefaultOptionType } from 'antd/es/select';
import { ProjectLabel } from 'ui/ProjectLabel';
import { useAuth } from 'selectors/useAuthSelector';

export const GeneralStep: React.FC = () => {
  const { me } = useAuth();
  const { projectLabels } = useProjectLabels();
  const { form } = useEntityContext<BlueprintFormData>();
  const [projectOptions, setProjectOptions] = useState<ProjectLabel[]>(projectLabels || []);
  const [selectedProjectLabelIds, setSelectedProjectLabelIds] = useState<ProjectLabelId[]>(
    form.getFieldValue('projectLabels') || [],
  );

  const selectedProjectLabels = useMemo(() => {
    return selectedProjectLabelIds.map(id => projectLabels.find(l => l.id === id)!);
  }, [selectedProjectLabelIds, projectLabels]);

  const handleSelectProject = (_: string, option: DefaultOptionType) => {
    setSelectedProjectLabelIds(prev => [...prev, option.value as ProjectLabelId]);

    form.setFieldValue('projectLabels', [...selectedProjectLabelIds, option.value]);
    setProjectOptions(prev => prev.filter(opt => opt.id !== (option.value as ProjectLabelId)));
  };

  const handleRemoveProject = (label: ProjectLabel) => {
    setProjectOptions(prev => {
      const repeatLabel = prev.find(l => l.id === label.id);
      return [...prev, ...(!repeatLabel ? [label] : [])];
    });
    setSelectedProjectLabelIds(prev => prev.filter(l => l !== label.id));
    form.setFieldValue(
      'projectLabels',
      selectedProjectLabels.filter(l => l.id !== label.id),
    );
  };

  return (
    <>
      <Form.Item name="owner" hidden>
        <Input value={me!.id} />
      </Form.Item>
      <Form.Item label="Title" name="name" rules={[{ required: true, message: 'Please enter the title' }]}>
        <Input autoFocus />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="projectLabels" label="Projects">
        <Select
          //   className={classNames({ [styles.disableField]: !isEditing })}
          //   disabled={!isEditing}
          placeholder="Add to project"
          options={projectOptions
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(option => ({ label: option.name, value: option.id, color: option.color }))}
          value={0}
          onSelect={handleSelectProject}
          labelRender={() => <Typography.Text className={styles.selectLabel}>Add to project</Typography.Text>}
          showSearch={false}
        />
      </Form.Item>

      <Flex gap={8} wrap="wrap" className={styles.projectLabelsContainer}>
        {selectedProjectLabels.map(label => (
          <ProjectLabel
            // readOnly={!isEditing}
            key={label.id}
            text={label.name}
            color={label.color}
            filled={true}
            onRemove={() => handleRemoveProject(label)}
          />
        ))}
      </Flex>
    </>
  );
};
