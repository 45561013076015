import { Layout } from 'antd';
import { memo, PropsWithChildren } from 'react';
import { useSharedEntities } from 'selectors/useSharedEntitiesSelector';
import { Loader } from './loader/Loader';
import { useAnalysts } from 'selectors/useAnalystsSelector';
import { useBlueprints } from 'selectors/useBlueprints';
import { useDataSources } from 'selectors/useDataSourcesSelector';
import { useProjectLabels } from 'selectors/useProjectLabels';
import { useMemories } from 'selectors/userMemories';
import { useAgents } from 'selectors/useAgents';
import { useCreditsInfo } from 'selectors/useStatisticsSelector';

export const Preload: React.FC<PropsWithChildren> = memo(({ children }) => {
  const analystsLoading = useAnalysts().loading;
  const agentsLoading = useAgents().loading;
  const blueprintsLoading = useBlueprints().loading;
  const dataSourcesLoading = useDataSources().loading;
  const memoriesLoading = useMemories().loading;
  const projectLabelsLoading = useProjectLabels().loading;
  const sharedEntitiesLoading = useSharedEntities().loading;
  const creditsInfoLoading = useCreditsInfo().loading;

  const loading =
    analystsLoading ||
    agentsLoading ||
    blueprintsLoading ||
    dataSourcesLoading ||
    memoriesLoading ||
    projectLabelsLoading ||
    sharedEntitiesLoading ||
    creditsInfoLoading;

  if (loading) {
    return (
      <Layout
        style={{
          alignContent: 'center',
          height: '100vh',
          justifyContent: 'center',
        }}
      >
        <Loader fullscreen spinning={loading} />
      </Layout>
    );
  }

  return children;
});
