import { useReducer } from 'react';
import { Actions } from 'types/actionTypes';
import { agentsReducer, AgentState, initialAgentState } from './agentsReducer';
import { analystsReducer, AnalystsState, initialAnalystsState } from './analystsReducer';
import { AuthState } from './authReducer';
import { blueprintsReducer, BlueprintState, inititalBlueprintState } from './bluePrintReducer';
import { conversationsReducer, ConversationsState, initialConversationsState } from './conversationsReducer';
import { dataSourcesReducer, DataSourcesState, initialDataSourcesState } from './dataSourcesReducer';
import { feedbacksReducer, FeedbacksState, initialFeedbacksState } from './feedbackReducer';
import { initialMemoriesState, memoriesReducer, MemoriesState } from './memoriesReducer';
import { initialModalsState, modalsReducer, ModalsState } from './modalsReducer';
import { initialProjectLabelsState, projectLabelsReducer, ProjectLabelsState } from './projectsReducer';
import { initialSharedEntitiesState, sharedEntitiesReducer, SharedEntitiesState } from './sharedEntitiesReducer';
import { initialStatisticsState, statisticsReducer, StatisticsState } from './statisticsReducer';

type NewType = {
  analystsState: AnalystsState;
  bluePrintState: BlueprintState;
  conversationsState: ConversationsState;
  dataSourcesState: DataSourcesState;
  feedbacksState: FeedbacksState;
  memoriesState: MemoriesState;
  modalsState: ModalsState;
  projectLabelsState: ProjectLabelsState;
  sharedEntitiesState: SharedEntitiesState;
  statisticsState: StatisticsState;
  agentState: AgentState;
};

export type AppState = NewType;

export const initialAppState: AppState = {
  agentState: initialAgentState,
  analystsState: initialAnalystsState,
  bluePrintState: inititalBlueprintState,
  conversationsState: initialConversationsState,
  dataSourcesState: initialDataSourcesState,
  feedbacksState: initialFeedbacksState,
  memoriesState: initialMemoriesState,
  modalsState: initialModalsState,
  projectLabelsState: initialProjectLabelsState,
  sharedEntitiesState: initialSharedEntitiesState,
  statisticsState: initialStatisticsState,
};

export const combinedReducer = (state: AppState, action: Actions, authState: AuthState) => ({
  agentState: agentsReducer(state.agentState, action),

  analystsState: analystsReducer(state.analystsState, action),
  bluePrintState: blueprintsReducer(state.bluePrintState, action),
  conversationsState: conversationsReducer(state.conversationsState, action),
  dataSourcesState: dataSourcesReducer(state.dataSourcesState, action, authState.user?.id),
  feedbacksState: feedbacksReducer(state.feedbacksState, action),
  memoriesState: memoriesReducer(state.memoriesState, action),
  modalsState: modalsReducer(state.modalsState, action),
  projectLabelsState: projectLabelsReducer(state.projectLabelsState, action),
  sharedEntitiesState: sharedEntitiesReducer(state.sharedEntitiesState, action),
  statisticsState: statisticsReducer(state.statisticsState, action),
});

export const useCombinedReducer = (initialState: AppState, authState: AuthState) => {
  return useReducer((state: AppState, action: Actions) => combinedReducer(state, action, authState), initialState);
};
