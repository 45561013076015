import { FC } from 'react';
import StarIcon from 'assets/iconsNewVersion/star.svg?react';
import { Tooltip } from 'antd';
import { Button } from 'ui/Button';

export const FavoriteToggle: FC<{
  isFavorite: boolean;
  toggle?(): void;
  iconClassName?: string;
  iconColor?: string;
  iconSize?: number;
}> = ({ isFavorite, toggle, iconColor, iconSize = 16 }) => {
  const star = (
    <StarIcon
      color={iconColor ?? (isFavorite ? 'var(--nri-color-primary)' : undefined)}
      fill={isFavorite ? iconColor ?? 'var(--nri-color-primary)' : 'transparent'}
      height={iconSize}
      width={iconSize}
    />
  );

  if (!toggle) {
    return star;
  }

  return (
    <Tooltip title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}>
      <Button
        icon={star}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          toggle();
        }}
        size="small"
        style={{ width: '20px', height: '20px' }}
        type="text"
      />
    </Tooltip>
  );
};
