import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { DatasourceTags } from 'components/datasource-tags/DatasourceTags';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { useEntityContext } from 'components/layouts/context/EntityProvider';
import { BlueprintFormData } from 'components/blueprint-view/BlueprintView';
import { PlusOutlined } from '@ant-design/icons';
import { useDataSources } from 'selectors/useDataSourcesSelector';

export const DatasourcesStep: React.FC = () => {
  const { dataSources } = useDataSources();
  const { form } = useEntityContext<BlueprintFormData>();
  const [selectedDataSourceIds, setSelectedDataSourceIds] = useState<DataSourceId[]>(
    form.getFieldValue('dataSources') || [],
  );
  const { show: showSelectDataSourcesModal } = useModal<{
    selections: DataSource[];
    onConfirm(dataSources: DataSource[]): void;
  }>(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.SELECT);

  const onConfirm = useCallback(
    (selectedDataSources: DataSource[]) => {
      setSelectedDataSourceIds(selectedDataSources.map(ds => ds.id));
      form.setFieldsValue({ dataSources: selectedDataSources.map(ds => ds.id) });
    },
    [form],
  );

  const selectedDataSources = useMemo(() => {
    return selectedDataSourceIds.map(id => dataSources.find(ds => ds.id === id)!).filter(Boolean);
  }, [selectedDataSourceIds, dataSources]);

  const setSelectedDataSources = useCallback(
    (dataSources: DataSource[]) => {
      onConfirm(dataSources);
    },
    [onConfirm],
  );

  return (
    <>
      <Form.Item name="dataSources" hidden>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          type="text"
          onClick={() =>
            showSelectDataSourcesModal({
              selections: selectedDataSources,
              onConfirm,
            })
          }
          style={{
            padding: 0,
          }}
          icon={<PlusOutlined />}
        >
          Add Connector
        </Button>
      </Form.Item>

      <Space direction="vertical" style={{ width: '100%' }}>
        <DatasourceTags locked={[]} selected={selectedDataSources} onUpdate={setSelectedDataSources} />
      </Space>
    </>
  );
};
