import { Actions } from 'types/actionTypes';
import { CommonStatusEnum } from 'types/enum';

export interface SharedEntitiesState {
    pagination: PaginationType;
    sharedEntities: Map<ShareableEntity['id'], ShareableEntity>;
    status: CommonStatusEnum;
}

export const initialSharedEntitiesState = {
    pagination: {
        next: null,
        previous: null,
        count: 0,
    },
    sharedEntities: new Map<ShareableEntity['id'], ShareableEntity>(),
    status: CommonStatusEnum.INITIAL,
}
export enum SharedEntitiesActionTypes {
    FETCHING_SHAREABLE_ENTITIES = "FETCHING_SHAREABLE_ENTITIES",
    FETCH_SHAREABLE_ENTITIES = "FETCH_SHAREABLE_ENTITIES",
}

export type SharedEntitiesAction =
    | { type: SharedEntitiesActionTypes.FETCHING_SHAREABLE_ENTITIES; }
    | {
        type: SharedEntitiesActionTypes.FETCH_SHAREABLE_ENTITIES; payload: {
            sharedEntities: ShareableEntity[];
            pagination: PaginationType
        };
    };

export const sharedEntitiesReducer = (
    state: SharedEntitiesState,
    action: Actions
): SharedEntitiesState => {
    switch (action.type) {
        case SharedEntitiesActionTypes.FETCHING_SHAREABLE_ENTITIES:
            return { ...state, status: CommonStatusEnum.FETCHING };

        case SharedEntitiesActionTypes.FETCH_SHAREABLE_ENTITIES: {
            const { sharedEntities, pagination } = action.payload;
            const latestSharedEntities = new Map(sharedEntities.map(entity => [entity.id, entity]));
            return {
                ...state,
                pagination,
                sharedEntities: new Map([...state.sharedEntities, ...latestSharedEntities]),
                status: CommonStatusEnum.FETCHED,
            };
        }
        default:
            return state;
    }
};