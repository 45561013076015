import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useState } from 'react';
import { useArchiveBlueprint } from 'selectors/useBlueprints';
import { notification } from 'antd';
import ArchiveIcon from 'assets/icons/archive.svg?react';

export const ArchiveBlueprintModal: React.FC = () => {
  const {
    data: { blueprint, onConfirm: onConfirmCallback },
    hide,
  } = useModal<{ onConfirm: () => void; blueprint: Blueprint }>(ModalScopesEnum.BLUEPRINTS, ModalTypesEnum.ARCHIVE);
  const [name] = useState(blueprint.name);
  const [processing, setProcessing] = useState(false);
  const archive = useArchiveBlueprint(blueprint.id);
  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await archive();

    notification.open({
      icon: <ArchiveIcon />,
      message: <strong>Blueprint archived</strong>,
      description: (
        <>
          <strong>{name}</strong> was archived.You can unarchive it anytime.
        </>
      ),
    });

    hide();
    setProcessing(false);
    onConfirmCallback();
  }, [archive, name, hide, onConfirmCallback]);

  return (
    <ConfirmationModal
      key={'conversation::delete'}
      confirmationButton={processing ? 'Archiving' : 'Yes, archive'}
      isOpen
      title={'Archive blueprint'}
      description={
        <>
          if you are the owner, this will Archive the blueprint for everyone it is shared with. Are you sure you want to
          archive this blueprint <strong>{blueprint.name}</strong>?
        </>
      }
      onCancel={hide}
      onConfirm={onConfirm}
      processing={processing}
    />
  );
};
