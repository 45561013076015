import { Flex, Segmented, Tabs, Typography } from 'antd';
import styles from './MemoryAdministration.module.scss';
import { FC, useState } from 'react';
import { ConversationFeedbacks } from './ConversationFeedbacks';
import { SegmentedItemLabel } from '../../newVersionComponents/common/SegmentedItemLabel';
import { useAuth } from 'selectors/useAuthSelector';
import { Memories } from './Memories';

export const MemoryAdministration: FC = () => {
  const { me } = useAuth();

  const memoryBoardItems: {
    hide: boolean | undefined;
    label: string;
    key: string;
    children: JSX.Element;
  }[] = [
    {
      hide: false,
      label: 'Conversation feedback',
      key: 'feedbacks',
      children: <ConversationFeedbacks />,
    },
    {
      hide: !me?.isSuperuser,
      label: 'Memory admin',
      key: 'memories',
      children: <Memories />,
    },
  ].filter(({ hide }) => !hide);

  const [activeTab, setActiveTab] = useState(memoryBoardItems[0].key);

  return (
    <div className={styles.memoryBoard}>
      <Flex align="center" className={styles.memoryBoardHeader}>
        <Typography.Text className={styles.memoryBoardTitle}>Feedback and memory administration</Typography.Text>
      </Flex>
      <div className={styles.memoryBoardContent}>
        <Tabs
          className={styles.memoryBoardTabs}
          items={memoryBoardItems}
          activeKey={activeTab}
          renderTabBar={({ activeKey }) => {
            return (
              <div>
                <Segmented
                  className={styles.memoryBoardSegmented}
                  defaultValue={activeTab}
                  options={memoryBoardItems.map(({ label, key }) => ({
                    label: (
                      <SegmentedItemLabel
                        label={label}
                        active={activeKey === key}
                        handleClick={() => {
                          setActiveTab(key);
                        }}
                      />
                    ),
                    value: key,
                  }))}
                />
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};
