import { FormOutlined } from '@ant-design/icons';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import ArchiveIcon from 'assets/icons/archive.svg?react';
import CopyIcon from 'assets/icons/copy.svg?react';
import DeleteIcon from 'assets/icons/trash.svg?react';
import BookmarkIcon from 'assets/iconsNewVersion/bookmark.svg?react';
import MoreIcon from 'assets/iconsNewVersion/more.svg?react';
import classNames from 'classnames';
import styles from 'components/sidebar/Sidebar.module.scss';
import { useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useAuth } from 'selectors/useAuthSelector';
import { useModal } from 'selectors/useModals';
import { ActionItem, ActionsMenu } from 'ui/ActionsMenu';

enum ConversationActions {
  ARCHIVE = 'ARCHIVE',
  ADD_TO_PROJECT = 'ADD_TO_PROJECT',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
  RENAME = 'RENAME',
  MANAGE_ACCESS = 'MANAGE_ACCESS',
}

const items: ActionItem<Conversation>[] = [
  {
    icon: FormOutlined,
    label: 'Rename',
    key: ConversationActions.RENAME,
    filter: (e: Conversation, u: UserEntity) => e.owner === u.id,
  },
  {
    icon: BookmarkIcon,
    label: 'Add to project',
    key: ConversationActions.ADD_TO_PROJECT,
    filter: (e: Conversation, u: UserEntity) => e.owner === u.id,
  },
  {
    icon: UserCircleIcon,
    label: 'Manage access',
    key: ConversationActions.MANAGE_ACCESS,
    filter: (e: Conversation, u: UserEntity) => e.owner === u.id,
  },
  {
    icon: CopyIcon,
    label: <>Duplicate</>,
    key: ConversationActions.DUPLICATE,
  },
  {
    icon: ArchiveIcon,
    label: <>Archive</>,
    key: ConversationActions.ARCHIVE,
  },
  {
    color: 'var(--nri-color-red)',
    icon: DeleteIcon,
    label: <>Delete</>,
    key: ConversationActions.DELETE,
    filter: (e: Conversation, u: UserEntity) => e.owner === u.id,
  },
];

export const ConversationsActionMenu: React.FC<{ className?: string; conversation: Conversation }> = ({
  className,
  conversation,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { show: showArchiveModal } = useModal(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.ARCHIVE);
  const { show: showAddToProjectModal } = useModal(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.ADD_TO_PROJECT);
  const { show: showDeleteModal } = useModal(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.DELETE);
  const { show: showDuplicateModal } = useModal(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.DUPLICATE);
  const { show: showManageAccess } = useModal(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.MANAGE);
  const { show: showRenameModal } = useModal(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.RENAME);

  const { me } = useAuth();
  const onActionClick = (key: ConversationActions) => {
    switch (key) {
      case ConversationActions.ADD_TO_PROJECT:
        showAddToProjectModal(conversation);
        break;
      case ConversationActions.ARCHIVE:
        showArchiveModal(conversation);
        break;

      case ConversationActions.DELETE:
        showDeleteModal(conversation);
        break;

      case ConversationActions.DUPLICATE:
        showDuplicateModal(conversation);
        break;

      case ConversationActions.RENAME:
        showRenameModal(conversation);
        break;

      case ConversationActions.MANAGE_ACCESS:
        showManageAccess(conversation);
        break;
    }
  };

  const filteredItems = items.filter(item => {
    if (item.filter) {
      return item.filter(conversation, me!);
    }
    return true;
  });

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div onClick={handleClick}>
      <ActionsMenu
        className={classNames(className, {
          [styles.visible]: isOpen,
        })}
        onOpenChange={setIsOpen}
        items={filteredItems}
        onSelect={({ key }) => onActionClick(key as ConversationActions)}
      >
        <MoreIcon style={{ width: '16px', height: '16px' }} />
      </ActionsMenu>
    </div>
  );
};
