import { Flex } from 'antd';
import styles from 'ui/UIKit.module.scss';
import { highlightText } from 'utils/highlightText';

export const InputSearchOption = ({
  searchTerm,
  subText,
  title,
}: {
  searchTerm: string;
  subText: string;
  title: string;
}) => (
  <Flex className={styles.inputSearchOption} justify="space-between">
    <div className={styles.truncate}>{highlightText(title, searchTerm)}</div>
    <div className={styles.subText}>{subText}</div>
  </Flex>
);
