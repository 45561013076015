import { Flex, Space, Typography } from 'antd';
import CheckIcon from 'assets/iconsNewVersion/check_circle.svg?react';
import { Loader } from 'components/loader/Loader';
import { Message } from 'components/message/Message';

import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useActiveConversation, useConversationMessages } from 'selectors/useConversationSelectors';
import { ConversationViewEnum } from 'types/enum';
import { findKeysInLocalStorage } from 'utils/findKeysInLocalStorage';

const blankConversation = (
  <Flex justify="center" vertical style={{ height: '100%', paddingLeft: '20px' }}>
    <Typography.Title level={1} style={{ lineHeight: '30px' }}>
      Hi. Welcome to Newton.
      <br />
      What can our agents help you get done today?
    </Typography.Title>
    <Typography.Paragraph style={{ color: 'var(--nri-color-purple-grey)', lineHeight: '30px', marginTop: '16px' }}>
      <Space direction="vertical" size="small">
        <div>Some quick hints for getting started:</div>
        <div>
          <Flex align="center" gap="4px">
            <CheckIcon height="16px" />
            You can connect to a connector or upload data in the "Connectors" tab.
          </Flex>
          <Flex align="center" gap="4px">
            <CheckIcon height="16px" />
            Share this conversation with other users by adding them above.
          </Flex>
        </div>
      </Space>
    </Typography.Paragraph>
  </Flex>
);

type Props = {
  blankSlate?: React.ReactNode;
  view: ConversationViewEnum;
};

export const SummaryTab: React.FC<Props> = ({ blankSlate = blankConversation, view }) => {
  const { conversationId } = useParams();
  const { conversation, loading: convoLoading } = useActiveConversation();
  const { messages, streaming } = useConversationMessages(Number(conversationId) as Conversation['id']);
  const ConversationWrapper = motion(Flex);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      findKeysInLocalStorage('feedback').forEach(key => {
        localStorage.removeItem(key);
      });
      findKeysInLocalStorage('message-set-code').forEach(key => {
        localStorage.removeItem(key);
      });
      findKeysInLocalStorage('message-set-log').forEach(key => {
        localStorage.removeItem(key);
      });
    };
  }, [conversationId]);

  const loading = !conversationId ? false : convoLoading;

  return (
    <ConversationWrapper
      animate={{ opacity: 1 }}
      gap="32px"
      transition={{ duration: 1 }}
      style={{ height: '100%' }}
      vertical
    >
      {messages.length
        ? messages.map((msg: Message, idx) => (
            <Message index={idx} conversation={conversation!} message={msg} key={`${msg.id}-${idx}`} view={view} />
          ))
        : loading
          ? null
          : blankSlate}
      {conversation && streaming ? (
        <Flex justify="center" style={{ padding: '10px 0' }}>
          <Loader size="default" />
        </Flex>
      ) : null}
    </ConversationWrapper>
  );
};
