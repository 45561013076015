import { Layout } from "antd";
import { ConversationsView } from "../components/conversations-view/ConversationsView";
import styles from "./Layout.module.scss";
import { useConversation } from "selectors/useConversationSelectors";
import { useParams } from "react-router-dom";
import { memo, useEffect } from "react";

export const ConversationPage = memo(() => {
  const { conversationId } = useParams();
  const selectConversation = useConversation()

  useEffect(() => {
    if (conversationId)
      selectConversation(Number(conversationId) as Conversation['id'])
  }, [conversationId, selectConversation])

  return (
    <Layout className={styles.conversationPage}>
      <ConversationsView />
    </Layout>
  )
});
