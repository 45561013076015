import { Layout, message } from 'antd';
import { BlueprintFormData, BlueprintView } from 'components/blueprint-view/BlueprintView';
import React, { memo, useCallback, useEffect } from 'react';
import { useCreateBlueprint } from 'selectors/useBlueprints';
import styles from './Layout.module.scss';
import { useSearchParams } from 'hooks/useURLParams';
import { useConversation, useConversationContainer } from 'selectors/useConversationSelectors';
import { useNavigate } from 'react-router-dom';

export const NewBlueprintPage: React.FC = memo(() => {
  const { conversationId } = useSearchParams();
  const createBlueprint = useCreateBlueprint();
  const getConversation = useConversationContainer();
  const fetchConversation = useConversation();
  const { conversation } = getConversation(Number(conversationId) as ConversationId) || {};
  const navigate = useNavigate();

  const initialValues: Partial<BlueprintFormData> = conversation
    ? {
        name: conversation.name,
        dataSources: conversation.dataSources.map(ds => ds.id),
        description: conversation.description || '',
        initialMessage: conversation.messageSet[0]?.text || '',
        projectLabels: conversation.projectLabels.map(pl => pl.id),
      }
    : {};

  const onSubmit = useCallback(
    async (values: BlueprintFormData) => {
      const blueprint = await createBlueprint({
        data_sources: values.dataSources,
        description: values.description,
        input_parameters: values.inputParameters,
        initial_message: values.initialMessage,
        // memories: values.memories.filter(Boolean),
        name: values.name,
        owner: values.owner,
        project_labels: values.projectLabels,
        share_with_organization: values.shareWithOrganization,
        steps: values.steps,
      });
      navigate(`/blueprints/${blueprint.id}/edit`);

      message.success('Blueprint created successfully');
    },
    [createBlueprint, navigate],
  );

  useEffect(() => {
    if (conversationId && !conversation) {
      fetchConversation(Number(conversationId) as ConversationId, { skipNavigate: true });
    }
  }, [conversation, conversationId, fetchConversation]);

  if (!conversation && conversationId) {
    return null;
  }

  return (
    <Layout className={styles.blueprintPage}>
      <BlueprintView edit onSubmit={onSubmit} initialValues={initialValues} isNew={true} />
    </Layout>
  );
});
