import { ConfigProvider, Flex, Table } from 'antd';
import styles from './EntityList.module.scss';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { ReactNode, useState } from 'react';
import { highlightText } from 'utils/highlightText';

type EntityTableProps<T extends EntityBase> = {
  actionMenu: (entity: T) => ReactNode;
  columns: ColumnsType<T>;
  entities: T[];
  onRowClick?: (entity: T) => void;
  /** The search term to highlight in the card */
  search?: string;
};

export const EntityTable = <T extends EntityBase>({
  actionMenu,
  columns,
  entities,
  onRowClick,
  search = '',
}: EntityTableProps<T>) => {
  const [selectedRow, setSelectedRow] = useState<undefined | number>();

  const renderField = (column: ColumnType<T>, record: T) => {
    switch (true) {
      case column.key === 'actions' && selectedRow === record.id:
        return (
          <Flex justify="flex-end" align="center">
            {actionMenu(record)}
          </Flex>
        );
      default:
        return highlightText(record[column.dataIndex as keyof T] as unknown as string, search);
    }
  };

  const tableColumns: ColumnsType<T> = columns.map((column: ColumnType<T>) => ({
    key: column.key,
    dataIndex: column.dataIndex,
    title: column.title,
    width: column.width,
    ellipsis: column.ellipsis ?? false,
    render: (_, record) => {
      return column.render ? column.render(_, record, _) : renderField(column, record);
    },
  }));

  const handleShowMenu = (id: number, action: string) => {
    if (!action) return;

    if (action === 'open') {
      setSelectedRow(id);
    } else {
      setSelectedRow(undefined);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: { colorText: 'var(--nri-color-purple-grey)' },
        components: {
          Table: {
            headerColor: 'var(--nri-color-purple-grey)',
            headerBg: 'var(--nri-color-light-grey)',
            headerSplitColor: 'transparent',
            cellPaddingBlock: 24,
            cellPaddingInline: 16,
            cellFontSize: 12,
          },
        },
      }}
    >
      <Table
        onRow={row => {
          return {
            onClick: () => onRowClick?.(row),
            onMouseEnter: () => handleShowMenu(row.id, 'open'),
            onMouseLeave: () => handleShowMenu(row.id, 'close'),
          };
        }}
        tableLayout="fixed"
        className={styles.entityTable}
        columns={tableColumns}
        dataSource={entities}
      />
    </ConfigProvider>
  );
};
