import * as React from "react";
const SvgGooglesheets = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("mask", { id: "mask0_7653_51964", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 0, width: 17, height: 23 }, /* @__PURE__ */ React.createElement("path", { d: "M14.559 0.332031H5.83491C4.98816 0.332031 4.29536 1.02482 4.29536 1.87157V21.3724C4.29536 22.2192 4.98816 22.912 5.83491 22.912H19.1776C20.0243 22.912 20.7171 22.2192 20.7171 21.3724V6.49019L14.559 0.332031Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_7653_51964)" }, /* @__PURE__ */ React.createElement("path", { d: "M14.5585 0.332031H5.83448C4.98773 0.332031 4.29494 1.02482 4.29494 1.87157V21.3724C4.29494 22.2192 4.98773 22.912 5.83448 22.912H19.1772C20.0239 22.912 20.7167 22.2192 20.7167 21.3724V6.49019L17.1244 3.92429L14.5585 0.332031Z", fill: "#0F9D58" })), /* @__PURE__ */ React.createElement("mask", { id: "mask1_7653_51964", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 0, width: 17, height: 23 }, /* @__PURE__ */ React.createElement("path", { d: "M14.559 0.332031H5.83491C4.98816 0.332031 4.29536 1.02482 4.29536 1.87157V21.3724C4.29536 22.2192 4.98816 22.912 5.83491 22.912H19.1776C20.0243 22.912 20.7171 22.2192 20.7171 21.3724V6.49019L14.559 0.332031Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_7653_51964)" }, /* @__PURE__ */ React.createElement("path", { d: "M8.3992 11.3665V18.8076H16.6101V11.3665H8.3992ZM11.9915 17.7812H9.42556V16.4983H11.9915V17.7812ZM11.9915 15.7285H9.42556V14.4455H11.9915V15.7285ZM11.9915 13.6758H9.42556V12.3928H11.9915V13.6758ZM15.5837 17.7812H13.0178V16.4983H15.5837V17.7812ZM15.5837 15.7285H13.0178V14.4455H15.5837V15.7285ZM15.5837 13.6758H13.0178V12.3928H15.5837V13.6758Z", fill: "#F1F1F1" })), /* @__PURE__ */ React.createElement("mask", { id: "mask2_7653_51964", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 0, width: 17, height: 23 }, /* @__PURE__ */ React.createElement("path", { d: "M14.559 0.332031H5.83491C4.98816 0.332031 4.29536 1.02482 4.29536 1.87157V21.3724C4.29536 22.2192 4.98816 22.912 5.83491 22.912H19.1776C20.0243 22.912 20.7171 22.2192 20.7171 21.3724V6.49019L14.559 0.332031Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask2_7653_51964)" }, /* @__PURE__ */ React.createElement("path", { d: "M15.0085 6.03931L20.7163 11.7459V6.48962L15.0085 6.03931Z", fill: "url(#paint0_linear_7653_51964)" })), /* @__PURE__ */ React.createElement("mask", { id: "mask3_7653_51964", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 0, width: 17, height: 23 }, /* @__PURE__ */ React.createElement("path", { d: "M14.559 0.332031H5.83491C4.98816 0.332031 4.29536 1.02482 4.29536 1.87157V21.3724C4.29536 22.2192 4.98816 22.912 5.83491 22.912H19.1776C20.0243 22.912 20.7171 22.2192 20.7171 21.3724V6.49019L14.559 0.332031Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask3_7653_51964)" }, /* @__PURE__ */ React.createElement("path", { d: "M14.5602 0.332031V4.95065C14.5602 5.80125 15.2492 6.49019 16.0998 6.49019H20.7184L14.5602 0.332031Z", fill: "#87CEAC" })), /* @__PURE__ */ React.createElement("mask", { id: "mask4_7653_51964", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 0, width: 17, height: 23 }, /* @__PURE__ */ React.createElement("path", { d: "M14.559 0.332031H5.83491C4.98816 0.332031 4.29536 1.02482 4.29536 1.87157V21.3724C4.29536 22.2192 4.98816 22.912 5.83491 22.912H19.1776C20.0243 22.912 20.7171 22.2192 20.7171 21.3724V6.49019L14.559 0.332031Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask4_7653_51964)" }, /* @__PURE__ */ React.createElement("path", { d: "M5.83448 0.332031C4.98773 0.332031 4.29494 1.02482 4.29494 1.87157V1.99987C4.29494 1.15312 4.98773 0.460326 5.83448 0.460326H14.5585V0.332031H5.83448Z", fill: "white", fillOpacity: 0.2 })), /* @__PURE__ */ React.createElement("mask", { id: "mask5_7653_51964", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 0, width: 17, height: 23 }, /* @__PURE__ */ React.createElement("path", { d: "M14.559 0.332031H5.83491C4.98816 0.332031 4.29536 1.02482 4.29536 1.87157V21.3724C4.29536 22.2192 4.98816 22.912 5.83491 22.912H19.1776C20.0243 22.912 20.7171 22.2192 20.7171 21.3724V6.49019L14.559 0.332031Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask5_7653_51964)" }, /* @__PURE__ */ React.createElement("path", { d: "M19.1772 22.7859H5.83448C4.98773 22.7859 4.29494 22.0931 4.29494 21.2463V21.3746C4.29494 22.2214 4.98773 22.9142 5.83448 22.9142H19.1772C20.0239 22.9142 20.7167 22.2214 20.7167 21.3746V21.2463C20.7167 22.0931 20.0239 22.7859 19.1772 22.7859Z", fill: "#263238", fillOpacity: 0.2 })), /* @__PURE__ */ React.createElement("mask", { id: "mask6_7653_51964", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 0, width: 17, height: 23 }, /* @__PURE__ */ React.createElement("path", { d: "M14.559 0.332031H5.83491C4.98816 0.332031 4.29536 1.02482 4.29536 1.87157V21.3724C4.29536 22.2192 4.98816 22.912 5.83491 22.912H19.1776C20.0243 22.912 20.7171 22.2192 20.7171 21.3724V6.49019L14.559 0.332031Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask6_7653_51964)" }, /* @__PURE__ */ React.createElement("path", { d: "M16.0979 6.49047C15.2473 6.49047 14.5584 5.80152 14.5584 4.95093V5.07922C14.5584 5.92982 15.2473 6.61876 16.0979 6.61876H20.7165V6.49047H16.0979Z", fill: "#263238", fillOpacity: 0.1 })), /* @__PURE__ */ React.createElement("path", { d: "M14.5572 0.332031H5.83317C4.98642 0.332031 4.29362 1.02482 4.29362 1.87157V21.3724C4.29362 22.2192 4.98642 22.912 5.83317 22.912H19.1759C20.0226 22.912 20.7154 22.2192 20.7154 21.3724V6.49019L14.5572 0.332031Z", fill: "url(#paint1_radial_7653_51964)" }), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "paint0_linear_7653_51964", x1: 300.432, y1: 55.0365, x2: 300.432, y2: 576.775, gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "#263238", stopOpacity: 0.2 }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#263238", stopOpacity: 0.02 })), /* @__PURE__ */ React.createElement("radialGradient", { id: "paint1_radial_7653_51964", cx: 0, cy: 0, r: 1, gradientUnits: "userSpaceOnUse", gradientTransform: "translate(56.3186 44.9599) scale(2647.99 2647.99)" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "white", stopOpacity: 0.1 }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "white", stopOpacity: 0 }))));
export default SvgGooglesheets;
