import { Form, FormInstance, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { Context, PropsWithChildren, createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

export type Step = {
  className?: string;
  content: React.ReactNode;
  description?: string | React.ReactNode;
  formTitle?: React.ReactNode;
  title: string;
  key: string;
};

export type EntityContextProps<T> = {
  current: number;
  errorFields: { name: string[] }[];
  form: FormInstance;
  initialFormValue: T;
  isDirty: boolean;
  isNew: boolean;
  isSubmitting: boolean;
  jump: (index: number) => void;
  next: () => void;
  onFinish: () => Promise<void>;
  prev: () => void;
  readOnly: boolean;
  stepRef: React.MutableRefObject<HTMLDivElement | null>;
  steps: Step[];
  validateAndSubmit: () => Promise<void>;
};
interface ValidateErrorField {
  errors: string[];
  name: string[];
  warnings: string[];
}

interface ValidateError {
  errorFields: ValidateErrorField[];
  outOfDate: boolean;
  values: Record<string, unknown>;
}
const EntityContext = createContext<unknown | undefined>(undefined);

type EntityProviderProps<T> = PropsWithChildren & {
  initialFormValue?: T;
  isNew: boolean;
  readOnly?: boolean;
  onSubmit?: (data: T) => Promise<void>;
  steps: Step[];
};

export const EntityProvider = <T,>({
  children,
  initialFormValue,
  isNew = false,
  readOnly = false,
  steps,
  onSubmit,
}: EntityProviderProps<T>) => {
  const [form] = Form.useForm();
  const stepRef = useRef<HTMLDivElement | null>(null);
  const [current, setCurrent] = useState(0);
  const [errorFields, setErrorFields] = useState<{ name: string[] }[]>([]);
  const allValues = useWatch([], form);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDirty = useMemo(() => allValues && form.isFieldsTouched(false), [form, allValues]);

  const getFieldsToValidate = useCallback(() => {
    if (!stepRef.current) return [];
    const inputs = stepRef.current.querySelectorAll('input[id], select[id], textarea[id]');
    const fieldNames = Array.from(inputs).map(input => input.getAttribute('id') || '');
    return fieldNames
      .map(name => {
        const path = name.replace(/_(\d+)_/g, '.$1.').split('.');
        return path.map(x => (isNaN(parseInt(x)) ? x : parseInt(x)));
      })
      .filter(path => path.length > 0);
  }, [stepRef]);

  const next = useCallback(async () => {
    try {
      const fieldsToValidate = getFieldsToValidate();
      if (fieldsToValidate.length > 0) await form.validateFields(fieldsToValidate, { recursive: true });
      setCurrent(prev => prev + 1);
    } catch (errorInfo) {
      message.error('Please complete the required fields.');
      setErrorFields((errorInfo as { errorFields?: { name: string[] }[] })?.errorFields || []);
    }
  }, [form, getFieldsToValidate]);

  const prev = useCallback(() => {
    setCurrent(prev => prev - 1);
  }, []);

  const onFinish = useCallback(async () => {
    try {
      const values = await form!.validateFields();
      await onSubmit!(values as T);
    } catch (errorInfo) {
      message.error('Please complete the required fields.');
    } finally {
      setIsSubmitting(false);
    }
  }, [form, onSubmit]);

  const validateAndSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await form.validateFields();
      await form.submit();
    } catch (errorInfo) {
      (errorInfo as ValidateError).errorFields.forEach(field => message.error(field.errors.join(' ')));
      setIsSubmitting(false);
    }
  }, [form]);

  return (
    <EntityContext.Provider
      value={{
        current,
        errorFields,
        form,
        initialFormValue,
        isDirty,
        isNew,
        isSubmitting,
        jump: setCurrent,
        next,
        onFinish,
        prev,
        readOnly,
        stepRef,
        steps,
        validateAndSubmit,
      }}
    >
      {children}
    </EntityContext.Provider>
  );
};
export function useEntityContext<T>() {
  const context = useContext(EntityContext as Context<EntityContextProps<T>>);
  if (!context) {
    throw new Error('useFormContext must be used within a EntityProvider');
  }
  return context;
}
