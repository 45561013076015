import { ResponsiveBar } from '@nivo/bar';
import { Flex, Typography } from 'antd';
import { FC } from 'react';
import styles from './UsageRemainingCredits.module.scss';
import { Loader } from 'components/loader/Loader';

type UsageRemainingCreditsProps = {
  expendedCredits: number;
  maxValue: number;
  loading: boolean;
  renewDate: string;
};

export const UsageRemainingCredits: FC<UsageRemainingCreditsProps> = ({
  expendedCredits,
  loading,
  maxValue,
  renewDate,
}) => {
  const data = [
    {
      user: 'Usage',
      consumedCredits: expendedCredits / maxValue,
      remainingCredits: (maxValue - expendedCredits) / maxValue,
    },
  ];

  return (
    <Flex vertical justify="center" gap={12} className={styles.container}>
      {loading ? (
        <Loader size="default" />
      ) : (
        <>
          <Flex justify="space-between" className={styles.titleContainer}>
            <Typography.Text className={styles.title}>Org-level usage</Typography.Text>{' '}
            <Typography.Text className={styles.renew}>{`Renews on ${renewDate}`}</Typography.Text>
          </Flex>
          <div className={styles.bar}>
            <ResponsiveBar
              data={data}
              indexBy="user"
              theme={{
                labels: {
                  text: {
                    fill: '#fff',
                    fontSize: 'var(--nri-font-size-xl)',
                    fontWeight: 'var(--nri-weight-medium)',
                    fontFamily: 'var(--nri-font-family)',
                  },
                },
              }}
              defs={[
                {
                  id: 'lines-pattern',
                  type: 'patternLines',
                  spacing: 4,
                  rotation: 120,
                  lineWidth: 2,
                  background: '#e2e3e4',
                  color: '#ffffff',
                },
              ]}
              fill={[
                {
                  match: {
                    id: 'remainingCredits', // Aplica el patrón solo a este índice
                  },
                  id: 'lines-pattern',
                },
              ]}
              label={label => (label.id === 'remainingCredits' ? '' : label.formattedValue)}
              padding={0}
              colors={['var(--nri-color-lavander)']}
              keys={['consumedCredits', 'remainingCredits']}
              layout="horizontal"
              innerPadding={4}
              maxValue={1}
              enableGridY={false}
              borderRadius={8}
              isInteractive={false}
              valueFormat="<-0.1~%"
              animate={false}
            />
          </div>
          <Flex justify="flex-end">
            <Typography.Text className={styles.creditsLeftText}>Credits left: </Typography.Text>
            <Typography.Text className={styles.creditsLeft}>{maxValue - expendedCredits}</Typography.Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};
