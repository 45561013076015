import { Flex, Form, FormInstance, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import styles from './DatasourceView.module.scss';
import { Upload } from 'ui/Upload';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { RcFile, UploadFile } from 'antd/es/upload';
import { useGenerateDescription } from '../../hooks/useGenerateDescription';
import { DatasourceUploadType } from '../../reducers/dataSourcesReducer';
import { validateDatasourceFile } from '../../utils/fileValidation';
import { InputSimple } from 'ui/Input';
import { Select } from 'ui/Select';
import { typeSelectOptions } from 'utils/uploadDatasource';
import { ProjectLabel } from '../../ui/ProjectLabel';
import { useProjectLabels } from 'selectors/useProjectLabels';
import { DefaultOptionType } from 'antd/es/select';
import classNames from 'classnames';
import { DataSourceDetailsFields, EmptyValues } from './DatasourceView';

type DatasourceDetailsFormProps = {
  dataSource: DataSource;
  fileList: UploadFile[];
  form: FormInstance;
  initialValues: DataSourceDetailsFields;
  isCSV: boolean;
  isEditing: boolean;
  onRemoveFile: (emptyValues: EmptyValues) => void;
  selectedProjectLabels: ProjectLabel[];
  setSelectedProjectLabels: Dispatch<SetStateAction<ProjectLabel[]>>;
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
};

export const DatasourceDetailsForm: FC<DatasourceDetailsFormProps> = ({
  fileList,
  form,
  initialValues,
  isCSV,
  isEditing,
  onRemoveFile,
  selectedProjectLabels,
  setFileList,
  setSelectedProjectLabels,
}) => {
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const { projectLabels } = useProjectLabels();
  const generateDefaultDescription = useGenerateDescription();
  const initialProjectOptions = projectLabels?.filter(
    (label: ProjectLabel) => !initialValues.projectLabels.map((l: ProjectLabel) => l.id).includes(label.id),
  );
  const [projectOptions, setProjectOptions] = useState<ProjectLabel[]>(initialProjectOptions || []);

  const emptyValues = { fileInput: [], name: '', description: '', projectLabels: [] };

  const customBeforeUpload = useCallback(
    async (file: RcFile) => {
      form.setFieldsValue(emptyValues);
      if (validateDatasourceFile(file)) {
        setFileLoading(true);
        const descriptionData = new FormData();
        descriptionData.append('file', file as unknown as Blob);
        descriptionData.append('delimiter', 'auto');
        const autoDescription = await generateDefaultDescription(descriptionData);
        setFileList([file]);
        form.setFieldValue('fileInput', [file]);
        form.setFieldValue('description', autoDescription);
        form.setFieldValue('name', file.name.slice(0, 80).split('.csv')[0]);
        form.setFieldValue('type', DatasourceUploadType.ORGANIZATION);
        form.validateFields();
        setFileLoading(false);
      }
      return false;
    },
    [form, generateDefaultDescription],
  );

  const handleFileChange = (info: { fileList: UploadFile[]; file: UploadFile }) => {
    if (info.file.status === undefined && info.file.type !== 'text/csv') form.setFieldValue('fileInput', []);
    else {
      form.setFieldValue('fileInput', info.fileList);
    }
  };

  const handleSelectProject = (_: string, option: DefaultOptionType) => {
    setSelectedProjectLabels(prev => [
      ...prev,
      { id: option.value as ProjectLabelId, name: option.label as string, color: option.color },
    ]);
    form.setFieldValue('projectLabels', [
      ...selectedProjectLabels,
      { id: option.value as ProjectLabelId, name: option.label as string, color: option.color },
    ]);
    setProjectOptions(prev => prev.filter(opt => opt.id !== (option.value as ProjectLabelId)));
  };

  const handleRemoveProject = (label: ProjectLabel) => {
    setProjectOptions(prev => {
      const repeatLabel = prev.find(l => l.id === label.id);
      return [...prev, ...(!repeatLabel ? [label] : [])];
    });
    setSelectedProjectLabels(prev => prev.filter(l => l.id !== label.id));
    form.setFieldValue(
      'projectLabels',
      selectedProjectLabels.filter(l => l.id !== label.id),
    );
  };

  const formValidations = {
    name: {
      required: true,
      message: 'Please enter a valid name',
    },
    type: { required: true },
    fileInput: { required: true, message: 'Please select a file' },
    description: {
      required: true,
      message: 'Please enter a valid description',
    },
  };

  return (
    <Form
      form={form}
      className={styles.detailsForm}
      layout="vertical"
      name="control-hooks"
      initialValues={initialValues}
      requiredMark={false}
    >
      <Flex vertical gap={32}>
        <Typography.Text className={styles.subTitle}>Here you can edit connectors </Typography.Text>
        {isCSV ? (
          <Form.Item name="fileInput" rules={[formValidations['fileInput']]} validateTrigger={['onChange', 'onBlur']}>
            <Upload
              disabled={!isEditing}
              accept=".csv"
              beforeUpload={customBeforeUpload}
              fileList={fileList}
              onChange={info => handleFileChange(info)}
              onRemove={() => onRemoveFile(emptyValues)}
              multiple={false}
              loading={fileLoading}
            />
            {isEditing && (
              <Typography className={styles.nameWarning}>
                *We keep the original file name for backward compatibility
              </Typography>
            )}
          </Form.Item>
        ) : (
          <Typography.Text>No CSV associated to this connector</Typography.Text>
        )}

        <Form.Item
          name="name"
          label="Connector title"
          rules={[formValidations['name']]}
          validateTrigger={['onChange', 'onBlur']}
        >
          <InputSimple
            disabled={!isEditing || isCSV}
            type="text"
            maxLength={80}
            className={classNames({ [styles.disableField]: !isEditing || isCSV })}
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="Share connector"
          rules={[formValidations['type']]}
          validateTrigger={['onChange', 'onBlur']}
        >
          <Select
            disabled={true}
            placeholder="Select type"
            options={typeSelectOptions}
            className={classNames(styles.disableField)}
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[formValidations['description']]}
          validateTrigger={['onChange', 'onBlur']}
          label="Connector description"
        >
          <TextArea
            className={classNames(styles.formTextArea, { [styles.disableField]: !isEditing })}
            disabled={!isEditing}
            placeholder="Enter description"
            maxLength={500}
            autoSize={{ minRows: 3, maxRows: 3 }}
            rows={3}
          />
        </Form.Item>
        <div>
          <Form.Item name="projectLabels" label="Projects">
            <Select
              className={classNames({ [styles.disableField]: !isEditing })}
              disabled={!isEditing}
              placeholder="Add to project"
              options={projectOptions
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map(option => ({ label: option.name, value: option.id, color: option.color }))}
              onSelect={handleSelectProject}
              labelRender={() => <Typography.Text className={styles.selectLabel}>Add to project</Typography.Text>}
              showSearch={false}
            />
          </Form.Item>
          <Flex gap={8} wrap="wrap" className={styles.projectLabelsContainer}>
            {selectedProjectLabels.map(label => (
              <ProjectLabel
                readOnly={!isEditing}
                key={label.id}
                text={label.name}
                color={label.color}
                filled={true}
                onRemove={() => handleRemoveProject(label)}
              />
            ))}
          </Flex>
        </div>
      </Flex>
    </Form>
  );
};
