import { ButtonProps } from 'antd';
import PlusIcon from 'assets/icons/plus.svg?react';
import classNames from 'classnames';
import { QueryParams, urlParameters } from 'hooks/useURLParams';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui/Button';
import styles from 'ui/UIKit.module.scss';

type NewConversationButtonProps = {
  bordered?: boolean;
  placeholder?: string;
  params?: QueryParams;
  icon?: React.ReactNode;
  variant?: 'default' | 'icon';
} & Omit<ButtonProps, 'onClick' | 'variant'>;

export const NewConversationButton: React.FC<NewConversationButtonProps> = memo(
  ({
    placeholder = 'Start conversation',
    params = {},
    icon,
    variant = 'default',
    type = variant === 'default' ? 'primary' : 'text',
    bordered = variant === 'default',
    ...buttonProps
  }) => {
    const navigate = useNavigate();
    const urlParams = urlParameters(params);

    let route = `/conversations/new`;
    if (urlParams) route += `?${urlParams}`;

    return (
      <Button
        icon={variant === 'icon' ? icon || <PlusIcon /> : undefined}
        type={type}
        bordered={bordered}
        onClick={() => navigate(route)}
        {...buttonProps}
        className={classNames({
          [styles.iconButton]: variant === 'icon',
        })}
      >
        {placeholder}
      </Button>
    );
  },
);

NewConversationButton.displayName = 'NewConversationButton';
