import { Flex, Typography } from 'antd';
import styles from './PageSection.module.scss';
import classNames from 'classnames';

type PageSectionProps = {
  button?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  fullHeight?: boolean;
  heading?: React.ReactNode;
  headingSmall?: string;
  onScroll?(e: React.UIEvent): void;
  subheading?: string;
};

/**
 * TODO: We used to have several "sections" within a page that had a heading and right button. This component
 * was created to standardize the look and feel of those sections. It's possible we may not need it anymore and it
 * would be more of a PageHeading
 */
export const PageSection = ({
  button,
  children,
  className,
  fullHeight = true,
  heading,
  headingSmall,
  onScroll,
  subheading,
}: PageSectionProps) => {
  return (
    <Flex className={classNames(styles.section, { [styles.fullHeight]: fullHeight }, className)}>
      <Flex className={styles.heading} align="center" justify="space-between">
        <div>
          <Typography.Title
            aria-hidden
            level={1}
            style={{
              lineHeight: '30px',
            }}
          >
            {heading}
            {headingSmall && (
              <Typography.Text
                style={{
                  color: '#695AD9',
                  fontSize: '20px',
                  lineHeight: '30px',
                  marginLeft: '10px',
                }}
              >
                {headingSmall}
              </Typography.Text>
            )}
          </Typography.Title>
          <Typography.Text
            style={{
              color: 'var(--nri-color-purple-grey)',
              lineHeight: '24px',
            }}
          >
            {subheading}
          </Typography.Text>
        </div>
        <div>{button}</div>
      </Flex>
      <Flex className={styles.container} onScroll={onScroll}>
        {children}
      </Flex>
    </Flex>
  );
};
