import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Flex, Form, FormListFieldData, Input } from 'antd';
import AddInstructionIcon from 'assets/icons/addInstruction.svg?react';
import CodeIcon from 'assets/icons/code.svg?react';
import FileIcon from 'assets/icons/file.svg?react';
import ChipIcon from 'assets/iconsNewVersion/chip.svg?react';
import { BlueprintFormData } from 'components/blueprint-view/BlueprintView';
import { useEntityContext } from 'components/layouts/context/EntityProvider';
import { MemorySelect } from 'components/memory-select/MemorySelect';
import React, { useCallback, useEffect } from 'react';
import { useAnalysts } from 'selectors/useAnalystsSelector';
import { useAuth } from 'selectors/useAuthSelector';
import { useMemories } from 'selectors/userMemories';
import { Button } from 'ui/Button';
import { CodeTextArea } from 'ui/CodeTextArea';
import { Collapse } from 'ui/Collapse';
import { InputInlineEdit } from 'ui/InputInlineEdit';
import { Select } from 'ui/Select';
import styles from 'ui/UIKit.module.scss';

interface WorkflowFormProps {
  field: FormListFieldData;
  initialCollapsed?: boolean;
}

export const WorkflowForm: React.FC<WorkflowFormProps> = ({ field, initialCollapsed = true }) => {
  const { memories } = useMemories();
  const { form } = useEntityContext<BlueprintFormData>();
  const { me } = useAuth();
  const initialMemoryIds: MemoryId[] = form.getFieldValue('steps')[field.name].memories || [];
  const initialMemories = initialMemoryIds.map(id => memories.find(memory => memory.id === id)!).filter(Boolean);

  const [showDetailedInstructions, setShowDetailedInstructions] = React.useState(true);
  const [showQA, setShowQA] = React.useState(form.getFieldValue('steps')[field.name].qaInstructions?.length > 0);
  const [showCode, setShowCode] = React.useState(form.getFieldValue('steps')[field.name].code?.length > 0);
  const [showMemories, setShowMemories] = React.useState(initialMemoryIds.length > 0);
  const initialCode = form.getFieldValue('steps')[field.name].code || '';
  const [showAnalyst, setShowAnalyst] = React.useState(!!form.getFieldValue('steps')[field.name].analyst);

  const { analysts, loading: analystsLoading } = useAnalysts();

  const onSelect = useCallback(
    (memories: Memory[]) => {
      const data = form.getFieldValue('steps');
      data[field.name] = { ...data[field.name], memories: memories.map(memory => memory.id) };
      form.setFieldValue('steps', data);
    },
    [field.name, form],
  );

  const currentStep = Form.useWatch(['steps', field.name], form);

  const hasErrors = [
    ['steps', field.name, 'code'],
    ['steps', field.name, 'detailedInstructions'],
    ['steps', field.name, 'name'],
    ['steps', field.name, 'qaInstructions'],
  ].some(fieldPath => form.getFieldError(fieldPath).length > 0);
  const hasSomeFieldEntered = showDetailedInstructions || showQA || showCode;

  useEffect(() => {
    form.validateFields([['steps', field.name]]).catch(() => {});
  }, [currentStep, field.name, form, hasSomeFieldEntered]);

  const clearFieldValue = useCallback(
    (fieldName: string) => {
      const data = form.getFieldValue('steps');
      data[field.name] = { ...data[field.name], [fieldName]: null };
      form.setFieldValue('steps', data);
    },
    [field.name, form],
  );

  const handleRemoveDetailedInstructions = () => {
    clearFieldValue('detailedInstructions');
    setShowDetailedInstructions(false);
  };

  const handleRemoveQA = () => {
    clearFieldValue('qaInstructions');
    setShowQA(false);
  };

  const handleRemoveCode = () => {
    clearFieldValue('code');
    setShowCode(false);
  };

  const handleRemoveMemories = () => {
    clearFieldValue('memories');
    setShowMemories(false);
  };

  const handleRemoveAnalyst = () => {
    clearFieldValue('analyst');
    setShowAnalyst(false);
  };

  return (
    <Collapse
      activeKey={hasErrors || !hasSomeFieldEntered ? 0 : undefined}
      defaultActiveKey={initialCollapsed ? undefined : 0}
      expandIcon={({ isActive }) =>
        isActive ? <MinusOutlined style={{ fontSize: 16 }} /> : <PlusOutlined style={{ fontSize: 16 }} />
      }
      expandIconPosition="end"
      size="large"
      variant="secondary"
      items={[
        {
          children: (
            <>
              <Form.Item
                {...field}
                name={[field.name, '_validation']}
                rules={[
                  {
                    validator: async () => {
                      if (!hasSomeFieldEntered) {
                        throw new Error(
                          'At least one of Detailed Instructions, QA Instructions, or Code must be provided',
                        );
                      }
                    },
                  },
                ]}
                noStyle
              >
                <div style={{ display: 'none' }} />
              </Form.Item>

              <Form.Item {...field} name={[field.name, 'id']} hidden>
                <Input />
              </Form.Item>

              <Form.Item
                {...field}
                name={[field.name, 'analyst']}
                label={
                  <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                    Specify Agent
                    <Button type="text" size="small" onClick={handleRemoveAnalyst}>
                      Remove agent specification (auto select)
                    </Button>
                  </Flex>
                }
                className={styles.wideFormItem}
                hidden={!showAnalyst}
              >
                <Select
                  placeholder="Select agent"
                  loading={analystsLoading}
                  allowClear
                  options={analysts.map(analyst => ({
                    label: analyst.name,
                    value: analyst.name,
                  }))}
                />
              </Form.Item>

              <Form.Item
                {...field}
                className={styles.wideFormItem}
                name={[field.name, 'detailedInstructions']}
                label={
                  <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                    Step detailed instructions
                    <Button type="text" size="small" onClick={handleRemoveDetailedInstructions}>
                      Remove Detailed Instructions
                    </Button>
                  </Flex>
                }
                rules={[
                  {
                    validator: async (_, value) => {
                      if (showDetailedInstructions) {
                        if (!value) {
                          throw new Error('Please enter step detailed instructions');
                        }
                        if (!value.trim()) {
                          throw new Error('Detailed instructions cannot be empty');
                        }
                      }
                    },
                  },
                ]}
                hidden={!showDetailedInstructions}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} placeholder="Enter step detailed instructions" />
              </Form.Item>

              <Form.Item
                className={styles.wideFormItem}
                {...field}
                name={[field.name, 'qaInstructions']}
                label={
                  <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                    Step QA Instructions
                    <Button type="text" size="small" onClick={handleRemoveQA}>
                      Remove QA Instructions
                    </Button>
                  </Flex>
                }
                rules={[
                  {
                    validator: async (_, value) => {
                      if (showQA) {
                        if (!value) {
                          throw new Error('Please enter step QA Instructions');
                        }
                        if (!value.trim()) {
                          throw new Error('QA Instructions cannot be empty');
                        }
                      }
                    },
                  },
                ]}
                hidden={!showQA}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} placeholder="Enter step QA instructions" />
              </Form.Item>

              <Form.Item noStyle className={styles.wideFormItem} hidden={!showCode}>
                <Card
                  title={
                    <Flex align="center" justify="space-between" style={{ width: '100%' }}>
                      Code
                      <Button type="text" size="small" onClick={handleRemoveCode}>
                        Remove Code
                      </Button>
                    </Flex>
                  }
                >
                  <CodeTextArea
                    name={[field.name, 'code']}
                    initialValue={initialCode}
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (showCode) {
                            if (!value) {
                              throw new Error('Please enter code');
                            }
                            if (!value.trim()) {
                              throw new Error('Code cannot be empty');
                            }
                          }
                        },
                      },
                    ]}
                  />
                </Card>
              </Form.Item>

              <Form.Item {...field} name={[field.name, 'memories']} fieldKey={[field.key, 'memories']} hidden>
                <Input />
              </Form.Item>

              <Form.Item
                className={styles.wideFormItem}
                {...field}
                label={
                  <Flex align="center" justify="space-between" style={{ marginTop: '12px', width: '100%' }}>
                    Memories
                    <Button type="text" size="small" onClick={handleRemoveMemories}>
                      Remove Memories
                    </Button>
                  </Flex>
                }
                hidden={!showMemories}
              >
                <MemorySelect initialSelections={initialMemories} onSelect={onSelect} />
              </Form.Item>

              {showAnalyst ? null : (
                <Button key="analyst-button" type="text" icon={<ChipIcon />} onClick={() => setShowAnalyst(true)}>
                  Specify Agent
                </Button>
              )}

              {showDetailedInstructions ? null : (
                <Button
                  key="detailed-instructions-button"
                  type="text"
                  icon={<AddInstructionIcon />}
                  onClick={() => setShowDetailedInstructions(true)}
                >
                  Add Detailed Instructions
                </Button>
              )}
              {showQA ? null : (
                <Button
                  key="qa-button"
                  type="text"
                  disabled={!me?.isSuperuser}
                  icon={<AddInstructionIcon />}
                  onClick={() => setShowQA(true)}
                >
                  Add QA Instructions
                </Button>
              )}

              {showCode ? null : (
                <Button key="code-button" type="text" icon={<CodeIcon />} onClick={() => setShowCode(true)}>
                  Add Code
                </Button>
              )}
              {showMemories ? null : (
                <Button key="memories-button" type="text" icon={<FileIcon />} onClick={() => setShowMemories(true)}>
                  Connect Memories
                </Button>
              )}
            </>
          ),
          key: 0,
          label: (
            <div style={{ display: 'flex' }}>
              <Form.Item
                {...field}
                name={[field.name, 'name']}
                rules={[{ required: true, message: 'Please enter step name' }]}
                style={{ margin: 0 }}
                noStyle
              >
                <InputInlineEdit
                  autoFocus
                  bordered={false}
                  onClick={e => e.stopPropagation()}
                  placeholder="Enter a step name"
                />
              </Form.Item>
            </div>
          ),
        },
      ]}
    />
  );
};
