import { LoadingOutlined } from '@ant-design/icons';
import { Upload as AntUpload, ConfigProvider, Typography, UploadProps } from 'antd';
import UploadCloud from '../assets/iconsNewVersion/upload_cloud.svg?react';
import DocumentIcon from '../assets/iconsNewVersion/file.svg?react';
import classNames from 'classnames';
import { Button } from 'ui/Button';
import styles from 'ui/UIKit.module.scss';

type Props = UploadProps & {
  loading?: boolean;
};

/**
 * TODO: Finish uploading UI
 */
export const Upload: React.FC<Props> = ({ className, fileList, loading, ...props }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultShadow: 'none',
            // @ts-expect-error type string is not assignable to type number, still works
            controlHeight: '68px',
            defaultBorderColor: 'var(--nri-color-grey)',
            fontWeight: 400,
          },
          List: {},
        },
      }}
    >
      <AntUpload
        className={classNames(styles.upload, className)}
        iconRender={() => (
          <div className={styles.documentIconContainer}>
            <DocumentIcon className={styles.documentIcon} />
          </div>
        )}
        fileList={fileList}
        listType="picture"
        {...props}
      >
        {!fileList?.length && (
          <Button
            block
            bordered
            className={loading ? styles.button : undefined}
            icon={
              loading ? (
                <LoadingOutlined className={styles.loadingIcon} />
              ) : (
                <UploadCloud className={styles.uploadIcon} />
              )
            }
          >
            {loading ? (
              'Uploading...'
            ) : (
              <Typography.Paragraph style={{ marginBottom: 0, textAlign: 'left' }}>
                <strong>Click to upload</strong> or drag and drop
                <br />
                <span className={styles.warningText}>CSV (max. 800)</span>
              </Typography.Paragraph>
            )}
          </Button>
        )}
      </AntUpload>
    </ConfigProvider>
  );
};
