import { Card, ConfigProvider, Flex, Typography } from 'antd';
import styles from './EntityList.module.scss';
import { PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';

type Props = {
  /** The action to display in the card */
  action?: string;
  /** The name of the entity */
  entityName: string;
  /** A function to format the name of the entity */
  formatName?(name: string): string;
  /** The function to call when the card is clicked */
  onClick(): void;
  /** The subtext to display in the card */
  subText?: React.ReactNode;
};

export const NewEntityCard: React.FC<Props> = ({ action = 'Add', entityName, formatName, onClick, subText }) => (
  <ConfigProvider
    theme={{
      components: {
        Card: {
          colorBgContainer: 'var(--nri-color-light-grey)',
          colorBorderSecondary: 'var(--nri-color-purple-grey)',
        },
      },
      token: {
        colorText: 'var(--nri-color-purple-grey)',
        lineType: 'dashed',
      },
    }}
  >
    <Card className={classNames(styles.newEntityCard)} onClick={onClick}>
      <Flex align="center" justify="center" style={{ height: '100%' }} vertical>
        <Typography.Text className={styles.newEntityHeading}>
          <PlusOutlined /> {action} {formatName ? formatName(entityName) : entityName}
        </Typography.Text>
        <Typography.Text className={styles.newEntitySubText}>{subText}</Typography.Text>
      </Flex>
    </Card>
  </ConfigProvider>
);
