/**
 * This component will be used for "Selectors" and "Filters"
 *
 * https://www.figma.com/design/Imk8oaY1eyQJjI94EU18kY/Newton-research?node-id=3301-48769&t=0WiX1LGDVNCL4yqz-4
 */

import { ConfigProvider, Dropdown as AntDropdown, DropdownProps, Button, Space } from 'antd';
import React from 'react';
import styles from './UIKit.module.scss';
import classNames from 'classnames';
import DownArrowIcon from 'assets/iconsNewVersion/chevron_down.svg?react';

type Props = DropdownProps & {
  filled?: boolean;
  items?: { key: string; label: React.ReactNode }[];
};

// FIXME: For styling purposes only
const defaultItems = [
  {
    key: "1",
    label: "One",
  },
  {
    key: "2",
    label: "Two",
  },
  {
    key: "3",
    label: "Three",
  },
];

export const Dropdown: React.FC<Props> = ({
  children,
  className,
  filled,
  items: propItems,
  placement = "bottomLeft",
  trigger = ["click"],
  ...props
}) => {
  let items = propItems;
  if (!items || items.length === 0) {
    items = defaultItems;
  }
  return (
    <ConfigProvider
      theme={{
        components: {
          // Re-style the "default" button. The rest of the styling is in the global styles
          Button: {
            colorBgContainer: filled ? "var(--nri-color-white)" : undefined,
            defaultBorderColor: "#e0e0ed",
            defaultHoverBg: "white",
            defaultHoverColor: "var(--nri-color-primary)",
            defaultHoverBorderColor: "var(--nri-color-primary)",
            paddingInline: "10px",
            defaultShadow: "none",
          },
        },
      }}
    >
      <AntDropdown
        className={classNames(styles.dropdown, className)}
        overlayClassName={styles.dropdownOverlay}
        placement={placement}
        trigger={trigger}
        {...props}
      >
        <Button>
          <Space size="large">
            {children}
            <DownArrowIcon color="var(--nri-color-purple-grey)" />
          </Space>
        </Button>
      </AntDropdown>
    </ConfigProvider>
  );
};
