import * as React from "react";
const SvgGoogleslides = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("mask", { id: "mask0_7653_51863", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1H5.57161C4.70723 1 4 1.66562 4 2.47917V21.2153C4 22.0288 4.70723 22.6944 5.57161 22.6944H19.1923C20.0567 22.6944 20.7639 22.0288 20.7639 21.2153V6.91667L14.4774 1Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_7653_51863)" }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1H5.57161C4.70723 1 4 1.66562 4 2.47917V21.2153C4 22.0288 4.70723 22.6944 5.57161 22.6944H19.1923C20.0567 22.6944 20.7639 22.0288 20.7639 21.2153V6.91667L17.0968 4.45139L14.4774 1Z", fill: "#F4B400" })), /* @__PURE__ */ React.createElement("mask", { id: "mask1_7653_51863", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1H5.57161C4.70723 1 4 1.66562 4 2.47917V21.2153C4 22.0288 4.70723 22.6944 5.57161 22.6944H19.1923C20.0567 22.6944 20.7639 22.0288 20.7639 21.2153V6.91667L14.4774 1Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_7653_51863)" }, /* @__PURE__ */ React.createElement("path", { d: "M15.7871 10.8611H8.97678C8.54458 10.8611 8.19097 11.1939 8.19097 11.6007V18.0104C8.19097 18.4172 8.54458 18.75 8.97678 18.75H15.7871C16.2193 18.75 16.5729 18.4172 16.5729 18.0104V11.6007C16.5729 11.1939 16.2193 10.8611 15.7871 10.8611ZM15.5252 16.5312H9.23871V13.0798H15.5252V16.5312Z", fill: "#F1F1F1" })), /* @__PURE__ */ React.createElement("mask", { id: "mask2_7653_51863", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1H5.57161C4.70723 1 4 1.66562 4 2.47917V21.2153C4 22.0288 4.70723 22.6944 5.57161 22.6944H19.1923C20.0567 22.6944 20.7639 22.0288 20.7639 21.2153V6.91667L14.4774 1Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask2_7653_51863)" }, /* @__PURE__ */ React.createElement("path", { d: "M14.9371 6.48413L20.7639 11.9669V6.91679L14.9371 6.48413Z", fill: "url(#paint0_linear_7653_51863)" })), /* @__PURE__ */ React.createElement("mask", { id: "mask3_7653_51863", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1H5.57161C4.70723 1 4 1.66562 4 2.47917V21.2153C4 22.0288 4.70723 22.6944 5.57161 22.6944H19.1923C20.0567 22.6944 20.7639 22.0288 20.7639 21.2153V6.91667L14.4774 1Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask3_7653_51863)" }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1V5.4375C14.4774 6.25474 15.1807 6.91667 16.049 6.91667H20.7639L14.4774 1Z", fill: "#FADA80" })), /* @__PURE__ */ React.createElement("mask", { id: "mask4_7653_51863", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1H5.57161C4.70723 1 4 1.66562 4 2.47917V21.2153C4 22.0288 4.70723 22.6944 5.57161 22.6944H19.1923C20.0567 22.6944 20.7639 22.0288 20.7639 21.2153V6.91667L14.4774 1Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask4_7653_51863)" }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1V1.12326L20.6329 6.91667H20.7639L14.4774 1Z", fill: "white", fillOpacity: 0.1 })), /* @__PURE__ */ React.createElement("mask", { id: "mask5_7653_51863", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1H5.57161C4.70723 1 4 1.66562 4 2.47917V21.2153C4 22.0288 4.70723 22.6944 5.57161 22.6944H19.1923C20.0567 22.6944 20.7639 22.0288 20.7639 21.2153V6.91667L14.4774 1Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask5_7653_51863)" }, /* @__PURE__ */ React.createElement("path", { d: "M5.57161 1C4.70723 1 4 1.66563 4 2.47917V2.60243C4 1.78889 4.70723 1.12326 5.57161 1.12326H14.4774V1H5.57161Z", fill: "white", fillOpacity: 0.2 })), /* @__PURE__ */ React.createElement("mask", { id: "mask6_7653_51863", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1H5.57161C4.70723 1 4 1.66562 4 2.47917V21.2153C4 22.0288 4.70723 22.6944 5.57161 22.6944H19.1923C20.0567 22.6944 20.7639 22.0288 20.7639 21.2153V6.91667L14.4774 1Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask6_7653_51863)" }, /* @__PURE__ */ React.createElement("path", { d: "M19.1923 22.5712H5.57161C4.70723 22.5712 4 21.9056 4 21.092V21.2153C4 22.0288 4.70723 22.6945 5.57161 22.6945H19.1923C20.0567 22.6945 20.7639 22.0288 20.7639 21.2153V21.092C20.7639 21.9056 20.0567 22.5712 19.1923 22.5712Z", fill: "#BF360C", fillOpacity: 0.2 })), /* @__PURE__ */ React.createElement("mask", { id: "mask7_7653_51863", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 1, width: 17, height: 22 }, /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1H5.57161C4.70723 1 4 1.66562 4 2.47917V21.2153C4 22.0288 4.70723 22.6944 5.57161 22.6944H19.1923C20.0567 22.6944 20.7639 22.0288 20.7639 21.2153V6.91667L14.4774 1Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask7_7653_51863)" }, /* @__PURE__ */ React.createElement("path", { d: "M16.049 6.91667C15.1807 6.91667 14.4774 6.25474 14.4774 5.4375V5.56076C14.4774 6.378 15.1807 7.03993 16.049 7.03993H20.7639V6.91667H16.049Z", fill: "#BF360C", fillOpacity: 0.1 })), /* @__PURE__ */ React.createElement("path", { d: "M14.4774 1H5.57161C4.70723 1 4 1.66562 4 2.47917V21.2153C4 22.0288 4.70723 22.6944 5.57161 22.6944H19.1923C20.0567 22.6944 20.7639 22.0288 20.7639 21.2153V6.91667L14.4774 1Z", fill: "url(#paint1_radial_7653_51863)" }), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "paint0_linear_7653_51863", x1: 306.307, y1: 53.5598, x2: 306.307, y2: 554.838, gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "#BF360C", stopOpacity: 0.2 }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#BF360C", stopOpacity: 0.02 })), /* @__PURE__ */ React.createElement("radialGradient", { id: "paint1_radial_7653_51863", cx: 0, cy: 0, r: 1, gradientUnits: "userSpaceOnUse", gradientTransform: "translate(57.1088 43.8778) scale(2703.15 2544.14)" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "white", stopOpacity: 0.1 }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "white", stopOpacity: 0 }))));
export default SvgGoogleslides;
