import { Flex, Typography } from 'antd';
import styles from './UsageSavedTime.module.scss';
import { FC } from 'react';
import { Loader } from 'components/loader/Loader';

type UsageSavedTimeProps = {
  time: { hours: number; minutes: number };
  description?: string;
  loading?: boolean;
};

export const UsageSavedTime: FC<UsageSavedTimeProps> = ({ description = 'Saved with Newton', loading, time }) => {
  const { hours, minutes } = time;
  return (
    <Flex vertical align="center" justify="center" className={styles.container}>
      {loading ? (
        <Loader size="default" />
      ) : (
        <>
          <Typography.Text className={styles.time}>
            {hours}h {minutes}m
          </Typography.Text>
          <Typography.Text className={styles.description}>{description} </Typography.Text>
        </>
      )}
    </Flex>
  );
};
