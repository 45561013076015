import { Card, ConfigProvider, Flex, Typography } from 'antd';
import classNames from 'classnames';
import styles from './ProjectsView.module.scss';
import { PlusOutlined } from '@ant-design/icons';
type NewProjectCardProps = {
  onClick: () => void;
};
export const NewProjectCard: React.FC<NewProjectCardProps> = ({ onClick }) => (
  <ConfigProvider
    theme={{
      components: {
        Card: {
          colorBgContainer: 'var(--nri-color-light-grey)',
          colorBorderSecondary: 'var(--nri-color-purple-grey)',
        },
      },
      token: {
        colorText: 'var(--nri-color-primary)',
        fontSize: 16,
        lineType: 'dashed',
      },
    }}
  >
    <Card className={classNames(styles.newProjectCard)} onClick={onClick}>
      <Flex align="center" justify="center" style={{ height: '100%' }} vertical>
        <Typography.Text className={styles.newProjectHeading}>
          <PlusOutlined /> Create Project
        </Typography.Text>
      </Flex>
    </Card>
  </ConfigProvider>
);
