import { useAppContext } from 'contexts/AppProviders';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalsActionTypes, ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';

const useModalsState = () => {
  const {
    state: { modalsState },
  } = useAppContext();

  return { state: modalsState };
};

export const useModals = () => {
  const { state } = useModalsState();

  return state.modals;
};

export function useModal<T>(scope: ModalScopesEnum, type: ModalTypesEnum, location?: string) {
  const { dispatch } = useAppContext();
  const { state } = useModalsState();
  const redirect = useNavigate();

  return {
    // @ts-expect-error - data is always defined
    visible: state.modals[scope][type],
    // @ts-expect-error - data is always defined
    data: state.modals[scope][type].data as T,
    // @ts-expect-error - data is always defined
    navigation: state.modals[scope][type].navigation as string,
    hide: useCallback(() => {
      // @ts-expect-error - data is always defined
      const { navigation } = state.modals[scope][type];

      dispatch({
        type: ModalsActionTypes.HIDE_MODAL,
        payload: {
          scope,
          type,
        },
      });
      navigation && redirect(navigation);
    }, [dispatch, scope, type]),
    show: useCallback(
      (data?: T) => {
        dispatch({
          type: ModalsActionTypes.SHOW_MODAL,
          payload: {
            data,
            navigation: location || undefined,
            scope,
            type,
          },
        });
      },
      [dispatch, scope, type],
    ),
  };
}
