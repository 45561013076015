import { Flex, Tag, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { BlueprintActionsMenu } from 'components/blueprint-actions-menu/BlueprintActionsMenu';
import { EntityTableProps } from 'components/entity-list/EntityList';
import { EntityTable } from 'components/entity-list/EntityTable';
import { FavoriteToggle } from 'components/favorite-toggle/FavoriteToggle';
import { MultipleElements } from 'components/multiple-labels/MultipleElements';
import { urlParameters } from 'hooks/useURLParams';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type BlueprintTableProps = EntityTableProps<Blueprint>;
export const BlueprintTable = ({ entities: blueprints, search }: BlueprintTableProps) => {
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (blueprint: Blueprint) => {
      navigate(
        `/conversations/new?${urlParameters({
          blueprint: blueprint.id,
        })}`,
      );
    },
    [navigate],
  );

  const columns: ColumnsType<Blueprint> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      width: 15,
      render: (value, blueprint) => (
        <Flex align="center">
          {blueprint.isFavorite && <FavoriteToggle isFavorite={blueprint.isFavorite!} />}
          <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ margin: 0, color: 'var(--nri-color-primary)' }}>
            {value}
          </Typography.Paragraph>
        </Flex>
      ),
    },
    { key: 'description', dataIndex: 'description', title: 'Description', width: 35, ellipsis: true },
    {
      key: 'dataSources',
      dataIndex: 'dataSources',
      title: 'Connectors',
      width: 45,
      render: value => {
        return (
          <MultipleElements>
            {value.map((ds: DataSource) => (
              <Tag
                key={ds.id}
                style={{ color: 'var(--nri-color-purple-grey)', borderColor: 'var(--nri-color-purple-grey)' }}
                color="var(--nri-color-grey-variant)"
              >
                {ds.name}
              </Tag>
            ))}
          </MultipleElements>
        );
      },
    },
    { key: 'actions', title: '', width: 5 },
  ];
  return (
    <EntityTable<Blueprint>
      actionMenu={blueprint => <BlueprintActionsMenu blueprint={blueprint} />}
      columns={columns}
      entities={blueprints}
      search={search}
      onRowClick={handleRowClick}
    />
  );
};
