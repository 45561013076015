import { Form, Space } from 'antd';
import { useEntityContext } from 'components/layouts/context/EntityProvider';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui/Button';

type EntityFormActionsProps = {
  entityName: string;
};

export const EntityFormActions: FC<EntityFormActionsProps> = ({ entityName }) => {
  const { validateAndSubmit, isSubmitting, isNew } = useEntityContext();

  const buttonText = isSubmitting
    ? isNew
      ? `Creating ${entityName}`
      : `Updating ${entityName}`
    : isNew
      ? 'Create'
      : 'Save';

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <Form.Item noStyle>
      <Space>
        <Button bordered onClick={navigateBack}>
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          onClick={validateAndSubmit}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {buttonText}
        </Button>
      </Space>
    </Form.Item>
  );
};
