import { Layout } from 'antd';
import { Loader } from 'components/loader/Loader';
import { useSearchParams } from 'hooks/useURLParams';
import { memo, useEffect, useMemo } from 'react';
import { useBlueprints } from 'selectors/useBlueprints';
import { useCreateConversation } from 'selectors/useConversationSelectors';
import { useAppContext } from 'contexts/AppProviders';
import { ConversationsActionTypes } from 'reducers/conversationsReducer';
import { useAnalystById } from 'selectors/useAgents';

/** This is a placeholder page since data is not loading right now */
export const NewConversationPage = memo(() => {
  const { blueprint, dataSources, agent } = useSearchParams();
  const { blueprints, libraryBlueprints } = useBlueprints();
  const createConversation = useCreateConversation();
  const selectedBlueprint = useMemo(
    () => blueprints.find(bp => bp.id === blueprint) || libraryBlueprints.find(bp => bp.id === blueprint),
    [blueprints, libraryBlueprints, blueprint],
  );
  const { dispatch } = useAppContext();
  const analyst = useAnalystById(Number(agent) as Analyst['id']);

  // Only run this on mount.
  useEffect(() => {
    (async () => {
      const conversation = await createConversation({
        icebreaker: selectedBlueprint ? selectedBlueprint.id : blueprint || undefined,
        dataSources: selectedBlueprint
          ? selectedBlueprint.dataSources.map(d => d.id)
          : (dataSources as DataSource['id'][]),
      });

      if (dataSources) {
        // Save the prompt with the selected data sources
        dispatch({
          type: ConversationsActionTypes.SAVE_PROMPT,
          payload: {
            conversationId: conversation.id,
            data: { dataSources: conversation.dataSources },
          },
        });
      }

      if (analyst) {
        // Save the prompt with the selected analyst
        dispatch({
          type: ConversationsActionTypes.SAVE_PROMPT,
          payload: {
            conversationId: conversation.id,
            data: {
              analyst,
              prompt: { text: '' },
            },
          },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBlueprint]);

  return (
    <Layout
      style={{
        alignContent: 'center',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <div>
        <Loader size="default" />
      </div>
    </Layout>
  );
});
