import { Form, Input } from 'antd';
import { Highlight, themes, Language } from 'prism-react-renderer';
import React, { useRef, useState } from 'react';
import styles from './UIKit.module.scss';
import { NamePath } from 'antd/es/form/interface';
import { Rule } from 'antd/es/form';
import { Select } from './Select';

interface CodeEditorProps {
  name?: NamePath<unknown>;
  initialValue?: string;
  readOnly?: boolean;
  rules?: Rule[];
}

const LANGUAGE_OPTIONS = [
  { value: 'typescript', label: 'TypeScript' },
  { value: 'javascript', label: 'JavaScript' },
  { value: 'python', label: 'Python' },
  { value: 'jsx', label: 'JSX' },
  { value: 'tsx', label: 'TSX' },
  { value: 'css', label: 'CSS' },
  { value: 'scss', label: 'SCSS' },
  { value: 'html', label: 'HTML' },
  { value: 'json', label: 'JSON' },
  { value: 'markdown', label: 'Markdown' },
  { value: 'bash', label: 'Bash' },
  { value: 'sql', label: 'SQL' },
] satisfies { value: Language; label: string }[];

const defaultValue = Array(10).fill('\n').join('');

export const CodeTextArea: React.FC<CodeEditorProps> = ({
  initialValue = defaultValue,
  name = '',
  readOnly = false,
  rules = [],
}) => {
  const [content, setContent] = useState(initialValue);
  const [language, setLanguage] = useState<Language>('python');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const lineNumbersRef = useRef<HTMLDivElement>(null);
  const lineCount = content.split('\n').length;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setContent(newValue);
  };

  const handleScroll = (e: React.UIEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLElement;
    const pre = target.nextElementSibling as HTMLElement;
    const lineNumbers = lineNumbersRef.current;

    if (pre) {
      pre.scrollTop = target.scrollTop;
      pre.scrollLeft = target.scrollLeft;
    }

    if (lineNumbers) {
      lineNumbers.scrollTop = target.scrollTop;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Tab') {
      e.preventDefault();

      const textarea = e.target as HTMLTextAreaElement;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      // Insert 2 spaces for tab
      const newValue = content.substring(0, start) + '  ' + content.substring(end);

      setContent(newValue);

      // Move cursor to after the inserted spaces
      requestAnimationFrame(() => {
        textarea.selectionStart = textarea.selectionEnd = start + 2;
      });
    }
  };

  return (
    <div className={styles.codeEditorWrapper}>
      <Form.Item name={name} rules={rules} noStyle>
        <div className={styles.codeEditorContainer}>
          <div ref={lineNumbersRef} className={styles.lineNumbers}>
            {Array.from({ length: lineCount }, (_, i) => (
              <div key={i} className={styles.lineNumber}>
                {i + 1}
              </div>
            ))}
          </div>
          <div className={styles.editorWrapper}>
            <Input.TextArea
              ref={textAreaRef}
              value={content}
              onChange={handleChange}
              onScroll={handleScroll}
              onKeyDown={handleKeyDown}
              className={styles.codeTextArea}
              autoSize={false}
              readOnly={readOnly}
            />
            <Highlight theme={themes.duotoneLight} code={content} language={language}>
              {({ tokens, getLineProps, getTokenProps }) => (
                <pre className={styles.highlighting}>
                  {tokens.map((line, i) => (
                    <div key={i} {...getLineProps({ line })}>
                      {line.map((token, key) => (
                        <span key={key} {...getTokenProps({ token })} />
                      ))}
                    </div>
                  ))}
                </pre>
              )}
            </Highlight>
          </div>
        </div>
      </Form.Item>

      <div className={styles.codeEditorFooter}>
        <Select
          value={language}
          onChange={value => setLanguage(value as Language)}
          options={LANGUAGE_OPTIONS}
          className={styles.languageSelect}
        />
      </div>
    </div>
  );
};
