import { useAppContext } from 'contexts/AppProviders';
import { StatisticsActionTypes } from './../reducers/statisticsReducer';
import { useEffect } from 'react';
import { CommonStatusEnum } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';

export const useCreditsInfoState = () => {
  const {
    state: { statisticsState },
    dispatch,
  } = useAppContext();
  const { status } = statisticsState.creditsInfo;
  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: StatisticsActionTypes.FETCHING_CREDITS_INFO,
        });

        const response = await NewtonApi.fetchExpendedCreditsInfo();

        dispatch({
          type: StatisticsActionTypes.FETCH_CREDITS_INFO,
          payload: response,
        });
      })();
    }
  }, [dispatch, status]);

  return { state: statisticsState };
};

const useUsageInfoState = (timePeriod: string) => {
  const {
    state: { statisticsState },
    dispatch,
  } = useAppContext();
  const { status } = statisticsState.dailyCredits;

  const params = {
    time_period: timePeriod,
  };

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: StatisticsActionTypes.FETCHING_DAILY_CREDITS,
        });
        const response = await NewtonApi.fetchUsageReport(params);
        const creditsByDay = response.map((item: { date: string; totalTokensCreditSum: number }) => ({
          date: item.date,
          credits: item.totalTokensCreditSum,
        }));
        dispatch({
          type: StatisticsActionTypes.FETCH_DAILY_CREDITS,
          payload: creditsByDay,
        });
      })();
    }

    return () => {
      if (status === CommonStatusEnum.FETCHED)
        dispatch({
          type: StatisticsActionTypes.CLEAN_DAILY_CREDITS,
        });
    };
  }, [dispatch, status, timePeriod]);

  return { state: statisticsState };
};

export const useDailyCredits = (timePeriod: string) => {
  const {
    state: { dailyCredits },
  } = useUsageInfoState(timePeriod);

  const { status, data } = dailyCredits;

  return {
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
    data: Array.from(data.values()),
  };
};

export const useCreditsInfo = () => {
  const {
    state: { creditsInfo },
  } = useCreditsInfoState();
  const { status, data } = creditsInfo;

  return {
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
    totalCredits: data.totalCredits,
    totalTokens: data.totalTokensCredit,
  };
};
