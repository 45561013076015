import { notification } from 'antd';
import DeleteIcon from 'assets/icons/trash.svg?react';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useDeleteAgent } from 'selectors/useAgents';
import { useModal } from 'selectors/useModals';

export const DeleteAgentModal: React.FC = () => {
  const { data: agent, hide } = useModal<Agent>(ModalScopesEnum.AGENTS, ModalTypesEnum.DELETE);
  const [name] = useState(agent.name);
  const [processing, setProcessing] = useState(false);
  const archive = useDeleteAgent(agent.id);
  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await archive();

    notification.open({
      icon: <DeleteIcon color="var(--nri-color-red)" />,
      message: <strong>Agent deleted</strong>,
      description: (
        <>
          <strong>{name}</strong> was deleted.
        </>
      ),
    });

    hide();
    setProcessing(false);
  }, [archive, hide, name]);

  return (
    <ConfirmationModal
      key={'agent::delete'}
      confirmationButton={processing ? 'Deleting' : 'Yes, delete'}
      isOpen
      title={'Delete agent'}
      description={
        <>
          Are you sure you want to delete this agent <strong>{agent.name}</strong>?
        </>
      }
      onCancel={hide}
      onConfirm={onConfirm}
      processing={processing}
    />
  );
};
