import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import katex from 'katex';
import styles from './NewtonMarkdown.module.scss';
import 'katex/dist/katex.min.css';
import 'katex/dist/contrib/mhchem.min.js';

type CustomLinkProps = {
  href?: string;
  children?: React.ReactNode;
  node: unknown;
};

const CustomLink: React.FC<CustomLinkProps> = ({ href, children, ...props }) => {
  const handleClick = async (e: React.MouseEvent) => {
    // Prevent the default behavior first
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileName = (props.node as any).children[0].children[0].value;

    try {
      // Attempt to download the content on behalf of the user.
      const response = await fetch(href!);
      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // We have the content so we'll use the markdown info as the filename
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      // Prevent the default behavior only if the fetch is successful
      e.preventDefault();
    } catch (error) {
      // Failed to download on behalf of the user, but it will fallback to the
      // browsers default behavior and download with the nri file name
      console.error('Download attempt failed, falling back to browser', error, href);

      // Revert the preventDefault to allow the default download behavior
      window.location.href = href!;
    }
  };

  return (
    <a href={href} onClick={handleClick}>
      {children}
    </a>
  );
};

type Props = {
  isAnalyst: boolean;
  children: string | null | undefined;
};

export const Markdown: React.FC<Props> = ({ isAnalyst, children }) => {
  return (
    <ReactMarkdown
      components={{
        // @ts-expect-error not assignable
        a: CustomLink,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        table: ({ node: _, ...props }) => (
          <div className={styles.table}>
            <table {...props} />
          </div>
        ),

        code: ({ className, children }) => {
          const match = /language-(\w+)/.exec(className || '');
          const language = match ? match[1] : '';
          const content = String(children);

          // Regex patterns for different LaTeX math delimiters
          const mathPatterns = {
            displayMath: /\$\$(.*?)\$\$/s,  // $$...$$
            brackets: /\\\[(.*?)\\\]/s,      // \[...\]
            bareBrackets: /\[(.*?)\]/s,      // [...]
            parentheses: /\\\((.*?)\\\)/s,   // \(...\)
            environments: /\\begin\{(equation|align|gather|matrix|cases)\}(.*?)\\end\{\1\}/s
          };

          // Check for various LaTeX indicators
          const hasLatexIndicators = (
            language === 'latex' || 
            ((language === 'markdown' || !language) && (
              Object.values(mathPatterns).some(pattern => pattern.test(content)) ||
              /\\[a-zA-Z]+\{/.test(content) || // Matches common LaTeX commands
              (content.includes('\\text') && /\[.*\]/.test(content)) // Special case for bare brackets with LaTeX commands
            ))
          );

          if (hasLatexIndicators) {
            let formula = content
              .replace(/```(latex|markdown)/g, '') // Remove language markers
              .replace(/```/g, '') // Remove code block markers
              .trim();

            // Extract the math content from the first matching delimiter pattern
            for (const pattern of Object.values(mathPatterns)) {
              const match = pattern.exec(formula);
              if (match) {
                formula = match[match.length - 1].trim(); // Get the captured math content
                break;
              }
            }

            try {
              const html = katex.renderToString(formula, {
                displayMode: true,
                throwOnError: false,
                trust: true,
                strict: false,
                output: 'html',
              });

              return <div className={styles.math} dangerouslySetInnerHTML={{ __html: html }} />;
            } catch (error) {
              console.error('KaTeX rendering error:', error);
              return <code className={className}>{children}</code>;
            }
          }

          return <code className={className}>{children}</code>;
        },
      }}
      remarkPlugins={[remarkGfm, [remarkMath, { singleDollarTextMath: false }]]}
      rehypePlugins={[rehypeKatex]}
      className={classNames(styles.markdown, {
        [styles.user]: !isAnalyst,
      })}
    >
      {children}
    </ReactMarkdown>
  );
};
