import { Button as AntButton, ButtonProps } from 'antd';
import classNames from 'classnames';
import styles from 'ui/UIKit.module.scss';

type Props = Omit<ButtonProps, 'type'> & { bordered?: boolean; type?: ButtonProps['type'] | 'secondary' };

export const Button: React.FC<Props> = ({ bordered, className, type, ...props }) => {
  let styleProps = {};
  if (type === 'secondary') {
    styleProps = { bordered: true };
  } else {
    styleProps = { type };
  }

  return (
    <AntButton
      className={classNames(styles.button, className, {
        [styles.noBorder]: type !== 'secondary' && !bordered,
      })}
      {...styleProps}
      {...props}
    />
  );
};
