import { message } from 'antd';
import { useAppContext } from 'contexts/AppProviders';
import { useAuth } from './useAuthSelector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlueprintActionTypes } from 'reducers/bluePrintReducer';
import { CommonStatusEnum, RetrieveTypesEnum } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';

const useBlueprintsState = () => {
  const {
    state: { bluePrintState },
    dispatch,
  } = useAppContext();
  const fetchBlueprints = useFetchBlueprints();
  const { status } = bluePrintState;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        fetchBlueprints(RetrieveTypesEnum.ALL);
      })();
    }
  }, [dispatch, fetchBlueprints, status]);

  return { state: bluePrintState };
};

const useFetchBlueprints = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (scope: RetrieveTypesEnum | 'LIBRARY') => {
      dispatch({
        type: BlueprintActionTypes.FETCHING_BLUEPRINTS,
      });
      const [{ results: blueprints, ...pagination }, categories] = await Promise.all([
        NewtonApi.fetchAllBlueprints(scope, true),
        NewtonApi.fetchAllBlueprintCategories(),
      ]);

      dispatch({
        type: BlueprintActionTypes.FETCH_BLUEPRINTS,
        payload: { blueprints, pagination, scope },
      });
      dispatch({
        type: BlueprintActionTypes.FETCH_BLUEPRINT_CATEGORIES,
        payload: categories,
      });
    },
    [dispatch],
  );
};

const useFetchBlueprint = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (id: IceBreakerId) => {
      dispatch({
        type: BlueprintActionTypes.FETCHING_BLUEPRINTS,
      });
      const blueprint = await NewtonApi.fetchBlueprint(id);

      dispatch({
        type: BlueprintActionTypes.FETCH_BLUEPRINT,
        payload: blueprint,
      });
    },
    [dispatch],
  );
};

export const useBlueprints = () => {
  const {
    state: { scope, status },
  } = useBlueprintsState();

  return {
    blueprintsCount: scope[RetrieveTypesEnum.ALL].pagination.count,
    blueprints: Array.from(scope[RetrieveTypesEnum.ALL].blueprints.values()),
    libraryBlueprints: Array.from(scope['LIBRARY'].blueprints.values()),
    loading: status === CommonStatusEnum.INITIAL,
    status,
  };
};

export const useBlueprintCategories = () => {
  const {
    state: { categories },
  } = useBlueprintsState();
  return categories;
};

export const useFilteredBlueprints = () => {
  const { me } = useAuth();
  const {
    state: { scope, status },
  } = useBlueprintsState();
  const fetchBlueprints = useFetchBlueprints();
  const [filter, setFilter] = useState<RetrieveTypesEnum | 'LIBRARY'>(RetrieveTypesEnum.ALL);

  const scopedBlueprints = useMemo(() => {
    const base = Array.from(scope[RetrieveTypesEnum.ALL].blueprints.values());
    if (filter === 'LIBRARY') {
      return Array.from(scope['LIBRARY'].blueprints.values());
    } else if (filter === RetrieveTypesEnum.FAVORITE) {
      return Array.from(scope[RetrieveTypesEnum.FAVORITE].blueprints.values());
    } else if (filter === RetrieveTypesEnum.OWNED) {
      return base.filter(blueprint => blueprint.owner === me!.id);
    } else if (filter === RetrieveTypesEnum.ORGANIZATION) {
      return base;
    } else if (filter === RetrieveTypesEnum.SHARED) {
      return base;
    } else if (filter === RetrieveTypesEnum.RECENT) {
      return Array.from(scope[RetrieveTypesEnum.RECENT].blueprints.values());
    }
    return base;
  }, [scope, filter, me]);

  useEffect(() => {
    const shouldFetch =
      filter === RetrieveTypesEnum.ALL ||
      filter === 'LIBRARY' ||
      filter === RetrieveTypesEnum.FAVORITE ||
      filter === RetrieveTypesEnum.RECENT;

    if (shouldFetch && status !== CommonStatusEnum.INITIAL && scope[filter]?.status === CommonStatusEnum.INITIAL) {
      fetchBlueprints(filter);
    }
  }, [status, filter, fetchBlueprints, scope]);

  return {
    filtered: scopedBlueprints,
    filter: useCallback(
      (filter: RetrieveTypesEnum | 'LIBRARY') => {
        setFilter(filter);
      },
      [setFilter],
    ),
    status: scope[RetrieveTypesEnum.ALL].status,
    loading: useMemo(
      () =>
        scope[RetrieveTypesEnum.ALL].status === CommonStatusEnum.INITIAL ||
        scope[RetrieveTypesEnum.ALL].status === CommonStatusEnum.FETCHING,
      [scope],
    ),
  };
};

export const useLibraryBlueprintsByCategory = () => {
  const {
    state: { scope },
  } = useBlueprintsState();
  const [filter, setFilter] = useState<BlueprintCategoryId>();
  const fetchBlueprints = useFetchBlueprints();

  return {
    fetch: useCallback(() => {
      fetchBlueprints('LIBRARY');
    }, [fetchBlueprints]),
    filtered: useMemo(() => {
      const all = Array.from(scope['LIBRARY'].blueprints.values());
      if (filter) {
        return all.filter(blueprint => blueprint.useCaseCategories.includes(filter));
      }
      return all;
    }, [filter, scope]),
    filter: useCallback((category: BlueprintCategoryId) => {
      setFilter(category);
    }, []),
  };
};

export const useCategoriesById = () => {
  const {
    state: { categories },
  } = useBlueprintsState();
  return useCallback(
    (ids: BlueprintCategoryId[]) => {
      return categories.filter(category => ids.includes(category.id));
    },
    [categories],
  );
};

export const useActiveBlueprint = () => {
  const {
    state: { scope, status },
  } = useBlueprintsState();
  const { blueprintId } = useParams();
  const fetchBlueprint = useFetchBlueprint();

  return useMemo(() => {
    const id = Number(blueprintId) as IceBreaker['id'];
    if (id && !scope[RetrieveTypesEnum.ALL].blueprints.has(id) && status === CommonStatusEnum.FETCHED) {
      fetchBlueprint(id);
    } else {
      return scope[RetrieveTypesEnum.ALL].blueprints.get(Number(blueprintId) as IceBreaker['id']);
    }
  }, [blueprintId, fetchBlueprint, scope, status]);
};

export const useCreateBlueprint = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (payload: NewIceBreaker) => {
      const blueprint = await NewtonApi.createBlueprint(payload);

      message.info('Associating steps to blueprint...');
      await Promise.all(
        payload.steps.map(async (step: NewBlueprintStep, order: number) => {
          return await NewtonApi.createBlueprintStep({
            ...step,
            icebreaker: blueprint.id,
            order,
          });
        }),
      );

      const data = await NewtonApi.fetchBlueprint(blueprint.id);
      dispatch({ type: BlueprintActionTypes.FETCH_BLUEPRINT, payload: data });
      return blueprint;
    },
    [dispatch],
  );
};

export const useUpdateBlueprint = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (payload: UpdateIceBreaker, removedSteps: BlueprintStepId[]) => {
      const blueprint = await NewtonApi.updateBlueprint(payload);

      message.info('Updating steps to blueprint...');

      await Promise.all(
        removedSteps.map(async (stepId: BlueprintStepId) => {
          return await NewtonApi.deleteBlueprintStep(stepId);
        }),
      );

      await Promise.all(
        payload.steps.map(async (step: BlueprintStep, order: number) => {
          if (step.id) {
            return await NewtonApi.updateBlueprintStep({
              ...(step as unknown as UpdateBlueprintStep),
              icebreaker: blueprint.id,
              order,
            });
          } else {
            return await NewtonApi.createBlueprintStep({
              ...(step as unknown as NewBlueprintStep),
              icebreaker: blueprint.id,
              order,
            });
          }
        }),
      );

      const data = await NewtonApi.fetchBlueprint(blueprint.id);
      dispatch({ type: BlueprintActionTypes.FETCH_BLUEPRINT, payload: data });
    },
    [dispatch],
  );
};

export const useToggleBlueprintFavorite = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (blueprint: Blueprint) => {
      try {
        await NewtonApi.toggleBlueprintFavorite(blueprint.id, !blueprint.isFavorite);
      } catch (e) {
        message.error('Failed to favorite blueprint');
        return;
      }
      dispatch({
        type: BlueprintActionTypes.FETCH_BLUEPRINT,
        payload: {
          ...blueprint,
          isFavorite: !blueprint.isFavorite,
        },
      });
    },
    [dispatch],
  );
};

export const useArchiveBlueprint = (id: IceBreaker['id']) => {
  const { dispatch } = useAppContext();
  return useCallback(async () => {
    try {
      await NewtonApi.archiveBlueprint(id, true);
    } catch (error) {
      message.error('Failed to archive blueprint');
      return;
    }
    dispatch({
      type: BlueprintActionTypes.ARCHIVE_BLUEPRINT,
      payload: id,
    });
  }, [dispatch, id]);
};

export const useDuplicateBlueprint = (id: IceBreaker['id']) => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (name: string) => {
      try {
        const blueprint = await NewtonApi.copyBlueprint({ id, name } as UpdateIceBreaker, true);

        dispatch({
          type: BlueprintActionTypes.FETCH_BLUEPRINT,
          payload: blueprint,
        });
      } catch (error) {
        message.error('Failed to copy blueprint');
        return;
      }
    },
    [dispatch, id],
  );
};

export const useDeleteBlueprint = (id: IceBreaker['id']) => {
  const { dispatch } = useAppContext();
  return useCallback(async () => {
    try {
      await NewtonApi.deleteBlueprint(id, true);
    } catch (error) {
      message.error('Failed to delete blueprint');
      return;
    }
    dispatch({
      type: BlueprintActionTypes.DELETE_BLUEPRINT,
      payload: id,
    });
  }, [dispatch, id]);
};

export const useGenerateBlueprintFromConversation = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (conversationId: ConversationId) => {
      const blueprint: Blueprint = await NewtonApi.generateBlueprintFromConversation(conversationId);
      dispatch({
        type: BlueprintActionTypes.FETCH_BLUEPRINT,
        payload: blueprint,
      });
      return blueprint;
    },
    [dispatch],
  );
};
