import { Avatar, Flex } from 'antd';
import { FC, ReactNode } from 'react';
import styles from './EntitySelectedItem.module.scss';
import Typography from 'antd/es/typography/Typography';

export type EntitySelectedItemProps = {
  data: ShareableEntityProps;
  rightSideInfo?: ReactNode;
};

export const EntitySelectedItem: FC<EntitySelectedItemProps> = ({ data, rightSideInfo }) => {
  return (
    <Flex justify="space-between" align="center">
      <Flex align="center" gap="10px">
        {data.picture !== '' ? (
          <Avatar size={26} src={data.picture} />
        ) : (
          <Avatar className={styles.emptyAvatar}>{data?.name?.split('')[0].toUpperCase()}</Avatar>
        )}
        <Typography className={styles.userName}>{data.name}</Typography>
      </Flex>
      {rightSideInfo}
    </Flex>
  );
};
