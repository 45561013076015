import { Actions } from 'types/actionTypes';
import { CommonStatusEnum } from 'types/enum';

type Container<T extends { id: string | number }> = {
  list: Map<T['id'], T>;
  pagination: PaginationType;
  status: CommonStatusEnum;
};

export interface MemoriesState {
  memories: Container<Memory>;
  labels: Container<MemoryLabel>;
  sources: Container<MemorySource>;
  types: Container<MemoryType>;
  filteredMemories: Container<Memory>;
  status: CommonStatusEnum;
}

export const initialMemoriesState: MemoriesState = {
  memories: {
    list: new Map(),
    pagination: {
      next: null,
      previous: null,
      count: 0,
    },
    status: CommonStatusEnum.INITIAL,
  },
  labels: {
    list: new Map(),
    pagination: {
      next: null,
      previous: null,
      count: 0,
    },
    status: CommonStatusEnum.INITIAL,
  },
  sources: {
    list: new Map(),
    pagination: {
      next: null,
      previous: null,
      count: 0,
    },
    status: CommonStatusEnum.INITIAL,
  },
  types: {
    list: new Map(),
    pagination: {
      next: null,
      previous: null,
      count: 0,
    },
    status: CommonStatusEnum.INITIAL,
  },
  filteredMemories: {
    list: new Map(),
    pagination: {
      next: null,
      previous: null,
      count: 0,
    },
    status: CommonStatusEnum.INITIAL,
  },
  status: CommonStatusEnum.INITIAL,
};

export enum MemoryActionTypes {
  FETCHING_MEMORIES = 'FETCHING_MEMORIES',
  FETCH_MEMORIES = 'FETCH_MEMORIES',
  FETCHING_MEMORY_LABELS = 'FETCHING_MEMORY_LABELS',
  FETCH_MEMORY_LABELS = 'FETCH_MEMORY_LABELS',
  FETCHING_MEMORY_SOURCES = 'FETCHING_MEMORY_SOURCES',
  FETCH_MEMORY_SOURCES = 'FETCH_MEMORY_SOURCES',
  FETCHING_MEMORY_TYPES = 'FETCHING_MEMORY_TYPES',
  FETCH_MEMORY_TYPES = 'FETCH_MEMORY_TYPES',
  UPDATE_MEMORY = 'UPDATE_MEMORY',
  FETCHING_FILTERED_MEMORIES = 'FETCHING_FILTERED_MEMORIES',
  FETCH_FILTERED_MEMORIES = 'FETCH_FILTERED_MEMORIES',
  CLEAN_FILTERED_MEMORIES = 'CLEAN_FILTERED_MEMORIES',
}

export type MemoryActions =
  | { type: MemoryActionTypes.FETCHING_MEMORIES }
  | { type: MemoryActionTypes.FETCH_MEMORIES; payload: { pagination: PaginationType; memories: Memory[] } }
  | { type: MemoryActionTypes.FETCHING_MEMORY_LABELS }
  | { type: MemoryActionTypes.FETCH_MEMORY_LABELS; payload: { pagination: PaginationType; labels: MemoryLabel[] } }
  | { type: MemoryActionTypes.FETCHING_MEMORY_SOURCES }
  | { type: MemoryActionTypes.FETCH_MEMORY_SOURCES; payload: { pagination: PaginationType; sources: MemorySource[] } }
  | { type: MemoryActionTypes.FETCHING_MEMORY_TYPES }
  | { type: MemoryActionTypes.FETCH_MEMORY_TYPES; payload: { pagination: PaginationType; types: MemoryType[] } }
  | { type: MemoryActionTypes.UPDATE_MEMORY; payload: { id: Memory['id']; memory: Memory } }
  | { type: MemoryActionTypes.FETCHING_FILTERED_MEMORIES }
  | { type: MemoryActionTypes.FETCH_FILTERED_MEMORIES; payload: { pagination: PaginationType; memories: Memory[] } }
  | { type: MemoryActionTypes.CLEAN_FILTERED_MEMORIES };

export const memoriesReducer = (state: MemoriesState, action: Actions): MemoriesState => {
  switch (action.type) {
    case MemoryActionTypes.FETCHING_MEMORIES:
      return {
        ...state,
        memories: {
          ...state.memories,
          status: CommonStatusEnum.FETCHING,
        },
      };

    case MemoryActionTypes.FETCH_MEMORIES: {
      const { memories, pagination } = action.payload;
      const latestMemories = new Map(memories.map(memory => [memory.id, memory]));
      return {
        ...state,
        memories: {
          list: new Map([...state.memories.list, ...latestMemories]),
          pagination,
          status: CommonStatusEnum.FETCHED,
        },
      };
    }
    case MemoryActionTypes.FETCHING_MEMORY_LABELS:
      return {
        ...state,
        labels: {
          ...state.labels,
          status: CommonStatusEnum.FETCHING,
        },
      };
    case MemoryActionTypes.FETCH_MEMORY_LABELS: {
      const { labels, pagination } = action.payload;
      const latestLabels = new Map(labels.map(label => [label.id, label]));
      return {
        ...state,
        labels: {
          list: new Map([...state.labels.list, ...latestLabels]),
          pagination,
          status: CommonStatusEnum.FETCHED,
        },
      };
    }
    case MemoryActionTypes.FETCHING_MEMORY_SOURCES:
      return {
        ...state,
        sources: {
          ...state.sources,
          status: CommonStatusEnum.FETCHING,
        },
      };
    case MemoryActionTypes.FETCH_MEMORY_SOURCES: {
      const { sources, pagination } = action.payload;
      const latestSources = new Map(sources.map(source => [source.id, source]));
      return {
        ...state,
        sources: {
          list: new Map([...state.sources.list, ...latestSources]),
          pagination,
          status: CommonStatusEnum.FETCHED,
        },
      };
    }
    case MemoryActionTypes.FETCHING_MEMORY_TYPES:
      return {
        ...state,
        types: {
          ...state.types,
          status: CommonStatusEnum.FETCHING,
        },
      };
    case MemoryActionTypes.FETCH_MEMORY_TYPES: {
      const { types, pagination } = action.payload;
      const latestTypes = new Map(types.map(type => [type.id, type]));

      return {
        ...state,
        types: {
          list: new Map([...state.types.list, ...latestTypes]),
          pagination,
          status: CommonStatusEnum.FETCHED,
        },
      };
    }
    case MemoryActionTypes.FETCHING_FILTERED_MEMORIES:
      return {
        ...state,
        filteredMemories: {
          ...state.filteredMemories,
          status: CommonStatusEnum.FETCHING,
        },
      };
    case MemoryActionTypes.FETCH_FILTERED_MEMORIES: {
      const { memories, pagination } = action.payload;
      const latestMemories = new Map(memories.map(memory => [memory.id, memory]));
      return {
        ...state,
        filteredMemories: {
          list: latestMemories,
          pagination,
          status: CommonStatusEnum.FETCHED,
        },
      };
    }
    case MemoryActionTypes.CLEAN_FILTERED_MEMORIES:
      return {
        ...state,
        filteredMemories: {
          list: new Map(),
          pagination: {
            next: null,
            previous: null,
            count: 0,
          },
          status: CommonStatusEnum.INITIAL,
        },
      };
    case MemoryActionTypes.UPDATE_MEMORY: {
      const { id, memory } = action.payload;
      const memoryWrapper = state.memories.list.get(id);

      if (memoryWrapper) {
        state.memories.list.set(id, {
          ...memoryWrapper,
          ...memory,
        });
        state.filteredMemories.list.set(id, {
          ...memoryWrapper,
          ...memory,
        });
      }
      return { ...state };
    }
    default:
      return state;
  }
};
