import { Actions } from "types/actionTypes";
import { CommonStatusEnum } from "types/enum";


export interface AnalystsState {
    analysts: Map<Analyst["id"], Analyst>;
    defaultAnalyst?: Analyst;
    pagination: PaginationType;
    status: CommonStatusEnum;
}

export const initialAnalystsState: AnalystsState = {
    analysts: new Map(),
    defaultAnalyst: undefined,
    pagination: {
        count: 0,
        next: null,
        previous: null,
    },
    status: CommonStatusEnum.INITIAL,
};

export enum AnalystsActionTypes {
    FETCHING_ANALYSTS = "FETCHING_ANALYSTS",
    FETCH_ANALYSTS = "FETCH_ANALYSTS",
    FETCH_DEFAULT_ANALYST = "FETCH_DEFAULT_ANALYST",


    ADD_ANALYST = "ADD_ANALYST",
    UPDATE_ANALYST = "UPDATE_ANALYST",
    DELETE_ANALYST = "DELETE_ANALYST",
}

export type AnalystsAction =
    | { type: AnalystsActionTypes.FETCHING_ANALYSTS }
    | { type: AnalystsActionTypes.FETCH_ANALYSTS; payload: { analysts: Analyst[], pagination: PaginationType } }
    | { type: AnalystsActionTypes.FETCH_DEFAULT_ANALYST; payload: Analyst }

    | { type: AnalystsActionTypes.ADD_ANALYST; payload: Analyst }
    | { type: AnalystsActionTypes.UPDATE_ANALYST; payload: Analyst }
    | { type: AnalystsActionTypes.DELETE_ANALYST; payload: string };

export const analystsReducer = (state: AnalystsState, action: Actions): AnalystsState => {
    switch (action.type) {
        case AnalystsActionTypes.FETCHING_ANALYSTS:
            return { ...state, status: CommonStatusEnum.FETCHING };
        case AnalystsActionTypes.FETCH_ANALYSTS: {
            const { analysts, pagination } = action.payload;
            const analystsMap = new Map(analysts.map(analyst => [analyst.id, analyst]));
            return {
                ...state,
                analysts: new Map([
                    ...state.analysts,
                    ...analystsMap,
                ]),
                pagination,
                status: CommonStatusEnum.FETCHED
            };
        }
        case AnalystsActionTypes.FETCH_DEFAULT_ANALYST:
            return { ...state, defaultAnalyst: action.payload };

        default:
            return state;
    }
};