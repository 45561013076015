import { DeleteOutlined } from '@ant-design/icons';
import { Badge, Form, List, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useMemo, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useUpdateConversation } from 'selectors/useConversationSelectors';
import { useModal } from 'selectors/useModals';
import { useProjectLabels } from 'selectors/useProjectLabels';
import { Button } from 'ui/Button';
import { Select } from 'ui/Select';

export const AddToProjectConversationModal: React.FC = () => {
  const { show: showCreateProjectModal } = useModal(ModalScopesEnum.PROJECTS, ModalTypesEnum.ADD);
  const { data: conversation, hide } = useModal<Conversation>(
    ModalScopesEnum.CONVERSATIONS,
    ModalTypesEnum.ADD_TO_PROJECT,
  );
  const [processing, setProcessing] = useState(false);
  const [form] = Form.useForm();
  const updateConversation = useUpdateConversation();
  const { projectLabels } = useProjectLabels();

  const onConfirm = useCallback(async () => {
    setProcessing(true);
    try {
      const values = await form.validateFields();
      await updateConversation({
        id: conversation.id,
        projectLabels: values.projectLabels,
      });
      hide();
    } finally {
      setProcessing(false);
    }
  }, [conversation.id, form, hide, updateConversation]);

  const [selectedProjectLabelIds, setSelectedProjectLabelIds] = useState<ProjectLabelId[]>(
    conversation.projectLabels.map(l => l.id),
  );
  const projectOptions = useMemo(() => {
    return (projectLabels || []).filter(label => !selectedProjectLabelIds.includes(label.id));
  }, [projectLabels, selectedProjectLabelIds]);

  const selectedProjectLabels = useMemo(() => {
    return selectedProjectLabelIds.map(id => projectLabels.find(l => l.id === id)!);
  }, [projectLabels, selectedProjectLabelIds]);

  const handleSelectProject = (_: string, option: DefaultOptionType) => {
    setSelectedProjectLabelIds(prev => [...prev, option.value as ProjectLabelId]);

    form.setFieldValue('projectLabels', [...selectedProjectLabelIds, option.value]);
  };

  const handleRemoveProject = (label: ProjectLabel) => {
    setSelectedProjectLabelIds(prev => prev.filter(l => l !== label.id));
    form.setFieldValue(
      'projectLabels',
      selectedProjectLabels.filter(l => l.id !== label.id),
    );
  };

  return (
    <ConfirmationModal
      key={'conversation::rename'}
      confirmationButton={processing ? 'Saving' : 'Save'}
      isOpen
      title={'Add to project'}
      description={
        <Form
          form={form}
          layout="vertical"
          initialValues={{ projectLabels: conversation.projectLabels.map(l => l.id) }}
        >
          <Form.Item name="projectLabels" label="Projects">
            <Select
              placeholder="Add project"
              options={projectOptions
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map(option => ({
                  label: (
                    <>
                      <Badge text={option.name} color={option.color ?? 'var(--nri-color-purple-grey)'} />
                    </>
                  ),
                  value: option.id,
                  color: option.color,
                }))}
              value={0}
              onSelect={handleSelectProject}
              labelRender={() => <Typography.Text>Add to project</Typography.Text>}
              showSearch={false}
            />
          </Form.Item>

          <List
            style={{
              borderBlockStart: 'var(--ant-line-width) var(--ant-line-type) var(--ant-color-split)',
              borderBlockEnd: 'var(--ant-line-width) var(--ant-line-type) var(--ant-color-split)',
              width: '100%',
            }}
            dataSource={selectedProjectLabels}
            renderItem={label => (
              <List.Item
                style={{ margin: '0', padding: '10px' }}
                key={label.id}
                extra={
                  <DeleteOutlined
                    onClick={() => handleRemoveProject(label)}
                    style={{ color: 'var(--nri-color-red)', cursor: 'pointer' }}
                  />
                }
              >
                <Badge text={label.name} color={label.color ?? 'var(--nri-color-purple-grey)'} />
              </List.Item>
            )}
          />
          <Button type="text" onClick={showCreateProjectModal}>
            + Create New
          </Button>
        </Form>
      }
      onCancel={hide}
      onConfirm={onConfirm}
      processing={processing}
    />
  );
};
