import React, { useMemo } from 'react';
import { Flex, Form, Input, Typography } from 'antd';
import { useAuth } from 'selectors/useAuthSelector';
import { Select } from 'ui/Select';
import { useAgentModels } from 'selectors/useAgents';
import { AgentFormData } from 'components/agent-view/AgentView';
import { useEntityContext } from 'components/layouts/context/EntityProvider';
import styles from 'ui/UIKit.module.scss';
import { Switch } from 'ui/Switch';

export const GeneralStep: React.FC = () => {
  const { form } = useEntityContext<AgentFormData>();
  const { me } = useAuth();
  const models = useAgentModels();

  const isEnabled = Form.useWatch('isEnabled', form);

  const handleToggle = (checked: boolean) => {
    form.setFieldValue('isEnabled', checked);
  };

  const providers = useMemo(() => {
    if (!models) return [];
    return models
      .map(model => model.provider)
      .filter((provider, index, self) => self.indexOf(provider) === index)
      .sort()
      .map(provider => ({ label: provider, value: provider }));
  }, [models]);

  const selectedProvider = Form.useWatch(['agentParams', 'modelProvider'], form);

  const availableModels = useMemo(() => {
    if (!models || !selectedProvider) return [];

    return models
      .filter(model => model.provider === selectedProvider)
      .sort((a, b) => a.model.localeCompare(b.model))
      .map(model => ({
        label: model.model,
        value: model.model,
      }));
  }, [models, selectedProvider]);

  return (
    <>
      <Typography.Title className={styles.title} level={2}>
        <Flex justify="space-between" align="center" style={{ marginBlock: '24px 40px' }}>
          <div>Agent Details</div>
          <Flex>
            <Form.Item name="isEnabled" noStyle valuePropName="checked">
              <Switch checked={isEnabled} displayLabel onChange={handleToggle} />
            </Form.Item>
          </Flex>
        </Flex>
      </Typography.Title>

      <Form.Item name="owner" hidden>
        <Input value={me!.id} />
      </Form.Item>

      <Form.Item label="Agent name" name="name" rules={[{ required: true, message: 'Enter agent name' }]}>
        <Input autoFocus />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item label="Controller" name="agent">
        <Select
          options={[
            {
              label: 'Prism',
              value: 'prism-1',
            },
          ]}
          placeholder="Select base agent"
        />
      </Form.Item>

      <Form.Item label="LLM provider" name={['agentParams', 'modelProvider']}>
        <Select options={providers} placeholder="Select LLM provider" />
      </Form.Item>

      <Form.Item label="LLM model" name={['agentParams', 'modelName']}>
        <Select options={availableModels} placeholder="Select LLM model" disabled={!selectedProvider} />
      </Form.Item>

      <Form.Item label="Agent knowledge base" name={['agentParams', 'memoriesPath']}>
        <Input />
      </Form.Item>

      <Form.Item label="Special Instructions" name={['agentParams', 'instructions']}>
        <Input.TextArea rows={4} />
      </Form.Item>
    </>
  );
};
