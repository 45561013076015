import { Actions } from 'types/actionTypes';

interface ModalState<T> {
  data?: T;
  navigation?: string;
  visible: boolean;
}

export enum ModalScopesEnum {
  AGENTS = 'agents',
  BLUEPRINTS = 'blueprints',
  CONVERSATIONS = 'conversations',
  DATA_SOURCES = 'dataSources',
  PROJECTS = 'projects',
  MEMORIES = 'memories',
}

export enum ModalTypesEnum {
  ADD = 'add',
  ADD_TO_PROJECT = 'add-to-project',
  ARCHIVE = 'archive',
  DELETE = 'delete',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
  MANAGE = 'manage',
  REPLACE = 'replace',
  RENAME = 'rename',
  SELECT = 'select',
  TOGGLE = 'toggle',
}

export enum ModalsActionTypes {
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
}

export interface ModalsState {
  modals: {
    [ModalScopesEnum.AGENTS]: {
      [ModalTypesEnum.MANAGE]: ModalState<Agent>;
      [ModalTypesEnum.DELETE]: ModalState<Agent>;
      [ModalTypesEnum.TOGGLE]: ModalState<Agent>;
    };
    [ModalScopesEnum.BLUEPRINTS]: {
      [ModalTypesEnum.ARCHIVE]: ModalState<IceBreaker>;
      [ModalTypesEnum.DELETE]: ModalState<IceBreaker>;
      [ModalTypesEnum.DUPLICATE]: ModalState<IceBreaker>;
    };
    [ModalScopesEnum.DATA_SOURCES]: {
      [ModalTypesEnum.ADD]: ModalState<DataSource>;
      [ModalTypesEnum.ARCHIVE]: ModalState<DataSource>;
      [ModalTypesEnum.DELETE]: ModalState<DataSource>;
      [ModalTypesEnum.MANAGE]: ModalState<DataSource>;
      [ModalTypesEnum.REPLACE]: ModalState<DataSource>;
      [ModalTypesEnum.SELECT]: ModalState<DataSource>;
    };

    [ModalScopesEnum.CONVERSATIONS]: {
      [ModalTypesEnum.ARCHIVE]: ModalState<Conversation>;
      [ModalTypesEnum.ADD_TO_PROJECT]: ModalState<Conversation>;
      [ModalTypesEnum.DELETE]: ModalState<Conversation>;
      [ModalTypesEnum.DUPLICATE]: ModalState<Conversation>;
      [ModalTypesEnum.MANAGE]: ModalState<Conversation>;
      [ModalTypesEnum.RENAME]: ModalState<Conversation>;
    };

    [ModalScopesEnum.PROJECTS]: {
      [ModalTypesEnum.ADD]: ModalState<ProjectLabel>;
      [ModalTypesEnum.DELETE]: ModalState<ProjectLabel>;
    };
    [ModalScopesEnum.MEMORIES]: {
      [ModalTypesEnum.ADD]: ModalState<Memory>;
      [ModalTypesEnum.EDIT]: ModalState<Memory>;
      [ModalTypesEnum.DELETE]: ModalState<Memory>;
    };
  };
}

export const initialModalsState: ModalsState = {
  modals: {
    [ModalScopesEnum.AGENTS]: {
      [ModalTypesEnum.MANAGE]: { data: undefined, visible: false },
      [ModalTypesEnum.DELETE]: { data: undefined, visible: false, navigation: undefined },
      [ModalTypesEnum.TOGGLE]: { data: undefined, visible: false },
    },
    [ModalScopesEnum.BLUEPRINTS]: {
      [ModalTypesEnum.ARCHIVE]: { data: undefined, visible: false },
      [ModalTypesEnum.DELETE]: { data: undefined, visible: false, navigation: undefined },
      [ModalTypesEnum.DUPLICATE]: { data: undefined, visible: false },
    },
    [ModalScopesEnum.CONVERSATIONS]: {
      [ModalTypesEnum.ARCHIVE]: { data: undefined, visible: false },
      [ModalTypesEnum.ADD_TO_PROJECT]: { data: undefined, visible: false },
      [ModalTypesEnum.DELETE]: { data: undefined, visible: false },
      [ModalTypesEnum.DUPLICATE]: { data: undefined, visible: false },
      [ModalTypesEnum.MANAGE]: { data: undefined, visible: false },
      [ModalTypesEnum.RENAME]: { data: undefined, visible: false },
    },

    [ModalScopesEnum.DATA_SOURCES]: {
      [ModalTypesEnum.ADD]: { data: undefined, visible: false },
      [ModalTypesEnum.ARCHIVE]: { data: undefined, visible: false, navigation: undefined },
      [ModalTypesEnum.DELETE]: { data: undefined, visible: false, navigation: undefined },
      [ModalTypesEnum.MANAGE]: { data: undefined, visible: false },
      [ModalTypesEnum.REPLACE]: { data: undefined, visible: false },
      [ModalTypesEnum.SELECT]: { data: undefined, visible: false },
    },

    [ModalScopesEnum.PROJECTS]: {
      [ModalTypesEnum.ADD]: { data: undefined, visible: false },
      [ModalTypesEnum.DELETE]: { data: undefined, visible: false, navigation: undefined },
    },
    [ModalScopesEnum.MEMORIES]: {
      [ModalTypesEnum.ADD]: { data: undefined, visible: false },
      [ModalTypesEnum.EDIT]: { data: undefined, visible: false },
      [ModalTypesEnum.DELETE]: { data: undefined, visible: false },
    },
  },
};

export type ModalsActions =
  | {
      type: ModalsActionTypes.SHOW_MODAL;
      payload: {
        scope: ModalScopesEnum;
        type: ModalTypesEnum;
        data?: unknown;
        navigation?: string | undefined;
      };
    }
  | {
      type: ModalsActionTypes.HIDE_MODAL;
      payload: {
        scope: ModalScopesEnum;
        type: ModalTypesEnum;
      };
    };

export const modalsReducer = (state: ModalsState, action: Actions): ModalsState => {
  switch (action.type) {
    case ModalsActionTypes.SHOW_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.scope]: {
            ...state.modals[action.payload.scope],
            [action.payload.type]: {
              visible: true,
              data: action.payload.data,
              navigation: action.payload?.navigation,
            },
          },
        },
      };
    case ModalsActionTypes.HIDE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.scope]: {
            ...state.modals[action.payload.scope],
            [action.payload.type]: {
              visible: false,
              data: undefined,
              navigation: undefined,
            },
          },
        },
      };
    default:
      return state;
  }
};
