import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useDeleteDataSource } from 'selectors/useDataSourcesSelector';
import { useCallback, useState } from 'react';

export const DeleteDataSourceModal: React.FC = () => {
  const { data: dataSource, hide } = useModal<DataSource>(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.DELETE);
  const [processing, setProcessing] = useState(false);

  const remove = useDeleteDataSource(dataSource.id);
  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await remove();
    hide();
    setProcessing(false);
  }, [remove, hide]);

  return (
    <ConfirmationModal
      key={'datasource::delete'}
      isOpen={true}
      title={'Delete connector'}
      description={
        <>
          This connector may be used in multiple conversations. Are you sure you want to delete this connector{' '}
          <strong>{dataSource.name}</strong>?
        </>
      }
      onCancel={() => {
        hide();
      }}
      onConfirm={onConfirm}
      cancelButton={'Cancel'}
      confirmColor={'#E23C26'}
      confirmationButton={processing ? 'Deleting' : 'Yes, delete'}
      processing={processing}
    />
  );
};
