import { ConfigProvider, Select, SelectProps, Typography } from 'antd';
import ChevronDown from 'assets/iconsNewVersion/chevron_down.svg?react';
import ChevronUp from 'assets/iconsNewVersion/chevron_up.svg?react';

import { FC, useState } from 'react';
import styles from './EntityShareSelect.module.scss';

export const EntityShareSelect: FC<SelectProps> = ({ options, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: 'var(--nri-color-primary)',
          controlHeight: 28,
          borderRadius: 4,
        },
        components: {
          Select: {
            selectorBg: 'var(--nri-color-grey-stroke)',
            optionPadding: '6px 10px',
            optionSelectedBg: 'var(--nri-color-light-grey)',
            optionFontSize: 14,
            optionSelectedFontWeight: 600,
          },
        },
      }}
    >
      <Select
        suffixIcon={
          isOpen ? <ChevronUp className={styles.chevronIcon} /> : <ChevronDown className={styles.chevronIcon} />
        }
        {...props}
        options={options}
        labelRender={option => <Typography.Text className={styles.optionLabel}>{option.label}</Typography.Text>}
        onDropdownVisibleChange={isOpen => setIsOpen(isOpen)}
        getPopupContainer={triggerNode => triggerNode.parentNode}
      />
    </ConfigProvider>
  );
};
