import { Button, Card, Checkbox, Input, Select, Switch, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Collapse } from 'ui/Collapse';

export const ComponentsPage = () => {
  return (
    <div
      style={{
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        rowGap: '15px',
        width: '100%',
        overflowX: 'auto',
        background: '#fff',
      }}
    >
      <Typography.Text>Buttons</Typography.Text>
      <Button type="primary">Start New Conversation</Button>
      <Button type="primary" disabled>
        Start New Conversation
      </Button>
      <Button className="textButton" type="text">
        Start New Conversation
      </Button>
      <Button className="textButton" type="text" disabled>
        Start New Conversation
      </Button>

      <Typography.Text>Card</Typography.Text>
      <Card title="something">hello</Card>

      <Typography.Text>Checkbox</Typography.Text>
      <Checkbox className="checkbox" />
      <Checkbox className="checkbox"> Example </Checkbox>

      <Typography.Text>Collapse</Typography.Text>
      <Collapse items={[{ children: 'hello', key: 'something', label: 'Step 1' }]} />
      <div>
        <Switch className="switch" />
      </div>
      <Button className="actionButton" icon={<SearchOutlined />} />
      <Button className="actionButton" icon={<SearchOutlined />} disabled />

      <Typography.Text>Inputs / Dropdowns</Typography.Text>
      <Input
        className="inputSearch"
        prefix={
          <SearchOutlined
            style={{
              fontSize: 20,
              color: 'var(--nri-color-primary-light)',
              margin: '0 8px 0 0',
            }}
          />
        }
        placeholder="Search"
      />
      <Input className="inputRegular" placeholder="regular" />

      <Select
        className="inputSelect"
        placeholder="Select"
        options={[
          { value: 'hey', label: 'hey' },
          { value: 'hey2', label: 'hey2' },
        ]}
      />
    </div>
  );
};
