import { ConfigProvider, SelectProps, Select as AntSelect } from 'antd';
import classNames from 'classnames';
import styles from 'ui/UIKit.module.scss';

const SelectComponent: React.FC<SelectProps> = ({ className, ...props }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: 'var(--nri-color-primary)',
          colorTextPlaceholder: 'var(--nri-color-purple-grey)',
          // @ts-expect-error type string is not assignable to type number, still works
          controlHeight: '42px',
          // @ts-expect-error type string is not assignable to type number, still works
          borderRadius: '4px',
          // @ts-expect-error type string is not assignable to type number, still works
          lineHeight: '24px',
        },
        components: {
          Select: {
            activeBorderColor: 'var(--nri-color-primary-light)',
            hoverBorderColor: 'var(--nri-color-purple-grey)',
            // @ts-expect-error type string is not assignable to type number, still works
            optionHeight: '36px',
            optionLineHeight: '24px',
            optionPadding: '8px 12px',
            optionSelectedColor: 'var(--nri-color-purple-grey)',
          },
        },
      }}
    >
      <AntSelect className={classNames(styles.selectSimple, className)} {...props} />
    </ConfigProvider>
  );
};

export const Select = Object.assign(SelectComponent, {
  Option: AntSelect.Option,
  OptGroup: AntSelect.OptGroup,
});
