import { Switch as AntSwitch, Flex, SwitchProps, Typography } from 'antd';
import styles from './UIKit.module.scss';

type Props = SwitchProps & {
  displayLabel?: boolean;
};

export const Switch: React.FC<Props> = ({ checked, displayLabel, ...props }) => {
  const switchElement = <AntSwitch checked={checked} className={styles.switch} {...props} />;

  if (displayLabel) {
    return (
      <Flex align="center" gap="8px">
        {switchElement}
        <Typography.Text>{checked ? 'Enabled' : 'Disabled'}</Typography.Text>
      </Flex>
    );
  }

  return switchElement;
};
