import { useEffect, useCallback } from 'react';
import { NewtonApi } from 'utils/newtonApi';

interface ActivityMonitorOptions {
  inactivityTimeout?: number;
  keepAliveInterval?: number;
  onInactivityTimeout?: () => void;
  onKeepAlive?: () => void;
}

export const useActivityMonitor = ({
  inactivityTimeout = 4 * 60 * 60 * 1000, // 4 hours default
  keepAliveInterval = 10 * 60 * 1000, // 10 minutes default
  onInactivityTimeout = () => (window.location.href = '/login'),
  onKeepAlive,
}: ActivityMonitorOptions = {}) => {
  // On user activity reset the timer
  const resetInactivityTimer = useCallback(
    (timeoutId: number | null) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      return setTimeout(() => {
        console.log('User inactive for 4 hours, redirecting to login');
        onInactivityTimeout();
      }, inactivityTimeout);
    },
    [inactivityTimeout, onInactivityTimeout],
  );

  // Keep the user authenticated by sending keep-alive requests every 10 minutes
  useEffect(() => {
    let keepAliveIntervalId: NodeJS.Timeout | undefined;
    let inactivityTimeout: NodeJS.Timeout | undefined;

    const startKeepAliveTimer = () => {
      keepAliveIntervalId = setInterval(() => {
        console.log('Keep-alive interval triggered');
        NewtonApi.keepAlive({ shouldThrow: true });
        if (onKeepAlive) {
          onKeepAlive();
        }
      }, keepAliveInterval);
    };

    const stopKeepAliveTimer = () => {
      if (keepAliveIntervalId) {
        clearInterval(keepAliveIntervalId);
        keepAliveIntervalId = undefined;
      }
    };

    const handleUserActivity = () => {
      inactivityTimeout = resetInactivityTimer(inactivityTimeout as unknown as number);
    };

    // @TODO maybe we don't want this?
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Page hidden, pausing keep-alive timer');
        // stopKeepAliveTimer();
      } else {
        console.log('Page visible, resuming keep-alive timer');
        // startKeepAliveTimer();
        handleUserActivity();
      }
    };

    // Start initial timers
    startKeepAliveTimer();
    handleUserActivity();

    // Add event listeners for capturing any user activity
    const events = ['mousedown', 'keydown', 'touchstart', 'scroll'];
    events.forEach(event => {
      document.addEventListener(event, handleUserActivity);
    });
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup
    return () => {
      events.forEach(event => {
        document.removeEventListener(event, handleUserActivity);
      });
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      stopKeepAliveTimer();
      if (inactivityTimeout) {
        clearTimeout(inactivityTimeout);
      }
    };
  }, [keepAliveInterval, onKeepAlive, resetInactivityTimer]);
};
