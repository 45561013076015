export enum CommonStatusEnum {
  INITIAL = 'INITIAL',
  CREATING = 'CREATING',
  FETCHING = 'FETCHING',
  FETCHED = 'FETCHED',
  UPDATING = 'UPDATING',
}

export const Roles: { [K in Uppercase<RoleTypes>]: RoleTypes } = {
  USER: 'user',
  USER_PROXY: 'user_proxy',
  ASSISTANT: 'assistant',
  SUMMARY: 'summary',
  INTERNAL: 'internal',
} as const;

export type RoleValues = (typeof Roles)[keyof typeof Roles];

export const DisplayRoles: { [K in Uppercase<DisplayRoleType>]: DisplayRoleType } = {
  ...Roles,
  COORDINATOR_AGENT: 'Coordinator Agent',
  MARKETING_ANALYST_AGENT: 'Marketing Analyst Agent',
  CODING_SPECIALIST: 'Coding Specialist',
  MEASUREMENT_AGENT: 'Measurement Agent',
} as const;

export enum ConversationOwnerEnum {
  USER = 'user',
  SHARED = 'shared',
}

export enum ConversationViewEnum {
  ALL = 'ALL',
  DATA_SOURCES = 'DATA_SOURCES',
  EXECUTIVE = 'EXECUTIVE',
  NOTES = 'NOTES',
}

export enum ConversationFeedbacksViewEnum {
  ALL = 'ALL',
  EXECUTIVE = 'EXECUTIVE',
}

export enum RetrieveTypesEnum {
  ALL = 'all',
  ORGANIZATION = 'organization',
  OWNED = 'owned',
  FAVORITE = 'only_favorites',
  SHARED = 'shared',
  RECENT = 'recent',
}

export enum FeedbackTypeEnum {
  LIKE = 'Positive',
  DISLIKE = 'Negative',
  QUESTION = 'Question',
}

export enum ConversationFeedbacksViewEnum {
  MESSAGES = 'MESSAGES',
  FEEDBACKS = 'FEEDBACKS',
}

export enum FeedbackScopeEnum {
  ALL = 'all',
  USER = 'user',
}

export enum EntityActionsEnum {
  ARCHIVE = 'archive',
  DELETE = 'delete',
  FAVORITE = 'favorite',
  COPY = 'copy',
  DOWNLOAD = 'download',
  MANAGE_ACCESS = 'manage_access',
}

export enum SharedEntityTypes {
  ORGANIZATION = 'organization',
  TEAM = 'team',
  USER = 'user',
}

export enum ViewOptionsEnum {
  CARD = 'card',
  LIST = 'list',
}

export enum DatasourceTypesEnum {
  ATHENA = 'athena',
  BIGQUERY = 'bigquery',
  CSV = 'csv',
  GOOGLE_SHEETS = 'google_sheets',
  GOOGLE_SLIDES = 'google_slides',
  HABU = 'habu',
  LOCAL_SQLITE = 'local_sqlite',
  NOTEBOOK = 'notebook',
  POSTGRES = 'postgres',
  REDSHIFT = 'redshift',
  SNOWFLAKE = 'snowflake',
  TTD = 'ttd',
}

export enum PageOptionsEnum {
  ACTIVITY = 'activity',
  BLUEPRINT = 'blueprint',
  DATASOURCE = 'datasource',
}

export enum EntityPermissionsEnum {
  READ = 'read',
  WRITE = 'write',
  OWNER = 'owner',
  REMOVE = 'remove',
}
