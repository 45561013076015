import { useAuth0 } from '@auth0/auth0-react';
import { ConfigProvider, Flex, Layout, Menu } from 'antd';
import CollapsedLogo from 'assets/collapsedLogo.svg?react';
import CogIcon from 'assets/icons/cog.svg?react';
import ConfigureIcon from 'assets/icons/threeSquares.svg?react';
import BookmarkIcon from 'assets/iconsNewVersion/bookmark.svg?react';
import CalendarIcon from 'assets/iconsNewVersion/calendar.svg?react';
import DataSourcesIcon from 'assets/iconsNewVersion/datasource.svg?react';
import MemoriesIcon from 'assets/iconsNewVersion/file.svg?react';
import BlueprintsIcon from 'assets/iconsNewVersion/folder.svg?react';
import HomeIcon from 'assets/iconsNewVersion/home2.svg?react';
import Maximize from 'assets/iconsNewVersion/maximize.svg?react';
import ConversationIcon from 'assets/iconsNewVersion/message_chat.svg?react';
import Minimize from 'assets/iconsNewVersion/minimize.svg?react';
import TeamAdminIcon from 'assets/iconsNewVersion/user.svg?react';
import ChipIcon from 'assets/iconsNewVersion/chip.svg?react';
import NewtonLogo from 'assets/logo2.svg?react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Sidebar } from 'components/sidebar/Sidebar';
import { useEffect, useState } from 'react';
import { useAuth } from 'selectors/useAuthSelector';
import { cx } from '../../utils/cx';
import styles from './MainMenu.module.scss';
import { SecondaryMenu } from './SecondaryMenu';
import { useUIContext } from 'contexts/UIContext';

// @FIXME: Add the correct paths for the Dashboard and Templates menu item

export const MainMenu: React.FC = () => {
  const { user, logout } = useAuth0();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isCollapsed, setIsCollapsed } = useUIContext();
  const showSidebar = pathname.startsWith('/conversations');
  const [selectedPathname, setSelectedPathname] = useState(pathname);
  const { me } = useAuth();

  const mainMenuItems = [
    { id: 'home', icon: <HomeIcon />, path: '/', title: 'Home' },
    {
      id: 'conversation',
      icon: <ConversationIcon />,
      path: '/conversations',
      title: 'Conversations',
    },
    {
      id: 'data-config',
      disabled: me?.flags?.uploadCredentials ? false : true,
      icon: <ConfigureIcon />,
      path: '/data-config',
      title: 'Configure Data',
    },
    {
      id: 'data',
      icon: <DataSourcesIcon />,
      path: '/datasources',
      title: 'Connectors',
    },
    {
      id: 'blueprints',
      icon: <BlueprintsIcon />,
      path: '/blueprints',
      title: 'Blueprints',
    },
    {
      id: 'team',
      disabled: true,
      icon: <TeamAdminIcon />,
      path: '/team',
      title: 'Team admin',
    },
    {
      id: 'user',
      disabled: true,
      icon: <CogIcon />,
      path: '/user',
      title: 'User settings',
    },
    {
      id: 'memories',
      disabled: false,
      icon: <MemoriesIcon />,
      path: '/memories',
      title: 'Memories',
    },
    {
      id: 'schedule',
      disabled: true,
      icon: <CalendarIcon />,
      path: '/calendar',
      title: 'Schedule Work',
    },
    {
      id: 'projects',
      disabled: false,
      icon: <BookmarkIcon />,
      path: '/projects',
      title: 'Projects',
    },
    {
      id: 'agents',
      disabled: !me?.isSuperuser,
      icon: <ChipIcon />,
      path: '/agents',
      title: 'Agents',
    },
  ];

  useEffect(() => {
    if (!pathname) return;

    if (pathname.includes('/conversations')) {
      setSelectedPathname('/conversations');
    } else {
      setSelectedPathname(pathname);
    }
  }, [pathname]);

  if (!user) return null;

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const TriggerIcon = isCollapsed ? Maximize : Minimize;

  return (
    <>
      <Layout.Sider
        className={styles.sider}
        collapsedWidth={'var(--nri-menu-collapsed-width)'}
        collapsible
        collapsed={isCollapsed}
        trigger={
          <Flex className={styles.trigger}>
            <TriggerIcon onClick={() => setIsCollapsed(c => !c)} />
          </Flex>
        }
        width={'var(--nri-menu-width)'}
      >
        <div className={styles.logo}>{isCollapsed ? <CollapsedLogo /> : <NewtonLogo />}</div>
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                collapsedIconSize: 24,
                itemActiveBg: 'var(--nri-color-disabled-bg)',
                itemColor: 'var(--nri-color-purple-grey)',
                itemHeight: 48,
                itemMarginBlock: 0,
                itemMarginInline: 0,
                itemPaddingInline: 12,
              },
            },
          }}
        >
          <Menu
            className={styles.mainMenu}
            mode="inline"
            selectedKeys={[selectedPathname]}
            items={mainMenuItems.map(({ id, disabled, icon, path, title }) => ({
              className: cx(styles.item, {
                [styles.active]: path === pathname,
              }),
              disabled,
              icon,
              key: id,
              onClick: () => navigate(path),
              label: title,
              title: disabled ? `${title} coming soon` : title,
            }))}
          />
        </ConfigProvider>
        <SecondaryMenu collapsed={isCollapsed} logout={handleLogout} selectedPathname={selectedPathname} user={me} />
      </Layout.Sider>
      {showSidebar && <Sidebar />}
    </>
  );
};
