import { Flex, Typography } from 'antd';
import { useState } from 'react';
import { Button } from 'ui/Button';
import LeftArrowIcon from '../../assets/iconsNewVersion/arrow_left.svg?react';
import EditIcon from '../../assets/iconsNewVersion/edit.svg?react';
import { EditEntityTitleInput } from './EditEntityTitle';
import styles from './EntityHeader.module.scss';

export const EntityHeaderTitle = ({
  isEditable,
  canGoBack,
  onEdit,
  onGoBack = () => {},
  title,
}: {
  isEditable: boolean;
  onEdit: (editedName: string) => void;
  canGoBack: boolean;
  onGoBack: () => void;
  title: string;
}) => {
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isEditable && isEditing ? (
        <EditEntityTitleInput name={title} maxLength={100} setIsEditing={setIsEditing} onSave={onEdit} />
      ) : (
        <Flex
          style={{ overflow: 'hidden' }}
          flex={1}
          gap={8}
          onMouseEnter={e => {
            e.stopPropagation();
            setShowEditIcon(true);
          }}
          onMouseLeave={e => {
            e.stopPropagation();
            setShowEditIcon(false);
          }}
        >
          {canGoBack && (
            <Button
              type="link"
              className={styles.linkButton}
              icon={<LeftArrowIcon className={styles.goBackIcon} />}
              onClick={onGoBack}
            />
          )}

          <Typography.Title ellipsis={true} level={2}>
            {title}
          </Typography.Title>
          {isEditable && showEditIcon && (
            <Button
              className={styles.linkButton}
              onClick={() => setIsEditing(true)}
              icon={<EditIcon className={styles.editIcon} />}
              type="link"
            />
          )}
        </Flex>
      )}
    </>
  );
};
