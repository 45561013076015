import { notification } from 'antd';
import DeleteIcon from 'assets/icons/trash.svg?react';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useDeleteBlueprint } from 'selectors/useBlueprints';
import { useModal } from 'selectors/useModals';

export const DeleteBlueprintModal: React.FC = () => {
  const {
    data: { onConfirm: onConfirmCallback, blueprint },
    hide,
  } = useModal<{ onConfirm: () => void; blueprint: Blueprint }>(ModalScopesEnum.BLUEPRINTS, ModalTypesEnum.DELETE);
  const [name] = useState(blueprint.name);
  const [processing, setProcessing] = useState(false);
  const archive = useDeleteBlueprint(blueprint.id);
  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await archive();

    notification.open({
      icon: <DeleteIcon color="var(--nri-color-red)" />,
      message: <strong>Blueprint deleted</strong>,
      description: (
        <>
          <strong>{name}</strong> was deleted.
        </>
      ),
    });

    hide();
    setProcessing(false);
    onConfirmCallback();
  }, [archive, hide, name, onConfirmCallback]);

  return (
    <ConfirmationModal
      key={'blueprint::delete'}
      confirmationButton={processing ? 'Deleting' : 'Yes, delete'}
      isOpen
      title={'Delete blueprint'}
      description={
        <>
          Are you sure you want to delete this blueprint <strong>{blueprint.name}</strong>?
        </>
      }
      onCancel={hide}
      onConfirm={onConfirm}
      processing={processing}
    />
  );
};
