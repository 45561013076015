import { Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DataSourceActionsMenu } from 'components/datasource-actions-menu/DatasourceActionsMenu';
import { EntityTableProps } from 'components/entity-list/EntityList';
import { EntityTable } from 'components/entity-list/EntityTable';
import { MultipleElements } from 'components/multiple-labels/MultipleElements';
import { ProjectLabel } from 'ui/ProjectLabel';
import { formatDate } from 'utils/formatDate';

type DataSourceTableProps = EntityTableProps<DataSource>;
export const DataSourceTable = ({ entities: dataSources, search }: DataSourceTableProps) => {
  const columns: ColumnsType<DataSource> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      width: 15,
      render: value => (
        <Typography.Paragraph
          ellipsis={{ rows: 1 }}
          style={{ fontSize: '12px', margin: 0, color: 'var(--nri-color-primary)' }}
        >
          {value}
        </Typography.Paragraph>
      ),
    },
    { key: 'description', dataIndex: 'description', title: 'Description', width: 35, ellipsis: true },
    {
      key: 'project',
      dataIndex: 'projectLabels',
      title: 'Project',
      width: 20,
      render: value =>
        value.length > 0 && (
          <MultipleElements>
            {value.map((project: ProjectLabel) => (
              <ProjectLabel
                key={project.id}
                color={project.color}
                text={project.name}
                textColor="var(--nri-color-primary)"
              />
            ))}
          </MultipleElements>
        ),
    },
    {
      key: 'lastUsed',
      dataIndex: 'updatedAt',
      title: 'LastUsed',
      width: 20,
      render: value => <Typography style={{ fontSize: '14px' }}>{formatDate(value)}</Typography>,
    },
    { key: 'type', dataIndex: 'type', title: 'Type', width: 15, render: value => <Tag>{value}</Tag> },
    { key: 'actions', title: '', width: 5 },
  ];
  return (
    <EntityTable
      actionMenu={dataSource => <DataSourceActionsMenu dataSource={dataSource as DataSource} />}
      columns={columns}
      entities={dataSources}
      search={search}
    />
  );
};
