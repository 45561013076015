import { Layout } from 'antd';
import styles from './Layout.module.scss';
import { FeedbacksView } from '../components/feedbacksView/FeedbacksView';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useConversationFeedbacks, useFetchFeedbackSentiments } from 'selectors/useFeedbacksSelector';

export const FeedbacksViewPage = () => {
  const { conversationId } = useParams();
  const [searchParams] = useSearchParams();

  const selectConversationFeedbacks = useConversationFeedbacks();
  const fetchSentiments = useFetchFeedbackSentiments();
  const messageId = searchParams.get('message_id');

  useEffect(() => {
    fetchSentiments();
    if (conversationId) {
      selectConversationFeedbacks(
        Number(conversationId) as Conversation['id'],
        { skipNavigate: true },
        messageId as unknown as MessageId,
      );
    }
  }, [conversationId]);
  return (
    <Layout className={styles.feedbacksViewPage}>
      <FeedbacksView />
    </Layout>
  );
};
