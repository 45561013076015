import { cx } from 'utils/cx';
import styles from './SegmentedItemLabel.module.scss';
import React, { ReactNode } from 'react';
export const SegmentedItemLabel: React.FC<{
  label?: ReactNode;
  icon?: ReactNode;
  active?: boolean;
  handleClick: () => void;
}> = ({ label, icon, active = false, handleClick }) => {
  return (
    <div
      onClick={() => handleClick()}
      className={cx({
        [styles.label]: label && !icon,
        [styles.icon]: icon,
        [styles.activeText]: active && label && !icon,
        [styles.activeIcon]: active && icon,
      })}
    >
      {icon ?? label}
    </div>
  );
};
