import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useCallback, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { useRemoveMemory } from 'selectors/userMemories';

export const DeleteMemoryModal = () => {
  const {
    data: { memory },
    hide,
  } = useModal<{ memory: Memory }>(ModalScopesEnum.MEMORIES, ModalTypesEnum.DELETE);
  const [processing, setProcessing] = useState(false);
  const remove = useRemoveMemory();

  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await remove(memory.id);
    hide();
    setProcessing(false);
  }, [remove, hide]);

  return (
    <ConfirmationModal
      key={'memory::delete'}
      isOpen
      title={'Delete memory'}
      confirmationButton={processing ? 'Deleting' : 'Delete'}
      description={<>Are you sure you want to delete this memory?</>}
      onCancel={hide}
      onConfirm={onConfirm}
      confirmColor={'var(--nri-color-red)'}
      processing={processing}
    />
  );
};
