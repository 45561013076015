import { ResponsiveBar } from '@nivo/bar';
import { Flex, Typography } from 'antd';
import { Dropdown } from 'ui/Dropdown';
import styles from './UsageDailyCredits.module.scss';
import { useState } from 'react';
import { useDailyCredits } from 'selectors/useStatisticsSelector';
import { Loader } from 'components/loader/Loader';

export const UsageDailyCredits = () => {
  const timePeriodItems = [
    { key: 'this_week', label: 'This week' },
    { key: 'all_time', label: 'All time' },
    { key: 'last_year', label: 'Last year' },
    { key: 'last_3_months', label: 'Last 3 months' },
    { key: 'last_month', label: 'Last month' },
  ];
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(timePeriodItems[0]);
  const { loading, data } = useDailyCredits(selectedTimePeriod.key);
  const maxValue = Math.max(...data.map(item => item.credits));

  return (
    <Flex vertical justify="center" gap={20} className={styles.container}>
      {loading ? (
        <Loader size="default" className={styles.loader} />
      ) : (
        <>
          <Flex justify="space-between">
            <Flex gap={8} vertical>
              <Typography.Text className={styles.title}>Daily Credits</Typography.Text>
              <Typography.Text className={styles.description}>Output credit usage by day</Typography.Text>
            </Flex>
            <Dropdown
              className={styles.dropdown}
              menu={{
                items: timePeriodItems,
                onClick: ({ key }) => {
                  const timePeriodItem = timePeriodItems.find(l => l.key === key)!;
                  setSelectedTimePeriod(timePeriodItem);
                },
              }}
            >
              {selectedTimePeriod.label}
            </Dropdown>
          </Flex>
          <div style={{ height: '226px', width: '100%' }}>
            <ResponsiveBar
              data={data}
              indexBy="date"
              theme={{
                labels: {
                  text: {
                    fill: '#fff',
                    fontSize: 'var(--nri-font-size-xl)',
                    fontWeight: 'var(--nri-weight-medium)',
                    fontFamily: 'var(--nri-font-family)',
                  },
                },
              }}
              padding={0.6}
              keys={['credits']}
              margin={{ top: 50, right: 90, bottom: 50, left: 90 }}
              colors={['var(--nri-color-lavander)']}
              enableLabel={false}
              valueScale={{
                type: 'linear',
                min: 0,
                max: maxValue,
              }}
              axisLeft={{
                tickValues: 4,
                tickSize: 5,
                format: value => {
                  if (value >= 1000) return `${(value / 1000).toFixed(1)}k`;
                  return value.toString();
                },
              }}
              gridYValues={4}
              valueFormat="<-0.1~%"
              borderRadius={4}
              layout="vertical"
              isInteractive={false}
              animate={false}
            />
          </div>
        </>
      )}
    </Flex>
  );
};
