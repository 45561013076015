import { Flex, Form, FormInstance } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { validateDatasourceFile } from 'utils/fileValidation';
import { typeSelectOptions } from 'utils/uploadDatasource';

import { RcFile, UploadFile } from 'antd/es/upload';
import styles from 'components/datasource-modals/AddDatasourceModal.module.scss';
import { useGenerateDescription } from 'hooks/useGenerateDescription';
import { DatasourceUploadType } from 'reducers/dataSourcesReducer';
import { Checkbox } from 'ui/Checkbox';
import { InputSimple as Input } from 'ui/Input';
import { Select } from 'ui/Select';
import { Upload } from 'ui/Upload';

export const DataSourceForm: FC<{ csv: File; form: FormInstance; showConversationScope?: boolean }> = ({
  csv,
  form,
  showConversationScope = false,
}) => {
  const generateDefaultDescription = useGenerateDescription();
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const initialValues = {
    isSelectedForConvo: false,
    fileList: [],
    name: '',
    type: DatasourceUploadType.ORGANIZATION,
    description: '',
  };
  const customBeforeUpload = useCallback(
    async (file: RcFile) => {
      form.resetFields();
      if (validateDatasourceFile(file)) {
        setFileLoading(true);
        const descriptionData = new FormData();
        descriptionData.append('file', file as unknown as Blob);
        descriptionData.append('delimiter', 'auto');
        const autoDescription = await generateDefaultDescription(descriptionData);
        setFileList([file]);
        form.setFieldValue('fileList', { fileList: [file] });
        form.setFieldValue('description', autoDescription);
        form.setFieldValue('name', file.name.slice(0, 80));
        form.setFieldValue('type', DatasourceUploadType.ORGANIZATION);
        setFileLoading(false);
      }
      return false;
    },
    [form, generateDefaultDescription],
  );

  useEffect(() => {
    if (csv) customBeforeUpload(csv as RcFile);
  }, [csv]);

  const scopeOptions = typeSelectOptions.filter(option =>
    showConversationScope ? true : option.value !== DatasourceUploadType.CONVERSATION,
  );

  const formValidations = {
    name: {
      required: true,
      message: 'Please enter a valid name',
    },
    type: { required: true },
    description: {
      required: true,
      message: 'Please enter a valid description',
    },
  };

  return (
    <Form
      form={form}
      className={styles.form}
      layout="vertical"
      onFinish={() => {}}
      name="control-hooks"
      requiredMark={(label: ReactNode, info: { required: boolean }) => (
        <span>
          {info.required && '*'} {label}
        </span>
      )}
      initialValues={initialValues}
    >
      <Form.Item name="fileList" className={styles.formInput}>
        <Upload
          accept=".csv"
          beforeUpload={customBeforeUpload}
          fileList={fileList}
          loading={fileLoading}
          multiple={false}
          showUploadList={{ showRemoveIcon: csv ? false : true }}
          onRemove={() => {
            form.resetFields();
            setFileList([]);
          }}
        />
      </Form.Item>

      {fileList.length > 0 ? (
        <div className={styles.formInputContainer}>
          <Form.Item
            name="name"
            className={styles.formInput}
            label="Connector title"
            rules={[formValidations['name']]}
            validateTrigger="onBlur"
          >
            <Input type="text" maxLength={80} />
          </Form.Item>
          <Form.Item
            name="type"
            className={styles.formInput}
            label="Share connector"
            rules={[formValidations['type']]}
            validateTrigger="onBlur"
          >
            <Select placeholder="Select type" options={scopeOptions} />
          </Form.Item>
          <Form.Item
            name="description"
            className={styles.formInput}
            rules={[formValidations['description']]}
            validateTrigger="onBlur"
            label="Connector description"
          >
            <TextArea
              placeholder="Enter description"
              maxLength={500}
              autoSize={{ minRows: 3, maxRows: 3 }}
              className={styles.formTextArea}
              rows={3}
            />
          </Form.Item>
        </div>
      ) : null}
      <Flex>
        <Form.Item className={styles.formInput} name="isSelectedForConvo" valuePropName="checked" initialValue={false}>
          <Checkbox>Select for new conversation</Checkbox>
        </Form.Item>
      </Flex>
    </Form>
  );
};
