import { PoweroffOutlined, ShareAltOutlined } from '@ant-design/icons';
import DeleteIcon from 'assets/iconsNewVersion/trash.svg?react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useAuth } from 'selectors/useAuthSelector';
import { useModal } from 'selectors/useModals';
import { ActionItem, ActionsMenu } from '../../ui/ActionsMenu';
import { useMemo } from 'react';

enum AgentActions {
  TOGGLE = 'TOGGLE',
  MANAGE_ACCESS = 'MANAGE_ACCESS',
  DELETE = 'DELETE',
}

export const AgentActionsMenu: React.FC<{ agent: Agent }> = ({ agent }) => {
  const { show: showManageAccess } = useModal(ModalScopesEnum.AGENTS, ModalTypesEnum.MANAGE);
  const { show: showDeleteModal } = useModal(ModalScopesEnum.AGENTS, ModalTypesEnum.DELETE);
  const { show: showEnableModal } = useModal(ModalScopesEnum.AGENTS, ModalTypesEnum.TOGGLE);

  const items: ActionItem<Agent>[] = useMemo(
    () => [
      {
        icon: PoweroffOutlined,
        label: <>{agent.isEnabled ? 'Disable' : 'Enable'}</>,
        key: AgentActions.TOGGLE,
      },
      {
        icon: ShareAltOutlined,
        label: 'Manage access',
        key: AgentActions.MANAGE_ACCESS,
      },
      {
        color: 'var(--nri-color-red)',
        iconColor: 'var(--nri-color-red)',
        icon: DeleteIcon,
        label: 'Delete',
        key: AgentActions.DELETE,
      },
    ],
    [agent],
  );

  const { me } = useAuth();
  const onActionClick = (key: AgentActions) => {
    switch (key) {
      case AgentActions.TOGGLE:
        showEnableModal(agent);
        break;

      case AgentActions.MANAGE_ACCESS:
        showManageAccess({
          agent,
          permissions: [],
        });
        break;

      case AgentActions.DELETE:
        showDeleteModal(agent);
        break;
    }
  };

  const filteredItems = items.filter(item => {
    if (item.filter) {
      return item.filter(agent, me!);
    }
    return true;
  });

  return <ActionsMenu items={filteredItems} onSelect={({ key }) => onActionClick(key as AgentActions)} />;
};
