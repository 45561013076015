import { ModalScopesEnum, ModalTypesEnum } from "reducers/modalsReducer";
import { useModal } from "selectors/useModals";
import { ConfirmationModal } from "components/common/ConfirmationModal";
import { useCallback, useState } from "react";
import { useArchiveConversation } from "selectors/useConversationSelectors";

export const ArchiveConversationModal: React.FC = () => {
    const { data: conversation, hide } = useModal<Conversation>(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.ARCHIVE);
    const [processing, setProcessing] = useState(false);
    const archive = useArchiveConversation(conversation.id);
    const onConfirm = useCallback(async () => {
        setProcessing(true);
        await archive();
        hide();
        setProcessing(false);
    }, [archive, hide]);

    return <ConfirmationModal
        key={"conversation::delete"}
        confirmationButton={processing ? "Archiving" : "Confirm"}

        isOpen
        title={"Archive conversation"}
        description={<>
            Are you sure you want to archive this conversation?
        </>}
        onCancel={hide}
        onConfirm={onConfirm}
        processing={processing}
    />
}