import { Auth0Provider } from "@auth0/auth0-react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import { router } from "./router";

// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://8dfb6452ddd97ebf213f8ab97efbeca8@o4507747963305984.ingest.us.sentry.io/4507762095489024",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Can enable once the backen supports this
//   // tracePropagationTargets: ["localhost", /^https:\/\/develop\.newtonresearch.ai\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <Auth0Provider
    cacheLocation="localstorage"
    domain={__AUTH0_DOMAIN__}
    clientId={__AUTH0_CLIENT_ID__}
    authorizationParams={{
      audience: __AUTH0_AUDIENCE__,
      redirect_uri: `${window.location.origin}/authorizing`,
      organization: __AUTH0_ORGANIZATION__,
    }}
    onRedirectCallback={(appState) => {
      router.navigate(appState?.returnTo || "/");
    }}
  >
    <RouterProvider router={router} />
  </Auth0Provider>
  // </React.StrictMode>,
);
