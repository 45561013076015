import { message } from 'antd';
import { useAppContext } from 'contexts/AppProviders';
import { useCallback, useEffect } from 'react';
import { MemoryActionTypes } from 'reducers/memoriesReducer';
import { CommonStatusEnum } from 'types/enum';
import { getPage } from 'utils/getPage';
import { NewtonApi } from 'utils/newtonApi';

const useMemoriesState = () => {
  const {
    state: { memoriesState },
    dispatch,
  } = useAppContext();

  const fetchMemories = useFetchMemories();

  const {
    memories: { status },
  } = memoriesState;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      fetchMemories();
    }
  }, [dispatch, status]);

  return { state: memoriesState };
};

export const useFetchMemories = () => {
  const { dispatch } = useAppContext();

  return useCallback(async () => {
    dispatch({
      type: MemoryActionTypes.FETCHING_MEMORIES,
    });
    dispatch({
      type: MemoryActionTypes.FETCHING_MEMORY_LABELS,
    });
    dispatch({
      type: MemoryActionTypes.FETCHING_MEMORY_SOURCES,
    });
    dispatch({
      type: MemoryActionTypes.FETCHING_MEMORY_TYPES,
    });

    const [
      { results: memories, ...memoryPagination },
      { results: types, ...typePagination },
      { results: labels, ...labelPagination },
      { results: sources, ...sourcePagination },
    ] = await Promise.all([
      NewtonApi.fetchMemories(),
      NewtonApi.fetchMemoryTypes(),
      NewtonApi.fetchMemoryLabels(),
      NewtonApi.fetchMemorySources(),
    ]);

    dispatch({
      type: MemoryActionTypes.FETCH_MEMORIES,
      payload: { pagination: memoryPagination, memories },
    });
    dispatch({
      type: MemoryActionTypes.FETCH_MEMORY_LABELS,
      payload: { pagination: labelPagination, labels },
    });
    dispatch({
      type: MemoryActionTypes.FETCH_MEMORY_SOURCES,
      payload: { pagination: sourcePagination, sources },
    });
    dispatch({
      type: MemoryActionTypes.FETCH_MEMORY_TYPES,
      payload: { pagination: typePagination, types },
    });
  }, [dispatch]);
};

export const useMemories = () => {
  const {
    state: {
      memories: { list: memories, status: memoriesStatus },
      labels: { list: labels, status: labelsStatus },
      sources: { list: sources, status: sourcesStatus },
      types: { list: types, status: typesStatus },
    },
  } = useMemoriesState();

  const loading =
    memoriesStatus === CommonStatusEnum.FETCHING ||
    memoriesStatus === CommonStatusEnum.INITIAL ||
    labelsStatus === CommonStatusEnum.FETCHING ||
    labelsStatus === CommonStatusEnum.INITIAL ||
    sourcesStatus === CommonStatusEnum.FETCHING ||
    sourcesStatus === CommonStatusEnum.INITIAL ||
    typesStatus === CommonStatusEnum.FETCHING ||
    typesStatus === CommonStatusEnum.INITIAL;

  return {
    memories: Array.from(memories.values()),
    labels: Array.from(labels.values()),
    sources: Array.from(sources.values()),
    types: Array.from(types.values()),
    loading,
  };
};

export const useMemoriesListState = (page: number) => {
  const {
    dispatch,
    state: { memoriesState },
  } = useAppContext();

  const fetchMemoriesList = useFetchMemoriesList();

  const { status } = memoriesState.filteredMemories;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      dispatch({
        type: MemoryActionTypes.FETCHING_FILTERED_MEMORIES,
      });
      fetchMemoriesList(page);
    }
    return () => {
      if (status === CommonStatusEnum.FETCHED)
        dispatch({
          type: MemoryActionTypes.CLEAN_FILTERED_MEMORIES,
        });
    };
  }, [dispatch, status, page]);

  return { state: memoriesState };
};

export const useFetchMemoriesList = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (page: number) => {
      const { results: memories, ...pagination } = await NewtonApi.fetchMemories(page, 10);

      dispatch({
        type: MemoryActionTypes.FETCH_FILTERED_MEMORIES,
        payload: { memories, pagination },
      });
    },
    [dispatch],
  );
};

export const useFilteredMemories = (page: number) => {
  const { state: memoriesState } = useMemoriesListState(page);
  const { list, pagination, status } = memoriesState.filteredMemories;

  return {
    results: Array.from(list.values()),
    pagination,
    loading: status === CommonStatusEnum.FETCHING || status === CommonStatusEnum.INITIAL,
  };
};

export const useRemoveMemory = () => {
  const {
    dispatch,
    state: { memoriesState },
  } = useAppContext();

  const { pagination, list } = memoriesState.filteredMemories;
  const { previous } = pagination;
  const fetchMemories = useFetchMemoriesList();
  return useCallback(
    async (memoryId: Memory['id']) => {
      const page = getPage(previous, list.size === 1, 'remove');
      try {
        await NewtonApi.deleteMemory(memoryId, true);
        fetchMemories(page);
        message.success('Memory deleted successfully');
      } catch (error) {
        message.error('Failed to delete memory');
        return;
      }
    },
    [dispatch, pagination, list, previous],
  );
};

export const useToggleMemoryStatus = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (memoryId: Memory['id'], status: boolean) => {
      try {
        const res = await NewtonApi.toggleMemoryStatus(memoryId, !status);
        dispatch({
          type: MemoryActionTypes.UPDATE_MEMORY,
          payload: { id: memoryId, memory: res },
        });
        message.success(`Successfully ${!status ? 'activated' : 'deactivated'} memory`);
      } catch (error) {
        message.error(`Failed to ${!status ? 'activate' : 'deactivate'} memory status`);
        return;
      }
    },
    [dispatch],
  );
};

export const useUpdateMemory = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (memory: Memory) => {
      try {
        const res = await NewtonApi.updateMemory(memory);

        dispatch({
          type: MemoryActionTypes.UPDATE_MEMORY,
          payload: { id: memory.id, memory: res },
        });
        message.success(`Memory updated successfully`);
      } catch (error) {
        message.error(`Failed to update memory`);
        return;
      }
    },
    [dispatch],
  );
};
export const useCreateMemory = () => {
  const {
    dispatch,
    state: { memoriesState },
  } = useAppContext();

  const { pagination } = memoriesState.filteredMemories;
  const { previous } = pagination;
  const fetchMemories = useFetchMemoriesList();

  return useCallback(
    async (memory: Memory) => {
      const page = getPage(previous);

      try {
        await NewtonApi.createMemory(memory as NewMemory, true);
        fetchMemories(page);
        message.success(`Memory created successfully`);
      } catch (error) {
        message.error(`Failed to update memory`);
        return;
      }
    },
    [dispatch, pagination, previous],
  );
};
