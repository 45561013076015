import { Col, Grid, Layout, Row } from 'antd';
import styles from './ProjectsView.module.scss';
import { ProjectsHeader } from './ProjectsHeader';
import { NewProjectCard } from './NewProjectCard';
import { useProjectLabels } from 'selectors/useProjectLabels';
import { ProjectCard } from './ProjectCard';
import { useModal } from 'selectors/useModals';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
const { useBreakpoint } = Grid;

export const ProjectsView = () => {
  const { show: showAddProjectModal } = useModal(ModalScopesEnum.PROJECTS, ModalTypesEnum.ADD);

  const columnSpan = useBreakpoint().xxl ? 6 : 8;
  const { projectLabels } = useProjectLabels();

  return (
    <Layout className={styles.content}>
      <ProjectsHeader />
      <div className={styles.projectList}>
        <Row gutter={[20, 20]}>
          <Col span={columnSpan}>
            <NewProjectCard onClick={() => showAddProjectModal()} />
          </Col>
          {projectLabels.map(projectLabel => (
            <Col key={projectLabel.id} span={columnSpan}>
              <ProjectCard project={projectLabel} />
            </Col>
          ))}
        </Row>
      </div>
    </Layout>
  );
};
