import {
  DatabaseFilled,
  DislikeFilled,
  DislikeOutlined,
  InfoCircleFilled,
  LikeFilled,
  LikeOutlined,
} from '@ant-design/icons';
import { ArrowPathIcon } from '@heroicons/react/16/solid';
import { BeakerIcon, BookmarkIcon } from '@heroicons/react/24/outline';
import CopyIcon from '../../assets/icons/copy.svg?react';
import { Avatar, Button, Flex, Popover, Typography } from 'antd';
import { DatasourceTags } from 'components/datasource-tags/DatasourceTags';
import { Markdown } from 'components/newton-markdown/NewtonMarkdown';

import { TimeAgo } from 'components/timeago/TimeAgo';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import Logo from '../../assets/logo.svg?react';
import styles from './Message.module.scss';
import { DisplayObjectSet } from './MessageDisplayObjectSet';
import { copyToClipboard } from 'utils/copyToClipboard';
import QuestionIcon from '../../assets/icons/question.svg?react';
import { ConversationFeedbacksViewEnum, ConversationViewEnum, FeedbackTypeEnum } from '../../types/enum';
import { useLocation } from 'react-router-dom';
import { showUserActions } from 'utils/showUserActions';
import Icon from '@ant-design/icons/lib/components/Icon';
import { cx } from 'utils/cx';
import { isUserAllowed } from 'utils/isUserAllowed';
import { FeedbackGroup } from './FeedbackGroup';
import useLocalStorage from 'hooks/useLocalStorage';
import { useActiveConversation, useRewindConversation } from 'selectors/useConversationSelectors';
import { useSharedEntities } from 'selectors/useSharedEntitiesSelector';
import { useAuth } from 'selectors/useAuthSelector';
import { useConversationDataSources } from 'selectors/useDataSourcesSelector';
import classNames from 'classnames';

type Props = PropsWithChildren & {
  conversation: Conversation;
  index: number;
  message: Message;
  view: ConversationViewEnum | ConversationFeedbacksViewEnum;
};

export const Message: React.FC<Props> = ({ conversation: feedbackConversation, message, index, view }) => {
  const { me } = useAuth();
  const { conversation } = useActiveConversation();
  const { byMessageId } = useConversationDataSources(conversation?.id);
  const rewindConversation = useRewindConversation();
  const { sharedEntities } = useSharedEntities();
  const [feedbackType, setFeedbackType] = useState<FeedbackTypeEnum>(FeedbackTypeEnum.LIKE);

  const [isFeedbackOpen, setIsFeedbackOpen] = useLocalStorage(`feedback-${message.id}`, false);
  const messageDatasources: DataSource[] = useMemo(() => {
    return byMessageId(message.id);
  }, [byMessageId, message]);

  const location = useLocation();

  const showUserRoleActions = showUserActions(location.pathname);

  const user = useMemo(() => {
    return sharedEntities.find(u => u.id === message.author);
  }, [sharedEntities, message]);

  const sentimentsList = message?.feedbackSet?.flatMap(feedback => feedback.sentiment);
  const userFeedback = message?.feedbackSet?.find(feedback => feedback.user?.id === me?.id);

  const userNames: Record<RoleTypes, { icon?: React.ReactElement; label: string; src?: string }> = useMemo(
    () => ({
      'Coordinator Agent': {
        icon: <Logo style={{ background: 'white' }} />,
        label: 'Newton - Coordinator Agent',
      },
      user: { label: user?.name || 'You', src: user?.picture },
      assistant: {
        icon: <Logo style={{ background: 'white' }} />,
        label: 'Newton - Data Scientist',
      },
      user_proxy: {
        icon: <Logo style={{ background: 'white' }} />,
        label: 'Newton - Data Scientist',
      },
      summary: {
        icon: <Logo color="black" style={{ background: 'white' }} />,
        label: 'Newton - Marketing Analyst',
      },
      // Will never show up
      internal: {
        icon: <Logo color="black" style={{ background: 'white' }} />,
        label: 'Internal',
      },
    }),
    [user],
  );

  const isActive = (feedbackType: FeedbackTypeEnum) => {
    if (isUserAllowed(location.pathname) && userFeedback?.sentiment?.find(sentiment => sentiment.name === feedbackType))
      return true;
    if (!isUserAllowed(location.pathname) && sentimentsList?.find(sentiment => sentiment.name === feedbackType))
      return true;

    return false;
  };

  const handleShowFeedbackCard = useCallback(
    (sentiment: FeedbackTypeEnum) => {
      setFeedbackType(sentiment);
      setIsFeedbackOpen(true);
    },
    [setIsFeedbackOpen],
  );

  return message ? (
    <div className={classNames(styles.message, { [styles.notUser]: message.role !== 'user' })} key={index}>
      <div className={styles.avatar}>
        {/** @ts-expect-error display roll */}
        {message.role && <Avatar size={32} style={{ border: 'none' }} {...(userNames[message.displayRole] || userNames[message.role])} />}
      </div>
      <div className={styles.content}>
        <Flex className={styles.messageHeader} align="center">
          <div className={styles.messageTitle}>
            {message.role && (
              <Typography.Text style={{ fontWeight: 600 }}>
                {message?.displayRole !== null ? message?.displayRole : userNames[message.role].label}
              </Typography.Text>
            )}

            <Typography.Text style={{ color: 'var(--nri-color-purple-grey)', fontSize: 12 }}>
              &nbsp;&nbsp;&bull;&nbsp;&nbsp; <TimeAgo date={message.createdAt!} />
            </Typography.Text>
          </div>
          <div className={styles.buttons}>
            {message.role === 'user' ? (
              <>
                {messageDatasources.length > 0 && (
                  <Popover
                    placement="bottom"
                    title={
                      <Flex>
                        {messageDatasources.map(ds => (
                          <Typography key={ds.id}>{ds.name}</Typography>
                        ))}
                      </Flex>
                    }
                  >
                    <DatabaseFilled />
                  </Popover>
                )}
                {message.analyst && (
                  <Popover
                    placement="bottom"
                    title={
                      <Flex>
                        <BeakerIcon height={24} /> {message.analyst}
                      </Flex>
                    }
                  >
                    <InfoCircleFilled />
                  </Popover>
                )}

                {showUserRoleActions && (
                  <>
                    <BookmarkIcon width="16px" />
                    <ArrowPathIcon
                      width="16px"
                      onClick={e => {
                        e.preventDefault();
                        rewindConversation(conversation!.id, message.id);
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <Button
                  style={{ display: 'none' }}
                  icon={<QuestionIcon width="16px" onClick={() => handleShowFeedbackCard(FeedbackTypeEnum.QUESTION)} />}
                />
                <Icon
                  component={
                    isActive(FeedbackTypeEnum.LIKE)
                      ? (LikeFilled as React.ForwardRefExoticComponent<unknown>)
                      : (LikeOutlined as React.ForwardRefExoticComponent<unknown>)
                  }
                  className={cx({
                    [styles.activeSentiment]: isActive(FeedbackTypeEnum.LIKE),
                  })}
                  onClick={() => handleShowFeedbackCard(FeedbackTypeEnum.LIKE)}
                />
                <Icon
                  component={
                    isActive(FeedbackTypeEnum.DISLIKE)
                      ? (DislikeFilled as React.ForwardRefExoticComponent<unknown>)
                      : (DislikeOutlined as React.ForwardRefExoticComponent<unknown>)
                  }
                  className={cx({
                    [styles.activeSentiment]: isActive(FeedbackTypeEnum.DISLIKE),
                  })}
                  onClick={() => handleShowFeedbackCard(FeedbackTypeEnum.DISLIKE)}
                />
                <CopyIcon width="16px" onClick={() => copyToClipboard(message.text)} />
              </>
            )}
          </div>
        </Flex>
        {message.role !== 'user' && (
          <FeedbackGroup
            feedbacks={message.feedbackSet as FeedbackData[]}
            showFeedback={isFeedbackOpen}
            feedbackType={feedbackType}
            messageId={message.id as MessageId}
            setShowFeedback={() => setIsFeedbackOpen(false)}
            user={me as FeedbackUser}
            conversation={feedbackConversation as FeedbackConversation}
          />
        )}

        <Typography.Text>
          <Markdown isAnalyst={message.role !== 'user'}>{message?.text}</Markdown>
        </Typography.Text>
        {message.displayobjectSet && message.displayobjectSet.length > 0 && (
          <DisplayObjectSet set={message.displayobjectSet} messageId={message.id!} view={view} />
        )}

        <DatasourceTags locked={messageDatasources} selected={[]} />
      </div>
    </div>
  ) : null;
};
