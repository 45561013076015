import { Form } from 'antd';
import { ProjectForm } from 'components/project-form/ProjectForm';
import { FC, useCallback, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { useCreateProjectLabel } from 'selectors/useProjectLabels';
import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';

export const AddProjectModal: FC = () => {
  const { hide } = useModal<{
    project: ProjectLabel;
    onConfirm(project: ProjectLabel): void;
  }>(ModalScopesEnum.PROJECTS, ModalTypesEnum.ADD);
  const createProject = useCreateProjectLabel();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = useCallback(async () => {
    await form.validateFields();
    setIsLoading(true);
    const dataInfo = form.getFieldsValue();

    const payload = {
      name: dataInfo.name,
      description: dataInfo.description,
      color: dataInfo.color ?? `${'var(--nri-color-purple-grey)'}`,
    };

    await createProject(payload as ProjectLabel);
    setIsLoading(false);
    hide();
  }, [setIsLoading, hide, createProject, form]);

  return (
    <Modal
      title="Create Project"
      description="Here you can create new projects"
      onCancel={() => {
        hide();
      }}
      width="var(--nri-modal-with-sm)"
      open={true}
      footer={
        <>
          <Button
            onClick={() => {
              hide();
            }}
          >
            Cancel
          </Button>
          <Button type="primary" loading={isLoading} onClick={handleConfirm}>
            Create
          </Button>
        </>
      }
      divider={true}
    >
      <ProjectForm form={form} />
    </Modal>
  );
};
