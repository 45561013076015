import { Flex } from 'antd';
import styles from './EntityFooter.module.scss';
import { FC } from 'react';
import { Button } from 'ui/Button';

type EntityFooterProps = {
  isEditing: boolean;
  isSaving: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onEdit: () => void;
};
export const EntityFooter: FC<EntityFooterProps> = ({ isEditing, isSaving, onCancel, onConfirm, onEdit }) => {
  const handleCancelEdit = () => {
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Flex className={styles.content}>
      <Flex className={styles.buttonContainer} justify="end" gap={8}>
        {isEditing ? (
          <>
            <Button className={styles.submitButton} onClick={handleConfirm} loading={isSaving} type="primary">
              Save changes
            </Button>
            <Button className={styles.cancelButton} onClick={handleCancelEdit}>
              Cancel
            </Button>
          </>
        ) : (
          <Button
            className={styles.editButton}
            onClick={e => {
              e.preventDefault();
              onEdit();
            }}
            type="primary"
          >
            Edit
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
