import { Flex, Skeleton, Spin, Typography } from 'antd';
import { EntityHeader, EntityHeaderProps } from 'components/entity-header/EntityHeader';
import { FavoriteToggle } from 'components/favorite-toggle/FavoriteToggle';
import {
  useActiveConversation,
  useFavoriteConversation,
  useUpdateConversation,
} from 'selectors/useConversationSelectors';
import { EntityActionsEnum } from 'types/enum';
import styles from './ConversationView.module.scss';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGenerateBlueprintFromConversation } from 'selectors/useBlueprints';
import { EntityAccess } from 'components/entity-access/EntityAccess';
import { useSharedEntitiesById } from 'selectors/useSharedEntitiesSelector';
import { useModal } from 'selectors/useModals';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';

export const ConversationHeader: React.FC = () => {
  const { conversation, permissions = [], streaming } = useActiveConversation();
  const toggleFavoriteConversation = useFavoriteConversation();
  const updateConversation = useUpdateConversation();
  const navigate = useNavigate();
  const createBlueprintFromConversation = useGenerateBlueprintFromConversation();
  const [isCreatingBlueprint, setIsCreatingBlueprint] = useState(false);
  const { show: showManageAccessModal } = useModal(ModalScopesEnum.CONVERSATIONS, ModalTypesEnum.MANAGE);

  const owner = useSharedEntitiesById(conversation?.owner as UserId);
  const sharedUsers = permissions?.filter((permission: ShareableEntity) => permission.type === 'user');

  const onCreateBlueprint = useCallback(async () => {
    setIsCreatingBlueprint(true);
    const blueprint = await createBlueprintFromConversation(conversation!.id);
    navigate(`/blueprints/${blueprint.id}`);
    setIsCreatingBlueprint(false);
  }, [conversation, createBlueprintFromConversation, navigate]);

  const isHeaderEditable = (conversation?.messageSet?.length ?? 0) > 0 && !streaming;

  const headerProps: Partial<EntityHeaderProps<boolean>> = {};
  if (conversation?.id) {
    headerProps.actions = [
      {
        key: EntityActionsEnum.MANAGE_ACCESS,
        component: (
          <EntityAccess
            owner={owner as UserEntity}
            sharedUsers={sharedUsers || []}
            onAdd={() => showManageAccessModal(conversation)}
          />
        ),
      },
      {
        key: EntityActionsEnum.FAVORITE,
        icon: <FavoriteToggle isFavorite={conversation.isFavorite!} />,
        onClick: () => {
          toggleFavoriteConversation(conversation);
        },
      },
    ];
    headerProps.rightSideButtonLoading = isCreatingBlueprint;
    headerProps.rightSideButtonText = isCreatingBlueprint ? 'Creating blueprint...' : 'Save as Blueprint';
    headerProps.rightSideButtonOnClick = onCreateBlueprint;
  }
  const handleEdit = (editedName: string) => {
    updateConversation({ id: conversation?.id as ConversationId, name: editedName });
  };

  return (
    <>
      {isCreatingBlueprint ? (
        <Spin
          className={styles.spinner}
          fullscreen
          tip={
            <Typography.Text className={styles.spinnerText}>Generating blueprint from conversation...</Typography.Text>
          }
        />
      ) : null}
      {conversation ? (
        <EntityHeader
          isEditable={isHeaderEditable}
          title={conversation?.name || 'New Conversation'}
          onEdit={handleEdit}
          {...headerProps}
        />
      ) : (
        <Flex className={styles.skeletonContainer} align="center">
          <Skeleton active title={false} paragraph={{ rows: 1 }} className={styles.skeleton} />
        </Flex>
      )}
    </>
  );
};
