import { Actions } from 'types/actionTypes';
import { CommonStatusEnum, ConversationViewEnum } from 'types/enum';

type EventNotifications = {
  message: string;
};

export type ConversationContainer = {
  conversation: Conversation;
  notifications: EventNotifications[];
  permissions: ShareableEntity[];
  streaming: boolean;
  status: CommonStatusEnum;
  temporary?: TemporaryMessage;
  unreadMessages: Message[];
};

type ConversationPage = {
  activeTab: ConversationViewEnum;
};

export type TemporaryMessage = {
  analyst?: Analyst;
  prompt?: Partial<Message>;
  dataSources?: DataSource[];
};

export interface ConversationsState {
  archived: {
    status: CommonStatusEnum;
    conversations: Map<Conversation['id'], ConversationContainer>;
    pagination: PaginationType;
  };
  conversations: Map<Conversation['id'], ConversationContainer>;
  lastUpdate: number;
  loading: boolean;
  page: ConversationPage;
  pagination: PaginationType;
  status: CommonStatusEnum;
}

export const initialConversationsState: ConversationsState = {
  archived: {
    status: CommonStatusEnum.INITIAL,
    conversations: new Map<Conversation['id'], ConversationContainer>(),
    pagination: {
      count: 0,
      next: null,
      previous: null,
    },
  },
  conversations: new Map<Conversation['id'], ConversationContainer>(),
  lastUpdate: +new Date(),
  loading: true,
  page: {
    activeTab: ConversationViewEnum.ALL,
  },
  pagination: {
    count: 0,
    next: null,
    previous: null,
  },
  status: CommonStatusEnum.INITIAL,
};

export enum ConversationsActionTypes {
  ARCHIVE_CONVERSATION = 'ARCHIVE_CONVERSATION',
  DELETE_CONVERSATION = 'DELETE_CONVERSATION',
  FETCHING_ARCHIVED_CONVERSATIONS = 'FETCHING_ARCHIVED_CONVERSATIONS',
  FETCHING_CONVERSATIONS = 'FETCHING_CONVERSATIONS',
  FETCH_ARCHIVED_CONVERSATIONS = 'FETCH_ARCHIVED_CONVERSATIONS',
  FETCH_CONVERSATIONS = 'FETCH_CONVERSATIONS',
  FETCH_CONVERSATION = 'FETCH_CONVERSATION',
  FETCH_CONVERSATION_PERMISSIONS = 'FETCH_CONVERSATION_PERMISSIONS',
  FETCHING_CONVERSATION = 'FETCHING_CONVERSATION',
  INCOMING_STREAMING_MESSAGE = 'INCOMING_STREAMING_MESSAGE',
  MARK_CONVERSATION_AS_READ = 'MARK_CONVERSATION_AS_READ',
  REWIND_CONVERSATION = 'REWIND_CONVERSATION',
  SAVE_PROMPT = 'SAVE_PROMPT',
  SET_PAGE_ACTIVE_TAB = 'SET_PAGE_ACTIVE_TAB',
  START_STREAMING = 'START_STREAMING',
  STOP_STREAMING = 'STOP_STREAMING',
  UPDATING_CONVERSATION = 'UPDATING_CONVERSATION',
  UPDATE_CONVERSATION = 'UPDATE_CONVERSATION',
}

export type ConversationsAction =
  | { type: ConversationsActionTypes.ARCHIVE_CONVERSATION; payload: Conversation['id'] }
  | { type: ConversationsActionTypes.DELETE_CONVERSATION; payload: Conversation['id'] }
  | { type: ConversationsActionTypes.FETCHING_ARCHIVED_CONVERSATIONS }
  | { type: ConversationsActionTypes.FETCHING_CONVERSATIONS }
  | {
      type: ConversationsActionTypes.FETCH_CONVERSATIONS;
      payload: { conversations: Conversation[]; pagination: PaginationType };
    }
  | {
      type: ConversationsActionTypes.FETCH_ARCHIVED_CONVERSATIONS;
      payload: { conversations: Conversation[]; pagination: PaginationType };
    }
  | { type: ConversationsActionTypes.FETCH_CONVERSATION; payload: Conversation }
  | {
      type: ConversationsActionTypes.FETCH_CONVERSATION_PERMISSIONS;
      payload: {
        conversationId: ConversationId;
        permissions: ShareableEntity[];
      };
    }
  | { type: ConversationsActionTypes.FETCHING_CONVERSATION; payload: Conversation['id'] }
  | {
      type: ConversationsActionTypes.INCOMING_STREAMING_MESSAGE;
      payload: { conversationId: Conversation['id']; message: Message };
    }
  | {
      type: ConversationsActionTypes.MARK_CONVERSATION_AS_READ;
      payload: Conversation['id'];
    }
  | {
      type: ConversationsActionTypes.REWIND_CONVERSATION;
      payload: { conversationId: Conversation['id']; messageId: Message['id'] };
    }
  | {
      type: ConversationsActionTypes.SAVE_PROMPT;
      payload: { conversationId: Conversation['id']; data?: TemporaryMessage };
    }
  | { type: ConversationsActionTypes.SET_PAGE_ACTIVE_TAB; payload: ConversationViewEnum }
  | {
      type: ConversationsActionTypes.START_STREAMING;
      payload: Conversation['id'];
    }
  | { type: ConversationsActionTypes.STOP_STREAMING; payload: Conversation['id'] }
  | { type: ConversationsActionTypes.UPDATING_CONVERSATION; payload: Conversation['id'] }
  | {
      type: ConversationsActionTypes.UPDATE_CONVERSATION;
      payload: Partial<Conversation> & { id: Conversation['id'] };
    };

export const conversationsReducer = (state: ConversationsState, action: Actions): ConversationsState => {
  switch (action.type) {
    case ConversationsActionTypes.ARCHIVE_CONVERSATION: {
      state.lastUpdate = +new Date();
      const conversationId = action.payload;
      state.archived.conversations.set(conversationId, state.conversations.get(conversationId)!);
      state.conversations.delete(conversationId);
      return { ...state };
    }
    case ConversationsActionTypes.DELETE_CONVERSATION: {
      state.lastUpdate = +new Date();
      const conversationId = action.payload;
      if (state.archived.conversations.has(conversationId)) state.archived.conversations.delete(conversationId);
      state.conversations.delete(conversationId);
      return { ...state };
    }

    case ConversationsActionTypes.FETCHING_ARCHIVED_CONVERSATIONS: {
      state.lastUpdate = +new Date();
      return {
        ...state,
        archived: {
          ...state.archived,
          status: CommonStatusEnum.FETCHING,
        },
      };
    }

    case ConversationsActionTypes.FETCHING_CONVERSATIONS: {
      state.lastUpdate = +new Date();
      return {
        ...state,
        loading: true,
        status: CommonStatusEnum.FETCHING,
      };
    }

    case ConversationsActionTypes.FETCH_ARCHIVED_CONVERSATIONS: {
      state.lastUpdate = +new Date();

      const archivedConversations = new Map(
        action.payload.conversations.map(c => [
          c.id,
          {
            conversation: {
              ...c,
              messageSet: c.messageSet || [],
            },
            notifications: [],
            permissions: [],
            streaming: false,
            status: CommonStatusEnum.FETCHED,
            temporary: undefined,
            unreadMessages: [],
          },
        ]),
      );

      return {
        ...state,
        archived: {
          ...state.archived,
          conversations: new Map([...state.archived.conversations, ...archivedConversations]),
          pagination: action.payload.pagination,
          status: CommonStatusEnum.FETCHED,
        },
      };
    }

    case ConversationsActionTypes.FETCH_CONVERSATIONS: {
      state.lastUpdate = +new Date();

      const conversations = new Map(
        action.payload.conversations.map(c => [
          c.id,
          {
            conversation: { ...c, messageSet: [] },
            notifications: [],
            temporary: undefined,
            permissions: [],
            streaming: false,
            status: CommonStatusEnum.INITIAL,
            unreadMessages: [],
          },
        ]),
      );

      return {
        ...state,
        conversations: new Map([...state.conversations, ...conversations]),
        pagination: action.payload.pagination,
        loading: false,
        status: CommonStatusEnum.FETCHED,
      };
    }

    case ConversationsActionTypes.FETCH_CONVERSATION_PERMISSIONS: {
      state.lastUpdate = +new Date();

      const conversationId = action.payload.conversationId;
      const convoWrapper = state.conversations.get(conversationId);
      if (convoWrapper) {
        state.conversations.set(conversationId, {
          ...convoWrapper,
          permissions: action.payload.permissions,
        });
      }
      return { ...state };
    }

    case ConversationsActionTypes.FETCHING_CONVERSATION: {
      state.lastUpdate = +new Date();

      const conversationId = action.payload;
      const convoWrapper = state.conversations.get(conversationId);
      if (convoWrapper) {
        convoWrapper.conversation.messageSet = convoWrapper.conversation.messageSet || [];
        state.conversations.set(conversationId, {
          ...convoWrapper,
          status: CommonStatusEnum.FETCHING,
        });
      }
      return { ...state };
    }

    case ConversationsActionTypes.FETCH_CONVERSATION: {
      state.lastUpdate = +new Date();

      const conversation = action.payload;
      let convoWrapper = state.conversations.get(conversation.id);
      let actualState = state.conversations;
      if (!convoWrapper) {
        convoWrapper = state.archived.conversations.get(conversation.id);
        actualState = convoWrapper ? state.archived.conversations : state.conversations;
      }
      if (convoWrapper) {
        actualState.set(conversation.id, {
          ...convoWrapper,
          conversation,
          status: CommonStatusEnum.FETCHED,
        });
      } else {
        actualState.set(conversation.id, {
          conversation,
          notifications: [],
          permissions: [],
          streaming: false,
          status: CommonStatusEnum.FETCHED,
          temporary: undefined,
          unreadMessages: [],
        });
      }
      return { ...state };
    }

    case ConversationsActionTypes.INCOMING_STREAMING_MESSAGE: {
      state.lastUpdate = +new Date();

      const { conversationId, message } = action.payload;

      let convoWrapper = state.conversations.get(conversationId);
      let actualState = state.conversations;
      if (!convoWrapper) {
        convoWrapper = state.archived.conversations.get(conversationId);
        actualState = state.archived.conversations;
      }

      if (convoWrapper) {
        const unreadMessages = [...convoWrapper.unreadMessages, message];
        const currentMessages = [...convoWrapper.conversation!.messageSet];

        // Update message with a common parentId
        const parentId = message.parent || message.parentMessageId;
        message.parent = parentId;

        // Remove messages without IDs
        const filteredMessages = currentMessages.filter(m => m.id !== undefined);

        // Check if the message already exists
        const messageIndex = filteredMessages.findIndex(m => m.id === message.id);
        if (messageIndex >= 0) {
          // Update the existing message
          filteredMessages[messageIndex] = message;
        } else {
          // Insert the new message
          filteredMessages.push(message);
        }

        actualState.set(conversationId, {
          ...convoWrapper,
          conversation: {
            ...convoWrapper.conversation!,
            messageSet: filteredMessages,
          },
          unreadMessages,
        });
      }
      return { ...state };
    }

    case ConversationsActionTypes.MARK_CONVERSATION_AS_READ: {
      state.lastUpdate = +new Date();

      const conversationId = action.payload;
      const convoWrapper = state.conversations.get(conversationId);
      if (convoWrapper) {
        state.conversations.set(conversationId, {
          ...convoWrapper,
          unreadMessages: [],
        });
      }
      return { ...state };
    }

    case ConversationsActionTypes.REWIND_CONVERSATION: {
      state.lastUpdate = +new Date();

      const { conversationId, messageId } = action.payload;
      const convoWrapper = state.conversations.get(conversationId);
      if (convoWrapper) {
        const messageSet = [...convoWrapper.conversation!.messageSet];
        const messageIndex = messageSet.findIndex(m => m.id === messageId);

        if (messageIndex >= 0) {
          messageSet.splice(messageIndex);
        }
        state.conversations.set(conversationId, {
          ...convoWrapper,
          conversation: {
            ...convoWrapper.conversation!,
            messageSet,
          },
          status: CommonStatusEnum.FETCHED,
          unreadMessages: [],
        });
      }
      return { ...state };
    }

    case ConversationsActionTypes.SAVE_PROMPT: {
      state.lastUpdate = +new Date();

      const { conversationId, data } = action.payload;
      const convoWrapper = state.conversations.get(conversationId);
      if (convoWrapper) {
        state.conversations.set(conversationId, {
          ...convoWrapper,
          temporary:
            data === undefined
              ? undefined
              : {
                  ...convoWrapper.temporary,
                  ...data,
                },
        });
      }
      return { ...state };
    }

    case ConversationsActionTypes.SET_PAGE_ACTIVE_TAB: {
      state.lastUpdate = +new Date();

      return {
        ...state,
        page: {
          activeTab: action.payload,
        },
      };
    }

    case ConversationsActionTypes.START_STREAMING: {
      state.lastUpdate = +new Date();

      const conversationId = action.payload;
      let convoWrapper = state.conversations.get(conversationId);
      let actualState = state.conversations;
      if (!convoWrapper) {
        convoWrapper = state.archived.conversations.get(conversationId);
        actualState = state.archived.conversations;
      }
      if (convoWrapper) {
        actualState.set(conversationId, {
          ...convoWrapper,
          streaming: true,
        });
      }
      return { ...state };
    }

    case ConversationsActionTypes.STOP_STREAMING: {
      state.lastUpdate = +new Date();

      const conversationId = action.payload;
      let convoWrapper = state.conversations.get(conversationId);
      let actualState = state.conversations;
      if (!convoWrapper) {
        convoWrapper = state.archived.conversations.get(conversationId);
        actualState = state.archived.conversations;
      }
      if (convoWrapper) {
        actualState.set(conversationId, {
          ...convoWrapper,
          streaming: false,
        });
      }
      return { ...state };
    }

    case ConversationsActionTypes.UPDATING_CONVERSATION: {
      state.lastUpdate = +new Date();

      const conversationId = action.payload;
      let convoWrapper = state.conversations.get(conversationId);
      let actualState = state.conversations;
      if (!convoWrapper) {
        convoWrapper = state.archived.conversations.get(conversationId);
        actualState = state.archived.conversations;
      }
      if (convoWrapper) {
        actualState.set(conversationId, {
          ...convoWrapper,
          status: CommonStatusEnum.UPDATING,
        });
      }
      return { ...state };
    }

    case ConversationsActionTypes.UPDATE_CONVERSATION: {
      state.lastUpdate = +new Date();

      const { id, ...rest } = action.payload;
      let convoWrapper = state.conversations.get(id);
      let actualState = state.conversations;
      if (!convoWrapper) {
        convoWrapper = state.archived.conversations.get(id);
        actualState = state.archived.conversations;
      }

      if (convoWrapper) {
        actualState.set(id, {
          ...convoWrapper,
          conversation: { ...convoWrapper.conversation!, ...rest },
          status: CommonStatusEnum.FETCHED,
        });
      }
      return { ...state };
    }
    default:
      return state;
  }
};
