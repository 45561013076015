import { Divider, Form, Input } from 'antd';
import React from 'react';
// import RecommendationIcon from 'assets/iconsNewVersion/recommendation.svg?react';
// import { NewtonApi } from 'utils/newtonApi';
// import { useEntityContext } from 'components/layouts/context/EntityProvider';
// import { SyncOutlined, StopOutlined } from '@ant-design/icons';
// import styles from 'ui/UIKit.module.scss';

export const PlanInputsStep: React.FC = () => {
  // @TODO Bring back once UX is ready

  // const { form } = useEntityContext();
  // const [isProcessing, setIsProcessing] = useState(false);
  // const [newtonInitialMessage, setNewtonInitialMessage] = useState('');
  // const [abortController, setAbortController] = useState<AbortController | null>(null);

  // const handleStopProcessing = useCallback(() => {
  //   if (abortController) {
  //     abortController.abort();
  //     setIsProcessing(false);
  //     setAbortController(null);
  //   }
  // }, [abortController]);
  // const onInitialMessageBlur = useCallback(
  //   async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //     console.log('### onInitialMessageBlur', e.target.value);
  //     if (!newtonInitialMessage && e.target.value.length > 0) {
  //       const controller = new AbortController();
  //       setAbortController(controller);
  //       const { name, dataSources, description, inputParameters } = form.getFieldsValue();
  //       const blueprint = {
  //         name,
  //         description,
  //         input_parameters: inputParameters,
  //         // initialMessage: e.target.value,
  //         data_sources: dataSources,
  //       };
  //       setIsProcessing(true);
  //       try {
  //         const { initial_message } = await NewtonApi.generateInitialMessage(blueprint, controller.signal);
  //         setNewtonInitialMessage(initial_message);
  //       } catch (error) {
  //         if (error.name === 'AbortError') {
  //           console.log('Request was cancelled');
  //         }
  //       } finally {
  //         setIsProcessing(false);
  //         setAbortController(null);
  //       }
  //     }
  //   },
  //   [newtonInitialMessage, form],
  // );

  return (
    <>
      <Form.Item
        label="Input parameters (what do you need the user to specify when they start the blueprint?)"
        name="inputParameters"
        rules={[{ message: 'Enter parameters or select suggested by Newton' }]}
      >
        <Input.TextArea
          autoFocus
          rows={3}
          placeholder="- Campaign start date
- Campaign end date
- Benchmark category"
        />
      </Form.Item>
      <Divider />
      <br />
      <Form.Item
        label="Additional initial user message (this message is sent to the user in addition to a request for the inputs above when they start the blueprint)"
        name="initialMessage"
        rules={[{ message: 'Enter initial user message or select suggested by Newton' }]}
      >
        <Input.TextArea
          autoSize={{ minRows: 4 }}
          // disabled={isProcessing}
          // onBlur={onInitialMessageBlur}
        />
      </Form.Item>
      {/* <Form.Item
        className={styles.wideFormItem}
        label={
          <Flex align="center" style={{ gap: 4, width: '100%' }} justify="space-between">
            <Space>
              <RecommendationIcon />
              Recommendation by Newton
            </Space>
            {isProcessing ? (
              <StopOutlined onClick={handleStopProcessing} style={{ cursor: 'pointer' }} />
            ) : (
              <SyncOutlined />
            )}
          </Flex>
        }
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <div style={{ position: 'relative' }}>
          <Input.TextArea
            rows={3}
            disabled
            value={
              newtonInitialMessage
                ? newtonInitialMessage
                : isProcessing
                  ? 'Newton is processing your request...'
                  : 'Newton will make a recommendation based on the sample user message you'
            }
          />
          {isProcessing && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(255, 255, 255, 0.7)',
              }}
            >
              <Spin />
            </div>
          )}
        </div>
      </Form.Item> */}
    </>
  );
};
