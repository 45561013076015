import { Modal as AntModal, ConfigProvider, Flex, ModalProps, Typography } from 'antd';
import cx from 'classnames';
import React from 'react';
import styles from 'ui/UIKit.module.scss';

type CustomModalProps = Omit<ModalProps, 'title'> & {
  description?: string;
  divider?: boolean;
  minHeight?: number | string;
  rootClassName?: string;
  title: string;
};

const classNames = {
  body: styles.nriModalBody,
  content: styles.nriModalContent,
  header: styles.nriModalHeader,
  footer: styles.nriModalFooter,
  mask: styles.nriModalMask,
};

export const Modal: React.FC<CustomModalProps> = ({
  rootClassName,
  title: titleText,
  divider,
  description,
  minHeight,
  ...props
}) => {
  return (
    <ConfigProvider
      modal={{
        classNames,
      }}
      theme={{
        token: { colorText: 'var(--nri-color-purple-grey)' },
      }}
    >
      <AntModal
        styles={{
          content: { minHeight },
          // @ts-expect-error --nri-border-size is not a valid css property
          footer: {
            ...(!divider ? { ['--nri-border-size']: 0 } : {}),
          },
        }}
        title={
          <Flex vertical gap={'4px'}>
            <Typography.Title className={styles.modalTitle}>{titleText}</Typography.Title>
            {description && <Typography.Text className={styles.modalDescription}>{description} </Typography.Text>}
          </Flex>
        }
        rootClassName={cx(styles.modalWrapper, rootClassName)}
        {...props}
      />
    </ConfigProvider>
  );
};
