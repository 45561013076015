import { Flex } from 'antd';
import LeftArrowIcon from 'assets/iconsNewVersion/arrow_left.svg?react';
import { BlueprintList } from 'components/blueprint-list/BlueprintList';
import { PageSection } from 'components/page-section/PageSection';
import { useNavigate } from 'react-router-dom';
import { useBlueprintCategories, useLibraryBlueprintsByCategory } from 'selectors/useBlueprints';
import { Button } from 'ui/Button';
import styles from './Layout.module.scss';
import { useEffect } from 'react';

export const BlueprintLibraryPage = () => {
  const navigate = useNavigate();
  const categories = useBlueprintCategories();
  const { fetch, filtered, filter } = useLibraryBlueprintsByCategory();
  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterOptions = [
    { label: 'All', value: 'all' },
    ...categories.map(category => ({ label: category.name, value: `${category.id}` })),
  ];

  const onFilter = (key: string) => {
    filter(Number(key) as BlueprintCategoryId);
  };

  return (
    <PageSection
      fullHeight
      heading={
        <Flex align="center">
          <Button
            type="text"
            className={styles.linkButton}
            icon={<LeftArrowIcon color="var(--ant-menu-item-color)" />}
            onClick={handleGoBack}
          />{' '}
          Library blueprints
        </Flex>
      }
    >
      <BlueprintList
        entities={filtered}
        filterOptions={filterOptions}
        hideNewEntityCard
        onFilter={onFilter}
        showCategory
        showOwner={false}
      />
    </PageSection>
  );
};
