import { Flex, Skeleton } from 'antd';
import { EntityHeader } from 'components/entity-header/EntityHeader';
import styles from './FeedbacksView.module.scss';
import { useActiveConversationFeedbacks } from 'selectors/useFeedbacksSelector';

export const FeedbacksHeader = () => {
  const { conversation } = useActiveConversationFeedbacks();
  return (
    <>
      {conversation ? (
        <EntityHeader isEditable={false} title={conversation.name as string} canGoBack />
      ) : (
        <Flex className={styles.skeletonContainer} align="center">
          <Skeleton active title={false} paragraph={{ rows: 1 }} className={styles.skeleton} />
        </Flex>
      )}
    </>
  );
};
