import { InputSimple } from 'ui/Input';
import styles from './EntityHeader.module.scss';
import { useState } from 'react';

type EditEntityTitleProps = {
  name: string;
  maxLength: number;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (editedName: string) => void;
};

export const EditEntityTitleInput: React.FC<EditEntityTitleProps> = ({ maxLength, name, setIsEditing, onSave }) => {
  const [editedName, setEditedName] = useState<string>(name);
  const handleTextChange = (text: string) => {
    setEditedName(text);
  };

  return (
    <InputSimple
      variant="borderless"
      onBlur={() => {
        setIsEditing(false);
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e.target.value)}
      onPressEnter={() => {
        setIsEditing(false);
        onSave(editedName);
      }}
      value={editedName}
      maxLength={maxLength}
      autoFocus
      className={styles.borderlessInput}
    />
  );
};
