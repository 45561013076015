import { CheckOutlined } from '@ant-design/icons';
import { Flex, Tag } from 'antd';
import { DataSourceActionsMenu } from 'components/datasource-actions-menu/DatasourceActionsMenu';
import { EntityCard } from 'components/entity-list/EntityCard';
import { BaseEntityCardProps } from 'components/entity-list/EntityList';
import { MultipleElements } from 'components/multiple-labels/MultipleElements';
import { useProjectLabels } from 'selectors/useProjectLabels';
import { DatasourceTypesEnum } from 'types/enum';
import { Button } from 'ui/Button';
import { ProjectLabel } from 'ui/ProjectLabel';
import { highlightText } from 'utils/highlightText';
import RedshiftIcon from '../../assets/connectors/redshift.svg?react';
import SnowflakeIcon from '../../assets/connectors/snowflake.svg?react';
import BigqueryIcon from '../../assets/connectors/bigquery.svg?react';
import PostgresIcon from '../../assets/connectors/postgres.svg?react';
import CsvIcon from '../../assets/connectors/csv.svg?react';
import GoogleSlidesIcon from '../../assets/connectors/googleslides.svg?react';
import GoogleSheetsIcon from '../../assets/connectors/googlesheets.svg?react';
import TtdIcon from '../../assets/connectors/ttd_platform.svg?react';
import HabuIcon from '../../assets/connectors/habu.svg?react';
import { SVGProps } from 'react';

type DatasourceCardProps = BaseEntityCardProps<DataSource>;

type TagInfo = {
  bgColor?: string;
  color?: string;
  icon?: React.ComponentType<SVGProps<SVGSVGElement>>;
  title: string;
};

const tagMap: Record<DatasourceTypesEnum, TagInfo> = {
  [DatasourceTypesEnum.ATHENA]: { color: 'orange', title: 'Athena' },
  [DatasourceTypesEnum.BIGQUERY]: { bgColor: '#E7EEF8', color: '#4485F4', icon: BigqueryIcon, title: 'BigQuery' },
  [DatasourceTypesEnum.CSV]: { bgColor: '#F5F6F8', color: '#2B2544', icon: CsvIcon, title: 'CSV' },
  [DatasourceTypesEnum.GOOGLE_SHEETS]: {
    bgColor: '#ECF7E5',
    color: '#26A668',
    icon: GoogleSheetsIcon,
    title: 'Google Sheets',
  },
  [DatasourceTypesEnum.GOOGLE_SLIDES]: {
    bgColor: '#FDFDEA',
    color: '#F5BB18',
    icon: GoogleSlidesIcon,
    title: 'Google Slides',
  },
  [DatasourceTypesEnum.HABU]: { bgColor: '#F8F7E7', color: '#EBCD22', icon: HabuIcon, title: 'Habu' },
  [DatasourceTypesEnum.LOCAL_SQLITE]: { color: 'purple', title: 'Sqlite' },
  [DatasourceTypesEnum.NOTEBOOK]: { color: 'gray', title: 'Notebook' },
  [DatasourceTypesEnum.POSTGRES]: { bgColor: '#F0F6FA', color: '#336791', icon: PostgresIcon, title: 'Postgres' },
  [DatasourceTypesEnum.REDSHIFT]: { bgColor: '#E0F1FF', color: '#205B97', icon: RedshiftIcon, title: 'Redshift' },
  [DatasourceTypesEnum.SNOWFLAKE]: { bgColor: '#EAF5FF', color: '#29B5E8', icon: SnowflakeIcon, title: 'Snowflake' },
  [DatasourceTypesEnum.TTD]: { bgColor: '#E7EEF8', color: '#0099FA', icon: TtdIcon, title: 'TTD Platform' },
};

export const DatasourceCard = ({
  disabled = false,
  entity: dataSource,
  isSelectable = true,
  isSelected,
  onClick: onToggle,
  search = '',
}: DatasourceCardProps) => {
  const { projectLabels: labels } = useProjectLabels(dataSource.projectLabels.map(projectLabel => projectLabel.id));
  const { name, description, type } = dataSource;
  const tagInfo = tagMap[type];
  const Icon = tagInfo?.icon;

  return (
    <EntityCard
      actionsMenu={<DataSourceActionsMenu dataSource={dataSource} />}
      description={highlightText(description, search)}
      footer={
        <>
          <Flex gap="8px" align="center" justify="flex-start" wrap="wrap">
            <Flex>
              {tagInfo ? (
                <Tag
                  bordered
                  color={tagInfo.color}
                  style={{ backgroundColor: tagInfo.bgColor, borderColor: tagInfo.color, color: tagInfo.color }}
                >
                  <Flex align="center" gap="4px">
                    {Icon && <Icon style={{ width: '16px', height: '16px' }} />}
                    {tagInfo.title}
                  </Flex>
                </Tag>
              ) : (
                <span />
              )}
            </Flex>{' '}
            <MultipleElements>
              {labels.map((label: ProjectLabel) => (
                <ProjectLabel
                  key={label.id}
                  color={label.color}
                  text={label.name}
                  textColor="var(--nri-color-primary)"
                />
              ))}
            </MultipleElements>
          </Flex>
          {isSelectable && (
            <Button
              disabled={disabled}
              icon={isSelected ? <CheckOutlined /> : undefined}
              onClick={() => onToggle?.(dataSource)}
              type="text"
            >
              {isSelected ? 'Selected' : 'Select'}
            </Button>
          )}
        </>
      }
      selected={isSelected}
      title={highlightText(name, search)}
    />
  );
};
