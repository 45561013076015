import { Layout } from 'antd';
import styles from './Layout.module.scss';
import { memo, useEffect } from 'react';
import { DatasourceView } from 'components/datasource-view/DatasourceView';
import { useParams } from 'react-router-dom';
import { useCleanActiveDataSource, useDataSource } from 'selectors/useDataSourcesSelector';

export const DataSourcePage = memo(() => {
  const { dataSourceId } = useParams();
  const selectDatasource = useDataSource();
  const cleanDataSource = useCleanActiveDataSource();

  useEffect(() => {
    if (dataSourceId) {
      selectDatasource(Number(dataSourceId) as DataSource['id']);
    }

    return () => {
      cleanDataSource();
    };
  }, [cleanDataSource, dataSourceId, selectDatasource]);

  return (
    <Layout className={styles.dataSourcePage}>
      <DatasourceView edit={window.location.pathname.includes('edit')} />
    </Layout>
  );
});
