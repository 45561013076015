import { Alert, Collapse, Flex } from 'antd';
import Avatar from 'antd/es/avatar/avatar';
import DeleteIcon from 'assets/icons/trash.svg?react';
import styles from './ManageConversationAccessModal.module.scss';
import { useState } from 'react';

type Props = {
  onDelete?: (user: ShareableEntity, action: 'add' | 'remove') => void;
  user: ShareableEntity;
};

export const SelectedUser: React.FC<Props> = ({ onDelete, user }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const userComponent = (
    <Flex justify="space-between" style={{ height: 42, padding: 0, opacity: loading ? 0.5 : 1 }}>
      <Flex align="center" gap="10px">
        {user.picture !== '' ? (
          <Avatar size={24} src={user.picture} />
        ) : (
          <Avatar className={styles.emptyAvatar} size={24}>
            {user?.name?.split('')[0].toUpperCase()}
          </Avatar>
        )}{' '}
        {user.name}
      </Flex>
    </Flex>
  );
  if (!onDelete) {
    return userComponent;
  }
  return (
    <Collapse
      activeKey={open ? '1' : undefined}
      className={styles.selectedUser}
      collapsible="icon"
      expandIcon={() => <DeleteIcon className={styles.delete} style={{ height: 20 }} />}
      expandIconPosition="end"
      ghost
      onChange={() => setOpen(o => !o)}
      items={[
        {
          key: '1',
          label: userComponent,
          children: (
            <Alert
              action={
                <div>
                  <button className={styles.no} onClick={() => setOpen(false)}>
                    No
                  </button>
                  <button
                    className={styles.yes}
                    onClick={async () => {
                      setLoading(true);
                      onDelete(user, 'remove');
                      setOpen(false);
                      setLoading(false);
                    }}
                  >
                    Yes
                  </button>
                </div>
              }
              className={styles.confirm}
              message="Are you sure you want to remove this user from the conversation?"
              type="warning"
            />
          ),
        },
      ]}
    />
  );
};
