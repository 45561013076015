import { AutoComplete, AutoCompleteProps, ConfigProvider, Flex, Input, InputProps, Space, Typography } from 'antd';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import styles from 'ui/UIKit.module.scss';
import ExclamationIcon from 'assets/iconsNewVersion/exclamation.svg?react';
import SearchIcon from 'assets/iconsNewVersion/search.svg?react';

export type InputSearchOptionType<T> = {
  /** ID is required to use as key in the options array */
  id: string;
  label: React.ReactNode;
  meta?: T | undefined;
  value?: string | number;
  options?: InputSearchOptionType<T>[];
};

type Props<OptionMeta> = Omit<InputProps, 'onChange'> & {
  clearValueOnSelect?: boolean;
  fillColor?: string;
  filterOption?: AutoCompleteProps['filterOption'];
  onBlur?: () => void;
  /** Callback function when filtering */
  onFilter?(term: string): void;
  onFocus?: () => void;
  onOptionSelect: (value: string) => void;
  options: InputSearchOptionType<OptionMeta>[];
  prefix?: React.ReactNode;
};

export const InputSearch = <OptionMeta,>({
  className,
  clearValueOnSelect,
  fillColor,
  filterOption = true,
  onFilter,
  onOptionSelect,
  options,
  prefix = (
    <SearchIcon
      style={{
        color: 'var(--nri-color-purple-grey)',
        height: '16px',
        width: '16px',
      }}
    />
  ),
  style,
  onBlur,
  ...props
}: Props<OptionMeta>) => {
  const [value, setValue] = useState('');
  const isFocused = useRef(false);

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorFillSecondary: fillColor,
            colorFillTertiary: fillColor,
            colorSplit: 'transparent',
          },
        },
      }}
    >
      <AutoComplete
        // key={isFocused.current ? 'focused' : 'blurred'}
        className={classNames(styles.inputSearch, className)}
        popupMatchSelectWidth={300}
        options={options.map(opt => ({ ...opt, key: opt.id }))}
        virtual={false}
        filterOption={filterOption}
        notFoundContent={
          <Flex align="center" justify="center" style={{ textAlign: 'center', padding: '24px 12px' }}>
            <Space direction="vertical" align="center" size={0}>
              <ExclamationIcon color="var(--nri-color-purple-grey)" />
              <Typography.Text type="secondary" color="var(--nri-color-purple-grey)">
                No results found.
              </Typography.Text>
              <Typography.Text type="secondary">Change search request and try again</Typography.Text>
            </Space>
          </Flex>
        }
        style={{
          transition: 'width 0.2s ease-in-out',
          width: isFocused.current ? '100%' : undefined,
          ...style,
        }}
        onSelect={term => {
          clearValueOnSelect && setValue('');
          onOptionSelect(term);
        }}
        onSearch={value => {
          setValue(value);
          onFilter?.(value);
        }}
        onFocus={() => (isFocused.current = true)}
        value={value}
      >
        <Input.Search
          onBlur={() => {
            isFocused.current = false;
            onFilter?.('');
            onBlur?.();
          }}
          onSearch={onOptionSelect}
          placeholder="Search"
          prefix={prefix}
          variant="filled"
          {...props}
        />
      </AutoComplete>
    </ConfigProvider>
  );
};
