// eslint-disable-file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Divider, Layout } from 'antd';
import { Loader } from 'components/loader/Loader';
import { FC, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styles from './FeedbacksView.module.scss';
import { ConversationFeedbacksViewEnum } from '../../types/enum';
import { findKeysInLocalStorage } from 'utils/findKeysInLocalStorage';
import classNames from 'classnames';
import { FeedbacksHeader } from './FeedbacksHeader';
import {
  useActiveConversationFeedbacks,
  useConversationFeedbackMessages,
  useConversationFeedbacksPageView,
} from 'selectors/useFeedbacksSelector';
import { Segmented } from 'ui/Segmented';
import { FeedbacksSummaryTab } from './FeedbacksSummaryTabs';
import { useAutoScroll } from 'hooks/useAutoScroll';

export const FeedbacksView: FC = () => {
  const { conversationId } = useParams();
  const { loading: isConversationFeedbacksLoading, conversation } = useActiveConversationFeedbacks();
  const { messages } = useConversationFeedbackMessages(conversation?.id);
  const { activeTab, setActiveTab } = useConversationFeedbacksPageView();
  const divRef = useRef<HTMLDivElement>(null);
  const { jumpToBottom } = useAutoScroll(divRef, [messages]);

  useEffect(() => {
    return () => {
      findKeysInLocalStorage('feedback').forEach(key => {
        localStorage.removeItem(key);
      });
      findKeysInLocalStorage('message-set').forEach(key => {
        localStorage.removeItem(key);
      });
    };
  }, [conversationId]);

  useEffect(() => {
    if (activeTab === ConversationFeedbacksViewEnum.EXECUTIVE || activeTab === ConversationFeedbacksViewEnum.ALL) {
      jumpToBottom();
    }
  }, [activeTab, jumpToBottom]);

  const loading = !conversationId ? false : isConversationFeedbacksLoading;

  return (
    <Layout className={classNames(styles.conversationFeedbacks)}>
      <FeedbacksHeader />
      <Layout.Content className={classNames(styles.content)}>
        <div className={styles.messagePanel}>
          <div className={styles.messagesHeader}>
            <Segmented
              onChange={view => setActiveTab(view as ConversationFeedbacksViewEnum)}
              options={[
                {
                  value: ConversationFeedbacksViewEnum.EXECUTIVE,
                  label: 'Summary',
                },
                {
                  value: ConversationFeedbacksViewEnum.ALL,
                  label: 'Analytics',
                },
              ]}
              value={activeTab}
            />
          </div>
          <Divider style={{ marginTop: 0 }} />
          <div className={styles.messages} ref={divRef}>
            <Loader fillVertical spinning={loading}>
              {activeTab === ConversationFeedbacksViewEnum.EXECUTIVE && (
                <FeedbacksSummaryTab view={ConversationFeedbacksViewEnum.EXECUTIVE} />
              )}
              {activeTab === ConversationFeedbacksViewEnum.ALL && (
                <FeedbacksSummaryTab view={ConversationFeedbacksViewEnum.ALL} />
              )}
            </Loader>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
