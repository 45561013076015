import './App.scss';

import { Layout } from 'antd';
import { memo, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { MainMenu } from 'components/mainmenu/MainMenu';
import { NewtonApi } from 'utils/newtonApi';
import { ErrorNotification } from './components/error-notification/ErrorNotification';
import { ApiError } from './utils/newtonApi';
import AppProviders from 'contexts/AppProviders';
import { ModalsOutlet } from 'components/modals-outlet/ModalsOutlet';
import { useAuth } from 'contexts/AuthContext';

const CheckAuth: React.FC<PropsWithChildren> = memo(({ children }) => {
  const {
    state: { authenticated },
  } = useAuth();
  if (authenticated) return children;
  return '';
});

export const App: React.FC = () => {
  const [errors, setErrors] = useState<ApiError[]>([]);
  const location = useLocation();

  let className = 'nri__page';
  // FIXME: make this more robust
  if (location.pathname.includes('/conversations')) {
    className = 'conversation';
  }

  const onDismiss = useCallback((error: ApiError) => {
    setErrors(data => {
      return data.filter(e => e !== error);
    });
  }, []);

  useEffect(() => {
    const timeout = setInterval(() => {
      if (NewtonApi.errors.length === 0) return;
      setErrors(NewtonApi.errors);
      NewtonApi.clearErrors();
    }, 500);

    return () => clearInterval(timeout);
  }, []);

  return (
    <AppProviders>
      <Layout className={className} hasSider style={{ height: '100vh' }}>
        <Layout className={className} hasSider style={{ height: '100vh' }}>
          <MainMenu />
          <Outlet />
          <CheckAuth>
            <ModalsOutlet />
          </CheckAuth>
          <ErrorNotification errors={errors} onDismiss={onDismiss} />
        </Layout>
      </Layout>
    </AppProviders>
  );
};

export default App;
