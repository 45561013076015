import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { FC, useCallback, useState } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { useDeleteProjectLabel } from 'selectors/useProjectLabels';

export const DeleteProjectModal: FC = () => {
  const { data: project, hide } = useModal<ProjectLabel>(ModalScopesEnum.PROJECTS, ModalTypesEnum.DELETE);

  const [processing, setProcessing] = useState(false);
  const remove = useDeleteProjectLabel();

  const onConfirm = useCallback(async () => {
    setProcessing(true);
    await remove(project.id);

    hide();
    setProcessing(false);
  }, [hide]);

  return (
    <ConfirmationModal
      key={'project::delete'}
      isOpen
      title={'Delete project'}
      confirmationButton={processing ? 'Deleting' : 'Delete'}
      description={<>Are you sure you want to delete this project?</>}
      onCancel={hide}
      onConfirm={onConfirm}
      confirmColor={'var(--nri-color-red)'}
      processing={processing}
    />
  );
};
