import { message } from 'antd';
import { useAppContext } from 'contexts/AppProviders';
import { useAuth } from './useAuthSelector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AgentActionTypes } from 'reducers/agentsReducer';
import { CommonStatusEnum, RetrieveTypesEnum } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';
import { useParams } from 'react-router-dom';

const useAgentsState = () => {
  const {
    state: { agentState },
    dispatch,
  } = useAppContext();
  const fetchAgents = useFetchAgents();
  const { status } = agentState;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        fetchAgents(RetrieveTypesEnum.ALL);
      })();
    }
  }, [dispatch, fetchAgents, status]);

  return { state: agentState };
};

export const useDeleteAgent = (id: Agent['id']) => {
  const { dispatch } = useAppContext();
  return useCallback(async () => {
    try {
      await NewtonApi.deleteAgent(id, true);
    } catch (error) {
      message.error('Failed to delete agent');
      return;
    }
    dispatch({
      type: AgentActionTypes.DELETE_AGENT,
      payload: id,
    });
  }, [dispatch, id]);
};

const useFetchAgents = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (scope: RetrieveTypesEnum) => {
      dispatch({
        type: AgentActionTypes.FETCHING_AGENTS,
      });
      const [{ results: agents, ...pagination }, { results: models }] = await Promise.all([
        NewtonApi.fetchAllAnalysts({
          filter_enabled: 'false',
        }),
        NewtonApi.fetchAgentModels(),
      ]);

      dispatch({
        type: AgentActionTypes.FETCH_AGENTS,
        payload: { agents, pagination, scope },
      });

      dispatch({
        type: AgentActionTypes.FETCH_AGENT_MODELS,
        payload: models,
      });
    },
    [dispatch],
  );
};

export const useAgents = () => {
  const {
    state: { scope, status },
  } = useAgentsState();

  return {
    agents: Array.from(scope[RetrieveTypesEnum.ALL].agents.values()),
    loading: status === CommonStatusEnum.INITIAL,
    status,
  };
};

export const useFilteredAgents = () => {
  const { me } = useAuth();
  const {
    state: { scope, status },
  } = useAgentsState();
  const fetchAgents = useFetchAgents();
  const [filter, setFilter] = useState<RetrieveTypesEnum>(RetrieveTypesEnum.ALL);

  const scopedAgents = useMemo(() => {
    const base = Array.from(scope[RetrieveTypesEnum.ALL].agents.values());

    if (filter === RetrieveTypesEnum.FAVORITE) {
      return Array.from(scope[RetrieveTypesEnum.FAVORITE].agents.values());
    } else if (filter === RetrieveTypesEnum.OWNED) {
      return base.filter(agent => agent.owner === me!.id);
    } else if (filter === RetrieveTypesEnum.ORGANIZATION) {
      return base;
    } else if (filter === RetrieveTypesEnum.SHARED) {
      return base;
    } else if (filter === RetrieveTypesEnum.RECENT) {
      return Array.from(scope[RetrieveTypesEnum.RECENT].agents.values());
    }
    return base;
  }, [scope, filter, me]);

  useEffect(() => {
    const shouldFetch =
      filter === RetrieveTypesEnum.ALL || filter === RetrieveTypesEnum.FAVORITE || filter === RetrieveTypesEnum.RECENT;

    if (shouldFetch && status !== CommonStatusEnum.INITIAL && scope[filter]?.status === CommonStatusEnum.INITIAL) {
      fetchAgents(filter);
    }
  }, [status, filter, fetchAgents, scope]);

  return {
    filtered: scopedAgents,
    filter: useCallback(
      (filter: RetrieveTypesEnum) => {
        setFilter(filter);
      },
      [setFilter],
    ),
    status: scope[RetrieveTypesEnum.ALL].status,
    loading: useMemo(
      () =>
        scope[RetrieveTypesEnum.ALL].status === CommonStatusEnum.INITIAL ||
        scope[RetrieveTypesEnum.ALL].status === CommonStatusEnum.FETCHING,
      [scope],
    ),
  };
};

export const useToggleAgentFavorite = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (agent: Analyst) => {
      try {
        await NewtonApi.toggleAgentFavorite(agent.id, !agent.isFavorite);
      } catch (e) {
        message.error('Failed to favorite agent');
        return;
      }
      dispatch({
        type: AgentActionTypes.FETCH_AGENT,
        payload: {
          ...agent,
          isFavorite: !agent.isFavorite,
        },
      });
    },
    [dispatch],
  );
};

export const useAnalystById = (agentId: Agent['id']) => {
  const {
    state: { scope },
  } = useAgentsState();

  return useMemo(() => {
    if (!agentId) return null;
    return scope[RetrieveTypesEnum.ALL].agents.get(agentId);
  }, [agentId, scope]);
};

export const useActiveAgent = () => {
  const {
    state: { scope },
  } = useAgentsState();
  const { agentId } = useParams();

  return useMemo(() => {
    const id = Number(agentId) as Agent['id'];
    return scope[RetrieveTypesEnum.ALL].agents.get(id);
  }, [agentId, scope]);
};

export const useUpdateAgent = () => {
  const { dispatch } = useAppContext();
  useAgents();
  return useCallback(
    async (agent: UpdateAgent) => {
      const payload = await NewtonApi.updateAgent(agent);
      dispatch({
        type: AgentActionTypes.FETCH_AGENT,
        payload,
      });
      return payload;
    },
    [dispatch],
  );
};

export const useAgentModels = () => {
  const {
    state: { models },
  } = useAgentsState();
  return models;
};

export const useCreateAgent = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (payload: NewAgent) => {
      const agent = await NewtonApi.createAgent(payload);
      dispatch({
        type: AgentActionTypes.FETCH_AGENT,
        payload: agent,
      });
      return agent;
    },
    [dispatch],
  );
};

export const useToggleAgentEnabled = () => {
  const { dispatch } = useAppContext();
  const updateAgent = useUpdateAgent();
  return useCallback(
    async (agent: NewAgent) => {
      if (agent.id) {
        const payload = await updateAgent({
          ...agent,
          isEnabled: !agent.isEnabled,
        });

        dispatch({
          type: AgentActionTypes.FETCH_AGENT,
          payload,
        });
      }
    },
    [dispatch, updateAgent],
  );
};

export const useManageAccess = () => {
  return (
    agent: Agent['id'],
    permissions: { users?: { id: number; permission: string }[]; organizations?: { id: number; permission: string } },
    ownerId: UserId,
  ) => {
    console.log('### Saving agent access', agent, permissions, ownerId);
  };
};
