import { Flex } from 'antd';
import { UsageSavedTime } from 'components/usage-saved-time/UsageSavedTime';
import styles from './AccountView.module.scss';
import { UsageRemainingCredits } from 'components/usage-remaining-credits/UsageRemainingCredits';
import { useEffect, useState } from 'react';
import { UsageDailyCredits } from 'components/usage-daily-credits/UsageDailyCredits';
import { useCreditsInfo } from 'selectors/useStatisticsSelector';

const getNextMonth = () => {
  const currentDate = new Date();
  const nextMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
  const monthName = nextMonth.toLocaleString('us-US', { month: 'short' });
  const year = nextMonth.toLocaleString('us-US', { year: 'numeric' });
  return `${monthName} 1, ${year}`;
};

export const Usage = () => {
  const { loading: creditsLoading, totalCredits, totalTokens } = useCreditsInfo();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [setLoading]);

  return (
    <div>
      <Flex className={styles.orgLevelSection} justify="space-between" gap={20}>
        <UsageRemainingCredits
          renewDate={getNextMonth()}
          maxValue={totalCredits || 0}
          expendedCredits={totalTokens || 0}
          loading={creditsLoading}
        />
        <UsageSavedTime time={{ hours: 23, minutes: 47 }} loading={loading} />
      </Flex>
      <div className={styles.dailyCredits}>
        <UsageDailyCredits />
      </div>
    </div>
  );
};
