import { DataSourceList } from 'components/datasource-list/DataSourceList';
import { useMemo } from 'react';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useActiveConversation, useConversationMessages } from 'selectors/useConversationSelectors';
import { useConversationDataSources } from 'selectors/useDataSourcesSelector';
import { useModal } from 'selectors/useModals';

export const DataSourcesTab: React.FC = () => {
  const { conversation } = useActiveConversation();
  const { all } = useConversationDataSources(conversation?.id);
  const { saveTempData, temporary } = useConversationMessages(conversation?.id);

  const { show: showSelectDataSourcesModal } = useModal<{
    locked: DataSource[];
    selections: DataSource[];
    onConfirm(dataSources: DataSource[]): void;
  }>(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.SELECT);

  const selectedDataSources = useMemo(() => {
    return [...(temporary?.dataSources || []), ...(all || [])];
  }, [all, temporary]);

  return (
    <DataSourceList
      columns={2}
      entries={selectedDataSources}
      newEntityDetails={null}
      newEntityNameFormat={connector => `${connector} to conversation`}
      projectFilter={false}
      showSegments={false}
      showEmptyPlaceholder={false}
      viewOptions={false}
      onNewEntity={() => {
        showSelectDataSourcesModal({
          locked: all || [],
          selections: temporary?.dataSources || [],
          onConfirm: dataSources => {
            saveTempData({
              dataSources,
            });
          },
        });
      }}
    />
  );
};
