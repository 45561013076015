import { Avatar, ConfigProvider, Flex, Table, TableProps, Tag, Typography } from 'antd';
import styles from './ConversationFeedbacks.module.scss';
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { formatDate } from 'utils/formatDate';
import { ConversationFeedbacksDropdown } from './ConversationFeedbacksDropdown';
import { useFeedbacks } from 'selectors/useFeedbacksSelector';
import { MultipleElements } from 'components/multiple-labels/MultipleElements';

export const ConversationFeedbacks: FC = () => {
  const [selectedRow, setSelectedRow] = useState<undefined | number>();
  const [page, setPage] = useState(1);

  const { loading, results, pagination } = useFeedbacks(page);

  const handleShowMenu = (id: number, action: string) => {
    if (!action) return;

    if (action === 'open') {
      setSelectedRow(id);
    } else {
      setSelectedRow(undefined);
    }
  };

  const columns: TableProps['columns'] = [
    {
      title: 'Conversation',
      dataIndex: 'conversation',
      key: 'conversation',
      width: 200,

      render: value => (
        <Typography.Paragraph
          ellipsis={{ rows: 1 }}
          style={{
            fontSize: '12px',
            margin: 0,
            fontWeight: 'var(--nri-weight-medium)',
            color: 'var(--nri-color-primary)',
          }}
        >
          {value}
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      width: 300,

      render: value => (
        <Typography.Paragraph
          ellipsis={{ rows: 1 }}
          style={{ fontSize: '12px', margin: 0, color: 'var(--nri-color-purple-grey)' }}
        >
          {value}
        </Typography.Paragraph>
      ),
    },

    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      width: 250,
      render: (_, { label }) => (
        <MultipleElements>
          {label?.map((tag: string) => {
            return (
              <Tag className={styles.tag} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </MultipleElements>
      ),
    },
    {
      title: 'Sentiment',
      dataIndex: 'sentiment',
      key: 'sentiment',
      width: 100,
      render: (_, { sentiment }) => {
        const icon =
          sentiment === 2 ? (
            <LikeOutlined className={styles.sentimentIcon} />
          ) : (
            sentiment === 3 && <DislikeOutlined className={styles.sentimentIcon} />
          );
        return <Flex justify="center">{icon}</Flex>;
      },
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      width: 250,
      render: data => (
        <Flex gap={8} align="center">
          <Avatar src={data?.picture || null} size={32} style={{ minWidth: 32 }} />
          <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ fontSize: '14px', margin: 0 }}>
            {data?.fullName}
          </Typography.Paragraph>
        </Flex>
      ),
    },

    {
      title: '',
      dataIndex: 'x',
      key: 'actions',
      width: 60,
      render: data => {
        return (
          <div
            style={{
              width: '25px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {selectedRow === data.index && <ConversationFeedbacksDropdown feedback={data.feedback} />}
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className={styles.conversationFbs}>
      <ConfigProvider
        theme={{
          token: { colorText: 'var(--nri-color-purple-grey)' },
          components: {
            Table: {
              headerColor: 'var(--nri-color-purple-grey)',
              headerBg: 'var(--nri-color-light-grey)',
              headerSplitColor: 'transparent',
              cellPaddingBlock: 16,
              cellPaddingInline: 16,
              cellFontSize: 12,
            },
          },
        }}
      >
        <Table
          onRow={row => {
            return {
              onMouseEnter: () => handleShowMenu(row.id, 'open'),
              onMouseLeave: () => handleShowMenu(row.id, 'close'),
            };
          }}
          tableLayout="fixed"
          loading={loading}
          className={styles.feedbackTable}
          columns={columns}
          dataSource={results?.map((feedback, index) => {
            return {
              id: index,
              conversation: feedback?.conversation?.name,
              comment: feedback?.commentSet?.findLast(f => f)?.text ?? '-',
              date: formatDate(feedback?.createdAt as string),
              label: feedback?.sentiment.labelSet.map(label => label.name),
              sentiment: feedback?.id,
              user: feedback?.user,
              x: { feedback, index },
            };
          })}
          scroll={{ x: 1150 }}
          pagination={{
            total: pagination?.count,
            pageSize: 10,
            current: page,
            onChange: handlePageChange,
          }}
        />
      </ConfigProvider>
    </div>
  );
};
