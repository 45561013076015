import { EntitySelectedItem } from 'components/entity-selected-item/EntitySelectedItem';
import { Flex, Typography } from 'antd';
import styles from './DatasourceSelectedItem.module.scss';
import { EntityPermissionsEnum } from 'types/enum';
import { EntityShareSelect } from 'components/entity-share-select/EntityShareSelect';
import DeleteIcon from 'assets/iconsNewVersion/trash.svg?react';

const items = [
  {
    value: EntityPermissionsEnum.READ,
    label: 'Can view',
  },
  {
    value: EntityPermissionsEnum.WRITE,
    label: 'Can edit',
  },
];

export const DatasourceSelectedItem = ({
  data,
  isOwner,
  onPermissionChange = () => {},
}: {
  data: ShareableEntityProps;
  isOwner?: boolean;
  onPermissionChange?: (value: EntityPermissionsEnum | string, data: ShareableEntity) => void;
}) => {
  return (
    <EntitySelectedItem
      rightSideInfo={
        isOwner ? (
          <Typography.Text className={styles.ownerLabel}>owner</Typography.Text>
        ) : (
          <Flex align="center" gap={8}>
            <EntityShareSelect
              options={items}
              onChange={e => onPermissionChange(e, data)}
              className={styles.permissionsDropdown}
              defaultValue={data.permission}
            />
            <DeleteIcon className={styles.deleteIcon} onClick={() => onPermissionChange('remove', data)} />
          </Flex>
        )
      }
      data={data}
    />
  );
};
