import { PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Flex, Form, Row } from 'antd';
import ArrowUpIcon from 'assets/icons/arrowUp.svg?react';
import ArrowDownIcon from 'assets/icons/arrowDown.svg?react';
import TrashIcon from 'assets/icons/trash.svg?react';
import React from 'react';
import { WorkflowForm } from '../form/WorkflowForm';
import { Button } from 'ui/Button';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';

const WorkflowPlanStep: React.FC = () => {
  return (
    <Form.List name="steps">
      {(fields, { add, remove, move }) => {
        const onDragEnd = (result: DropResult) => {
          const { source, destination } = result;

          if (!destination) {
            return;
          }

          if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return;
          }

          move(source.index, destination.index);
        };

        return (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="workflowStepsDroppable">
                {provided => (
                  <div key="workflow-steps-container" ref={provided.innerRef} {...provided.droppableProps}>
                    {fields.map((field, index) => (
                      <Draggable key={`drag-${field.key}-${index}`} draggableId={`${field.key}-${index}`} index={index}>
                        {(provided, snapshot) => (
                          <Row
                            key={`row-${field.key}-${index}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              boxShadow: snapshot.isDragging ? '0 2px 8px rgba(0,0,0,0.15)' : 'none',
                              marginBottom: 32,
                            }}
                            gutter={[12, 32]}
                          >
                            <Col span={20}>
                              <WorkflowForm field={field} initialCollapsed={false} />
                            </Col>
                            <Col span={4}>
                              {fields.length > 1 && (
                                <Flex align="center" style={{ height: '56px' }}>
                                  <Flex align="center" justify="center" style={{ height: 24 }}>
                                    <ArrowUpIcon height={16} style={{ padding: 0 }} />
                                    <ArrowDownIcon height={16} style={{ padding: 0 }} />
                                  </Flex>

                                  <Divider type="vertical" />
                                  <Button
                                    type="text"
                                    size="small"
                                    icon={<TrashIcon height={16} />}
                                    onClick={() => remove(field.name)}
                                  />
                                </Flex>
                              )}
                            </Col>
                          </Row>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <Row style={{ margin: '22px 0 12px 0' }}>
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Button
              type="text"
              onClick={() =>
                add({
                  name: `Step ${fields.length + 1}`,
                })
              }
              icon={<PlusOutlined />}
            >
              Add step
            </Button>
          </>
        );
      }}
    </Form.List>
  );
};

export default WorkflowPlanStep;
