import { EyeOutlined } from '@ant-design/icons';
import ArchiveIcon from 'assets/icons/archive.svg?react';
import ChatIcon from 'assets/icons/chat.svg?react';
import CopyIcon from 'assets/icons/copy.svg?react';
import EditIcon from 'assets/icons/edit.svg?react';
import DeleteIcon from 'assets/icons/trash.svg?react';
import { urlParameters } from 'hooks/useURLParams';
import { useNavigate } from 'react-router-dom';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useAuth } from 'selectors/useAuthSelector';
import { useModal } from 'selectors/useModals';
import { ActionItem, ActionsMenu } from 'ui/ActionsMenu';

enum BlueprinActions {
  ARCHIVE = 'ARCHIVE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
  // MANAGE_ACCESS = "MANAGE_ACCESS",
  USE = 'USE',
  VIEW_DETAILS = 'VIEW_DETAILS',
}

const items: ActionItem<Blueprint>[] = [
  {
    icon: EyeOutlined,
    label: 'View Details',
    key: BlueprinActions.VIEW_DETAILS,
  },
  {
    icon: EditIcon,
    label: 'Edit',
    key: BlueprinActions.EDIT,
    filter: (e: Blueprint, u: UserEntity) => e.owner === u.id,
  },
  {
    icon: CopyIcon,
    label: <>Duplicate</>,
    key: BlueprinActions.DUPLICATE,
  },
  {
    icon: ChatIcon,
    label: 'Run blueprint',
    key: BlueprinActions.USE,
  },
  // {
  //   icon: ShareHandIcon,
  //   label: "Manage access",
  //   key: BlueprinActions.MANAGE_ACCESS,
  //   filter: (e: Blueprint, u: UserEntity) => e.owner === u.id,
  // },
  {
    icon: ArchiveIcon,
    label: <>Archive</>,
    key: BlueprinActions.ARCHIVE,
    filter: (e: Blueprint, u: UserEntity) => {
      if (e.isLibrary) {
        // only a super user can archive a library blueprint
        return u.isSuperuser;
      }
      return true;
    },
  },
  {
    color: 'var(--nri-color-red)',
    icon: DeleteIcon,
    label: <>Delete</>,
    key: BlueprinActions.DELETE,
    filter: (e: Blueprint, u: UserEntity) => e.owner === u.id,
  },
];

export const BlueprintActionsMenu: React.FC<{ blueprint: IceBreaker }> = ({ blueprint }) => {
  const { show: showArchiveModal } = useModal<{ onConfirm: () => void; blueprint: Blueprint }>(
    ModalScopesEnum.BLUEPRINTS,
    ModalTypesEnum.ARCHIVE,
  );
  const { show: showDeleteModal } = useModal<{ onConfirm: () => void; blueprint: Blueprint }>(
    ModalScopesEnum.BLUEPRINTS,
    ModalTypesEnum.DELETE,
  );
  const { show: showDuplicateModal } = useModal(ModalScopesEnum.BLUEPRINTS, ModalTypesEnum.DUPLICATE);
  const { me } = useAuth();
  const navigate = useNavigate();
  const onActionClick = (key: BlueprinActions) => {
    switch (key) {
      case BlueprinActions.ARCHIVE:
        showArchiveModal({
          onConfirm: () => {},
          blueprint,
        });
        break;

      case BlueprinActions.DELETE:
        showDeleteModal({
          onConfirm: () => {},
          blueprint,
        });
        break;

      case BlueprinActions.DUPLICATE:
        showDuplicateModal(blueprint);
        break;

      case BlueprinActions.EDIT:
        navigate(`/blueprints/${blueprint.id}/edit`);
        break;

      // case BlueprinActions.MANAGE_ACCESS:
      //   showManageAccess(blueprint)
      //   break

      case BlueprinActions.VIEW_DETAILS:
        navigate(`/blueprints/${blueprint.id}`);
        break;

      case BlueprinActions.USE: {
        const urlParams = urlParameters({
          blueprint: blueprint.id,
        });

        navigate(`/conversations/new?${urlParams}`);
        break;
      }
    }
  };

  const filteredItems = items.filter(item => {
    if (item.filter) {
      return item.filter(blueprint, me!);
    }
    return true;
  });

  return <ActionsMenu items={filteredItems} onSelect={({ key }) => onActionClick(key as BlueprinActions)} />;
};
