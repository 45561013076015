import { Dropdown, DropdownProps, Typography } from 'antd';
import { MenuItemType } from 'antd/lib/menu/interface';
import MenuIcon from 'assets/iconsNewVersion/more.svg?react';
import classNames from 'classnames';
import React from 'react';
import styles from 'ui/UIKit.module.scss';

export type ActionItem<T> = Omit<MenuItemType, 'icon'> & {
  color?: string;
  iconColor?: string;
  textColor?: string;
  filter?: (entity: T, user: UserEntity) => boolean;
  // FIXME: Should be React.ComponentType<SVGProps<SVGSVGElement>> but is causing errors in outer components
  icon?: React.ComponentType<{ color?: string }>;
};

type Props<T> = Omit<DropdownProps, 'menu'> & {
  items: ActionItem<T>[];
  iconSize?: number;
  onSelect(stuff: { key: string }): void;
  trigger?: React.ReactNode;
};

export const ActionsMenu = <T,>({ className, iconSize, items, onSelect, children, ...props }: Props<T>) => {
  return (
    <Dropdown
      className={classNames(styles.actionsTrigger, className)}
      rootClassName={styles.actionsMenu}
      menu={{
        items: items.map(({ color, disabled, icon: Icon, iconColor, label, ...item }) => ({
          icon: Icon ? <Icon color={iconColor} /> : undefined,
          style: disabled
            ? { '--icon-color': 'var(--nri-color-disabled-text)' }
            : color
              ? { '--icon-color': iconColor, color }
              : undefined,
          label: label ? (
            <Typography.Text style={{ color: disabled ? 'var(--nri-color-disabled-text)' : color }}>
              {label}
            </Typography.Text>
          ) : undefined,
          ...item,
          disabled,
        })),
        onClick: item => {
          item.domEvent.stopPropagation();
          onSelect(item);
        },
      }}
      placement="bottomRight"
      trigger={['click']}
      {...props}
    >
      {children || (
        <MenuIcon
          style={iconSize ? { width: iconSize, height: iconSize } : undefined}
          onClick={e => e.stopPropagation()}
        />
      )}
    </Dropdown>
  );
};
