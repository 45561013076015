import { Layout, message } from 'antd';
import { Loader } from 'components/loader/Loader';
import { ProjectView } from 'components/project-view/ProjectView';
import { memo, useCallback, useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useProjectLabels, useUpdateProjectLabel } from 'selectors/useProjectLabels';
import styles from './Layout.module.scss';

export type ProjectFormData = {
  name: string;
  description: string;
  color: string;
};

export const ProjectPage = memo(() => {
  const { projectId } = useParams();
  const location = useLocation();
  const isEditMode = location.pathname.endsWith('/edit');
  const { projectLabels } = useProjectLabels();
  const updateProjectLabel = useUpdateProjectLabel();
  const projectLabel = useMemo(
    () => projectLabels.find(label => label.id === Number(projectId)),
    [projectId, projectLabels],
  );
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (values: ProjectFormData) => {
      await updateProjectLabel({
        id: projectLabel?.id as ProjectLabel['id'],
        name: values.name,
        description: values.description,
        color: values.color,
      });

      message.success('Project label updated successfully');
      navigate('/projects');
    },
    [navigate, projectLabel, updateProjectLabel],
  );

  return (
    <Layout className={styles.conversationPage}>
      {projectLabel ? (
        <ProjectView projectLabel={projectLabel} edit={isEditMode} onSubmit={onSubmit} />
      ) : (
        <Loader fullscreen />
      )}
    </Layout>
  );
});
