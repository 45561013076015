import { Flex } from 'antd';
import FileSearchIcon from 'assets/iconsNewVersion/fileSearch.svg?react';
import { BlueprintActionsMenu } from 'components/blueprint-actions-menu/BlueprintActionsMenu';
import { EntityCard } from 'components/entity-list/EntityCard';
import { BaseEntityCardProps } from 'components/entity-list/EntityList';
import { FavoriteToggle } from 'components/favorite-toggle/FavoriteToggle';
import { ProjectLabels } from 'components/project-labels/ProjectLabels';
import { ShareList } from 'components/share-list/ShareList';
import { urlParameters } from 'hooks/useURLParams';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCategoriesById } from 'selectors/useBlueprints';
import { Button } from 'ui/Button';
import { highlightText } from 'utils/highlightText';

type BlueprintCardProps = BaseEntityCardProps<IceBreaker> & {
  showCategory?: boolean;
  showFavorite?: boolean;
  showOwner?: boolean;
};

export const BlueprintCard = ({
  entity: blueprint,
  isSelected,
  search = '',
  showCategory = false,
  showOwner = true,
  showFavorite = false,
}: BlueprintCardProps) => {
  const { name, description } = blueprint;
  const navigate = useNavigate();
  const categoriesById = useCategoriesById();
  const categories = categoriesById(blueprint.useCaseCategories);
  // Handle card click to use the blueprint
  const handleCardClick = useCallback(() => {
    navigate(
      `/conversations/new?${urlParameters({
        blueprint: blueprint.id,
      })}`,
    );
  }, [navigate, blueprint.id]);

  const viewDetails = useCallback(() => {
    navigate(`/blueprints/${blueprint.id}`);
  }, [navigate, blueprint.id]);

  return (
    <EntityCard
      actionsMenu={<BlueprintActionsMenu blueprint={blueprint} />}
      avatar={showOwner && <ShareList owner={blueprint.owner} />}
      description={highlightText(description, search)}
      descriptionLines={2}
      footer={
        <>
          <Flex gap="8px" align="center" justify="flex-start" wrap="wrap">
            <Flex>
              Connectors:&nbsp;<strong>{blueprint?.dataSources?.length}</strong>
            </Flex>
            <ProjectLabels projectLabels={blueprint.projectLabels} />
          </Flex>
          <Button
            onClick={e => {
              e.stopPropagation();
              viewDetails();
            }}
            type="text"
          >
            View details
          </Button>
        </>
      }
      icon={
        showFavorite && blueprint.isFavorite ? (
          <FavoriteToggle iconSize={20} isFavorite={blueprint.isFavorite!} />
        ) : null
      }
      selected={isSelected}
      title={highlightText(name, search)}
      titleLines={2}
      tags={showCategory && categories?.[0] ? [{ label: categories?.[0]?.name, icon: <FileSearchIcon /> }] : undefined}
      onClick={handleCardClick} // Attach the click handler to the card
    />
  );
};
