import { EntityDetails } from 'components/entity-details/EntityDetails';
import { EntityHeader } from 'components/entity-header/EntityHeader';
import { EntityProvider } from 'components/layouts/context/EntityProvider';
import { memo } from 'react';
import { ProjectForm } from 'components/project-form/ProjectForm';
import { EntityActionsEnum } from 'types/enum';
import DeleteIcon from '../../assets/iconsNewVersion/trash.svg?react';
import classNames from 'classnames';
import styles from './ProjectView.module.scss';
import { useModal } from 'selectors/useModals';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';

type ProjectViewProps = {
  projectLabel: ProjectLabel;
  edit?: boolean;
  onSubmit: (values: ProjectFormData) => Promise<void>;
};

export type ProjectFormData = {
  name: string;
  description: string;
  color: string;
};

export const ProjectView = memo(({ projectLabel, edit, onSubmit }: ProjectViewProps) => {
  const { show: showDeleteProjectModal } = useModal(ModalScopesEnum.PROJECTS, ModalTypesEnum.DELETE, '/projects');
  const steps = [
    {
      title: 'General',
      key: 'general',
      description: '',
      content: <ProjectForm editable={edit} form={undefined} project={projectLabel} />,
    },
  ];

  const projectActions = [
    {
      key: EntityActionsEnum.DELETE,
      onClick: () => showDeleteProjectModal(projectLabel),
      icon: <DeleteIcon className={classNames(styles.deleteIcon)} />,
      disabled: false,
    },
  ];

  return (
    <EntityProvider<ProjectFormData>
      steps={steps}
      initialFormValue={{
        name: projectLabel.name,
        description: projectLabel.description ?? '',
        color: projectLabel.color,
      }}
      isNew={false}
      readOnly={!edit}
      onSubmit={onSubmit}
    >
      <EntityHeader isEditable={false} canGoBack actions={projectActions} title={projectLabel.name} />
      <EntityDetails<ProjectFormData> isEditable={edit} entityName="project" />
    </EntityProvider>
  );
});
