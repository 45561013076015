import { ConfigProvider, Radio } from 'antd';
import styles from './EntityList.module.scss';
import ListViewIcon from '../../assets/iconsNewVersion/list_view.svg?react';
import CardViewIcon from '../../assets/iconsNewVersion/card_view.svg?react';
import { ViewOptionsEnum } from 'types/enum';
import { Dispatch, FC, SetStateAction } from 'react';

type EntityViewOptionsProps = {
  view: ViewOptionsEnum;
  setView: Dispatch<SetStateAction<ViewOptionsEnum>>;
};

export const EntityViewOptions: FC<EntityViewOptionsProps> = ({ view, setView }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryActive: 'transparent',
        },
        components: {
          Radio: {
            buttonBg: 'var(--nri-color-light-grey)',
            buttonCheckedBg: 'var(--nri-color-light-grey)',
            buttonColor: 'none',
            borderRadius: 10,
          },
        },
      }}
    >
      <Radio.Group className={styles.viewOptions} onChange={e => setView(e.target.value)} value={view}>
        <Radio.Button className={styles.viewOptionsButton} value={ViewOptionsEnum.CARD}>
          <CardViewIcon className={styles.viewOptionsIcon} />
        </Radio.Button>
        <Radio.Button className={styles.viewOptionsButton} value={ViewOptionsEnum.LIST}>
          <ListViewIcon className={styles.viewOptionsIcon} />
        </Radio.Button>
      </Radio.Group>
    </ConfigProvider>
  );
};
