import { message } from 'antd';
import { useAppContext } from 'contexts/AppProviders';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ConversationContainer, ConversationsActionTypes, TemporaryMessage } from 'reducers/conversationsReducer';
import { DataSourcesActionTypes } from 'reducers/dataSourcesReducer';
import { CommonStatusEnum, ConversationViewEnum, DisplayRoles, Roles } from 'types/enum';
import { NewtonApi } from 'utils/newtonApi';
import { useAnalystByName, useAnalysts } from './useAnalystsSelector';
import { useAuth } from './useAuthSelector';
import { useDataSourceByConversationIdMessageId } from './useDataSourcesSelector';

const ViewToRole: Record<ConversationViewEnum, (message: Message) => boolean> = {
  [ConversationViewEnum.ALL]: (message: Message) => {
    const roles = [Roles.USER, Roles.ASSISTANT, Roles.USER_PROXY];
    return roles.includes(message.role as RoleTypes);
  },
  [ConversationViewEnum.DATA_SOURCES]: (message: Message) => {
    const roles = [Roles.USER, Roles.ASSISTANT, Roles.USER_PROXY];
    return roles.includes(message.role as RoleTypes);
  },
  [ConversationViewEnum.EXECUTIVE]: (message: Message) => {
    const roles = [Roles.USER, Roles.SUMMARY];
    const displayRoles = [DisplayRoles.COORDINATOR_AGENT, DisplayRoles.USER, DisplayRoles.MARKETING_ANALYST_AGENT];
    if (message.displayRole) {
      return displayRoles.includes(message.displayRole ?? '');
    } else {
      return roles.includes(message.role as RoleTypes);
    }
  },
  [ConversationViewEnum.NOTES]: (message: Message) => {
    const roles = [Roles.USER, Roles.ASSISTANT, Roles.USER_PROXY];
    return roles.includes(message.role as RoleTypes);
  },
};

// Base Loader
const useConversationsState = () => {
  const {
    state: { conversationsState },
    dispatch,
  } = useAppContext();
  const { status } = conversationsState;

  useEffect(() => {
    if (status === CommonStatusEnum.INITIAL) {
      (async () => {
        dispatch({
          type: ConversationsActionTypes.FETCHING_CONVERSATIONS,
        });
        const { results: conversations, ...pagination } = await NewtonApi.fetchAllConversations({
          sort: '-updated_at',
          retrieve_type: 'all',
        });

        dispatch({
          type: ConversationsActionTypes.FETCH_CONVERSATIONS,
          payload: { conversations, pagination },
        });
      })();
    }
  }, [dispatch, status]);

  return { state: conversationsState };
};

export const useConversationContainer = () => {
  const {
    state: { conversationsState },
  } = useAppContext();

  return useCallback(
    (id: Conversation['id']): (ConversationContainer & { loading: boolean }) | undefined => {
      if (id) {
        const container = conversationsState.conversations.get(id) || conversationsState.archived.conversations.get(id);

        if (container) {
          return {
            ...container,
            loading: container.status === CommonStatusEnum.FETCHING,
          };
        }
      }
      return undefined;
    },
    [conversationsState.archived.conversations, conversationsState.conversations],
  );
};

// Returns a function that selects a conversation by id
// Deals with any preloading of conversation data that might need to take place
export const useConversation = () => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const getConversationContainer = useConversationContainer();
  const { conversationId } = useParams();
  const { pathname } = useLocation();

  return useCallback(
    async (id: Conversation['id'], { skipNavigate = false }: { skipNavigate?: boolean } = {}) => {
      const conversationWrapper = getConversationContainer(id);
      const newConvoSelected = id !== Number(conversationId) && id;
      const currentConvoNotLoaded = conversationWrapper?.status === CommonStatusEnum.INITIAL || !conversationWrapper;

      if (newConvoSelected || currentConvoNotLoaded) {
        if (currentConvoNotLoaded) {
          (async () => {
            dispatch({
              type: ConversationsActionTypes.FETCHING_CONVERSATION,
              payload: id,
            });
            try {
              const [conversation, dataSourceMessageContainers, permissions] = await Promise.all([
                NewtonApi.fetchConversation(id, {}, true),
                NewtonApi.fetchConversationDataSources(id),
                NewtonApi.fetchConversationPermissions({
                  conversation_id: id,
                }),
              ]);

              dispatch({
                type: DataSourcesActionTypes.FETCH_CONVERSATION_DATASOURCES,
                payload: {
                  conversationId: id,
                  dataSources: conversation.dataSources,
                  dataSourceMessageContainers,
                },
              });
              await dispatch({
                type: ConversationsActionTypes.FETCH_CONVERSATION,
                payload: conversation,
              });

              await dispatch({
                type: ConversationsActionTypes.FETCH_CONVERSATION_PERMISSIONS,
                payload: {
                  conversationId: id,
                  permissions: permissions.results,
                },
              });

              if (!skipNavigate) {
                if (!pathname.includes(`/conversations/${id}`)) {
                  navigate(`/conversations/${id}`);
                }
              }
            } catch (error) {
              if ((error as Response)?.status === 404) {
                navigate('/conversations');
                message.error(`Conversation ${id} not found`);
              } else {
                message.error('Failed to load conversation');
              }
            }
          })();
        } else {
          if (!skipNavigate) {
            if (!pathname.includes(`/conversations/${id}`)) {
              navigate(`/conversations/${id}`);
            }
          }
        }
      }
    },
    [conversationId, dispatch, getConversationContainer, navigate, pathname],
  );
};

export const useArchivedConversations = () => {
  const { dispatch } = useAppContext();
  const {
    state: { conversationsState },
  } = useAppContext();

  return {
    fetch: useCallback(
      async (next?: boolean) => {
        const nextPage =
          conversationsState.archived.pagination.next !== null
            ? new URLSearchParams(conversationsState.archived.pagination.next)
            : undefined;

        !next &&
          dispatch({
            type: ConversationsActionTypes.FETCHING_ARCHIVED_CONVERSATIONS,
          });

        const { results: conversations, ...pagination } = await NewtonApi.fetchAllConversations({
          sort: '-updated_at',
          retrieve_type: 'all',
          status: 'archived',
          page_size: '50',
          ...(next && nextPage && { page: nextPage.get('page') }),
        });
        dispatch({
          type: ConversationsActionTypes.FETCH_ARCHIVED_CONVERSATIONS,
          payload: { conversations, pagination },
        });
      },
      [dispatch, conversationsState],
    ),
    conversations: Array.from(conversationsState.archived.conversations.values()).map(
      ({ conversation }: ConversationContainer) => conversation,
    ),
    status: conversationsState.archived.status,
    showLoadMoreConversations: conversationsState.archived.pagination.next !== null,
    loading:
      conversationsState.archived.status === CommonStatusEnum.INITIAL ||
      conversationsState.archived.status === CommonStatusEnum.FETCHING,
  };
};

// Returns a function that returns all the conversations, and current status
export const useConversations = () => {
  const {
    state: { conversations, status, pagination },
  } = useConversationsState();

  return {
    conversations: Array.from(conversations.values()).map(({ conversation }: ConversationContainer) => conversation),
    status,
    loading: status === CommonStatusEnum.INITIAL || status === CommonStatusEnum.FETCHING,
    showLoadMoreConversations: pagination.next !== null,
  };
};

export const useLoadMoreConversations = () => {
  const {
    dispatch,
    state: { conversationsState },
  } = useAppContext();

  const { pagination: currentPagination } = conversationsState;

  return useCallback(async () => {
    if (currentPagination.next) {
      const urlObj = new URL(currentPagination.next);
      const urlSearch = new URLSearchParams(urlObj.search);
      const params: Record<string, string> = {};

      Array.from(urlSearch.entries()).forEach(([key, value]) => {
        params[key] = value;
      });

      const { results: conversations, ...pagination } = await NewtonApi.fetchAllConversations(params);

      dispatch({
        type: ConversationsActionTypes.FETCH_CONVERSATIONS,
        payload: { conversations, pagination },
      });
    }
  }, [dispatch, currentPagination.next]);
};

// Returns the current conversation based on the URL params, as well as its status
export const useActiveConversation = () => {
  const {
    state: { conversationsState },
  } = useAppContext();

  const getConversationContainer = useConversationContainer();
  const { conversationId: convoId } = useParams();
  const conversationId = Number(convoId);

  const base = useMemo(
    () => ({
      conversation: undefined,
      notifications: [],
      loading: undefined,
      permissions: [],
      status: CommonStatusEnum.INITIAL,
      streaming: false,
      temporary: undefined,
      unreadMessages: [],
    }),
    [],
  );

  return useMemo(() => {
    if (!convoId) return base;
    return getConversationContainer(conversationId as Conversation['id']) || base;
  }, [base, conversationId, getConversationContainer, conversationsState]);
};

const useConversationStopStreaming = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (conversationId: Conversation['id']) => {
      await NewtonApi.stopConversation(conversationId);

      dispatch({
        type: ConversationsActionTypes.STOP_STREAMING,
        payload: conversationId,
      });
    },
    [dispatch],
  );
};

const useConversationSendMessage = () => {
  const { analysts, defaultAnalyst: globalAnalyst } = useAnalysts();
  const { dispatch } = useAppContext();
  const getConversationContainer = useConversationContainer();
  const { conversationId: id } = useParams();

  const conversationId = Number(id) as Conversation['id'];
  const conversation = getConversationContainer(conversationId)?.conversation;
  const messageSet = conversation?.messageSet || [];
  const lastAnalyst = messageSet[messageSet.length - 1]?.analyst;
  const convoAnalyst = analysts.find(({ name }) => name === lastAnalyst);

  const defaultAnalyst = convoAnalyst || globalAnalyst;

  return useCallback(
    async (payload: Partial<NewMessage> & { text: string }) => {
      const convoId = conversationId || payload.conversationId;
      // Analyst is now always sent on messages. We base determine it in the following order.
      // 1. User selection
      // 2. Prior message
      // 3. Default analyst
      const analystId = payload?.analystId || defaultAnalyst!.id;

      if (conversation?.ownerStatus === 'shared') {
        message.error('You cannot send messages to a shared conversation.');
        return;
      }

      dispatch({
        type: ConversationsActionTypes.START_STREAMING,
        payload: convoId,
      });

      if (payload.dataSources?.length) {
        dispatch({
          type: DataSourcesActionTypes.FETCH_CONVERSATION_DATASOURCES,
          payload: {
            conversationId: convoId,
            dataSources: payload.dataSources,
            dataSourceMessageContainers: [],
          },
        });
      }

      dispatch({
        type: ConversationsActionTypes.INCOMING_STREAMING_MESSAGE,
        payload: {
          conversationId: convoId,
          message: {
            ...payload,
            createdAt: new Date().toISOString(),
            displayRole: null,
            role: 'user',
            analystId,
            parentMessageId: conversation?.messageSet.findLast((x: Message) => x)?.id || null,
          },
        },
      });

      await NewtonApi.newMessage({
        conversationId: convoId,
        dataSources: (payload.dataSources || []).map((ds: DataSource) => ds.id),
        iceBreakerId: payload.iceBreakerId,
        message: {
          ...payload,
          analystId,
          parentMessageId: conversation?.messageSet.findLast((x: Message) => x)?.id || null,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
      });
    },
    [conversation, conversationId, defaultAnalyst, dispatch],
  );
};

// Returns the messages of the current conversation filtering out internal messages
export const useConversationMessages = (conversationId: Conversation['id'] | undefined) => {
  const { dispatch } = useAppContext();
  const { activeTab } = useConversationPageView();
  const getConversationContainer = useConversationContainer();
  let container: ConversationContainer | undefined = undefined;
  let conversation: Conversation | undefined = undefined;

  if (conversationId) {
    container = getConversationContainer(conversationId);
    conversation = container?.conversation;
  }

  const saveTempData = useCallback(
    async (data?: TemporaryMessage) => {
      dispatch({
        type: ConversationsActionTypes.SAVE_PROMPT,
        payload: {
          conversationId: conversationId!,
          data,
        },
      });
    },
    [conversationId, dispatch],
  );

  return {
    loading: useMemo(() => {
      if (!container) return true;
      return container?.status === CommonStatusEnum.FETCHING;
    }, [container]),
    markAsRead: useMarkConversationAsRead(),
    messages: useMemo(() => {
      if (!conversation) return [];
      return (conversation?.messageSet || []).filter(message => {
        return ViewToRole[activeTab](message);
      });
    }, [activeTab, conversation]),
    sendMessage: useConversationSendMessage(),
    streaming: useMemo(() => {
      if (!container) return false;
      return container?.streaming;
    }, [container]),
    stopStreaming: useConversationStopStreaming(),
    saveTempData,
    temporary: useMemo(() => {
      if (!container) return undefined;
      return container?.temporary;
    }, [container]),
  };
};

export const useConversationPageView = () => {
  const {
    dispatch,
    state: { conversationsState },
  } = useAppContext();
  const {
    page: { activeTab },
  } = conversationsState;

  const setActiveTab = useCallback(
    (tab: ConversationViewEnum) => {
      dispatch({
        type: ConversationsActionTypes.SET_PAGE_ACTIVE_TAB,
        payload: tab,
      });
    },
    [dispatch],
  );

  return {
    activeTab,
    setActiveTab,
  };
};

export const useUpdateConversation = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (payload: Partial<Conversation> & { id: Conversation['id'] }) => {
      dispatch({
        type: ConversationsActionTypes.UPDATING_CONVERSATION,
        payload: payload.id,
      });

      const data = await NewtonApi.updateConversation(payload);
      dispatch({
        type: ConversationsActionTypes.UPDATE_CONVERSATION,
        payload: data,
      });
    },
    [dispatch],
  );
};

export const useCreateConversation = () => {
  const { me } = useAuth();
  const loadConversation = useConversation();

  return useCallback(
    async (payload: Partial<Conversation>, skipLoad: boolean = false, skipNavigate: boolean = false) => {
      const date = new Date();
      const text = payload?.name || 'New Conversation';
      const convo = {
        createdAt: date.toISOString(),
        dataSources: [],
        description: payload?.description,
        name: text.substring(0, 30),
        messageSet: [],
        updatedAt: date.toISOString(),
        owner: me!.id,
        ...payload,
      };

      const data = await NewtonApi.createConversation(convo);
      if (!skipLoad) {
        await loadConversation(data.id, {
          skipNavigate,
        });
      }
      return data;
    },
    [loadConversation, me],
  );
};

export const useArchiveConversation = (
  id?: Conversation['id'],
  { surpressMessage = false }: { surpressMessage?: boolean } = {},
) => {
  const { dispatch } = useAppContext();

  return useCallback(
    async (conversationId?: Conversation['id']) => {
      const targetId = (conversationId || id) as Conversation['id'];
      try {
        await NewtonApi.archiveConversation(targetId, true);
      } catch (error) {
        message.error('Failed to archive conversation');
        return;
      }
      dispatch({
        type: ConversationsActionTypes.ARCHIVE_CONVERSATION,
        payload: targetId,
      });
      if (!surpressMessage) {
        message.success('Conversation archived successfully');
      }
    },
    [dispatch, id, surpressMessage],
  );
};

export const useDeleteConversation = (
  id?: Conversation['id'],
  { skipNavigate = false, surpressMessage = false }: { skipNavigate?: boolean; surpressMessage?: boolean } = {},
) => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  const { conversationId } = useParams();
  return useCallback(
    async (convoId?: Conversation['id']) => {
      const targetId = (convoId || id) as Conversation['id'];
      try {
        await NewtonApi.deleteConversation(targetId, true);
      } catch (error) {
        message.error('Failed to delete conversation');
        return;
      }
      dispatch({
        type: ConversationsActionTypes.DELETE_CONVERSATION,
        payload: targetId,
      });
      if (!surpressMessage) {
        message.success('Conversation deleted successfully');
      }

      if (conversationId && Number(conversationId) === targetId && !skipNavigate) {
        navigate('/conversations');
      }
    },
    [conversationId, dispatch, id, navigate, skipNavigate, surpressMessage],
  );
};

export const useFavoriteConversation = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (conversation: Conversation) => {
      try {
        await NewtonApi.toggleConversationFavorite(conversation.id, !conversation.isFavorite);
      } catch (e) {
        message.error('Failed to favorite blueprint');
        return;
      }
      dispatch({
        type: ConversationsActionTypes.FETCH_CONVERSATION,
        payload: {
          ...conversation,
          isFavorite: !conversation.isFavorite,
        },
      });
    },
    [dispatch],
  );
};

export const useConversationStatus = (id: ConversationId) => {
  const getConversationContainer = useConversationContainer();
  const container = getConversationContainer(id);
  return {
    lastUpdate: useMemo(() => {
      const convo = container?.conversation;
      if (convo) return convo?.messageSet[convo?.messageSet?.length]?.createdAt;
      return '';
    }, [container]),
    notifications: useMemo(() => {
      return container?.notifications;
    }, [container]),
    streaming: useMemo(() => {
      return container?.streaming;
    }, [container]),
    unread: useMemo(() => container?.unreadMessages, [container]),
  };
};

export const useMarkConversationAsRead = () => {
  const { dispatch } = useAppContext();
  return useCallback(
    async (conversationId: Conversation['id']) => {
      dispatch({
        type: ConversationsActionTypes.MARK_CONVERSATION_AS_READ,
        payload: conversationId,
      });
    },
    [dispatch],
  );
};

export const useRewindConversation = () => {
  const { dispatch } = useAppContext();
  const getConversationContainer = useConversationContainer();
  const getAnalystByName = useAnalystByName();
  const getDataSourceByConversationIdMessageId = useDataSourceByConversationIdMessageId();

  return useCallback(
    async (conversationId: ConversationId, messageId: MessageId) => {
      const convoWrapper = getConversationContainer(conversationId);
      const message = convoWrapper?.conversation?.messageSet.find(m => m.id === messageId);
      const analyst = getAnalystByName(message?.analyst);
      const dataSources = getDataSourceByConversationIdMessageId(conversationId, messageId);
      dispatch({
        type: ConversationsActionTypes.FETCHING_CONVERSATION,
        payload: conversationId,
      });
      await NewtonApi.rewindConversation(conversationId, messageId);
      dispatch({
        type: ConversationsActionTypes.REWIND_CONVERSATION,
        payload: { conversationId, messageId },
      });
      if (message) {
        dispatch({
          type: ConversationsActionTypes.SAVE_PROMPT,
          payload: {
            conversationId,
            data: {
              analyst,
              dataSources,
              prompt: { text: message.text },
            },
          },
        });
      }
    },
    [dispatch, getAnalystByName, getConversationContainer, getDataSourceByConversationIdMessageId],
  );
};

export const useShareConversation = () => {
  const { dispatch } = useAppContext();
  return useCallback(async (conversationId: ConversationId, entities: ShareableEntity[]) => {
    await NewtonApi.shareConversation(conversationId, entities);

    dispatch({
      type: ConversationsActionTypes.FETCH_CONVERSATION_PERMISSIONS,
      payload: {
        conversationId,
        permissions: entities,
      },
    });
    message.success('Conversation permissions updated successfully');
  }, []);
};

export const useDuplicateConversation = (id: Conversation['id']) => {
  const { dispatch } = useAppContext();
  return useCallback(async () => {
    const copiedConversation = await NewtonApi.copyConversation(id);
    const newConversation = await NewtonApi.fetchConversation(copiedConversation.id);
    dispatch({
      type: ConversationsActionTypes.FETCH_CONVERSATION,
      payload: newConversation,
    });
  }, [dispatch, id]);
};
