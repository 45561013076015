import { Flex, Layout, Typography } from 'antd';
import styles from './ProjectsView.module.scss';
import { InputSearch } from 'ui/InputSearch';

export const ProjectsHeader = () => {
  return (
    <Layout.Header className={styles.header}>
      <Flex align="center" justify="space-between" className={styles.headerContent}>
        <Typography className={styles.headerTitle}>Projects</Typography>
        <InputSearch className={styles.searchInput} options={[]} onOptionSelect={() => {}} />
      </Flex>
    </Layout.Header>
  );
};
