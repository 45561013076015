import { Spin, SpinProps } from "antd";
import classNames from "classnames";
import styles from "components/loader/Loader.module.scss";

type Props = SpinProps & {
  disabled?: boolean;
  /** Fill vertical height of parent container */
  fillVertical?: boolean;
  full?: boolean;
};

export const Loader: React.FC<Props> = ({
  className,
  disabled,
  fillVertical,
  wrapperClassName,
  ...props
}) => (
  <Spin
    className={classNames(
      styles.spinner,
      { [styles.disabled]: disabled, [styles.fillVertical]: fillVertical },
      className,
    )}
    size="large"
    wrapperClassName={classNames(
      styles.spinnerWrapper,
      { [styles.fillVertical]: fillVertical },
      wrapperClassName,
    )}
    {...props}
  />
);
