import { message } from 'antd';
import { useAuth as useAuthContext } from 'contexts/AuthContext';
import { Dispatch, useCallback, useRef, useEffect, useState } from 'react';
import { ConversationsActionTypes } from 'reducers/conversationsReducer';
import { DataSourcesActionTypes } from 'reducers/dataSourcesReducer';
import { Actions } from 'types/actionTypes';
import { NewtonApi } from 'utils/newtonApi';

let reconnectTimeout: NodeJS.Timeout;
const KEEP_ALIVE_TIMEOUT = 5 * 60 * 1000; // 5 minutes
const KEEP_ALIVE_CHECK_INTERVAL = 60 * 1000; // 1 minute
const MAX_RETRIES = 3;
const RETRY_DELAY = 10000; // 10 seconds

export const useAuth = () => {
  const {
    state: { authenticated, loading, user },
  } = useAuthContext();

  return { authenticated, loading, me: user };
};

const activeStreamingConversations: Record<ConversationId, boolean> = {};
const fetchCompletedStreams: ConversationId[] = [];

export const useStreamListener = (dispatch: Dispatch<Actions>) => {
  const {
    state: { user },
  } = useAuthContext();

  const [shouldReconnect, setShouldReconnect] = useState(false);
  const [retries, setRetries] = useState(0);

  const lastKeepAliveRef = useRef<number>(Date.now());
  const keepAliveTimeoutRef = useRef<NodeJS.Timeout>();
  const eventSourceRef = useRef<EventSource>();

  // Add effect to fetch streaming conversations on mount
  useEffect(() => {
    const fetchStreamingConversations = async () => {
      if (user) {
        try {
          const priorActivelyStreaming = activeStreamingConversations;
          const streamingConversations = await NewtonApi.streamingConversations();
          Object.keys(priorActivelyStreaming).forEach(conversationId => {
            const convoId = Number(conversationId) as ConversationId;
            if (!streamingConversations.includes(convoId))
              dispatch({
                type: ConversationsActionTypes.STOP_STREAMING,
                payload: convoId,
              });
            fetchCompletedStreams.push(convoId);
          });
          // Update conversations that are currently streaming
          streamingConversations.forEach(conversationId => {
            activeStreamingConversations[conversationId] = true;
            dispatch({
              type: ConversationsActionTypes.START_STREAMING,
              payload: conversationId,
            });
          });
        } catch (error) {
          console.error('Failed to fetch streaming conversations:', error);
        }
      }
    };

    fetchStreamingConversations();
  }, [user, dispatch]);

  const fetchActiveConvoData = useCallback(() => {
    const convoIds = [...fetchCompletedStreams, ...Object.keys(activeStreamingConversations)];

    convoIds.map(async convoId => {
      const id = convoId as unknown as ConversationId;
      if (!id) {
        console.warn('### Attempted to re-fetch data thats missing', {
          fetchCompletedStreams,
          activeStreamingConversations,
        });
      } else {
        const [conversation, dataSourceMessageContainers, permissions] = await Promise.all([
          NewtonApi.fetchConversation(id, {}, true),
          NewtonApi.fetchConversationDataSources(id),
          NewtonApi.fetchConversationPermissions({
            conversation_id: id,
          }),
        ]);

        dispatch({
          type: DataSourcesActionTypes.FETCH_CONVERSATION_DATASOURCES,
          payload: {
            conversationId: id,
            dataSources: conversation.dataSources,
            dataSourceMessageContainers,
          },
        });
        await dispatch({
          type: ConversationsActionTypes.FETCH_CONVERSATION,
          payload: conversation,
        });

        await dispatch({
          type: ConversationsActionTypes.FETCH_CONVERSATION_PERMISSIONS,
          payload: {
            conversationId: id,
            permissions: permissions.results,
          },
        });
      }
    });
  }, [dispatch]);

  const checkKeepAlive = useCallback(() => {
    const timeSinceLastKeepAlive = Date.now() - lastKeepAliveRef.current;
    if (timeSinceLastKeepAlive > KEEP_ALIVE_TIMEOUT) {
      console.warn('### Keep-alive timeout exceeded, restarting stream...');
      setShouldReconnect(true);
    }
  }, []);

  const notify = useCallback(
    (streamEvent: StreamEvents | string) => {
      const { event, payload, type } = streamEvent as StreamEvents;

      switch (type) {
        case 'conversation': {
          if (event === 'streaming-complete') {
            delete activeStreamingConversations[payload.id];
            dispatch({
              type: ConversationsActionTypes.STOP_STREAMING,
              payload: payload.id,
            });
          }
          if (event === 'created') {
            dispatch({
              type: ConversationsActionTypes.FETCH_CONVERSATION,
              payload: {
                ...payload,
                messageSet: [],
              },
            });
          }
          if (event === 'updated') {
            dispatch({
              type: ConversationsActionTypes.UPDATE_CONVERSATION,
              payload,
            });
          }
          if (event === 'deleted') {
            dispatch({
              type: ConversationsActionTypes.DELETE_CONVERSATION,
              payload: payload.id,
            });
          }
          break;
        }
        case 'message': {
          if (event === 'created') {
            dispatch({
              type: ConversationsActionTypes.INCOMING_STREAMING_MESSAGE,
              payload: {
                conversationId: payload.conversationId!,
                message: payload,
              },
            });

            dispatch({
              type: ConversationsActionTypes.START_STREAMING,
              payload: payload.conversationId!,
            });
            activeStreamingConversations[payload.conversationId!] = true;

            if (payload.dataSources?.length) {
              dispatch({
                type: DataSourcesActionTypes.FETCH_CONVERSATION_DATASOURCES,
                payload: {
                  conversationId: payload.conversationId!,
                  dataSources: payload.dataSources,
                  dataSourceMessageContainers: [
                    {
                      id: payload.id,
                      dataSources: payload.dataSources as DataSource[],
                    },
                  ],
                },
              });
            }
          }

          break;
        }
      }

      // We received some notification so we can say we are re-connected
      if (event === 'error') {
        console.warn('### Stream connection failed, triggering reconnect...');
        setShouldReconnect(true);
        return;
      } else {
        lastKeepAliveRef.current = Date.now();
        setShouldReconnect(false);
      }
    },
    [dispatch],
  );

  const startStreamListener = useCallback(async () => {
    if (user) {
      // Kill existing event source
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = undefined;
      }

      // Reset keep alive timer
      if (keepAliveTimeoutRef.current) {
        clearInterval(keepAliveTimeoutRef.current);
      }

      // Start keep alive timer
      keepAliveTimeoutRef.current = setInterval(checkKeepAlive, KEEP_ALIVE_CHECK_INTERVAL);

      try {
        const eventSource = await NewtonApi.streamListener(user.uuid, notify);
        eventSourceRef.current = eventSource as EventSource;
      } catch (error) {
        setShouldReconnect(true);
      }
    }
  }, [user, notify, checkKeepAlive]);

  // Reconnection logic, should get triggered by keep alive events and errors.
  useEffect(() => {
    const attemptReconnect = async () => {
      if (retries === 0) {
        message.error({
          content: 'Lost connection to server. Attempting to reconnect...',
          key: 'connection-error',
          duration: 0, // Message will persist until manually destroyed
        });
      }

      if (retries < MAX_RETRIES) {
        const nextRetry = retries + 1;
        console.warn(`### Stream connection failed. Retrying in 10 seconds... (Attempt ${nextRetry}/${MAX_RETRIES})`);
        message.loading({
          content: `Lost connection to the server. Attempting to reconnect... (Attempt ${nextRetry}/${MAX_RETRIES})`,
          key: 'connection-error',
          duration: 0,
        });

        reconnectTimeout = setTimeout(async () => {
          setRetries(retry => retry + 1);

          try {
            await startStreamListener();
          } catch (e) {
            console.error('### Stream connection failed', e);
          }
        }, RETRY_DELAY);
      } else {
        message.error({
          content: 'Lost connection to server',
          key: 'connection-error',
          duration: 0,
        });
        console.error('### Max retries exceeded, redirecting to login...');
        window.location.href = '/login';
      }
    };

    if (shouldReconnect) {
      attemptReconnect();
    } else {
      if (retries > 0) {
        message.destroy();
        message.success({
          content: 'Connection to server re-established',
          key: 'connection-error',
        });
        fetchActiveConvoData();
      }
      setRetries(0);
      clearTimeout(reconnectTimeout);
    }

    return () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
    };
  }, [fetchActiveConvoData, retries, shouldReconnect, startStreamListener]);

  useEffect(() => {
    return () => {
      if (keepAliveTimeoutRef.current) {
        clearInterval(keepAliveTimeoutRef.current);
      }
      clearTimeout(reconnectTimeout);
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = undefined;
      }
    };
  }, []);

  return { startListener: startStreamListener, isConnected: !shouldReconnect };
};
