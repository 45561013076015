import { Avatar, Flex } from 'antd';
import { PlusIcon } from '@heroicons/react/24/outline';
import { IconButton } from 'ui/IconButton';
import styles from './EntityAccess.module.scss';
import classNames from 'classnames';

export type EntityAccessProps = {
  owner: UserEntity;
  sharedUsers: ShareableEntity[];
  onAdd?: () => void;
  maxVisibleUsers?: number;
};

export const EntityAccess: React.FC<EntityAccessProps> = ({ owner, sharedUsers, onAdd, maxVisibleUsers = 3 }) => {
  if (!owner) return null;

  return (
    <Flex align="center" gap={4} justify="space-between" className={styles.container}>
      <Avatar className={styles.avatar} src={owner.picture} size={32}>
        {!owner.picture && owner.name.charAt(0).toUpperCase()}
      </Avatar>
      <span style={{ fontSize: 18, fontWeight: 300 }}>/</span>
      <Flex align="center" gap={8}>
        <Avatar.Group
          max={{
            count: maxVisibleUsers,
            style: {
              backgroundColor: 'var(--nri-color-purple)',
              border: '1px solid var(--nri-color-primary)',
              color: 'var(--nri-color-primary)',
              fontSize: '12px',
              fontWeight: 500,
            },
          }}
          size={32}
        >
          {sharedUsers.map(user => (
            <Avatar
              className={classNames(styles.avatar, styles.shared)}
              key={user.id}
              src={'picture' in user ? user.picture : undefined}
            >
              {user.name.charAt(0).toUpperCase()}
            </Avatar>
          ))}
        </Avatar.Group>

        {onAdd && (
          <IconButton
            bordered={false}
            className={styles.addButton}
            icon={<PlusIcon width={20} height={20} />}
            onClick={onAdd}
            shape="circle"
            type="dashed"
            size="small"
          />
        )}
      </Flex>
    </Flex>
  );
};
