import { useLocation } from "react-router-dom";

type Primitive = string | number | boolean;
export type QueryParams = Record<string, Primitive | Primitive[]>;

export const urlParameters = (params: QueryParams): string => {
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(val => searchParams.append(`${key}[]`, String(val)));
        } else {
            searchParams.append(key, String(value));
        }
    });

    return searchParams.toString();
};

export const useSearchParams = <T = QueryParams>(): T => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const params: QueryParams = {};

    query.forEach((value, key) => {
        // Check if key ends with []
        const arrayKeyMatch = key.match(/^(.*)\[\]$/);
        const cleanKey = arrayKeyMatch ? arrayKeyMatch[1] : key;

        // Split value by commas
        const values = value.split(',').map(v => v.trim()).filter(v => v !== '');

        // Determine if the key should be treated as an array
        const isArray = arrayKeyMatch || query.getAll(key).length > 1 || values.length > 1;

        if (isArray) {
            if (!params[cleanKey]) {
                params[cleanKey] = [];
            }

            if (Array.isArray(params[cleanKey])) {
                (params[cleanKey] as Primitive[]).push(...values.map(v => parseValue(v)));
            } else {
                params[cleanKey] = [parseValue(params[cleanKey] as string), ...values.map(v => parseValue(v))];
            }
        } else {
            params[cleanKey] = parseValue(value);
        }
    });

    // Remove duplicate entries if necessary
    Object.keys(params).forEach(key => {
        if (Array.isArray(params[key])) {
            params[key] = Array.from(new Set(params[key] as Primitive[]));
        }
    });

    return params as T
};

const parseValue = (value: string): Primitive => {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
    const number = Number(value);
    return isNaN(number) ? value : number;
};