import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useModal } from 'selectors/useModals';
import { ConfirmationModal } from 'components/common/ConfirmationModal';

export const ReplaceDataSourceCSV: React.FC = () => {
  const { hide } = useModal(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.REPLACE);

  const onConfirm = () => {};

  return (
    <ConfirmationModal
      key={'datasource::replace'}
      isOpen={true}
      title={'Replace CSV file'}
      description={
        <>Note: the connector is being replaced in conversations. // TODO Insert some CSV component upload here</>
      }
      onCancel={() => {
        hide();
      }}
      onConfirm={onConfirm}
      cancelButton={'Cancel'}
      confirmColor={'#E23C26'}
      confirmationButton={'Save'}
    />
  );
};
