import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';
import styles from 'ui/UIKit.module.scss';

type Props = Omit<ButtonProps, 'icon'> & { bordered?: boolean; icon: React.ReactNode };

/** Use this IconButton when using icons that aren't from @ant-design/icons */
export const IconButton: React.FC<Props> = ({ bordered = true, className, icon, ...props }) => {
  return (
    <Button
      className={classNames(styles.iconButton, className, {
        [styles.disabled]: props.disabled,
        [styles.bordered]: bordered,
      })}
      icon={<span className="anticon">{icon}</span>}
      {...props}
    />
  );
};
