// src/contexts/AuthContext.tsx
import React, { createContext, useReducer, useContext, PropsWithChildren, useEffect, memo } from 'react';
import { AuthAction, authReducer, AuthState } from '../reducers/authReducer';
import { NewtonApi } from '../utils/newtonApi';
import { AuthActionTypes } from '../types/actionTypes';
import { useAuth0 } from '@auth0/auth0-react';

interface AuthContextProps {
    state: AuthState;
    dispatch: React.Dispatch<AuthAction>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const initialAuthState: AuthState = {
    authenticated: false,
    loading: true,
    user: undefined,
};

export const AuthProvider: React.FC<PropsWithChildren> = memo(({ children }) => {
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
    const [state, dispatch] = useReducer(authReducer, initialAuthState);

    useEffect(() => {
        NewtonApi.fetchToken = getAccessTokenSilently;
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                dispatch({ type: AuthActionTypes.SET_LOADING, payload: true });
                await getAccessTokenSilently();
                const me: UserEntity = await NewtonApi.fetchUser();
                dispatch({ type: AuthActionTypes.SET_USER, payload: me });
                dispatch({ type: AuthActionTypes.SET_AUTHENTICATED, payload: true });
            } catch (error) {
                dispatch({ type: AuthActionTypes.SET_AUTHENTICATED, payload: false });
            } finally {
                dispatch({ type: AuthActionTypes.SET_LOADING, payload: false });
            }
        };

        if (isAuthenticated && !isLoading) {
            fetchUser();
        } else if (!isLoading) {
            dispatch({ type: AuthActionTypes.SET_AUTHENTICATED, payload: false });
            dispatch({ type: AuthActionTypes.SET_LOADING, payload: false });
        }
    }, [getAccessTokenSilently, isAuthenticated, isLoading]);

    return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
});

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};