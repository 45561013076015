import { Flex, Typography } from 'antd';
import { Message } from 'components/message/Message';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useActiveConversationFeedbacks, useConversationFeedbackMessages } from 'selectors/useFeedbacksSelector';
import { ConversationFeedbacksViewEnum } from 'types/enum';
import { findKeysInLocalStorage } from 'utils/findKeysInLocalStorage';

export const FeedbacksSummaryTab: React.FC<{ view: ConversationFeedbacksViewEnum }> = ({ view }) => {
  const { conversationId } = useParams();
  const { loading: isConversationFeedbacksLoading, conversation } = useActiveConversationFeedbacks();
  const { messages } = useConversationFeedbackMessages(conversation?.id);

  const ConversationWrapper = motion(Flex);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      findKeysInLocalStorage('feedback').forEach(key => {
        localStorage.removeItem(key);
      });
      findKeysInLocalStorage('message-set-code').forEach(key => {
        localStorage.removeItem(key);
      });
      findKeysInLocalStorage('message-set-log').forEach(key => {
        localStorage.removeItem(key);
      });
    };
  }, [conversationId]);

  const loading = !conversationId ? false : isConversationFeedbacksLoading;

  return (
    <ConversationWrapper
      animate={{ opacity: 1 }}
      gap="32px"
      transition={{ duration: 1 }}
      style={{ height: '100%' }}
      vertical
    >
      {messages.length ? (
        messages.map((msg: Message, idx: number) => (
          <Message index={idx} conversation={conversation!} message={msg} key={`${msg.id}-${idx}`} view={view} />
        ))
      ) : loading ? null : (
        <Flex align="center" justify="center" vertical style={{ height: '100%', textAlign: 'center' }}>
          <Typography.Title level={1}>
            You do not have messaging in this
            <br />
            conversation history yet.
          </Typography.Title>
        </Flex>
      )}
    </ConversationWrapper>
  );
};
