import { Actions } from 'types/actionTypes';
import { CommonStatusEnum, RetrieveTypesEnum } from 'types/enum';

type AgentScope = {
  agents: Map<Analyst['id'], Analyst>;
  pagination: PaginationType;
  status: CommonStatusEnum;
};

export interface AgentState {
  models: Model[];
  scope: {
    [RetrieveTypesEnum.ALL]: AgentScope;
    [RetrieveTypesEnum.FAVORITE]: AgentScope;
    [RetrieveTypesEnum.RECENT]: AgentScope;
  };
  status: CommonStatusEnum;
}

export const initialAgentState: AgentState = {
  models: [],
  scope: {
    [RetrieveTypesEnum.ALL]: {
      agents: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [RetrieveTypesEnum.FAVORITE]: {
      agents: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
    [RetrieveTypesEnum.RECENT]: {
      agents: new Map(),
      pagination: {
        next: null,
        previous: null,
        count: 0,
      },
      status: CommonStatusEnum.INITIAL,
    },
  },
  status: CommonStatusEnum.INITIAL,
};

export enum AgentActionTypes {
  DELETE_AGENT = 'DELETE_AGENT',
  FETCHING_AGENTS = 'FETCHING_AGENTS',
  FETCH_AGENTS = 'FETCH_AGENTS',
  FETCH_AGENT = 'FETCH_AGENT',
  FETCH_AGENT_MODELS = 'FETCH_AGENT_MODELS',
}

export type AgentActions =
  | { type: AgentActionTypes.DELETE_AGENT; payload: Analyst['id'] }
  | { type: AgentActionTypes.FETCHING_AGENTS }
  | {
      type: AgentActionTypes.FETCH_AGENTS;
      payload: {
        agents: Analyst[];
        pagination: PaginationType;
        scope: RetrieveTypesEnum;
      };
    }
  | { type: AgentActionTypes.FETCH_AGENT; payload: Analyst }
  | { type: AgentActionTypes.FETCH_AGENT_MODELS; payload: Model[] };

export const agentsReducer = (state: AgentState, action: Actions): AgentState => {
  switch (action.type) {
    case AgentActionTypes.DELETE_AGENT: {
      const agentId = action.payload;
      const allAgents = new Map(state.scope[RetrieveTypesEnum.ALL].agents);
      const favoriteAgents = new Map(state.scope[RetrieveTypesEnum.FAVORITE].agents);

      allAgents.delete(agentId);
      favoriteAgents.delete(agentId);

      return {
        ...state,
        scope: {
          ...state.scope,
          [RetrieveTypesEnum.ALL]: {
            ...state.scope[RetrieveTypesEnum.ALL],
            agents: allAgents,
          },
          [RetrieveTypesEnum.FAVORITE]: {
            ...state.scope[RetrieveTypesEnum.FAVORITE],
            agents: favoriteAgents,
          },
        },
      };
    }
    case AgentActionTypes.FETCHING_AGENTS:
      return { ...state, status: CommonStatusEnum.FETCHING };

    case AgentActionTypes.FETCH_AGENTS: {
      const { agents, pagination, scope } = action.payload;

      return {
        ...state,
        scope: {
          ...state.scope,
          [scope]: {
            agents: new Map(agents.map(agent => [agent.id, agent])),
            pagination,
            status: CommonStatusEnum.FETCHED,
          },
        },
        status: CommonStatusEnum.FETCHED,
      };
    }

    case AgentActionTypes.FETCH_AGENT: {
      const agent = action.payload;
      const favoriteAgents = new Map(state.scope[RetrieveTypesEnum.FAVORITE].agents);

      if (agent.isFavorite) {
        favoriteAgents.set(agent.id, agent);
      } else {
        favoriteAgents.delete(agent.id);
      }

      return {
        ...state,
        scope: {
          ...state.scope,
          [RetrieveTypesEnum.ALL]: {
            ...state.scope[RetrieveTypesEnum.ALL],
            agents: new Map([...state.scope[RetrieveTypesEnum.ALL].agents, [agent.id, agent]]),
          },
          [RetrieveTypesEnum.FAVORITE]: {
            ...state.scope[RetrieveTypesEnum.FAVORITE],
            agents: favoriteAgents,
          },
        },
        status: CommonStatusEnum.FETCHED,
      };
    }

    case AgentActionTypes.FETCH_AGENT_MODELS: {
      const models = action.payload;
      return {
        ...state,
        models,
      };
    }

    default:
      return state;
  }
};
