import { Flex, Form, FormInstance, Typography, Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FC } from 'react';
import { InputSimple } from 'ui/Input';
import styles from './ProjectForm.module.scss';
import { Select } from 'ui/Select';
import { projectColors } from 'utils/projectColors';

import { LabelInValueType } from 'rc-select/lib/Select';
import { useNavigate, useParams } from 'react-router-dom';

type ProjectFormProps = {
  form?: FormInstance;
  project?: ProjectLabel;
  editable?: boolean;
};

type RenderedOptionProps = {
  option: LabelInValueType;
};

export const RenderedOption: FC<RenderedOptionProps> = ({ option }) => (
  <Flex gap={8}>
    <div className={styles.colorBox} style={{ background: option.value }} />
    <div className={styles.colorLabel}>{option.label}</div>
  </Flex>
);

export const ProjectForm: FC<ProjectFormProps> = ({ form, project, editable = true }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const hasForm = !!form;

  const initialValues = {
    name: project?.name ?? '',
    description: project?.description ?? '',
    color: project?.color ?? '',
  };

  const formValidations = {
    name: {
      required: true,
      message: 'Please enter a valid name',
    },

    description: {
      required: true,
      message: 'Please enter a valid description',
    },
    color: {
      required: true,
      message: 'Please enter a valid color',
    },
  };

  const content = (
    <Flex vertical gap={32}>
      <Form.Item
        className={styles.formItem}
        name="name"
        label="Name"
        rules={editable ? [formValidations['name']] : undefined}
        validateTrigger={['onChange', 'onBlur']}
      >
        {editable ? (
          <InputSimple type="text" maxLength={80} placeholder="Enter name" />
        ) : (
          <Typography.Text>{project?.name}</Typography.Text>
        )}
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name="description"
        rules={editable ? [formValidations['description']] : undefined}
        validateTrigger={['onChange', 'onBlur']}
        label="Project description"
      >
        {editable ? (
          <TextArea placeholder="Enter description" maxLength={500} autoSize={{ minRows: 3, maxRows: 3 }} rows={3} />
        ) : (
          <Typography.Text>{project?.description}</Typography.Text>
        )}
      </Form.Item>
      <Form.Item
        className={styles.formItem}
        name="color"
        rules={editable ? [formValidations['color']] : undefined}
        validateTrigger={['onChange', 'onBlur']}
        label="Color"
      >
        {editable ? (
          <Select
            labelRender={option =>
              option.value ? (
                <RenderedOption option={option as LabelInValueType} />
              ) : (
                <Typography.Text className={styles.placeholder}>Select color for your project</Typography.Text>
              )
            }
            options={projectColors}
            optionRender={option => <RenderedOption option={option as LabelInValueType} />}
          />
        ) : (
          <RenderedOption
            option={{
              value: project?.color ?? '',
              label: projectColors.find(c => c.value === project?.color)?.label ?? '',
            }}
          />
        )}
      </Form.Item>

      {!editable && (
        <Form.Item>
          <Button type="primary" onClick={() => navigate(`/projects/${projectId}/edit`)}>
            Edit Project
          </Button>
        </Form.Item>
      )}
    </Flex>
  );

  return hasForm ? (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      name="control-hooks"
      requiredMark={editable ? false : undefined}
    >
      {content}
    </Form>
  ) : (
    content
  );
};
