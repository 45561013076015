import ViewIcon from 'assets/iconsNewVersion/eye.svg?react';
import EditIcon from 'assets/iconsNewVersion/edit.svg?react';
import DownlaodIcon from 'assets/iconsNewVersion/download.svg?react';
import ChatIcon from 'assets/iconsNewVersion/message_chat.svg?react';
import ShareHandIcon from 'assets/iconsNewVersion/account.svg?react';
import ArchiveIcon from 'assets/iconsNewVersion/archive.svg?react';
import DeleteIcon from 'assets/iconsNewVersion/trash.svg?react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'selectors/useAuthSelector';
import { ActionsMenu } from '../../ui/ActionsMenu';
import { urlParameters } from 'hooks/useURLParams';
import { useModal } from 'selectors/useModals';
import { ModalScopesEnum, ModalTypesEnum } from 'reducers/modalsReducer';
import { useDownloadDataSource } from 'selectors/useDataSourcesSelector';
import { DatasourceTypesEnum } from 'types/enum';
import { ActionItem } from '../../ui/ActionsMenu';

enum DataSourceActions {
  ARCHIVE = 'ARCHIVE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DOWNLOAD = 'DOWNLOAD',
  MANAGE_ACCESS = 'MANAGE_ACCESS',
  NEW_CONVERSATION = 'NEW_CONVERSATION',
  REPLACE_CSV = 'REPLACE_CSV',
  VIEW_DETAILS = 'VIEW_DETAILS',
}

const items: ActionItem<DataSource>[] = [
  {
    color: 'var(--nri-color-primary)',
    iconColor: 'var(--nri-color-purple-grey)',
    icon: ViewIcon,
    label: 'View Details',
    key: DataSourceActions.VIEW_DETAILS,
  },
  {
    color: 'var(--nri-color-primary)',
    iconColor: 'var(--nri-color-purple-grey)',
    icon: EditIcon,
    label: 'Edit',
    key: DataSourceActions.EDIT,
    filter: (e: DataSource, u: UserEntity) => e.owner === u.id,
  },

  {
    color: 'var(--nri-color-primary)',
    iconColor: 'var(--nri-color-purple-grey)',
    icon: DownlaodIcon,
    label: 'Download',
    key: DataSourceActions.DOWNLOAD,
    filter: (e: DataSource) => (e.hasFile ? e.type?.includes(DatasourceTypesEnum.CSV) : false),
  },
  {
    color: 'var(--nri-color-primary)',
    iconColor: 'var(--nri-color-purple-grey)',
    icon: ChatIcon,
    label: 'Start new conversation',
    key: DataSourceActions.NEW_CONVERSATION,
  },
  {
    color: 'var(--nri-color-primary)',
    iconColor: 'var(--nri-color-purple-grey)',
    icon: ShareHandIcon,
    label: 'Manage access',
    key: DataSourceActions.MANAGE_ACCESS,
    filter: (e: DataSource, u: UserEntity) => e.owner === u.id,
  },
  {
    color: 'var(--nri-color-primary)',
    iconColor: 'var(--nri-color-purple-grey)',
    icon: ArchiveIcon,
    label: 'Archive',
    key: DataSourceActions.ARCHIVE,
    filter: (e: DataSource) => e.type?.includes(DatasourceTypesEnum.CSV),
  },
  {
    color: 'var(--nri-color-red)',
    iconColor: 'var(--nri-color-red)',
    icon: DeleteIcon,
    label: 'Delete',
    key: DataSourceActions.DELETE,
    filter: (e: DataSource) => e.isDeletable ?? false,
  },
];

export const DataSourceActionsMenu: React.FC<{ dataSource: DataSource }> = ({ dataSource }) => {
  const downloadDataSource = useDownloadDataSource();
  const { show: showArchiveModal } = useModal(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.ARCHIVE);
  const { show: showDeleteModal } = useModal(ModalScopesEnum.DATA_SOURCES, ModalTypesEnum.DELETE);

  const { me } = useAuth();
  const navigate = useNavigate();
  const onActionClick = (key: DataSourceActions) => {
    switch (key) {
      case DataSourceActions.ARCHIVE:
        showArchiveModal(dataSource);
        break;

      case DataSourceActions.DELETE:
        showDeleteModal(dataSource);
        break;

      case DataSourceActions.DOWNLOAD:
        downloadDataSource(dataSource.id, dataSource.name);
        break;

      case DataSourceActions.EDIT:
        navigate(`/datasource/${dataSource.id}`);
        break;

      case DataSourceActions.MANAGE_ACCESS:
        navigate(`/datasource/${dataSource.id}`);
        break;

      case DataSourceActions.NEW_CONVERSATION: {
        const urlParams = urlParameters({
          dataSources: [dataSource.id],
        });

        navigate(`/conversations/new?${urlParams}`);
        break;
      }

      case DataSourceActions.VIEW_DETAILS:
        navigate(`/datasource/${dataSource.id}`);
        break;
    }
  };

  const filteredItems = items.filter(item => {
    if (item.filter) {
      return item.filter(dataSource, me!);
    }
    return true;
  });

  return <ActionsMenu items={filteredItems} onSelect={({ key }) => onActionClick(key as DataSourceActions)} />;
};
