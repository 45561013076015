import { Collapse as AntCollapse, CollapseProps } from 'antd';
import classNames from 'classnames';
import styles from 'ui/UIKit.module.scss';

type Props = CollapseProps & {
  /**
   * There are currently two variants in the UI Kit. The first is used in the DisplayObject component and
   * the second is used in the WorkflowForm component. Should try to consolidate these into one variant.
   */
  variant?: 'secondary';
};

export const Collapse: React.FC<Props> = ({ className, variant, ...props }) => (
  <AntCollapse
    className={classNames(styles.collapse, className, { [styles.secondary]: variant === 'secondary' })}
    {...props}
  />
);
