import { Segmented as AntSegmented, SegmentedProps } from "antd";
import classNames from "classnames";
import styles from "ui/UIKit.module.scss";

export const Segmented: React.FC<SegmentedProps> = ({
  className,
  ...props
}) => {
  return (
    <AntSegmented
      className={classNames(styles.segmented, className)}
      {...props}
    />
  );
};
