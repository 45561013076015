import { Flex, Avatar } from 'antd';
import { FC } from 'react';
import { useSharedEntities } from 'selectors/useSharedEntitiesSelector';
import { SharedEntityTypes } from 'types/enum';

export const ShareList: FC<{ owner: UserId }> = ({ owner }) => {
  const userList = useSharedEntities().sharedEntities.filter(e => e.type === SharedEntityTypes.USER) as UserEntity[];
  const user = userList.find(u => u.id === owner);
  if (!user) return null;
  return (
    <Flex gap={8} align="center">
      <Avatar src={user?.picture || null} size={32} style={{ minWidth: 32 }} />
    </Flex>
  );
};
