import { Flex } from 'antd';
import { AgentActionsMenu } from 'components/agent-actions-menu/AgentActionsMenu';
import { EntityCard } from 'components/entity-list/EntityCard';
import { BaseEntityCardProps } from 'components/entity-list/EntityList';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui/Button';
import { highlightText } from 'utils/highlightText';

type AgentCardProps = BaseEntityCardProps<Analyst> & {
  showFavorite?: boolean;
  showOwner?: boolean;
};

export const AgentCard = ({ entity: agent, search = '' }: AgentCardProps) => {
  const { name, description } = agent;
  const navigate = useNavigate();

  const viewDetails = useCallback(() => {
    navigate(`/agents/${agent.id}`);
  }, [navigate, agent.id]);

  const handleCardClick = viewDetails;

  return (
    <EntityCard
      actionsMenu={<AgentActionsMenu agent={agent} />}
      description={highlightText(description, search)}
      descriptionLines={2}
      footer={
        <>
          <Flex gap="8px" align="center" justify="flex-start" wrap="wrap">
            <div /> {/* <ProjectLabels projectLabels={agent.projectLabels ?? []} /> */}
          </Flex>
          <Button
            onClick={e => {
              e.stopPropagation();
              viewDetails();
            }}
            type="text"
          >
            View details
          </Button>
        </>
      }
      height={227}
      onClick={handleCardClick}
      title={highlightText(name, search)}
      titleLines={2}
    />
  );
};
