import { DownOutlined } from '@ant-design/icons';
import { Flex, MenuProps } from 'antd';
import Dropdown from 'antd/es/dropdown/dropdown';
import { useMemo, useEffect } from 'react';
import styles from './SelectAnalystsDropdown.module.scss';
import { useAnalysts } from 'selectors/useAnalystsSelector';
import { useActiveConversation, useConversationMessages } from 'selectors/useConversationSelectors';

export const SelectAnalystDropdown: React.FC = () => {
  const { conversation } = useActiveConversation();
  const { saveTempData, temporary } = useConversationMessages(conversation?.id);
  const { analysts, defaultAnalyst, loading } = useAnalysts();

  // Get the last used analyst from the conversation's message set
  const lastUsedAnalyst = useMemo(() => {
    const messageSet = conversation?.messageSet || [];
    const lastAnalystName = messageSet[messageSet.length - 1]?.analyst;
    return analysts.find(a => a.name === lastAnalystName);
  }, [analysts, conversation?.messageSet]);

  // Use the temporary analyst, fall back to last used, then default
  const analyst = useMemo(() => {
    return temporary?.analyst || lastUsedAnalyst || defaultAnalyst;
  }, [temporary?.analyst, lastUsedAnalyst, defaultAnalyst]);

  // Reset selection when conversation changes
  useEffect(() => {
    if (lastUsedAnalyst) {
      saveTempData({ analyst: lastUsedAnalyst });
    }
  }, [conversation?.id, lastUsedAnalyst, saveTempData]);

  const items = useMemo<MenuProps['items']>(
    () => [
      {
        type: 'group',
        label: 'Agents:',
        className: styles.header,
      },
      ...analysts.map(a => ({
        label: a.name,
        key: `${a.id}`,
        className: styles.menuItem,
        onClick: () => {
          saveTempData({ analyst: a });
        },
      })),
    ],
    [analysts, saveTempData],
  );

  if (loading) return null;

  return (
    <>
      <Dropdown
        className={styles.dropdown}
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [`${analyst?.id}`],
        }}
        trigger={['click']}
      >
        <Flex className={styles.selected}>
          {analyst?.name}
          <DownOutlined className={styles.chevron} />
        </Flex>
      </Dropdown>
    </>
  );
};
