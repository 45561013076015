import Authorizing from 'components/auth/Authorizing';
import ProtectedRoute from 'components/auth/Protect';
import Login from 'components/login/Login';
import { createBrowserRouter } from 'react-router-dom';
import { AccountPage } from 'routes/AccountPage';
import { AgentPage } from 'routes/AgentPage';
import { AgentsPage } from 'routes/AgentsPage';
import { BlueprintLibraryPage } from 'routes/BlueprintLibraryPage';
import { BlueprintPage } from 'routes/BlueprintPage';
import { BlueprintsPage } from 'routes/BlueprintsPage';
import { ComponentsPage } from 'routes/ComponentsPage';
import { ConversationPage } from 'routes/ConversationPage';
import { DataSourcePage } from 'routes/DataSourcePage';
import { DataSourcesPage } from 'routes/DatasSourcesPage';
import { ErrorPage } from 'routes/ErrorPage';
import { FeedbacksViewPage } from 'routes/FeedbacksViewPage';
import { HomePage } from 'routes/HomePage';
import { MemoriesPage } from 'routes/MemoriesPage';
import { NewAgentPage } from 'routes/NewAgentPage';
import { NewBlueprintPage } from 'routes/NewBlueprintPage';
import { NewConversationPage } from 'routes/NewConversationPage';
import { ProjectPage } from 'routes/ProjectPage';
import { ProjectsPage } from 'routes/ProjectsPage';
import { App } from './App';

export const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      {
        element: <App />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'components/',
            element: <ComponentsPage />,
          },
          {
            path: 'blueprint/library',
            element: (
              <ProtectedRoute>
                <BlueprintLibraryPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'blueprints',
            element: (
              <ProtectedRoute>
                <BlueprintsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'blueprints/new',
            element: (
              <ProtectedRoute>
                <NewBlueprintPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'blueprints/:blueprintId',
            element: (
              <ProtectedRoute>
                <BlueprintPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'blueprints/:blueprintId/edit',
            element: (
              <ProtectedRoute>
                <BlueprintPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'conversations/',
            element: (
              <ProtectedRoute>
                <ConversationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'conversations/new',
            element: (
              <ProtectedRoute>
                <NewConversationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'conversations/:conversationId',
            element: (
              <ProtectedRoute>
                <ConversationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'memories',
            element: (
              <ProtectedRoute>
                <MemoriesPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'datasources',
            element: (
              <ProtectedRoute>
                <DataSourcesPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'datasource/:dataSourceId',
            element: (
              <ProtectedRoute>
                <DataSourcePage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'datasource/:dataSourceId',
            element: (
              <ProtectedRoute>
                <DataSourcePage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'datasource/:dataSourceId/edit',
            element: (
              <ProtectedRoute>
                <DataSourcePage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'projects',
            element: (
              <ProtectedRoute>
                <ProjectsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'projects/:projectId',
            element: (
              <ProtectedRoute>
                <ProjectPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'projects/:projectId/edit',
            element: (
              <ProtectedRoute>
                <ProjectPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'memories/feedbacks/:conversationId',
            element: (
              <ProtectedRoute>
                <FeedbacksViewPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'account',
            element: (
              <ProtectedRoute onlySuperUser={true}>
                <AccountPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'agents',
            element: (
              <ProtectedRoute onlySuperUser={true}>
                <AgentsPage />
              </ProtectedRoute>
            ),
          },

          {
            path: 'agents/new',
            element: (
              <ProtectedRoute onlySuperUser={true}>
                <NewAgentPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'agents/:agentId/:conversation?/:conversationId?',
            element: (
              <ProtectedRoute onlySuperUser={true}>
                <AgentPage edit={false} />
              </ProtectedRoute>
            ),
          },
          {
            path: 'agents/:agentId/edit/:conversation?/:conversationId?',
            element: (
              <ProtectedRoute onlySuperUser={true}>
                <AgentPage edit />
              </ProtectedRoute>
            ),
          },
          // {
          //   path: "data-config/",
          //   element: (
          //     <ProtectedRoute>
          //       <ConfigureDataPage />
          //     </ProtectedRoute>
          //   ),
          // },
        ],
      },
      {
        path: 'unauthorized',
        element: <ErrorPage type="unauthorized" />,
      },

      {
        path: 'login/',
        element: <Login />,
      },
      {
        path: 'authorizing/',
        element: <Authorizing />,
      },
    ],
  },
]);
